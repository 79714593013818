import React,  { useEffect, useState }from 'react';
import { useSelector } from 'react-redux';

import { Card, CardBody, CardHeader }  from '../../../../components/card/card';

import { getDerechohabientesById, updateDerechohabientesById } from '../../../../api/derechohabiente/derechohabiente';
import { getDireccionById, getDireccionSepomexByCp, updateDireccionById } from '../../../../api/direcciones/direcciones';
import { getProyectoBancoById } from '../../../../api/proyectos/proyectos';

import { deleteKeyNull, errorNotification, formatoMoneda, successNotification } from '../../../../helpers';

import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

function ModalDerechohabiente({ sharedDerechohabienteId, proyecto_id, paquete_id, onSuccessDerechohabiente, onCloseModal }) {
    const token = useSelector(state => state.authentication.user?.token);

    const [action, setAction] = useState("");
    const [formDerechohabiente, setFormDerechohabiente] = useState({});
    const [formDireccion, setFormDireccion] = useState({});
    const [colonias, setColonias] = useState([]);

    useEffect(() => {
        if(sharedDerechohabienteId !== null && token !== undefined)
            if(sharedDerechohabienteId === 0) 
                setAction("Registrar");
            else {
                setAction("Editar");
                fetchDerechohabienteById();            
            }
    }, [sharedDerechohabienteId, token]);


    const fetchDerechohabienteById = async() => {
        try {
			const response = await getDerechohabientesById(sharedDerechohabienteId, token);
            setFormDerechohabiente(response);
            fetchDireccionById(response.direccion_id)
            if(response.clabe_cuenta === null) 
                fetchProyectoBancoById();
		} catch (e) {
			errorNotification("Ocurrio un error en el servidor.");
		}
    }


    const fetchProyectoBancoById = async() => {
        try {
			const response = await getProyectoBancoById(proyecto_id, token);
            setFormDerechohabiente((prevForm) => ({
                ...prevForm,
                clabe_cuenta: response.nombre + " / " + response.cuenta,
            }));
		} catch (e) {
			errorNotification("Ocurrio un error en el servidor.");
		}
    }


    const fetchDireccionById = async(id) => {
        try {
			const response = await getDireccionById(id, token);
			setFormDireccion({
                "calle": response.calle === null ? "" : response.calle,
                "colonia": response.colonia === null ? "" : response.colonia,
                "cp": response.cp === null ? "" : response.cp,
                "estado": response.estado === null ? "" : response.estado,
                "id": response.id === null ? "" : response.id,
                "lote": response.lote === null ? "" : response.lote,
                "manzana": response.manzana === null ? "" : response.manzana,
                "municipio": response.municipio === null ? "" : response.municipio,
                "num_exterior": response.num_exterior === null ? "" : response.num_exterior,
                "num_interior": response.num_interior === null ? "" : response.num_interior 
            })
            if(response.cp !== '') {
                if( response.cp !== null) {
                    fetchDireccionSepomexByCp(response.cp)
                }
            } 
		} catch (e) {
			errorNotification("Ocurrio un error en el servidor.");
		}
    }


    const fetchUpdateDireccionById = async() => {
        try {
			const response = await updateDireccionById(formDerechohabiente.direccion_id, deleteKeyNull(formDireccion), token);
            if(response.message === "success") {
                successNotification("Derechohabiente actualizado correctamente.");
                onSuccessDerechohabiente(paquete_id);
                handleOnCloseModal();
            }
            else if(response.message === "failed")
				errorNotification("No es posible actualizar el derechohabiente.");
		} catch (e) {
			errorNotification("Ocurrio un error en el servidor.");
		}
    }


    const fetchUpdateDerechohabienteById = async() => {
        try {
            const response = await updateDerechohabientesById(formDerechohabiente.id, deleteKeyNull(formDerechohabiente), token);
            if(response.message === "success")
                fetchUpdateDireccionById();
            else if(response.message === "failed")
				errorNotification("No es posible actualizar el derechohabiente.");
            
		} catch (e) {
			errorNotification("Ocurrio un error en el servidor.");
		}
    }

    
    const fetchDireccionSepomexByCp = async(cp) => {
        try {
            const response = await getDireccionSepomexByCp(cp, token);
            if(response.status === 200) {
                setFormDireccion(prevFormDireccion => ({
                    ...prevFormDireccion,
                    estado: response.data.codigo_postal.estado,
                    municipio: response.data.codigo_postal.municipio,
                    cp: cp
                }));
                setColonias(response.data.codigo_postal.colonias)
            } 
        } catch (error) {
            console.log("Ocurrio un error en el servidor.", error);
        }
	}

    
    const handleChangeFormDerechohabiente = (event) => {
        try {
            const { name, value } = event.target;
            if (name === "valor_vivienda" || name === "monto_deposito" || name === "monto_ministracion" ||  name === "monto_subsidio") {

                const unformattedValue = value.replace(/\$|,/g, "");
                const parsedValue = parseFloat(unformattedValue);
                
                //if(parsedValue == 0)
                //    parsedValue = 0.00;
                console.log(parsedValue)
                setFormDerechohabiente((prevForm) => ({
                    ...prevForm,
                    [name]: parsedValue || "",
                }));
            } else {
                setFormDerechohabiente((prevForm) => ({
                    ...prevForm,
                    [name]: value,
                }));
            }
        } catch(e) {
            const fechaMoment = event; 
            const fechaString = fechaMoment.format('YYYY-MM-DD');
            setFormDerechohabiente((prevForm) => ({
                ...prevForm,
                fecha_firma: fechaString
            }));
        }
	};

    
    const handleChangeFormDireccion = (event) => {
		const { name, value } = event.target;
        if (name === 'cp') {
			setFormDireccion((prevForm) => ({
				...prevForm,
				[name]: value,
			}));

			if (/^\d{5}$/.test(value)) {
				fetchDireccionSepomexByCp(value);
			} else {
				if(value.length === 5)
					errorNotification('El código postal debe contener 5 dígitos numéricos.');
			}
		} else {
            setFormDireccion(prevFormDireccion => ({
                ...prevFormDireccion,
                [name]: value,
            }));
        }	
	};

    
    const handleActionDerechohabiente = async (e) => {    
		e.preventDefault(); 
		if (e.target.form.checkValidity()) {
			if(action === "Registrar") {
				console.log("Nuevo", formDerechohabiente, formDireccion)
			} else {
				fetchUpdateDerechohabienteById();
			}
		} else {
			errorNotification("Faltan campos requeridos.")
		}
	}

    
    const cleanForm = () => {
        setColonias([]);
        setFormDerechohabiente({});
        setFormDireccion({});
    }

    const handleOnCloseModal = () => {
        document.getElementById('modalDerechohabiente').classList.remove('show');
		document.querySelector('.modal-backdrop').remove();
        document.getElementById('modalDerechohabiente').style.display = 'none';

        cleanForm();
        
        onCloseModal();
    }

    return(
        
			<div className="modal modal-cover fade" id="modalDerechohabiente" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                    <h5 className="modal-title">{action} Derechohabiente</h5>
                        <button type="button" className="btn-close" onClick={handleOnCloseModal}></button>
                    </div>
                    <div className="modal-body">
                        <form id="formDerechohabiente" className="was-validated" >
                            <Card>
                                <CardHeader className="fw-bold small d-flex">
                                    <span className="flex-grow-1">Información de Derechohabiente</span>
                                </CardHeader>
                                <CardBody>
                                    <div className="col-xl-12">
                                        <div className='row'>
                                            <div className='col-4'>
                                                <div className="form-group mb-3">
                                                    <label className="form-label" htmlFor="proyectos" ><strong>Nombre</strong></label>
                                                    <input className="form-control" placeholder='Nombre'
                                                        id="nombres"
                                                        name="nombres"
                                                        value={formDerechohabiente.nombres}
                                                        onChange={handleChangeFormDerechohabiente}
                                                        required
                                                        pattern=".*\S+.*" />
                                                    <div className="invalid-feedback" id="validationNoInterior">
                                                        Campo requerido
                                                    </div>	    
                                                </div>
                                            </div>
                                            <div className='col-4'>
                                                <div className="form-group mb-3">
                                                    <label className="form-label" htmlFor="etapas" ><strong>Apellido Paterno</strong></label>
                                                    <input className="form-control" placeholder='Apellido Paterno'
                                                        id="apellido_paterno"
                                                        name="apellido_paterno"
                                                        value={formDerechohabiente.apellido_paterno}
                                                        onChange={handleChangeFormDerechohabiente}
                                                        required
                                                        pattern=".*\S+.*" />
                                                    <div className="invalid-feedback" id="validationNoInterior">
                                                        Campo requerido
                                                    </div>	    
                                                </div>
                                            </div>
                                            <div className='col-4'>
                                                <div className="form-group mb-3">
                                                    <label className="form-label" htmlFor="paquetes" ><strong>Apellido Materno</strong></label>
                                                    <input className="form-control" placeholder='Apellido Materno'
                                                        id="apellido_materno"
                                                        name="apellido_materno"
                                                        value={formDerechohabiente.apellido_materno}
                                                        onChange={handleChangeFormDerechohabiente}
                                                        pattern=".*\S+.*" />	    
                                                </div>
                                            </div>
                                        </div>	
                                        <div className='row'>
                                            <div className='col-4'>
                                                <div className="form-group mb-3">
                                                    <label className="form-label" htmlFor="proyectos" ><strong>CURP</strong></label>
                                                    <input className="form-control" placeholder='CURP'
                                                        id="curp"
                                                        name="curp"
                                                        value={formDerechohabiente.curp}
                                                        onChange={handleChangeFormDerechohabiente}
                                                        required
                                                        pattern=".*\S+.*" />
                                                    <div className="invalid-feedback" id="validationNoInterior">
                                                        Campo requerido
                                                    </div>	    
                                                </div>
                                            </div>
                                            <div className='col-4'>
                                                <div className="form-group mb-3">
                                                    <label className="form-label" htmlFor="etapas" ><strong>Número de Seguro Social</strong></label>
                                                    <input className="form-control" placeholder='Número de Seguro Social'
                                                        id="num_ssocial"
                                                        name="num_ssocial"
                                                        value={formDerechohabiente.num_ssocial}
                                                        onChange={handleChangeFormDerechohabiente}
                                                        required
                                                        pattern=".*\S+.*" />
                                                    <div className="invalid-feedback" id="validationNoInterior">
                                                        Campo requerido
                                                    </div>	    
                                                </div>
                                            </div>
                                            <div className='col-4'>
                                                <div className="form-group mb-3">
                                                    <label className="form-label" htmlFor="paquetes" ><strong>Número de Crédito</strong></label>
                                                    <input className="form-control" placeholder='Número de Crédito'
                                                        id="num_credito"
                                                        name="num_credito"
                                                        value={formDerechohabiente.num_credito}
                                                        onChange={handleChangeFormDerechohabiente}
                                                        required
                                                        pattern=".*\S+.*" />
                                                    <div className="invalid-feedback" id="validationNoInterior">
                                                        Campo requerido
                                                    </div>	    
                                                </div>
                                            </div>
                                        </div>	
                                        <div className='row'>
                                            <div className='col-4'>
                                                <div className="form-group mb-3">
                                                    <label className="form-label" htmlFor="proyectos" ><strong>Teléfono Fijo</strong></label>
                                                    <input className="form-control" placeholder='Teléfono Fijo'
                                                        id="telefono"
                                                        name="telefono"
                                                        value={formDerechohabiente.telefono}
                                                        onChange={handleChangeFormDerechohabiente}
                                                        pattern=".*\S+.*" />
                                                    <div className="invalid-feedback" id="validationNoInterior">
                                                        Campo requerido
                                                    </div>	    
                                                </div>
                                            </div>
                                            <div className='col-4'>
                                                <div className="form-group mb-3">
                                                    <label className="form-label" htmlFor="etapas" ><strong>Telefono Celular</strong></label>
                                                    <input className="form-control" placeholder='Telefono Celular'
                                                        id="celular"
                                                        name="celular"
                                                        value={formDerechohabiente.celular}
                                                        onChange={handleChangeFormDerechohabiente}
                                                        pattern=".*\S+.*" />
                                                    <div className="invalid-feedback" id="validationNoInterior">
                                                        Campo requerido
                                                    </div>	    
                                                </div>
                                            </div>
                                            <div className='col-4'>
                                                <div className="form-group mb-3">
                                                    <label className="form-label" htmlFor="paquetes" ><strong>Email</strong></label>
                                                    <input className="form-control" placeholder='Email'
                                                        id="email"
                                                        name="email"
                                                        value={formDerechohabiente.email}
                                                        onChange={handleChangeFormDerechohabiente}
                                                        pattern=".*\S+.*" />
                                                    <div className="invalid-feedback" id="validationNoInterior">
                                                        Campo requerido
                                                    </div>	    
                                                </div>
                                            </div>
                                        </div>	
                                    </div>
                                </CardBody>
                            </Card>
                            <br/>
                            <br/>
                            <Card>
                                <CardHeader className="fw-bold small d-flex">
                                    <span className="flex-grow-1">Instrucción notarial (Datos físicos de la vivienda)</span>
                                </CardHeader>
                                <CardBody>
                                    <div className="col-xl-12">
                                        
                                        <div className="row">	
                                            
                                            <div className="col-xl-6">
                                                    <label className="form-label">Dirección</label>
                                                    <input className="form-control"  placeholder='Dirección'
                                                        id="calle"
                                                        name="calle"
                                                        value={formDireccion.calle}
                                                        onChange={handleChangeFormDireccion}
                                                        required
                                                        pattern=".*\S+.*" />
                                                    <div className="invalid-feedback" id="validationDireccion">
                                                        Campo requerido
                                                    </div>	
                                            </div>
                                            <div className="col-xl-2">
                                                    <label className="form-label" htmlFor="exampleFormControlTextarea1">No. Interior</label>
                                                    <input className="form-control"  placeholder='No. Interior'
                                                        id="num_interior"
                                                        name="num_interior"
                                                        value={formDireccion.num_interior}
                                                        onChange={handleChangeFormDireccion}
                                                        
                                                        />
                                                                                                        
                                            </div>
                                            <div className="col-xl-2">
                                                    <label className="form-label" htmlFor="exampleFormControlTextarea1">No. Exterior</label>
                                                    <input className="form-control"  placeholder='No. Exterior'
                                                        id="num_exterior"
                                                        name="num_exterior"
                                                        value={formDireccion.num_exterior}
                                                        onChange={handleChangeFormDireccion}
                                                         />
                                                            
                                                
                                            </div>

                                            <div className="col-xl-2">
                                                <div className="form-group mb-3">
                                                    <label className="form-label" htmlFor="cp">C.P.</label>
                                                    <input className="form-control"  placeholder='C.P,'
                                                        id="cp"
                                                        name="cp"
                                                        value={formDireccion.cp}
                                                        onChange={handleChangeFormDireccion}
                                                        pattern=".*\S+.*" />
                                                    <div className="invalid-feedback" id="validationCP">
                                                        Campo requerido
                                                    </div>	    
                                                </div>
                                            </div>

                                            <div className="col-xl-4">
                                                <div className="form-group mb-3">
                                                    <label className="form-label" htmlFor="exampleFormControlInput1">Colonia</label>
                                                    <select className="form-select" onChange={handleChangeFormDireccion} value={formDireccion.colonia} id='colonia' name='colonia' >
                                                        <option value="">Selecciona una colonia</option>
                                                        {colonias.map((colonia, key) => (
                                                            <option key={key} value={colonia}>
                                                                {colonia}
                                                            </option>
                                                        ))}
														</select>
                                                    <div className="invalid-feedback" id="validationNoInterior">
                                                        Campo requerido
                                                    </div>	    
                                                </div>
                                            </div>
                                            <div className="col-xl-4">
                                                <div className="form-group mb-3">
                                                    <label className="form-label" htmlFor="exampleFormControlTextarea1">Municipio</label>
                                                    <input className="form-control" placeholder='Municipio'
                                                        id="municipio"
                                                        name="municipio"
                                                        value={formDireccion.municipio}
                                                        onChange={handleChangeFormDireccion}
                                                        required
                                                        disabled={true}
                                                        pattern=".*\S+.*" />
                                                    <div className="invalid-feedback" id="validationNoInterior">
                                                        Campo requerido
                                                    </div>	    
                                                </div>
                                            </div>
                                            <div className="col-xl-4">
                                                <div className="form-group mb-3">
                                                    <label className="form-label" htmlFor="exampleFormControlTextarea1">Estado</label>
                                                    <input className="form-control" placeholder='Estado'
                                                        id="estado"
                                                        name="estado"
                                                        value={formDireccion.estado}
                                                        onChange={handleChangeFormDireccion}
                                                        required
                                                        disabled={true}
                                                        pattern=".*\S+.*" />
                                                    <div className="invalid-feedback" id="validationNoInterior">
                                                        Campo requerido
                                                    </div>	    
                                                </div>
                                            </div>
                                            
                                        </div>

                                        <div className='row'>
                                            <div className='col-4'>
                                                <div className="form-group mb-3">
                                                    <label className="form-label" htmlFor="proyectos" ><strong>Monto de Depósito</strong></label>
                                                    <input className="form-control" placeholder='Monto de Depósit'
                                                        id="monto_deposito"
                                                        name="monto_deposito"
                                                        value={formatoMoneda.format(parseFloat(formDerechohabiente.monto_deposito || 0.00))}
                                                        onChange={handleChangeFormDerechohabiente}
                                                        required
                                                        pattern=".*\S+.*" />
                                                    <div className="invalid-feedback" id="validationNoInterior">
                                                        Campo requerido
                                                    </div>	    
                                                </div>
                                            </div>
                                            <div className='col-4'>
                                                <div className="form-group mb-3">
                                                    <label className="form-label" htmlFor="etapas" ><strong>Monto Ministración</strong></label>
                                                    <input className="form-control" placeholder='Monto Ministración'
                                                        id="monto_ministracion"
                                                        name="monto_ministracion"
                                                        value={formatoMoneda.format(parseFloat(formDerechohabiente.monto_ministracion || 0.00 ))}
                                                        onChange={handleChangeFormDerechohabiente}
                                                        required
                                                        pattern=".*\S+.*" />
                                                    <div className="invalid-feedback" id="validationNoInterior">
                                                        Campo requerido
                                                    </div>	    
                                                </div>
                                            </div>
                                            <div className='col-4'>
                                                <div className="form-group mb-3">
                                                    <label className="form-label" htmlFor="paquetes" ><strong>Monto de Subsidio</strong></label>
                                                    <input className="form-control" placeholder='Monto de Subsidio'
                                                        id="monto_subsidio"
                                                        name="monto_subsidio"
                                                        value={formatoMoneda.format(parseFloat(formDerechohabiente.monto_subsidio || 0.00 ))}
                                                        onChange={handleChangeFormDerechohabiente}
                                                        required
                                                        pattern=".*\S+.*" />
                                                    <div className="invalid-feedback" id="validationNoInterior">
                                                        Campo requerido
                                                    </div>	  
                                                </div>
                                            </div>
                                        </div>	
                                        <div className='row'>
                                            <div className='col-4'>
                                                <div className="form-group mb-3">
                                                    <label className="form-label" htmlFor="proyectos" ><strong>Precio de Venta</strong></label>
                                                    <input className="form-control" placeholder='Precio de Venta'
                                                        id="valor_vivienda"
                                                        name="valor_vivienda"
                                                        value={formatoMoneda.format(parseFloat(formDerechohabiente.valor_vivienda || 0.00 ))}
                                                        onChange={handleChangeFormDerechohabiente}
                                                        required
                                                        pattern=".*\S+.*" />
                                                    <div className="invalid-feedback" id="validationNoInterior">
                                                        Campo requerido
                                                    </div>	    
                                                </div>
                                            </div>
                                            <div className='col-4'>
                                                <div className="form-group mb-3">
                                                    <label className="form-label" htmlFor="etapas" ><strong>Cuenta DAE / Clabe</strong></label>
                                                    <input className="form-control" placeholder='Cuenta DAE / Clabe'
                                                        id="clabe_cuenta"
                                                        name="clabe_cuenta"
                                                        value={formDerechohabiente.clabe_cuenta}
                                                        onChange={handleChangeFormDerechohabiente}
                                                        required
                                                        disabled={true}
                                                        pattern=".*\S+.*" />
                                                    <div className="invalid-feedback" id="validationNoInterior">
                                                        Campo requerido
                                                    </div>	    
                                                </div>
                                            </div>
                                        </div>	
                                    </div>
                                </CardBody>
                            </Card>
                            <br/>
                            <br/>
                            <Card>
                                <CardHeader className="fw-bold small d-flex">
                                    <span className="flex-grow-1">Valores del Folio</span>
                                </CardHeader>
                                <CardBody>
                                    <div className="col-xl-12">
                                        <div className="row">	
                                            <div className="col-xl-6">
                                            <div className="form-group mb-3">
                                                <label className="form-label"><strong>Número de Instrumento</strong></label>
                                                <input className="form-control"  placeholder='Número de Instrumento'
                                                    id="no_instrumnumero_instrumentoento"
                                                    name="numero_instrumento"
                                                    value={formDerechohabiente.numero_instrumento}
                                                    onChange={handleChangeFormDerechohabiente}
                                                    required
                                                    pattern=".*\S+.*" />
                                                <div className="invalid-feedback" id="validationDireccion">
                                                    Campo requerido
                                                </div>	
                                            </div>
                                            </div>
                                            <div className="col-xl-6">
                                            <div className="form-group mb-3">
                                                <label className="form-label" htmlFor="exampleFormControlTextarea1"><strong>Volumen</strong></label>
                                                <input type="number" inputMode="numeric" className="form-control"  placeholder='Volumen'
                                                    id="volumen"
                                                    name="volumen"
                                                    value={formDerechohabiente.volumen}
                                                    onChange={handleChangeFormDerechohabiente}
                                                    required
                                                    pattern=".*\S+.*" />
                                                <div className="invalid-feedback" id="validationDireccion">
                                                    Campo requerido
                                                </div>	                                              
                                            </div>
                                            </div>
                                        </div>
                                        <div className="row">	
                                            <div className="col-xl-4">
                                            <div className="form-group mb-3">
                                                <label className="form-label"><strong>Fecha de Revisión</strong></label>
                                                <input className="form-control"  placeholder='Fecha de Revisión'
                                                    id="fecha_revision"
                                                    name="fecha_revision"
                                                    value={formDerechohabiente.fecha_revision}
                                                    onChange={handleChangeFormDerechohabiente}
                                                    required
                                                    disabled={true}
                                                    pattern=".*\S+.*" />
                                                <div className="invalid-feedback" id="validationDireccion">
                                                    Campo requerido
                                                </div>	
                                                </div>
                                            </div>
                                            <div className="col-xl-4">
                                            <div className="form-group mb-3">
                                                <label className="form-label" htmlFor="exampleFormControlTextarea1"><strong>Estatus de Firma de Folio</strong></label>
                                                <select className="form-select"  id='estatus_firma_folio' name='estatus_firma_folio' onChange={handleChangeFormDerechohabiente} value={formDerechohabiente.estatus_firma_folio}>
                                                    <option value="1">Firmado</option>
                                                    <option value="2">No Firmado</option>
                                                    <option value="3">Firmado pero no forma parte del paquete</option>
                                                </select>                                               
                                            </div>
                                            </div>
                                            <div className="col-xl-4">
                                            <div className="form-group mb-3">
                                                <label className="form-label"><strong>Fecha Firma</strong></label>
                                                <DateTime closeOnSelect={true}
                                                    className="form-control"  
                                                    inputProps={{
                                                        placeholder:' Fecha de Firma',
                                                        readOnly: true 
                                                    }}
                                                    viewMode="days"
                                                    timeFormat={false}
                                                    dateFormat="YYYY-MM-DD" 
                                                    id="fecha_firma"
                                                    name="fecha_firma"
                                                    value={formDerechohabiente.fecha_firma}
                                                    onChange={handleChangeFormDerechohabiente}
                                                    />
                                                <div className="invalid-feedback" id="validationDireccion">
                                                    Campo requerido
                                                </div>	
                                                </div>
                                            </div>
                                           
                                        </div>
                                        <div className="row">	
                                            <div className="col-xl-12">
                                            <div className="form-group mb-3">
                                                <label className="form-label" htmlFor="exampleFormControlTextarea1"><strong>Observaciones</strong></label>
                                                <textarea className="form-control"  placeholder='Observaciones'
                                                    id="observaciones"
                                                    name="observaciones"
                                                    value={formDerechohabiente.observaciones}
                                                    onChange={handleChangeFormDerechohabiente} 
                                                >
                                                </textarea>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </CardBody>
                            </Card>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-default" onClick={handleOnCloseModal}>Cerrar</button>
                        <button type="button" className="btn btn-outline-theme"  form="formDerechohabiente" onClick={handleActionDerechohabiente} >{action}</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ModalDerechohabiente;