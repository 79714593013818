import { daeApi } from "../daeApi";

export const getPaquetes = async (token) => {
  const response = await daeApi.get(`/paquetes`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const createPaquete = async (data, token) => {
  const response = await daeApi.post(`/paquetes`, data, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-access-tokens': token 
    }
  });
  return response.data;
}


export const createPaqueteAnticipoArchivos = async (data, token) => {
  const response = await daeApi.post(`/paquetes/anticipo/archivos/enviar`, data, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

//paquetes/fondo/garantia/reporte/enviar
export const createPaqueteFondoGarantiaArchivos = async (data, token) => {
  const response = await daeApi.post(`/paquetes/fondo/garantia/reporte/enviar`, data, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const createPaqueteFondoGarantiaEnviar = async (data, token) => {
  const response = await daeApi.post(`/paquetes/fondo/garantia/enviar`, data, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const createPaqueteAnticipoMinistracion = async (data, token) => {
  const response = await daeApi.post(`/paquetes/anticipo/ministracion`, data, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}


export const getPaqueteById = async (id, token) => {
  const response = await daeApi.get(`/paquetes/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-access-tokens': token 
    }
  });
  return response.data;
}

export const getPaquetesByEtapaId = async (id, token) => {
  const response = await daeApi.get(`/paquetes/etapa/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-access-tokens': token 
    }
  });
  return response.data;
}

export const getPaqueteEstatusById = async (id, token) => {
  const response = await daeApi.get(`/paquetes/estatus/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-access-tokens': token 
    }
  });
  return response.data;
}

export const getPaquetesEstatusByEtapaId = async (id, token) => {
  const response = await daeApi.get(`/paquetes/estatus/etapa/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-access-tokens': token 
    }
  });
  return response.data;
}


export const getPaquetesCountByEtapaId = async (id, token) => {
  const response = await daeApi.get(`/paquetes/count/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-access-tokens': token 
    }
  });
  return response.data;
}

export const getPaqueteTotalFirmadosById = async (id, token) => {
  const response = await daeApi.get(`/paquetes/total/firmados/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-access-tokens': token 
    }
  });
  return response.data;
}

export const getPaquetesEstatusAdministradorById = async (id, token) => {
  const response = await daeApi.get(`/paquetes/estatus/administrador/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-access-tokens': token 
    }
  });
  return response.data;
}

export const getPaqueteTotalTodosById = async (id, token) => {
  const response = await daeApi.get(`/paquetes/total/todos/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-access-tokens': token 
    }
  });
  return response.data;
}


export const getPaqueteAnticiposById = async (id, token) => {
  const response = await daeApi.get(`/paquetes/anticipos/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getPaqueteAnticipoTecnicoById = async (id, token) => {
  const response = await daeApi.get(`/paquetes/anticipo/tecnico/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getPaqueteAnticipoAdminById = async (id, token) => {
  const response = await daeApi.get(`/paquetes/anticipo/admin/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getPaqueteAnticipoPDFAdminById = async (id, token) => {
  const response = await daeApi.get(`/paquetes/anticipo/pdf/admin/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getPaqueteDocumentoGarantiaAdminById = async (id, token) => {
  const response = await daeApi.get(`/paquetes/documento/garantia/admin/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getPaqueteDocumentoGarantiaById = async (id, token) => {
  const response = await daeApi.get(`/paquetes/documento/garantia/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}


export const getPaqueteDocumentoContratoById = async (id, token) => {
  const response = await daeApi.get(`/paquetes/documento/contrato/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getPaqueteAnticipoArchivosById = async (id, token) => {
  const response = await daeApi.get(`/paquetes/anticipo/archivos/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

///paquetes/archivos/terminacion/<id>
export const getPaqueteArchivosTerminacionById = async (id, token) => {
  const response = await daeApi.get(`/paquetes/archivos/terminacion/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const updatePaqueteById = async (id, data, token) => {
  const response = await daeApi.put(`/paquetes/${id}`, data, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const updatePaqueteCerrarTecnicoById = async (id, data, token) => {
  const response = await daeApi.put(`/paquetes/cerrar/tecnico/${id}`, data, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-access-tokens': token 
    }
  });
  return response.data;
}


export const updatePaqueteCerrarJuridicoById = async (id, data, token) => {
  const response = await daeApi.put(`/paquetes/cerrar/juridico/${id}`, data, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-access-tokens': token 
    }
  });
  return response.data;
}

export const updatePaqueteAbrirById = async (id, estatus, token) => {
  const response = await daeApi.put(`/paquetes/abrir/${id}/${estatus}`, {}, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const updatePaqueteEstatusById = async (id, estatus, token) => {
  const response = await daeApi.put(`/paquetes/estatus/${id}/${estatus}`, {}, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-access-tokens': token 
    }
  });
  return response.data;
}

export const updatePaqueteInconsistenciaById = async (id, data, token) => {
  const response = await daeApi.put(`/paquetes/inconsistencia/${id}`, data, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-access-tokens': token 
    }
  });
  return response.data;
}

export const updatePaqueteValorTodoById = async (id, data, token) => {
  const response = await daeApi.put(`/paquetes/valor/todo/${id}`, data, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-access-tokens': token 
    }
  });
  return response.data;
}

export const updatePaqueteAnticipoTecnicoById = async (id, data, token) => {
  const response = await daeApi.put(`/paquetes/anticipo/tecnico/${id}`, data, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const updatePaqueteAnticipoAdminById = async (id, data, token) => {
  const response = await daeApi.put(`/paquetes/anticipo/admin/${id}`, data, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const updatePaqueteAnticipoPDFAdminById = async (id, data, token) => {
  const response = await daeApi.put(`/paquetes/anticipo/pdf/admin/${id}`, data, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const updatePaqueteDocumentoGarantiaAdminById = async (id, data, token) => {
  const response = await daeApi.put(`/paquetes/documento/garantia/admin/${id}`, data, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const updatePaqueteDocumentoGarantiaById = async (id, data, token) => {
  const response = await daeApi.put(`/paquetes/documento/garantia/${id}`, data, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const updatePaqueteDocumentoContratoById = async (id, data, token) => {
  const response = await daeApi.put(`/paquetes/documento/contrato/${id}`, data, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const updatePaqueteCartaTerminacionById = async (id, data, token) => {
  const response = await daeApi.put(`/paquetes/carta/terminacion/${id}`, data, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const updatePaqueteCartaEntregaById = async (id, data, token) => {
  const response = await daeApi.put(`/paquetes/carta/entrega/${id}`, data, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const updatePaquetePolizaById = async (id, data, token) => {
  const response = await daeApi.put(`/paquetes/poliza/${id}`, data, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const deletePaqueteById = async (id, token) => {
  const response = await daeApi.delete(`/paquetes/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-access-tokens': token 
    }
  });
  return response.data;
}
