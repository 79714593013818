import { daeApi } from "../daeApi";

export const getParametrosMinistracion = async (token) => {
  const response = await daeApi.get(`/ministraciones/parametros`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const createParametrosMinistracion = async (data, token) => {
  const response = await daeApi.post(`/ministraciones/parametros`, data, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getParametrosMinistracionById = async (id, token) => {
  const response = await daeApi.get(`/ministraciones/parametros/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const updateParametrosMinistracionById = async (id, data, token) => {
  const response = await daeApi.put(`/ministraciones/parametros/${id}`, data, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const deleteParametrosMinistracion = async (id, token) => {
  const response = await daeApi.delete(`/ministraciones/parametros/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}
