import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getPaquetesByEtapaId } from '../../api/paquete_proyectos/paquete_proyectos';

function SelectPaquetes({ etapa_id, setSharedPaqueteId }) {
    const token = useSelector(state => state.authentication.user?.token);
    const location = useLocation();

    const [paquetes, setPaquetes] = useState([]);
    const [selectedPaquete, setSelectedPaquete] = useState(0);
    const [isInconsistencias, setIsInconsistencias] = useState(false);

    
    useEffect(() => { 
        if(etapa_id > 0) 
            fetchPaquetesByEtapaId(etapa_id);
        else {
            setPaquetes([]);
            setSelectedPaquete(0);
            setSharedPaqueteId(0);
            setIsInconsistencias(false);
        }
	}, [etapa_id]);
    
    const fetchPaquetesByEtapaId = async (etapa_id) => {
		try {
			const response = await getPaquetesByEtapaId(etapa_id, token);
            setPaquetes(response);
            if(location.pathname == "/historial/inconsistencias") 
                setIsInconsistencias(true);
		} catch (error) {
			console.error('Error al cargar los datos', error);
		}
	};

    const handlePaqueteChange = (event) => {
		const selectedId = event.target.value;
		setSelectedPaquete(selectedId);
        setSharedPaqueteId(selectedId);
	}

    return(
        <div className="form-group mb-3">
            <label className="form-label" htmlFor="paquetes" ><strong>Paquetes</strong></label>
            <select className="form-select" id="paquetes"  onChange={handlePaqueteChange} value={selectedPaquete} >
                <option value="0">Selecciona un paquete</option>
                { isInconsistencias ? <option value="-2">Ver todos</option> : null }
                {paquetes.map((paquete) => (
                    <option key={paquete.id} value={paquete.id}>
                        Paquete # {paquete.numero}
                    </option>
                ))}
            </select>
        </div>
    )
}

export default SelectPaquetes;