import React,  { useEffect, useRef } from 'react';

import { formatearFecha, formatoMoneda } from '../../../helpers';

import jsZip from 'jszip';

import './styles.css'; 

const $ = require('jquery');
window.JSZip = jsZip;

function TablaEstadoCuentas({ estado_cuentas }) {
    const datatableCuentaRef = useRef(null);

    useEffect(() => {
		if (datatableCuentaRef.current !== null) {
			let index = 1;
			datatableCuentaRef.current.clear();
			datatableCuentaRef.current.rows.add(estado_cuentas.map(estado => [index++, 
																		estado.num_credito, 
																		estado.nombres, 
																		formatoMoneda.format(parseFloat(estado.val_ministracion.replace(/,/g, ''))), 
																		formatoMoneda.format(parseFloat(estado.val_moto_subsidio.replace(/,/g, ''))),
																		formatearFecha(estado.fec_fecha_deposito)]));
	  		datatableCuentaRef.current.draw();
		} else 
			initDatatable();
		// eslint-disable-next-line
	}, [estado_cuentas]);

    const initDatatable = () => {
		datatableCuentaRef.current = $('#tablaEstadoCuentas').DataTable({
			dom: "<'row mb-3'<'col-md-4 mb-3 mb-md-0'l><'col-md-8 text-end'<'d-lg-flex justify-content-end'f<'d-lg-block d-none'B>>>>t<'row align-items-center'<'mr-auto col-md-6 mb-3 mb-md-0 mt-n2 'i><'mb-0 col-md-6'p>>",
			lengthMenu: [10, 20, 30, 40, 50],
			buttons: [
				{ 
					extend: 'excelHtml5', 
					className: 'btn btn-outline-default btn-sm',
					filename: function() {
						var timestamp = new Date().getTime(); 
						return 'inconsistencias_' + timestamp; 
					},
					exportOptions: {
						columns: ':visible',
						modifier: {
							page: 'none' // Evita agregar información de paginación
						}
					},
					customizeData: function ( data ) {
						for (var i=0; i < data.body.length; i++){
							for (var j=0; j<data.body[i].length; j++ ){
								data.body[i][j] = '\u200C' + data.body[i][j];
							}
						}
					}
				}
			],
			columns: [
				{ 
					title: "#",
					width: "10px",
					render: function (data, type, row, meta) {
						return `<div style="white-space: nowrap;">${row[0]}</div>`;
					},
				},
				{ 
					title: "No. Credito",
					width: "100px",
					render: function (data, type, row, meta) {
						return `<div style="white-space: nowrap;">${row[1]}</div>`;
					},
				},
				{ title: "Acreditado"},
				{ 
					title: "Monto Ministración. (INFONAVIT)",
					width: "200px",
					render: function (data, type, row, meta) {
						return `<div style="white-space: nowrap;">${row[3]}</div>`;
					},
				},
				{ 
					title: "Monto de Subsidio. (CONAVI)", 
					width: "200px",
					render: function (data, type, row, meta) {
						return `<div style="white-space: nowrap;">${row[4]}</div>`;
					},
				},
				{ title: "Fecha de Depósito" }
			]
		});
	}
	

    return (
        <div className='col-12 tabla-scrollable table-responsive'>
            <table id="tablaEstadoCuentas" className="table text-nowrap w-100 table-striped ">
                <thead></thead>
                <tbody></tbody>
            </table>
        </div>
    )
}

export default TablaEstadoCuentas;