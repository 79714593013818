import { daeApi } from "../daeApi";

export const getTasaInversion = async (token) => {
  const response = await daeApi.get(`/tasa-inversion`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const createTasaInversion = async (data, token) => {
  const response = await daeApi.post(`/tasa-inversion`, data, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getTasaInversionById = async (id, token) => {
  const response = await daeApi.get(`/tasa-inversion/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}


//
export const getTasaInversionByEtapaId = async (id, token) => {
  const response = await daeApi.get(`/tasa/inversion/etapa/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const updateTasaInversion = async (id, data, token) => {
  const response = await daeApi.put(`/tasa/inversion/${id}`, data, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const deleteTasaInversion = async (id, token) => {
  const response = await daeApi.delete(`/tasa-inversion/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}
