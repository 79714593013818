const routeAllowed = {
    '/': ['master', 'administracion', 'direccion', 'tecnico', 'juridico'],
    
    '/usuarios': ['master'],
    
    '/proyecto/lista': ['master', 'administracion', 'direccion', 'tecnico', 'juridico'],
    '/proyecto/detalle': ['master', 'administracion', 'direccion', 'tecnico', 'juridico'],
    '/proyecto/paquetes': ['master', 'administracion', 'direccion', 'tecnico', 'juridico'],
   
    '/vivienda/acreditados': ['master', 'tecnico'],
    
    '/catalogo': ['master', 'administracion', 'direccion'],
    '/catalogo/bancos': ['master','administracion', 'direccion'],
    '/catalogo/promotores': ['master','administracion', 'direccion'],
    
    '/estados/cuenta': ['master', 'administracion', 'direccion'],
    
    '/supervision': ['master', 'administracion', 'direccion', 'tecnico'],
    '/supervision/anticipos/autorizar': ['master', 'tecnico'],
    '/supervision/obra' : ['master', 'administracion', 'direccion', 'tecnico'],
    '/supervision/garantia': ['master', 'administracion', 'direccion', 'tecnico'],
    
    '/inversiones': ['master'],
    '/historial/inconsistencias': ['master','administracion', 'direccion'],
    '/reportes': ['master',  'tecnico'],
    '/reportes/paquetes': ['master','tecnico'],

    '/condusef': ['master','administracion', 'direccion'],
    '/condusef/redeco': ['master','administracion', 'direccion'],
    '/condusef/reune': ['master','administracion', 'direccion'],
};

export default routeAllowed;

//'/dashboard': ['master', 'administracion', 'direccion', 'tecnico', 'juridico'],