import { daeApi } from '../daeApi';

export const getUsuarios = async (token) => {
  const response = await daeApi.get(`/usuario`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getUsuariosRole = async (token) => {
  const response = await daeApi.get(`/usuario/role`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getUsuariosRoleById = async (token) => {
  const response = await daeApi.get(`/usuario/role`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getUsuarioById = async (id, token) => {
    const response = await daeApi.get(`/usuario/${id}`, {
      headers: {
        'Content-Type'	: 'application/json',
        'Accept'			: 'application/json',
        'x-access-tokens' : token
      }
  	});
    return response.data;
}

export const createUsuario = async (data, token) => {
  	const response = await daeApi.post(`/usuario`, data, {
      headers: {
        'Content-Type'	: 'application/json',
        'Accept'			: 'application/json',
        'x-access-tokens' : token
      }
  	});
  	return response.data;
}

export const updateUsuarioByID = async (id, data, token) => {
  const response = await daeApi.put(`/usuario/${id}`, data, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const updateUsuarioStatusById = async (id, id_2, token) => {
  const response = await daeApi.put(`/usuario/status/${id}/${id_2}`, null, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const updateUsuarioRoleById = async (id, id_2, token) => {
  const response = await daeApi.put(`/usuario/role/${id}/${id_2}`, null, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const updateUsuarioPasswordbyId = async (id, data, token) => {
  const response = await daeApi.put(`/usuario/password/${id}`, data, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const deleteUsuarioById = async (id, token) => {
  const response = await daeApi.delete(`/usuario/${id}`,  {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}


