import { daeApi } from "../daeApi";

export const getDirecciones = async (token) => {
  const response = await daeApi.get(`/direcciones`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const createDireccion = async (data, token) => {
  const response = await daeApi.post(`/direcciones`, data, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getDireccionById = async (id, token) => {
  const response = await daeApi.get(`/direcciones/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getDireccionSepomexByCp = async (cp, token) => {
  try {
    const response = await daeApi.get(`/direcciones/sepomex/${cp}`, {
      headers: {
        'Content-Type'	: 'application/json',
        'Accept'			: 'application/json',
        'x-access-tokens' : token
      }
    });
    return {
        data: response.data,
        status: response.status
    };
  } catch (e) {
    console.error(e)
  }
}

export const updateDireccionById = async (id, data, token) => {
  const response = await daeApi.put(`/direcciones/${id}`, data, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const deleteDireccionById = async (id, token) => {
  const response = await daeApi.delete(`/direcciones/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}
