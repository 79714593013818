import React,  { useEffect, useState }from 'react';
import { useSelector } from 'react-redux';

import { Card, CardBody } from '../../../../components/card/card';

import { deleteKeyNull, errorNotification, formatoMoneda, successNotification, numeroAMes, buscarMediosRecepcionById, buscarNivelAtencionById, alert } from '../../../../helpers';
import { createQuejas, getQuejaById, getSepomexEstados, getSepomexMunicipiosByEstadoId, getSepomexColoniasByCp } from '../../../../api/condusef/redeco';

import { meses, mediosRecepcion, nivelAtencion, productos, causas } from '../../../../helpers/catalogos';
import Swal from 'sweetalert2';


function ModalNuevaQueja({ onCloseModal }) {
    const token = useSelector(state => state.authentication.user?.token);
    const token_access = useSelector(state => state.authentication.user?.token_access);

    const [formQueja, setFormQueja] = useState({});
    const [selectedEstados, setSelectedEstados] = useState([]);
    const [selectedColonias, setSelectedColonias] = useState([]);

    useEffect(() => {
        if ( token !== undefined) {
            fetchSepomexEstados();
        }
    }, [, token])

    const fecthCreateQuejas = async () => {
        try {
            const response = await createQuejas(formQueja, token, token_access);
            if(response.message === 'success') {
                successNotification("Queja Registrada Correctamente");
                finished();
            }
        } catch(error) {
            let errors = error.response.data.errors;
            const mensaje = errors.map(error => `<li style="margin-bottom: 10px; text-align: left;">${error}</li>`).join('');
            Swal.fire({
                icon: 'error',
                title: 'Ocurrió un error.',
                html: `<ul style="text-align: left; padding-left: 20px;">${mensaje}</ul>`, // Utiliza HTML para formatear los errores en una lista con alineación a la izquierda
                confirmButtonText: 'Continuar'
            });
        }
    }
    
    const fetchSepomexEstados = async () => {
		try {
            const response = await getSepomexEstados(token);
            setSelectedEstados(response.estados);
        } catch (error) {
            console.error('Error al cargar los datos', error);
        }
	}

    const fetchSepomexColoniasByCp = async (cp) => {
		try {
            const response = await getSepomexColoniasByCp(cp, token);
            setSelectedColonias(response.colonias);
        } catch (error) {
            console.error('Error al cargar los datos', error);
        }
	}
    

    const handleChangeFormQueja = (event) => {
		const { name, value } = event.target;
		setFormQueja({
			...formQueja,
			[name]: value,
		});
        if(name === "QuejasCP" && value.length === 5) {
            handleCpComplete(value);
        } 

		if(name === "QuejasColId") {
            const coloniaEncontrada = selectedColonias.find(item => item.coloniaId == value);
            if (coloniaEncontrada) {
                setFormQueja({
                    ...formQueja,
                    ["QuejasColId"]: value,
                    ["municipio"]: coloniaEncontrada.municipio,
                    ["localidad"]: coloniaEncontrada.tipoLocalidad,
                });
            } 
        }
	};

    const handleNuevaQueja = (e) => {
		e.preventDefault(); 
		if (e.target.form.checkValidity()) {
            const coloniaEncontrada = selectedColonias.find(item => item.coloniaId == formQueja.QuejasColId);
            if (coloniaEncontrada) {
                setFormQueja({
                    ...formQueja,
                    ["QuejasMunId"]: coloniaEncontrada.municipioId,
                    ["QuejasLocId"]: coloniaEncontrada.tipoLocalidadId,
                });
            } 
            fecthCreateQuejas();
        } 
        else errorNotification("Faltan campos requeridos.");
	}

    const handleCpComplete = (cp) => {
        fetchSepomexColoniasByCp(cp);
    };

    const cleanForm = () => {
        setFormQueja({})
        setSelectedColonias([]);
        setSelectedEstados([]);
    }

    const finished = () => {
        document.getElementById('modalNuevaQueja').classList.remove('show');
        document.querySelector('.modal-backdrop').remove();
        document.getElementById('modalNuevaQueja').style.display = 'none';

        cleanForm();

        onCloseModal(); 
    }

  
    return(
        <div className="modal modal-cover fade" id="modalNuevaQueja" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Nueva Queja</h5>
                        <button type="button" className="btn-close" onClick={finished}></button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3">
                            <form id="formNuevaQueja" className="was-validated" >
                                <div className="row row-space-12">	
                                    <div className="col-12">
                                        <div className='row'>
                                            <div className="col-xl-2">
                                                <label className="form-label"><strong>No. de quejas</strong></label>
                                                <input className="form-control" placeholder="#"
                                                    id="QuejasNum"
                                                    name="QuejasNum"
                                                    value={formQueja.QuejasNum}
                                                    onChange={handleChangeFormQueja}
                                                    required/>
                                                <div className="invalid-feedback" id="validationRFC">
                                                    Campo requerido
                                                </div>	
                                            </div>
                                            <div className="col-xl-4">
                                                <label className="form-label"><strong>Mes a Reportar</strong></label>
                                                <select
                                                    className="form-select"
                                                    id="QuejasNoMes"
                                                    name="QuejasNoMes"
                                                    value={formQueja.QuejasNoMes}
                                                    onChange={handleChangeFormQueja}
                                                    required>
                                                    <option value="">Seleccione el mes</option>
                                                    {meses.map((mes) => (
                                                        <option key={mes.monthId} value={mes.monthId}>
                                                            {mes.month}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-xl-6">
                                                <label className="form-label"><strong>Folio</strong></label>
                                                <input className="form-control" placeholder="Folio" 
                                                    id="QuejasFolio"
                                                    name="QuejasFolio"
                                                    value={formQueja.QuejasFolio}
                                                    onChange={handleChangeFormQueja}
                                                    required/>
                                                <div className="invalid-feedback" id="validationRFC">
                                                    Campo requerido
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-space-12">	
                                    <div className="col-12">
                                        <div className='row'>
                                            <div className="col-xl-4">
                                                <label className="form-label"><strong>Fecha Recepcion</strong></label>
                                                <input className="form-control" placeholder="Fecha Recepcion"
                                                    type="date"
                                                    id="QuejasFecRecepcion"
                                                    name="QuejasFecRecepcion"
                                                    value={formQueja.QuejasFecRecepcion}
                                                    onChange={handleChangeFormQueja}
                                                    required/>
                                                <div className="invalid-feedback" id="validationRFC">
                                                    Campo requerido
                                                </div>
                                            </div>
                                            <div className="col-xl-4">
                                                <label className="form-label"><strong>Medio de entrega</strong></label>
                                                <select
                                                    className="form-select"
                                                    id="QuejasMedio"
                                                    name="QuejasMedio"
                                                    value={formQueja.QuejasMedio}
                                                    onChange={handleChangeFormQueja}
                                                    required>
                                                    <option value="">Seleccione una opción</option>
                                                    {mediosRecepcion.map((medio) => (
                                                        <option key={medio.id} value={medio.id}>
                                                            {medio.nombre}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-xl-4">
                                                <label className="form-label"><strong>Nivel de Atención</strong></label>
                                                <select
                                                    className="form-select"
                                                    id="QuejasNivelAT"
                                                    name="QuejasNivelAT"
                                                    value={formQueja.QuejasNivelAT}
                                                    onChange={handleChangeFormQueja}
                                                    required>
                                                    <option value="">Seleccione una opción</option>
                                                    {nivelAtencion.map((nivel) => (
                                                        <option key={nivel.id} value={nivel.id}>
                                                            {nivel.nombre}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-space-12">	
                                    <div className="col-12">
                                        <div className='row'>
                                            <div className="col-xl-4">
                                                <label className="form-label"><strong>Razón Social</strong></label>
                                                <input className="form-control" placeholder="Razón Social" 
                                                    id="QuejasDenominacion"
                                                    name="QuejasDenominacion"
                                                    onChange={handleChangeFormQueja}
                                                    value={formQueja.QuejasDenominacion}
                                                    required
                                                />
                                            </div>
                                            <div className="col-xl-4">
                                                <label className="form-label"><strong>Sector</strong></label>
                                                <input className="form-control" placeholder="Sector"
                                                    id="QuejasSector"
                                                    name="QuejasSector"
                                                    onChange={handleChangeFormQueja}
                                                    value={formQueja.QuejasSector}
                                                    required
                                                />
                                            </div>
                                            <div className="col-xl-4">
                                                <label className="form-label"><strong>Tipo Producto</strong></label>
                                                <select
                                                    className="form-select"
                                                    id="QuejasProducto"
                                                    name="QuejasProducto"
                                                    value={formQueja.QuejasProducto}
                                                    onChange={handleChangeFormQueja}
                                                    required>
                                                    <option value="">Seleccione una producto</option>
                                                    {productos.map((producto) => (
                                                        <option key={producto.productId} value={producto.productId}>
                                                            {producto.product}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                               
                                <div className="row row-space-12">	
                                    <div className="col-12">
                                        <div className='row'>
                                            <div className="col-xl-6">
                                                <label className="form-label"><strong>Queja a Reportar</strong></label>
                                                <select
                                                    className="form-select"
                                                    id="QuejasCausa"
                                                    name="QuejasCausa"
                                                    value={formQueja.QuejasCausa}
                                                    onChange={handleChangeFormQueja}
                                                    required>
                                                    <option value="">Seleccione una causa</option>
                                                    {causas.map((causa) => (
                                                        <option key={causa.causaId} value={causa.causaId}>
                                                            {causa.causa}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-xl-3">
                                                <label className="form-label"><strong>Posible Robo Identidad</strong></label>
                                                <select
                                                    className="form-select"
                                                    id="QuejasPORI"
                                                    name="QuejasPORI"
                                                    value={formQueja.QuejasPORI}
                                                    onChange={handleChangeFormQueja}
                                                    required >
                                                    <option value="">Seleccione una opción</option>
                                                    <option value="SI">SI</option>
                                                    <option value="NO">NO</option>
                                                </select>
                                            </div>
                                            <div className="col-xl-3">
                                                <label className="form-label"><strong>Estatus</strong></label>
                                                <select
                                                    className="form-select"
                                                    id="QuejasEstatus"
                                                    name="QuejasEstatus"
                                                    value={formQueja.QuejasEstatus}
                                                    onChange={handleChangeFormQueja}
                                                    required>
                                                    <option value="">Seleccione una opción</option>
                                                    <option value={1}>PENDIENTE</option>
                                                    <option value={2}>CONCLUIDA</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row row-space-12">	
                                    <div className="col-12">
                                        <div className='row'>
                                            <div className="col-xl-4">
                                                <label className="form-label"><strong>Estado</strong></label>
                                                <select
                                                    className="form-select"
                                                    id="QuejasEstados"
                                                    name="QuejasEstados"
                                                    value={formQueja.QuejasEstados}
                                                    onChange={handleChangeFormQueja}
                                                    required>
                                                    <option value="">Seleccione un Estado</option>
                                                    {selectedEstados.map((estado) => (
                                                        <option key={estado.claveEdo} value={estado.claveEdo}>
                                                            {estado.estado}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className="col-xl-4">
                                                <label className="form-label"><strong>CP</strong></label>
                                                <input
                                                    className="form-control"
                                                    placeholder="CP"
                                                    id="QuejasCP"
                                                    name="QuejasCP"
                                                    value={formQueja.QuejasCP}
                                                    onChange={handleChangeFormQueja}
                                                    required
                                                    maxLength={5} // Limita a 5 caracteres
                                                    inputMode="numeric" // Sugerencia para teclados móviles para mostrar solo números
                                                    pattern="^\d{5}$" // Solo acepta exactamente 5 dígitos
                                                    title="Debe ingresar un CP de 5 dígitos" // Mensaje que se muestra si no cumple el patrón
                                                    onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')} // Evita caracteres no numéricos
                                                />
                                                <div className="invalid-feedback" id="validationRFC">
                                                    Campo requerido
                                                </div>
                                            </div>
                                            <div className="col-xl-4">
                                                <label className="form-label"><strong>Colonia</strong></label>
                                                <select
                                                    className="form-select"
                                                    id="QuejasColId"
                                                    name="QuejasColId"
                                                    value={formQueja.QuejasColId}
                                                    onChange={handleChangeFormQueja}
                                                    required>
                                                    <option key={0} value="">
                                                        Seleccione una colonia
                                                    </option>
                                                    {selectedColonias.map((colonia) => (
                                                        <option key={colonia.coloniaId} value={colonia.coloniaId}>
                                                            {colonia.colonia}
                                                        </option>
                                                    ))}
                                                </select>
                                                
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>

                                <div className="row row-space-12">	
                                    <div className="col-12">
                                        <div className='row'>
                                        <div className="col-xl-4">
                                                <label className="form-label"><strong>Municipio</strong></label>
                                                <input className="form-control" placeholder="Municipio" 
                                                    id="municipio" 
                                                    name="municipio" 
                                                    value={formQueja.municipio}
                                                    onChange={handleChangeFormQueja}
                                                    required
                                                    disabled={true} />
                                                <div className="invalid-feedback" id="validationRFC">
                                                    Campo requerido
                                                </div>
                                            </div>
                                            <div className="col-xl-4">
                                                <label className="form-label"><strong>Localidad</strong></label>
                                                <input className="form-control" placeholder="Localidad" 
                                                    id="localidad" 
                                                    name="localidad"
                                                    value={formQueja.localidad}
                                                    onChange={handleChangeFormQueja}
                                                    required
                                                    disabled={true} />
                                                <div className="invalid-feedback" id="validationRFC">
                                                    Campo requerido
                                                </div>
                                            </div>
                                            
                                            
                                            
                                        </div>
                                    </div>
                                </div>

                                <div className="row row-space-12">	
                                    <div className="col-12">
                                        <div className='row'>
                                            <div className="col-xl-8">
                                                <label className="form-label"><strong>Tipo Persona</strong></label>
                                                <select
                                                    className="form-select"
                                                    id="QuejasTipoPersona"
                                                    name="QuejasTipoPersona"
                                                    value={formQueja.QuejasTipoPersona}
                                                    onChange={handleChangeFormQueja}
                                                    required
                                                >
                                                    <option value="">Seleccione el tipo de persona</option>
                                                    <option value="1">Persona Física</option>
                                                    <option value="2">Persona Moral</option>
                                                </select>
                                                <div className="invalid-feedback" id="validationRFC">
                                                    Campo requerido
                                                </div>
                                            </div>
                                            <div className="col-xl-2">
                                                <label className="form-label"><strong>Sexo</strong></label>
                                                <select
                                                    className="form-select"
                                                    id="QuejasSexo"
                                                    name="QuejasSexo"
                                                    value={formQueja.QuejasSexo}
                                                    onChange={handleChangeFormQueja}
                                                    required
                                                    disabled={formQueja.QuejasTipoPersona !== "1"} // Deshabilita si no es Persona Física
                                                >
                                                    <option value="">Seleccione el sexo</option>
                                                    <option value="H">Hombre</option>
                                                    <option value="M">Mujer</option>
                                                </select>
                                                <div className="invalid-feedback" id="validationRFC">
                                                    Campo requerido
                                                </div>
                                            </div>
                                            <div className="col-xl-2">
                                                <label className="form-label"><strong>Edad</strong></label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    placeholder="Edad"
                                                    id="QuejasEdad"
                                                    name="QuejasEdad"
                                                    onChange={handleChangeFormQueja}
                                                    value={formQueja.QuejasEdad}
                                                    min={18} // Establece el mínimo en 18 años
                                                    required={formQueja.QuejasTipoPersona === "1"} // Requerido solo para Persona Física
                                                    disabled={formQueja.QuejasTipoPersona !== "1"} // Deshabilita si no es Persona Física
                                                    title="Solo mayores de 18 años"
                                                />
                                                <div className="invalid-feedback" id="validationRFC">
                                                    Campo requerido
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row row-space-12">	
                                    <div className="col-12">
                                        <div className='row'>
                                            <div className="col-xl-4">
                                                <label className="form-label"><strong>Fecha Resolucion</strong></label>
                                                <input className="form-control" placeholder="Fecha Resolucion" 
                                                    type="date"
                                                    id="QuejasFecResolucion" 
                                                    name="QuejasFecResolucion"
                                                    onChange={handleChangeFormQueja}
                                                    value={formQueja.QuejasFecResolucion}
                                                    />
                                                <div className="invalid-feedback" id="validationRFC">
                                                    Campo requerido
                                                </div>
                                            </div>
                                            <div className="col-xl-4">
                                                <label className="form-label"><strong>Fecha Notificacion</strong></label>
                                                <input className="form-control" placeholder="Fecha Notificacion" 
                                                    type="date"
                                                    id="QuejasFecNotificacion" 
                                                    name="QuejasFecNotificacion" 
                                                    onChange={handleChangeFormQueja}
                                                    value={formQueja.QuejasFecNotificacion}
                                                    />
                                                <div className="invalid-feedback" id="validationRFC">
                                                    Campo requerido
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>

                                <div className="row row-space-12">	
                                    <div className="col-12">
                                        <div className='row'>
                                            <div className="col-xl-4">
                                                <label className="form-label"><strong>Respuesta</strong></label>
                                                <select
                                                    className="form-select"
                                                    id="QuejasRespuesta"
                                                    name="QuejasRespuesta"
                                                    value={formQueja.QuejasRespuesta}
                                                    onChange={handleChangeFormQueja}
                                                    required={formQueja.QuejasEstatus === 2} // Requerido solo si el estado es "Concluido"
                                                    disabled={formQueja.QuejasEstatus === 1} // Deshabilita si el estado es "Pendiente"
                                                >
                                                    <option value="">Seleccione una respuesta</option>
                                                    <option value="1">Totalmente favorable al usuario</option>
                                                    <option value="2">Desfavorable al Usuario</option>
                                                    <option value="3">Parcialmente favorable al usuario</option>
                                                </select>
                                                <div className="invalid-feedback" id="validationRespuesta">
                                                    Campo requerido si la queja está concluida.
                                                </div>
                                            </div>
                                            <div className="col-xl-4">
                                                <label className="form-label"><strong>Numero Penalizaciones</strong></label>
                                                <input className="form-control" placeholder="Numero Penalizaciones"
                                                    type="number"
                                                    id="QuejasNumPenal" 
                                                    name="QuejasNumPenal" 
                                                    value={formQueja.QuejasNumPenal}
                                                    onChange={handleChangeFormQueja}
                                                    min={0}
                                                    />
                                                <div className="invalid-feedback" id="validationRFC">
                                                    Campo requerido
                                                </div>
                                            </div>
                                            <div className="col-xl-4">
                                                <label className="form-label"><strong>Tipo Penalización</strong></label>
                                                <select
                                                    className="form-select"
                                                    id="PenalizacionId"
                                                    name="PenalizacionId"
                                                    value={formQueja.PenalizacionId}
                                                    onChange={handleChangeFormQueja}
                                                    
                                                >
                                                    <option value="">Seleccione el tipo de penalización</option>
                                                    <option value="1">Contractuales - Cancelación del contrato</option>
                                                    <option value="2">Contractuales - Reasignación de cartera</option>
                                                    <option value="3">Económicas - Multa</option>
                                                </select>
                                                <div className="invalid-feedback" id="validationPenalizacion">
                                                    Campo requerido
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </form>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-default" onClick={finished}>Cerrar</button>
                        <button type="button" className="btn btn-outline-theme" onClick={handleNuevaQueja} form="formNuevaQueja" >Nueva Queja</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ModalNuevaQueja;