import React,  { useEffect, useRef }from 'react';

import { formatoMoneda } from '../../../helpers';

import jsZip from 'jszip';

const $ = require('jquery');
window.JSZip = jsZip;

function TablaHistorialInconsistencias({ inconsistencias }) {
    const datatableInconsistenciasRef = useRef(null);


    useEffect(() => {
		if (datatableInconsistenciasRef.current !== null) {
			let index = 1;
			datatableInconsistenciasRef.current.clear();
			datatableInconsistenciasRef.current.rows.add(inconsistencias.map(inconsistencia => [
																		index++, 
																		inconsistencia.id, 
																		inconsistencia.nom_num_credito_derechohabiente, 
																		inconsistencia.nom_derechohabiente, 
																		inconsistencia.val_ministracion,
																		inconsistencia.val_moto_subsidio,
																		inconsistencia.num_motivo_inconsitencia,
																		inconsistencia.nom_deposito_no_reconocido,
																		inconsistencia.fecha_creacion]));
	  		datatableInconsistenciasRef.current.draw();
		} else 
			initDatatable();
		// eslint-disable-next-line
	}, [inconsistencias]);


    const initDatatable = () => {
		datatableInconsistenciasRef.current = $('#tablaIncosistencias').DataTable({
			dom: "<'row mb-3'<'col-md-4 mb-3 mb-md-0'l><'col-md-8 text-end'<'d-lg-flex justify-content-end'f<'d-lg-block d-none'B>>>>t<'row align-items-center'<'mr-auto col-md-6 mb-3 mb-md-0 mt-n2 'i><'mb-0 col-md-6'p>>",
			lengthMenu: [10, 20, 30, 40, 50],
			buttons: [
				{ 
					extend: 'excelHtml5', 
					className: 'btn btn-outline-default btn-sm',
					filename: function() {
						var timestamp = new Date().getTime(); 
						return 'supervision_obra_' + timestamp; 
					},
					exportOptions: {
						columns: ':visible',
						modifier: {
							page: 'none' // Evita agregar información de paginación
						}
					},
					customizeData: function ( data ) {
						for (var i=0; i < data.body.length; i++){
							for (var j=0; j<data.body[i].length; j++ ){
								data.body[i][j] = '\u200C' + data.body[i][j];
							}
						}
					}
				}
			],
			columns: [
				{ title: "#" },
				{ title: "ID" },
				{ title: "No. Acreditado"},
				{ title: "Acreditado"},
				{ 
					title: "Monto Ministración (INFONAVIT)",
					width: "150px",
					render: function (data, type, row, meta) {
						return `<div style="white-space: nowrap;">${formatoMoneda.format(parseFloat(row[4].replace(/,/g, '')))}</div>`;
					},
				},
				{ 
					title: "Monto de Subsidio (CONAVI)",
					width: "150px",
					render: function (data, type, row, meta) {
						return `<div style="white-space: nowrap;">${formatoMoneda.format(parseFloat(row[5].replace(/,/g, '')))}</div>`;
					},
				},
				{ 
					title: "Motivo Inconsistencia",
					render: function (data, type, row, meta) {
						const estatusFirmaFolioMapping = {
							"4": "INFONAVIT",
							"5": "CONAVI",
							"6": "INFONAVIT",
							"7": "CONAVI",
							"2": "NO FIRMADO",
							"3": "FIRMADO PERO NO PERTENECE AL PAQUETE",
						};
						
						return estatusFirmaFolioMapping[row[6]] || "Valor Desconocido";
					}
				},
				{ title: "Devolución del Depósito no reconocido" },
				{ title: "Fecha inconsistencias" }
			],
			columnDefs: [{
				targets: [1],
				visible: false
			  }
			]
		});
	}



    return (
        <div className='col-12 tabla-scrollable table-responsive'>
            <table id="tablaIncosistencias" className="table text-nowrap w-100 table-striped ">
                <thead></thead>
                <tbody></tbody>
            </table>
        </div>
    )
}

export default TablaHistorialInconsistencias;