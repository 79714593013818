import { daeApi } from "../daeApi";

export const getMinistraciones = async (token) => {
  const response = await daeApi.get(`/ministraciones`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const createMinistracion = async (data, token) => {
  const response = await daeApi.post(`/ministraciones`, data, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const createMinistracionAvanceArchivos = async (data, token) => {
  const response = await daeApi.post(`/ministraciones/avances/archivos/enviar`, data, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const createMinistracionSinComision = async (data, token) => {
  const response = await daeApi.post(`/ministraciones/solicitud/sin/comision`, data, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const createMinistracionConComision = async (data, token) => {
  const response = await daeApi.post(`/ministraciones/solicitud/con/comision`, data, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getMinistracionById = async (id, token) => {
  const response = await daeApi.get(`/ministraciones/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getMinistracionesByPaqueteId = async (id, token) => {
  const response = await daeApi.get(`/ministraciones/paquete/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getMinistracionesLiberadasByProyectoId = async (id, token) => {
  const response = await daeApi.get(`/ministraciones/liberadas/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getMinistracionesPorcentajeObraByPaqueteId = async (id, id2, token) => {
  const response = await daeApi.get(`/ministraciones/porcentaje/obra/${id}/${id2}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getMinistracionesPorcentajeObraNumeroByPaqueteId = async (id, id2, token) => {
  const response = await daeApi.get(`/ministraciones/porcentaje/obra/numero/${id}/${id2}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getMinistracionesComisionStatusByPaqueteId = async (id, id2, token) => {
  const response = await daeApi.get(`/ministraciones/comision/status/${id}/${id2}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getMinistracionesComisionesByPaqueteId = async (id, token) => {
  const response = await daeApi.get(`/ministraciones/comisiones/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getMinistracionesValorByPaqueteId = async (id, token) => {
  const response = await daeApi.get(`/ministraciones/valor/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getMinistracionesTotalByPaqueteId = async (id, token) => {
  const response = await daeApi.get(`/ministraciones/total/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getMinistracionesNumeroByPaqueteId = async (id, token) => {
  const response = await daeApi.get(`/ministraciones/numero/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getMinistracionesFondoGarantiaByPaqueteId = async (id, token) => {
  const response = await daeApi.get(`/ministraciones/fondo/garantia/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

///ministraciones/avance/admin/
export const getMinistracionAvanceAdminById = async (id, id2, token) => {
  const response = await daeApi.get(`/ministraciones/avance/admin/${id}/${id2}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

///ministraciones/avance/admin/
export const getMinistracionAvanceAdminByNumero = async (id, id2, token) => {
  const response = await daeApi.get(`/ministraciones/avance/admin/numero/${id}/${id2}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getMinistracionAvanceTecnicoById = async (id, id2, token) => {
  const response = await daeApi.get(`/ministraciones/avance/tecnico/${id}/${id2}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

///ministraciones/avance/admin/
export const getMinistracionAvanceTecnicoByNumero = async (id, id2, token) => {
  const response = await daeApi.get(`/ministraciones/avance/tecnico/numero/${id}/${id2}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

//ministraciones/neta/<id>/<id2>
export const getMinistracionNetaById = async (id, id2, token) => {
  const response = await daeApi.get(`/ministraciones/neta/${id}/${id2}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

///ministraciones/valor/actual/1/1
export const getMinistracionValorActualById = async (id, id2, token) => {
  const response = await daeApi.get(`/ministraciones/valor/actual/${id}/${id2}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}



export const getMinistracionListaByPaqueteId = async (id, id2, token) => {
  const response = await daeApi.get(`/ministraciones/lista/${id}/${id2}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}


export const getMinistracionListaNumeroByPaqueteId = async (id, id2, token) => {
  const response = await daeApi.get(`/ministraciones/lista/numero/${id}/${id2}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getMinistracionAvancesArchivosByPaqueteId = async (id, id2, token) => {
  const response = await daeApi.get(`/ministraciones/avances/archivos/${id}/${id2}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const updateMinistracionById = async (id, data, token) => {
  const response = await daeApi.put(`/ministraciones/${id}`, data, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const updateMinistracionAvanceAdminById = async (id, id2, data, token) => {
  const response = await daeApi.put(`/ministraciones/avance/admin/${id}/${id2}`, data, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}


export const updateMinistracionAvanceAdminByNumero = async (id, id2, data, token) => {
  const response = await daeApi.put(`/ministraciones/avance/admin/numero/${id}/${id2}`, data, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const updateMinistracionAvanceTecnicoByNumero = async (id, id2, data, token) => {
  const response = await daeApi.put(`/ministraciones/avance/tecnico/numero/${id}/${id2}`, data, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const deleteMinistracion = async (id, token) => {
  const response = await daeApi.delete(`/ministraciones/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const deleteMinistracionByPaqueteId = async (id, token) => {
  const response = await daeApi.delete(`/ministraciones/paquete/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}
