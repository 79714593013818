import React, {useEffect, useState, useRef	} from 'react';
import { useSelector } from 'react-redux';
import Breadcrumb from '../../../components/breadcrumb/breadcrumb';

import { 
    getReuneMyTickets,
    getReuneStatusTicketById,
    createReuneUploadFile,
    updateReuneUploadFile,
    deleteReuneFileByTicket
} from '../../../api/condusef/reune';

function Reune(props) {
    const token = useSelector(state => state.authentication.user?.token);


    useEffect(() => { 
    	//fetchReuneMyTickets();
	}, [token]);

    const fetchReuneMyTickets = async () => {
		try {
			const response = await getReuneMyTickets(token);
			console.log(response);
		} catch (error) {
			console.error('Error al cargar los datos', error);
		}
	}

    const fetchReuneStatusTicketById = async (id) => {
		try {
			const response = await getReuneStatusTicketById(id, token);
			console.log(response);
		} catch (error) {
			console.error('Error al cargar los datos', error);
		}
	}

    const fetchCreateReuneUploadFile = async (data) => {
		try {
			const response = await createReuneUploadFile(data, token);
			console.log(response);
		} catch (error) {
			console.error('Error al cargar los datos', error);
		}
	}

    const fetchUpdateReuneUploadFile = async (data) => {
		try {
			const response = await updateReuneUploadFile(data, token);
			console.log(response);
		} catch (error) {
			console.error('Error al cargar los datos', error);
		}
	}

    const fetchDeleteReuneFileByTicket = async (ticket) => {
		try {
			const response = await deleteReuneFileByTicket(ticket, token);
			console.log(response);
		} catch (error) {
			console.error('Error al cargar los datos', error);
		}
	}


    return (
        <div>
			<Breadcrumb title={props.title} subtitle={props.subtitle}/>
			<div className="row justify-content-center">
				<hr className="mb-4" />	
            </div>
        </div>
    )
}

export default Reune;