import React,  { useEffect, useState, useRef }from 'react';
import { useSelector } from 'react-redux';

import useAuthorization from '../../../../hooks/useAuthorization';

import { Card, CardBody, CardHeader }  from '../../../../components/card/card';
import Swal from 'sweetalert2'
import { Modal } from 'bootstrap'

import { errorNotification, successNotification, alert } from '../../../../helpers';

import { getPaquetesByEtapaId, deletePaqueteById } from '../../../../api/paquete_proyectos/paquete_proyectos';

const $ = require('jquery');

function TablePaquetes({ user_roles, paquetes, etapa_nombre, onSuccessPaquete, 
		onShowTableDerechohabientes, etapa_id, setSharedPaqueteId, setSharedDerechohabienteId, onOpenModal }) {
	const token = useSelector(state => state.authentication.user?.token);

    const tablePaquetesRef = useRef(null);


    useEffect(() => {
		if(user_roles.length > 0) {
			if (tablePaquetesRef.current !== null) {
				let index = 1;
				tablePaquetesRef.current.clear();
				tablePaquetesRef.current.rows.add(paquetes.map(paquete => [index++, paquete.id, "Paquete #" + paquete.numero, paquete.estatus_paquete]));
				tablePaquetesRef.current.draw();
			} else
				initTablePaquetes();
				// eslint-disable-next-line
		}
	}, [paquetes, user_roles]);


	const fetchDeletePaqueteById = async (id) => {
		try {
			const response = await deletePaqueteById(id, token);
			if(response.message === "success") {
				successNotification("Paquete Eliminado Correctamente");
				onSuccessPaquete(etapa_id);
			}
			else if(response.message === "failed")
				alert("No es posible eliminar el paquete.", "Existen derechohabientes asociados a este paquete", "error");
		} catch (error) {
			errorNotification("Ocurrio un error en el servidor.");
		}
	};


    const initTablePaquetes = () => {
		tablePaquetesRef.current = $('#tablePaquetes').DataTable({
			lengthMenu: [5, 10, 20, 30, 40],
			columns: [
				{ title: "#", width: "10%" },
				{ title: "ID" },
				{ title: "Nombre" },
				{ title: "Estatus" },
				{
					title: "",
					data: null,
					width: "20%",
					render: function (data, type, row, meta) {
						const paquete_id = row[1];
						const paquete_name = row[2];
						const paquete_estatus = row[3];
						if (['administracion'].some(role => user_roles.includes(role))) {
							return `<div style="text-align: right;">
										<a  style="cursor:pointer; margin-right:5px" id="editarPaquete" data-paquete-id="${paquete_id}"  data-paquete-name="${paquete_name}" >
											<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#F1C40F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
										</a>
										<a style="cursor:pointer" id="eliminarPaquete" data-paquete-id="${paquete_id}"  >
											<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#EE0C05" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
										</a>
									</div>`;
						} else if (['juridico'].some(role => user_roles.includes(role))) {
							if(paquete_estatus < 3) {
								return `<div style="text-align: right;">	
											<a style="cursor:pointer"  id="agregarDerechohabientes" data-paquete-id="${paquete_id}"  data-paquete-name="${paquete_name}" >
												<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#9B59B6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-user-plus"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><line x1="20" y1="8" x2="20" y2="14"></line><line x1="23" y1="11" x2="17" y2="11"></line></svg>	
											</a>
											<a style="cursor:pointer"  id="buscarDerechohabientes" data-paquete-id="${paquete_id}"  data-paquete-name="${paquete_name}" data-paquete-estatus="${paquete_estatus}" >
												<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#008080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
											</a>
										</div>`;
							} else {
									return `<div style="text-align: right;">	
												<a style="cursor:pointer"  id="buscarDerechohabientes" data-paquete-id="${paquete_id}"  data-paquete-name="${paquete_name}" data-paquete-estatus="${paquete_estatus}" >
												<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#008080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
												</a>
											</div>`;
							} 
							
						}  else if (['tecnico'].some(role => user_roles.includes(role))) {
							return `<div style="text-align: right;">	
										<a style="cursor:pointer"  id="verDetallePaquete" data-paquete-id="${paquete_id}"  data-paquete-name="${paquete_name}"  >
											<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#2874A6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>
										</a>
										<a  style="cursor:pointer" id="editarPaqueteTecnico" data-paquete-id="${paquete_id}"  data-paquete-name="${paquete_name}" >
											<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#F1C40F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
										</a>
										<a style="cursor:pointer"  id="buscarDerechohabientes" data-paquete-id="${paquete_id}"  data-paquete-name="${paquete_name}" data-paquete-estatus="${paquete_estatus}" >
											<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#008080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
										</a>
									</div>`;
						} else if (['master'].some(role => user_roles.includes(role))) {
							return `<div style="text-align: right;">
								${paquete_estatus < 3 ?
									`<a style="cursor:pointer"  id="agregarDerechohabientes" data-paquete-id="${paquete_id}"  data-paquete-name="${paquete_name}" >
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#9B59B6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-user-plus"><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="8.5" cy="7" r="4"></circle><line x1="20" y1="8" x2="20" y2="14"></line><line x1="23" y1="11" x2="17" y2="11"></line></svg>    
									</a>
									<a style="cursor:pointer"  id="buscarDerechohabientes" data-paquete-id="${paquete_id}"  data-paquete-name="${paquete_name}" data-paquete-estatus="${paquete_estatus}" >
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#008080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
									</a>` :
									`<a style="cursor:pointer"  id="buscarDerechohabientes" data-paquete-id="${paquete_id}"  data-paquete-name="${paquete_name}" data-paquete-estatus="${paquete_estatus}" >
										<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#008080" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path><circle cx="9" cy="7" r="4"></circle><path d="M23 21v-2a4 4 0 0 0-3-3.87"></path><path d="M16 3.13a4 4 0 0 1 0 7.75"></path></svg>
									</a>`}
								<a style="cursor:pointer"  id="verDetallePaquete" data-paquete-id="${paquete_id}"  data-paquete-name="${paquete_name}"  >
									<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#2874A6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>
								</a>
								<a  style="cursor:pointer" id="editarPaqueteTecnico" data-paquete-id="${paquete_id}"  data-paquete-name="${paquete_name}" >
									<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#800076" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
								</a>
								<a style="cursor:pointer" id="eliminarPaquete" data-paquete-id="${paquete_id}"  data-paquete-name="${paquete_name}"  >
									<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#EE0C05" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
								</a>
								
							</div>`;

						}
					},
					escape: false,
				}
			],
			columnDefs: [{
				targets: [1,3], 
				visible: false
			}]
		});

		$('#tablePaquetes').on('click', '#verDetallePaquete', function () {
			const id = $(this).data('paquete-id');
			const nombre = $(this).data('paquete-name');
			actionVerDetallePaquete(id, nombre);
		});
	
	
		$('#tablePaquetes').on('click', '#editarPaqueteTecnico', function () {
			const id = $(this).data('paquete-id');
			const nombre = $(this).data('paquete-name');
			actionEditarPaqueteTecnico(id, nombre);
		});
	
	
		$('#tablePaquetes').on('click', '#editarPaquete', function () {
			const id = $(this).data('paquete-id');
			const nombre = $(this).data('paquete-name');
			actionEditarPaquete(id, nombre);
		});
	
		$('#tablePaquetes').on('click', '#eliminarPaquete', function () {
			const id = $(this).data('paquete-id');
			const nombre = $(this).data('paquete-name');
			actionEliminarPaquete(id, nombre);
		});
	
		$('#tablePaquetes').on('click', '#agregarDerechohabientes', function () {
			const id = $(this).data('paquete-id');
			const nombre = $(this).data('paquete-name');
			actionAgregarDerechobientes(id, nombre);
		});
	
		$('#tablePaquetes').on('click', '#buscarDerechohabientes', function () {
			const id = $(this).data('paquete-id');
			const nombre = $(this).data('paquete-name');
			const estatus = $(this).data('paquete-estatus');
			actionBuscarDerechobientes(id, nombre, estatus);
		});		

	}

	
	const actionVerDetallePaquete = (id, nombre) => {
		setSharedPaqueteId(id);
		onOpenModal('DetallePaquete')
	}


	const actionEditarPaqueteTecnico = (id, nombre) => {
		setSharedPaqueteId(id);
		onOpenModal('EditarPaquete');
	}


    const actionEditarPaquete = (id, nombre) => {
		setSharedPaqueteId(id);
		onOpenModal('Paquete');
	}


	const actionEliminarPaquete = (id, nombre) => {
		Swal.fire({
			title: "¿Deseas eliminar el Paquete: " + nombre + "?",
			text: "Una vez eliminado ya no podrás acceder a esta Etapa",
			icon: "question",
			confirmButtonText: 'Continuar',
			cancelButtonText: 'Cancelar',
			showCancelButton: true,
			showCloseButton: true,
			allowOutsideClick: false,
			allowEscapeKey: false,
			allowEnterKey: false,
			reverseButtons : true
		}).then((result) => {
			if (result.isConfirmed) {
				confirmEliminarPaquete(id);
			}
		}).catch(() => {
			return false;
		})	
	}


	const confirmEliminarPaquete = (id) => {
		Swal.fire({
			title: "¿Estas seguro?",
			text: "Una vez eliminado ya no podrás acceder a este Paquete",
			icon: "question",
			confirmButtonText: 'Eliminar',
			cancelButtonText: 'Cancelar',
			showCancelButton: true,
			showCloseButton: true,
			allowOutsideClick: false,
			allowEscapeKey: false,
			allowEnterKey: false,
			reverseButtons : true
		}).then((result) => {
			if (result.isConfirmed) {
				fetchDeletePaqueteById(id);
			}
		}).catch(() => {
			return false;
		})
	}


	const actionAgregarDerechobientes = (id, nombre) => {
		setSharedDerechohabienteId(0);
		onOpenModal('Derechohabiente');
	}
	
	
	const actionBuscarDerechobientes = (id, nombre, estatus) => {
		onShowTableDerechohabientes(id, nombre, true, estatus);

	}

	
	const renderButtonAgregarPaquete = () => {
		if (['administracion', 'master'].some(role => user_roles.includes(role))) {
			return (
				<div className="ml-auto">
					<a className="btn btn-outline-default" onClick={() => { setSharedPaqueteId(0);  onOpenModal('Paquete'); }}>Registrar Paquete</a>
				</div>
			)
		} else if (['tecnico'].some(role => user_roles.includes(role))) {
			return (
				<div className="ml-auto">
					<a   className="btn btn-outline-default" style={{ visibility: 'hidden' }} >Calculos Proyecto</a>
				</div>
			)
		}
	}

    
	return (
        <div className="col-xl-6">
            <div className="list-group-item d-flex align-items-center" style={{marginBottom: '1em', marginTop: '-0.5em'}}>
                <div className="flex-1 text-break"></div>
				{ renderButtonAgregarPaquete() }
            </div>
            <div id="datatable" className="mb-4">
                <Card>
                    <CardHeader>
                        <span className="flex-grow-1">Lista de Paquetes. <b>{"Pertence a: " + etapa_nombre}</b></span>
                    </CardHeader>
                    <CardBody>
						<div className='col-12 tabla-scrollable table-responsive'>
							<table id="tablePaquetes" className="table table-striped text-nowrap w-100">
								<thead></thead>
								<tbody></tbody>
							</table>
						</div>
                    </CardBody>
                </Card>
            </div>
        </div>        
    );
}

export default TablePaquetes;
