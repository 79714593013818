import React,  { useEffect, useState }from 'react';
import { useSelector } from 'react-redux';

import { createUsuario, 
    getUsuarioById, 
    updateUsuarioRoleById, 
    updateUsuarioPasswordbyId, 
    updateUsuarioStatusById } from '../../../../api/usuarios/usuarios';

import { errorNotification, successNotification, isEmailValid, errorHTTP } from '../../../../helpers';

function ModalNuevoUsuario({ usuario_id, action, onCloseModal }) {
    const [formUsuario, setFormUsuario] = useState({});
    const [selectedTipo, setSelectedTipo] = useState("");
    const [selectedEstatus, setSelectedEstatus] = useState("");
    const [contrasenasCoinciden, setContrasenasCoinciden] = useState(true);


    const token = useSelector(state => state.authentication.user?.token);

    useEffect(() => {
        cleanForm();
        if(usuario_id > 0 && token !== undefined) 
            fetchUsuarioById();
    }, [usuario_id, token]);

    const fetchUsuarioById = async () => {
        try {
            const { message, status }  = await getUsuarioById(usuario_id, token);
            if(status === 200) {
                let role = message.rol_id.toString();
                let estatus = message.status.toString();
                setSelectedEstatus(estatus);
                setSelectedTipo(role);
                setFormUsuario(message);       
            } 
        } catch(error) { 
            errorHTTP(error, "Usuario inexistente.");
        }
    }

    const fecthCreateUsuario = async () => {
        try {
            const { status } = await createUsuario(formUsuario, token);
            if(status === 201) {
                successNotification("Usuario registrado con éxito.");
                finished();  
            }
        } catch(error) {
            errorHTTP(error, "El email o nombre de usuario ya está en uso. Por favor, elige otro.");
        }
    }

    const fetchUpdateUsuarioStatusById = async () => {
        try {
            const { status } = await updateUsuarioStatusById(usuario_id, selectedEstatus, token);
            if(status === 200) {
                successNotification("Usuario actualizado con éxito.");
                finished();
            }     
        } catch(e) { 
            errorHTTP(""); 
        }
    }

    const fetchUpdateUsuarioRoleById = async () => {
        try {
            const { status } = await updateUsuarioRoleById(usuario_id, selectedTipo, token);
            if(status === 200) 
                fetchUpdateUsuarioStatusById();
        } catch(e) { 
            errorHTTP(""); 
        }
    }

    const fetchUpdateUsuarioPasswordbyId = async () => {
        try {
            let data = {
                password: formUsuario.password
            }
            const { status } = await updateUsuarioPasswordbyId(usuario_id, data, token);
            if(status === 200) 
                fetchUpdateUsuarioRoleById();
        } catch (error) { 
            errorNotification(""); 
        }
    }

	const handleChangeFormUsuario = (event) => {
		const { name, value } = event.target;
		setFormUsuario({
			...formUsuario,
			[name]: value,
		});
        if (name === "repeat_password") {
            if (formUsuario.password === value) {
                setContrasenasCoinciden(true);
            } else {
                setContrasenasCoinciden(false);
            }
        }	
	};


    const handleAccionUsuario = (e) => {
        e.preventDefault(); 
        if (e.target.form.checkValidity()) {
            if(action === "Nuevo") {
                if(isEmailValid(formUsuario.email)) {
                    try {
                        if(formUsuario.rol_id > 0) {
                            if(contrasenasCoinciden) 
                                fecthCreateUsuario(); 
                            else
                            errorNotification("Las contraseñas no coinciden.");
                        }
                    } catch (e) {
                        console.log(e);
                    }
                }
            } else {
                if(formUsuario.password  && formUsuario.repeat_password) fetchUpdateUsuarioPasswordbyId();
                else fetchUpdateUsuarioRoleById();
            }
        }
        else errorNotification("Faltan campos requeridos.");
	}

    const handleTiposChange = (event) => {
		const selectedId = event.target.value;
		setSelectedTipo(selectedId);  
        setFormUsuario({
            ...formUsuario,
            ["rol_id"]: parseInt(selectedId)
        });
	};

    const handleEstatusChange = (event) => {
		const selectedId = event.target.value;
		setSelectedEstatus(selectedId);
	};


    const cleanForm = () => {
        setFormUsuario({
            username: "",
            email: "",
            password: "",
            repeat_password: "",
            rol_id: "   "
        });
        setSelectedTipo("");
    }

    const finished = () => {
        document.getElementById('modalUsuario').classList.remove('show');
        document.querySelector('.modal-backdrop').remove();
        document.getElementById('modalUsuario').style.display = 'none';

        cleanForm();

        onCloseModal(); 
    }


    return(
        <div className="modal modal-cover fade" id="modalUsuario" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Nuevo Usuario</h5>
                        <button type="button" className="btn-close" onClick={finished}></button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3">
                            <form id="formNuevoUsuario" className="was-validated" >
                                <div className="row row-space-12">	
                                    <div className="col-xl-12">
                                        <div className="mb-3 row" >
                                            <div className="col-6">
                                                <label className="form-label">Nombre de Usuario</label>
                                                <input className="form-control" placeholder="Nombre"
                                                    id="username"
                                                    name="username"
                                                    value={formUsuario.username}
                                                    onChange={handleChangeFormUsuario}
                                                    required
                                                    disabled={action === "Editar" ? true : false}
                                                    pattern=".{5,}" />
                                                <div className="invalid-feedback" id="">
                                                    El nombre de usuario debe tener al menos 5 caracteres.
                                                </div>	
                                            </div>
                                            <div className="col-6">
                                                <label className="form-label">Email</label>
                                                <input className="form-control" placeholder="Email"
                                                    id="email"
                                                    name="email"
                                                    value={formUsuario.email}
                                                    onChange={handleChangeFormUsuario}
                                                    disabled={action === "Editar" ? true : false}
                                                    type='email'
                                                    required
                                                    pattern=".*\S+.*" />
                                                <div className="invalid-feedback" id="">
                                                    Campo requerido
                                                </div>	
                                            </div>
                                        </div>
                                        <div className="mb-3 row" >
                                            <div className='col-xl-6'>
                                                <label className="form-label">Contraseña</label>
                                                <input className="form-control" placeholder="Contraseña"
                                                    id="password"
                                                    name="password"
                                                    value={formUsuario.password}
                                                    onChange={handleChangeFormUsuario}
                                                    type='password'
                                                    required={action === "Registrar" ? true : false}
                                                    pattern=".{8,}"  />
                                                <div className="invalid-feedback" id="">
                                                    La contraseña debe tener al menos 8 caracteres.
                                                </div>	
                                            </div>
                                            <div className='col-xl-6'>
                                                <label className="form-label">Repetir Contraseña</label>
                                                <input className="form-control" placeholder="Repetir Contraseña"
                                                    id="repeat_password"
                                                    name="repeat_password"
                                                    value={formUsuario.repeat_password}
                                                    onChange={handleChangeFormUsuario}
                                                    type='password'
                                                    required={action === "Registrar" ? true : false}
                                                    pattern={`^${formUsuario.password ? formUsuario.password.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&") : ""}$`} />
                                                <div className="invalid-feedback" id="">
                                                    La contraseña deben de ser igual.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3 row" >
                                            <div className='col-xl-6'>
                                                <label className="form-label">Tipo de Usuario</label>
                                                <select className="form-select" name="tipos"  onChange={handleTiposChange} value={selectedTipo} required>
                                                    <option value="">Seleccione un opción</option>
                                                    <option value="2">Administrador</option>
                                                    <option value="3">Tecnico</option>
                                                    <option value="4">Juridico</option>
                                                    <option value="5">Desarrollador</option>
                                                    <option value="6">Direccion</option>
                                                </select>
                                            </div>
                                            {
                                            action === "Editar" && (
                                                <div className='col-xl-6'>
                                                    <label className="form-label">Estatus</label>
                                                    <select className="form-select" name="tipos"  onChange={handleEstatusChange} value={selectedEstatus} >
                                                        <option value="1">Activo</option>
                                                        <option value="0">Inactivo</option>
                                                    </select>
                                                </div>
                                            )
                                        }
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-default" onClick={finished}>Cerrar</button>
                        <button type="button" className="btn btn-outline-theme"  onClick={handleAccionUsuario} form="formNuevoUsuario" >{action}</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ModalNuevoUsuario;