const Menu = [
    { is_header: true, title: 'Navigation' },
    { path: '/dashboard', icon: 'bi bi-cpu', title: 'Dashboard' },
    { path: '/usuarios', icon: 'bi bi-people-fill', title: 'Usuarios' },
    { path: '/proyecto/lista', icon: 'bi bi-grid-3x3', title: 'Proyectos' },
    { path: '/vivienda/acreditados', icon: 'fas fa-building', title: 'Viviendas' },
    { path: '/catalogo', icon: 'fas fa-folder-open', title: 'Catálogos',
        children: [
            { path: '/catalogo/bancos', title: 'Bancos' },
            { path: '/catalogo/promotores', title: 'Promotores' }
        ]
    },
    { path: '/estados/cuenta', icon: 'fas fa-dollar-sign', title: 'Estados de Cuenta' },
    { path: '/supervision', icon: 'fas fa-briefcase', title: 'Supervisión',
        children: [
            { path: '/supervision/anticipos/autorizar', title: 'Autorizar Anticipos' },
            { path: '/supervision/obra', title: 'Obra' },
            { path: '/supervision/garantia', title: 'Fondo en Garantía' }
        ]
    },
    { path: '/inversiones', title: 'Inversiones', icon: 'fa-solid fa-money-bill-trend-up'},
    { path: '/historial/inconsistencias', icon: 'fas fa-file-pdf', title: 'Historial de Inconsistencias' },
    { path: '/condusef', icon: 'fa-solid fa-industry', title: 'CONDUSEF',
        children: [
            { path: '/condusef/redeco', title: 'REDECO' },
            { path: '/condusef/reune', title: 'REUNE' }
        ]
    },
    { path: '/reportes', icon: 'fas fa-file-alt', title: 'Reportes',
        children: [
            { path: '/reportes/general', title: 'General' },
            { path: '/reportes/paquetes', title: 'Paquetes' }
        ]
    }
];

export default Menu;
