import { createSlice } from "@reduxjs/toolkit";
import { login } from "./thunks";

export const authenticationSlice = createSlice({
    name: 'authetication',
    initialState: {
        user: localStorage.getItem("user") !== undefined ? JSON.parse(localStorage.getItem("user")) : null,
        isLoading: false,
        error: null
    },
    reducers: {
        logout: (state) => {
            state.user = null;
            localStorage.removeItem('user');
        },
        setTokenAccess: (state, action) => {
            state.user.token_access = action.payload;
            localStorage.setItem('user', JSON.stringify(state.user));
        }
    },
    extraReducers: (builder) => {
    	builder
      		.addCase(login.pending, (state) => {
            	state.isLoading = true;
            	state.error = null;
          	})
          	.addCase(login.fulfilled, (state, action) => {
                state.isLoading = false;

        		const user = action.payload;
                localStorage.setItem('user', JSON.stringify(user));
                
            	state.user = user;
          	})
          	.addCase(login.rejected, (state, action) => {
                state.isLoading = false;
            	state.error = action.payload;
          	});
    },
});

export const { logout, setTokenAccess } = authenticationSlice.actions;