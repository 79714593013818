import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getEtapasByProyectoId } from '../../api/etapa_proyectos/etapa_proyectos';

function SelectEtapas({ proyecto_id, setSharedEtapaId, setSharedPaqueteId }) {
    const token = useSelector(state => state.authentication.user?.token);

    const [etapas, setEtapas] = useState([]);
    const [selectedEtapa, setSelectedEtapa] = useState(0);

    useEffect(() => { 
        if(proyecto_id > 0) 
    	    fetchEtapasByProyectoId(proyecto_id);
        else {
            setEtapas([]);
            setSelectedEtapa(0);
            setSharedEtapaId(0);
        }
	}, [proyecto_id]);

    const fetchEtapasByProyectoId = async (proyecto_id) => {
		try {
			const response = await getEtapasByProyectoId(proyecto_id, token);
            setEtapas(response);
		} catch (error) {
			console.error('Error al cargar los bancos', error);
		}
	};

    const handleEtapaChange = (event) => {
		const selectedId = event.target.value;
		setSelectedEtapa(selectedId);
        setSharedEtapaId(selectedId);
        setSharedPaqueteId(0);
    };

    return(
        <div className="form-group mb-3">
            <label className="form-label" htmlFor="etapas" ><strong>Etapa</strong></label>
            <select className="form-select" id="etapas"  onChange={handleEtapaChange} value={selectedEtapa} >
                <option value="0">Selecciona un etapa</option>
                {etapas.map((etapa) => (
                    <option key={etapa.id} value={etapa.id}>
                        {etapa.nombre}
                    </option>
                ))}
            </select>
        </div>
    )
}

export default SelectEtapas;