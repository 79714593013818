import React,  { useEffect, useState, useRef }from 'react';
import { useSelector } from 'react-redux';

import { Card, CardBody, CardHeader }  from '../../../../../components/card/card';
import Swal from 'sweetalert2';
import { Modal } from 'bootstrap';

import { formatoMoneda, successNotification, errorNotification, alert } from '../../../../../helpers';

import { 
    getDerechohabientesAnticposById,
    getDerechohabienteValorLoteByPaqueteId
} from '../../../../../api/derechohabiente/derechohabiente';

import { 
    getPaqueteTotalFirmadosById,
    getPaqueteTotalTodosById,
    updatePaqueteCerrarTecnicoById,
    updatePaqueteAbrirById
} from '../../../../../api/paquete_proyectos/paquete_proyectos';

import jsZip from 'jszip';

const $ = require('jquery');

// This line was the one missing
window.JSZip = jsZip;

function TableTecnico({ paquete_id, paquete_nombre, estatus_paquete, 
        setSharedDerechohabienteId, derechohabientes, onSuccessDerechohabiente, onOpenModal }) {
    const token = useSelector(state => state.authentication.user?.token);

    let tableDerechohabientesRef = useRef(null);

    const [titleTable, setTitleTable] = useState("");
    const [titleEstatus, setTitleEstatus] = useState("");
    const [showEstatusPaquete, setShowEstatusPaquete] = useState(false);
    const [nuevoEstatus, setNuevoEstatus] = useState([]);
    

    useEffect(() => {
        if(paquete_id !== null && token !== undefined) {
            setTitleTable("");
            setNuevoEstatus(estatus_paquete);
            
        }
    }, [paquete_id, token]);


    useEffect(() => {
		if (tableDerechohabientesRef !== null && tableDerechohabientesRef.current !== null) {
			let index = 1;
			tableDerechohabientesRef.current.clear();
			tableDerechohabientesRef.current.rows.add(
                derechohabientes.map(dech => [index++, //0 
                                                dech.dh.id, //1
                                                dech.dh.nombres + " " + dech.dh.apellido_paterno + " " + dech.dh.apellido_materno, //2
                                                dech.dh.curp, //3
                                                dech.dh.num_credito, //4
                                                dech.dh.num_ssocial, //5
                                                dech.direccion.calle, //6
                                                dech.direccion.manzana, //7
                                                dech.direccion.lote, //8
                                                dech.direccion.num_interior, //9
                                                dech.direccion.num_exterior, //10
                                                dech.dh.monto_ministracion, //11
                                                dech.dh.monto_subsidio, //12
                                                dech.dh.numero_instrumento, //13
                                                dech.dh.volumen, //14
                                                dech.dh.firma_folio, //15
                                                dech.dh.estatus_firma_folio === '1' ? 'Firmado' : (dech.dh.estatus_firma_folio === '2' ? 'No Firmado' : (dech.dh.estatus_firma_folio === '3' === 'Firmado pero no forma parte del paquete' ))])); //16
            tableDerechohabientesRef.current.draw();
            //totalDepositos();
		} else 
			initDatatableDerechohabientes();		
		// eslint-disable-next-line
	}, [derechohabientes]);


    useEffect(() => {
        setTitle();
        showColumnActions();
    }, [nuevoEstatus]);


    const fetchDerechohabientesAnticiposById = async(id, nombre, estatus_firma, ministracion, subsidio) => {
        try {
            const response = await getDerechohabientesAnticposById(id, token);  
            if(estatus_firma !== "Firmado") {
                alert("Error de consulta", "El derechohabiente: "+ nombre +" , no esta firmado  y por lo tanto no forma parte del paquete,contactate con el departamento de Jurídico para consultar la información", "warning");
            } else if (ministracion != parseFloat(response[0].ministracion_paquete)){
                alert(
                    "Depósito de INFONAVIT incorrecta",
                    "El valor de la Ministración no es correcta, contactate con el departatmento de Juridico para solucionarlo",
                    "warning");
            }  else if (subsidio != parseFloat(response[0].subsidio_paquete)) {
                alert(
                    "Depósito de CONAVI incorrecta",
                    "El valor del depósito de  CONAVI no es correcta, contactate con el departatmento de Jurídico para solucionarlo",
                    "warning"
                );
            } else {
                setSharedDerechohabienteId(id)
                onOpenModal('CalculosAnticipos');
            }
        } catch (error) {
            console.log("Ocurrio un error en el servidor.", error);
        }
    }


    const fetchDerechohabienteValorLoteByPaqueteId = async() => {
        try {
            const response = await getDerechohabienteValorLoteByPaqueteId(paquete_id, token);
            if(derechohabientes.length == 0) {
                alert("No puede cerrar el paquete", "No existen registros en el paquete", "error");
            } else {
                if(response.message == 0) {
                    alert("No puede cerrar el paquete", "Todos los acreditados deben tener un valor de lote.", "error");
                } else {
                    fetchPaqueteTotalFirmadosById()
                }
            }
        } catch(error) {
            errorNotification("Ocurrio un error en el servidor.", error);
        }
    }


    const fetchPaqueteTotalFirmadosById = async () => {
        try {
            const response = await getPaqueteTotalFirmadosById(paquete_id, token);
            fetchPaqueteTotalTodosById(response);
        } catch (error) {
            console.log("Ocurrio un error en el servidor.", error);
        }
    }


    const fetchPaqueteTotalTodosById = async (response1) => {
        try {
            const response = await getPaqueteTotalTodosById(paquete_id, token);
            actionCerrarPaqueteTecnico(response1.message, response.message, 3)
        } catch (error) {
            console.log("Ocurrio un error en el servidor.", error);
        }
    }


    const fetchUpdatePaqueteCerrarTecnicoById = async (data) => {
        try {
            const response = await updatePaqueteCerrarTecnicoById(paquete_id, data, token);
            if(response.message === "success") {
                successNotification("Paquete cerrado correctamente.");
                setNuevoEstatus(data.estatus_paquete);
                //fetchDerechohabientesByPaqueteId();
            } else if(response.message === "failed") {
                errorNotification("No fue posible cerrar el paquete.");
            }
        } catch (error) {
            console.log("Ocurrio un error en el servidor.", error);
        }
    }


    const fetchUpdatePaqueteAbrirById = async (estatus_paquete) => {
        try {
            const response = await updatePaqueteAbrirById(paquete_id, estatus_paquete, token);
            if(response.message === "success") {
                successNotification("Abriste el paquete correctamente.....")
                setNuevoEstatus(estatus_paquete);
            } else if(response.message === "failed") {
                errorNotification("No fue posible abrir el paquete.");
            }
        } catch (error) {
            console.log("Ocurrio un error en el servidor.", error);
        }
    }


    const initDatatableDerechohabientes = () => {
        //fetchDerechohabientesByPaqueteId();
        tableDerechohabientesRef.current = $('#tableDerechohabientesTecnico').DataTable({
            dom: "<'row mb-3'<'col-md-4 mb-3 mb-md-0'l><'col-md-8 text-end'<'d-lg-flex justify-content-end'f<'d-lg-block d-none'B>>>>t<'row align-items-center'<'mr-auto col-md-6 mb-3 mb-md-0 mt-n2 'i><'mb-0 col-md-6'p>>",		
            lengthMenu: [10, 20, 30, 40, 50],
            buttons: [
				{ 
					extend: 'excelHtml5', 
					className: 'btn btn-outline-default btn-sm',
					filename: function() {
						var timestamp = new Date().getTime(); 
						return 'derechohabientes_' + timestamp; 
					},
					exportOptions: {
						columns: ':visible',
						modifier: {
							page: 'none' // Evita agregar información de paginación
						}
					},
					customizeData: function ( data ) {
						for (var i=0; i < data.body.length; i++){
							for (var j=0; j<data.body[i].length; j++ ){
								data.body[i][j] = '\u200C' + data.body[i][j];
							}
						}
					}
				}
			],
            columns: [
                { title: "#" },//0
                { title: "ID" }, //1 
                { title: "Derechohabiente" }, //2 
                { title: "CURP" }, // 3
                { title: "No. Crédito" }, //4
                { title: "NSS" }, //5
                { title: "Dirección" }, // 6
                { title: "Mza." }, // 7
                { title: "Lote" }, // 8
                { title: "No. Ext." }, //9
                { title: "No. Int." }, // 10
                { 
                    title: "Dep. Infonavit",
                    width: "150px",
                    render: function (data, type, row, meta) {
                        let number = row[11]?.replace(/,/g, '') || 0;
                        return `<div style="white-space: nowrap;">${formatoMoneda.format(parseFloat(number))}</div>`;
                    },
                }, //11
                { 
                    title: "Dep. CONAVI",
                    width: "150px",
                    render: function (data, type, row, meta) {
                        let number = row[12]?.replace(/,/g, '') || 0;
                        return `<div style="white-space: nowrap;">${formatoMoneda.format(parseFloat(number))}</div>`;
                    },
                }, //12
                { title: "No. Instrumento" }, //13
                { title: "Vol." }, //14
                { title: "Folio" }, //15
                { 
                    title: "Firma",  //16
                    width: "250px",
                    render: function (data, type, row, meta) {
                        return `<div style="white-space: nowrap;">${row[16]}</div>`;
                    },
                },{
                    title: "",
                    data: null,
                    width: "200px",
                    render: function (data, type, row, meta) {
                        const derechohabiente_id = row[1];
                        const derechohabiente_name = row[2];
                        const ministracion = row[11];
                        const subsidio = row[12];
                        const estatus_firma = row[16];
                        
                        return `<div style="text-align: right; white-space: nowrap;">
                                    <a  style="cursor:pointer" id="anticipos" data-derechohabiente-id="${derechohabiente_id}"  data-derechohabiente-name="${derechohabiente_name}" data-estatus-firma="${estatus_firma}" data-ministracion="${ministracion}" data-subsidio="${subsidio}" >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#008000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
                                    </a>
                                    <a style="cursor:pointer" id="comisiones" data-derechohabiente-id="${derechohabiente_id}"  data-derechohabiente-name="${derechohabiente_name}"  >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#AE38DE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file-text"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="16" y1="13" x2="8" y2="13"></line><line x1="16" y1="17" x2="8" y2="17"></line><polyline points="10 9 9 9 8 9"></polyline></svg>
                                    </a>  
                                </div>`;
                        
                    },
                    escape: false,
                }
            ],
            columnDefs: [{
                targets: [1], 
                visible: false
            }]
        }); 

        $('#tableDerechohabientesTecnico').on('click', '#anticipos', function () {
            const id = $(this).data('derechohabiente-id');
            const nombre = $(this).data('derechohabiente-name');
            const estatus_firma = $(this).data('estatus-firma');
            const ministracion = $(this).data('ministracion');
            const subsidio = $(this).data('subsidio');
            
            actionAnticiposDerechohabiente(id, nombre, estatus_firma, ministracion, subsidio);
        });
    
        $('#tableDerechohabientesTecnico').on('click', '#comisiones', function () {
            const id = $(this).data('derechohabiente-id');
            const nombre = $(this).data('derechohabiente-name');
           
            actionComisionesDerechohabiente(id, nombre);
        });
	}


    const actionAnticiposDerechohabiente = (id, nombre, estatus_firma, ministracion, subsidio) => {
        fetchDerechohabientesAnticiposById(id, nombre, estatus_firma, ministracion, subsidio);
    }


    const actionComisionesDerechohabiente = (id, nombre) => {
        setSharedDerechohabienteId(id)
        onOpenModal('CalculosComisiones');
    }


    const setTitle = () => {
        if(nuevoEstatus < 3) {
            setShowEstatusPaquete(true);
            setTitleEstatus("Cerrar");
            setTitleTable("( " + paquete_nombre + " - Paquete Abierto )");
        } else if(nuevoEstatus === 3) {
            setShowEstatusPaquete(true);
            setTitleEstatus("Abrir");
            setTitleTable("( " + paquete_nombre + " - Paquete Cerrado )");
        } else {
            setShowEstatusPaquete(false);
            setTitleTable("( " +paquete_nombre + " - Paquete Cerrado por ANTICIPO )");
        }
    }


    const showColumnActions = () => {
        const table = tableDerechohabientesRef.current;
        const columnIndex = 17;
        if (nuevoEstatus < 3) {
            table.column(columnIndex).visible(true);
        } else {
            table.column(columnIndex).visible(false);
        } 
    }


    const handleUpdateEstatus = () => {
        Swal.fire({
			text: "¿Deseas " + titleEstatus.toLowerCase() + " el Paquete: " + paquete_nombre + "?",
			icon: "question",
			confirmButtonText: 'Continuar',
			cancelButtonText: 'Cancelar',
			showCancelButton: true,
			showCloseButton: true,
			allowOutsideClick: false,
			allowEscapeKey: false,
			allowEnterKey: false,
			reverseButtons : true
		}).then((result) => {
			if (result.isConfirmed) {
                if(titleEstatus === "Abrir") {
                    actionAbrirPaquete(2);
                } else {
                    cerrarPaqueteTecnico();
                }
			}
		}).catch(() => {
			return false;
		});
    }


    const actionAbrirPaquete = (estatus_paquete) => {
        Swal.fire({
			title: "¿Deseas abrir el paquete?",
            text: "Debes validar los datos...",
            icon: "warning",
			confirmButtonText: 'Continuar',
			cancelButtonText: 'Cancelar',
			showCancelButton: true,
			showCloseButton: true,
			allowOutsideClick: false,
			allowEscapeKey: false,
			allowEnterKey: false,
			reverseButtons : true
		}).then((result) => {
			if (result.isConfirmed) {
                if (estatus_paquete < 4)
                    fetchUpdatePaqueteAbrirById(estatus_paquete);
            }
		}).catch(() => {
			return false;
		});
    } 


    const actionCerrarPaqueteTecnico = (valor_paquete_firmados, valor_total_paquete, estatus_paquete) => {
        Swal.fire({
			title: "¿Deseas cerrar el paquete?",
            text: "Debes validar los datos...",
            icon: "warning",
			confirmButtonText: 'Continuar',
			cancelButtonText: 'Cancelar',
			showCancelButton: true,
			showCloseButton: true,
			allowOutsideClick: false,
			allowEscapeKey: false,
			allowEnterKey: false,
			reverseButtons : true
		}).then((result) => {
			if (result.isConfirmed) {
               let data = {
                    valor_paquete_firmados,
                    valor_total_paquete, 
                    estatus_paquete
               }
               fetchUpdatePaqueteCerrarTecnicoById(data);
            }
		}).catch(() => {
			return false;
		});
    }


    const cerrarPaqueteTecnico = async () => {
        if (estatus_paquete < 2){
            alert("No puede cerrar el paquete", 
                    "Requiere que el paquete sea cerrado previamente por Administración.", 
                    "error");
        } else if(estatus_paquete > 3){
            alert("Paquete Cerrado", "Cerrado por anticipo", "info");
        } else if(estatus_paquete == 2) {
            fetchDerechohabienteValorLoteByPaqueteId();
        }
    }


    return (
        <div className="col-xl-12">
            <div className="row justify-content-center mb-4">
                <div className="col-md-6">
                    <div className='row'>
                        <div className="col-xl-6">
                        
                        </div>
                        <div className="col-xl-2">
                        </div>
                    </div>
                </div>
                <div className="col-md-6 text-end">
                    { showEstatusPaquete && (
                        <button  className="btn btn-outline-default" style={{marginTop: "1.5em"}} onClick={() => handleUpdateEstatus()} >{titleEstatus} Paquete</button>
                    )}
                </div>
            </div>
            <div id="datatable" className="mb-4">
                <Card> 
                    <CardHeader>
                        <span className="flex-grow-1">Lista de Derechohabientes. <strong>{titleTable}</strong></span>
                    </CardHeader>
                    <CardBody>
                        <>
                            <hr className="mb-4" />
                            <div className='row tabla-scrollable table-responsive'>
                                <table id="tableDerechohabientesTecnico" className="table  w-100 table-striped table-derechohabientes">
                                    <thead></thead>
                                    <tbody></tbody>
                                </table>
                            </div>
                        </>
                    </CardBody>
                </Card>
            </div>
        </div>
    )

}


export default TableTecnico;