import React from "react";

function Breadcrumb(props) {
    return(
        <ul className="breadcrumb">
				<li className="breadcrumb-item"><a href="#/">{props.title}</a></li>
				<li className="breadcrumb-item active">{props.subtitle}</li>
		</ul>
    )
}

export default Breadcrumb;