import React from 'react';

import useAuthorization from '../../../hooks/useAuthorization';

import { Card, CardBody }  from '../../../components/card/card';
import { Link } from 'react-router-dom';
import Breadcrumb from '../../../components/breadcrumb/breadcrumb';

import TablaProyectos from './tablas/proyectos';


function ProyectoLista(props) {		
	const userRole = useAuthorization();

	const renderButtonNuevoProyecto = () => {
		if (['administracion', 'master'].some(role => userRole.includes(role))) {
			return (
				<div className="ml-auto">
					<Link to="/proyecto/nuevo"  className="btn btn-outline-default">Nuevo Proyecto</Link>
				</div>
			)
		}
	}

	return (
		<div>
			<Breadcrumb title={props.title} subtitle={props.subtitle}/>
			<div className="row justify-content-center">
				<hr className="mb-4" />
				<div className="list-group-item d-flex align-items-center" style={{marginRight: '1.5em', marginBottom: '1em', marginTop: '-0.5em'}}>
					<div className="flex-1 text-break"></div>
					{ renderButtonNuevoProyecto() }
				</div>
				<div id="datatable" className="mb-5">
					<Card>
						<CardBody>
							<TablaProyectos  />
						</CardBody>
					</Card>
				</div>
			</div>
		</div>
	)
}

export default ProyectoLista;
