import { toast } from 'react-toastify';

const getDefaultConfig = () => ({
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark"
  // Otras opciones de configuración predeterminadas que desees
});

export const successNotification = (message) => {
  toast.success(message, getDefaultConfig());
};

export const errorNotification = (message) => {
  toast.error(message, getDefaultConfig());
};

export const warningNotification = (message) => {
  toast.warning(message, getDefaultConfig());
};

export const infoNotification = (message) => {
  toast.info(message, getDefaultConfig());
};