import Swal from 'sweetalert2';


export const alert = (title, text, type) => {
    Swal.fire({
        title: title,
        text: text,
        icon: type,
        confirmButtonText: 'Continuar',
        showCloseButton: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        reverseButtons : true
    })
}


