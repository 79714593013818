import React, {useEffect, useState, useRef	} from 'react';
import { useSelector } from 'react-redux';

import { Card, CardBody }  from '../../../components/card/card';
import Breadcrumb from '../../../components/breadcrumb/breadcrumb';
import { Modal } from 'bootstrap';

import { getBancos, getBancosPromotores } from '../../../api/bancos/bancos';

import ModalBanco from './modals/banco';
import TablaBancos from './tablas/bancos';
import TablaBancosPromotores from './tablas/bancos-promotores';

function CatalogoBancos(props) {
	const token = useSelector(state => state.authentication.user?.token);

	const [bancos, setBancos] = useState([]);
	const [bancosPromotores, setBancosPromotores] = useState([]);
	const [type, setType] = useState(0);
	const [bancoId, setBancoId] = useState(0);

	const [isModalOpen, setIsModalOpen] = useState(false);
    const [activeModal, setActiveModal] = useState("");

	
	useEffect(() => { 
    	fetchBancos();
		fetchBancosPromotores();
	}, [token]);


	useEffect(() => {
		if(isModalOpen && activeModal === 'NuevoBanco') {
			let myModal = new Modal(document.getElementById('modalNuevoBanco'));
			myModal.show();
		} 
	}, [isModalOpen, activeModal]);


	const fetchBancos = async () => {
		try {
			const response = await getBancos(token);
			setBancos(response)
		} catch (error) {
			console.error('Error al cargar los bancos', error);
		}
	}
	

	const fetchBancosPromotores = async () => {
		try {
			const response = await getBancosPromotores(token);
			setBancosPromotores(response)
		} catch (error) {
			console.error('Error al cargar los bancos', error);
		}
	}


	const handleSuccessBancos = () => {
		fetchBancos();
		fetchBancosPromotores();
	}


	const handleOpenModal = (modalName) => {
        setIsModalOpen(true);
        setActiveModal(modalName);
    };


	const handleCloseModal = () => {
        setIsModalOpen(false);
        setActiveModal("");
		document.body.style.overflow = 'auto';
    };

	
	return (
		<div>
			<Breadcrumb title={props.title} subtitle={props.subtitle}/>
			<div className="row justify-content-center">
				<hr className="mb-4" />	
				<div className="list-group-item d-flex align-items-center" style={{marginRight: '1.5em', marginBottom: '1em', marginTop: '-0.5em'}}>
					<div className="flex-1 text-break">
						{/* Contenido principal */}
					</div>
					<div className="ml-auto">
						{/* Botón */}
						<a  className="btn btn-outline-default" onClick={() => { setType(1); handleOpenModal("NuevoBanco"); }}>Nuevo Banco</a>
					</div>
				</div>
				<div id="datatable" className="mb-5">
					<Card>
						<CardBody>
							<>
								<ul className="nav nav-tabs pt-3 ps-4 pe-4">
									<li className="nav-item me-3"><a href="#tabTtableBancos" className="nav-link active" data-bs-toggle="tab">Bancos</a></li>
									<li className="nav-item me-3"><a href="#tabTableCuentasDAE" className="nav-link" data-bs-toggle="tab">Cuentas DAE</a></li>
								</ul>
								<div className="tab-content p-4">
									<div className="tab-pane fade show active" id="tabTtableBancos">
										<TablaBancos  bancos={bancos} setType={setType} setBancoId={setBancoId} onOpenModal={handleOpenModal} onSuccessBancos={handleSuccessBancos} />
									</div>
									<div className="tab-pane fade" id="tabTableCuentasDAE">
										<TablaBancosPromotores  bancos={bancosPromotores} setType={setType} setBancoId={setBancoId} onOpenModal={handleOpenModal} onSuccessBancos={handleSuccessBancos} />
									</div>
								</div>
							</>
						</CardBody>
						
					</Card>
				</div>
			</div>
			{ isModalOpen && activeModal === "NuevoBanco" && <ModalBanco type={type}  bancoId={bancoId} onSuccessBancos={handleSuccessBancos} onCloseModal={handleCloseModal} /> }
			
		</div>
	)
}

export default CatalogoBancos;