import React from 'react';

import { serverUrl } from '../../helpers';


function Documento({ documento }) {

    return (
        <div style={{"fontStyle" : "italic"}}>
            {
                documento !== "Sin Documento" ? (
                    <a href={serverUrl + "/file/download/" + documento}   id="archivoPDF" name="archivoPDF" target="_blank">{ documento }</a> 
                ) : (
                    <span>{ documento }</span>
                )
                
            }
        </div>
    )
}

export default Documento;