import React,  { useEffect, useState, useRef }from 'react';
import { useSelector } from 'react-redux';

import { Card, CardBody, CardFooter, CardHeader } from '../../../../../../components/card/card';
import SelectProyectos from '../../../../../../components/select/proyectos';
import SelectEtapas from '../../../../../../components/select/etapas';
import SelectPaquetes from '../../../../../../components/select/paquetes';
import UploadFile from '../../../../../../components/upload/upload_file';
import SelectMinistraciones from '../../../../../../components/select/ministraciones';

import { updatePaqueteAnticipoAdminById, getPaqueteAnticipoAdminById, getPaqueteById } from '../../../../../../api/paquete_proyectos/paquete_proyectos';
import { updateMinistracionAvanceAdminByNumero, getMinistracionListaNumeroByPaqueteId, getMinistracionAvanceAdminByNumero, getMinistracionesPorcentajeObraByPaqueteId, getMinistracionNetaById, getMinistracionesPorcentajeObraNumeroByPaqueteId, getMinistracionValorActualById } from '../../../../../../api/ministraciones/ministraciones';
import TablaAvanceObra from './avance_obra';
import CalcularDatos from '../calcular_datos';
import Documento from '../../../../../../components/upload/documento';
import { alert } from '../../../../../../helpers';
import { getDerechohabienteValorPaqueteByPaqueteId } from '../../../../../../api/derechohabiente/derechohabiente';

function ObraAdministracion() {
    const token = useSelector(state => state.authentication.user?.token);

    const [sharedProyectoId, setSharedProyectoId] = useState(0);
	const [sharedEtapaId, setSharedEtapaId] = useState(0);
	const [sharedPaqueteId, setSharedPaqueteId] = useState(0);
    const [sharedMinistracionId, setSharedMinistracionId] = useState(0);

    const [sharedMinistraciones, setSharedMinistraciones] = useState([]);

    const [showUplaodFile, setShowUploadFile] = useState(false);
	
    const [avanceObra, setAvanceObra] = useState([]);

    const [documento, setDocumento] =  useState("");

    const [porcentaje, setPorcentaje] = useState("0.00");
    const [ministracionNeta, setMinistracionNeta] = useState("0.00");
    const [valorPaquete, setValorPaquete] = useState("0.00");
	const [ministracionValor, setMinistracionValor] = useState({
        "anticipo": "0.00",
        "ministracion": "0.00",
        "valor_actual": "0.00"
    });

	
    useEffect(() => {
		if(sharedProyectoId == 0) {
			setSharedEtapaId(0);
			setSharedPaqueteId(0);
            setSharedMinistracionId(0);
		}
	}, [sharedProyectoId]);

    useEffect(() => {
		if(sharedPaqueteId > 0)  {
			fetchPaqueteById(sharedPaqueteId);
        }
	}, [sharedPaqueteId]);

    useEffect(() => {
        if(sharedMinistracionId > 0) {
            fetchMinistracionListaNumeroByPaqueteId(sharedPaqueteId, sharedMinistracionId);
			setShowUploadFile(true);
            fetchMinistracionAvanceAdminByNumero(sharedMinistracionId, sharedPaqueteId);
            fetchMinistracionListaNumeroByPaqueteId(sharedPaqueteId, sharedMinistracionId);
		} else if(sharedMinistracionId == "-1") {
            setShowUploadFile(true);
            setAvanceObra([]);
            setMinistracionNeta("0.00");
            setPorcentaje("0.00")
            fetchPaqueteAnticipoAdminById(sharedPaqueteId);
        } else {
            setMinistracionNeta("0.00");
            setPorcentaje("0.00")
            setAvanceObra([]);
            setShowUploadFile(false);
            setDocumento("");
        }

        if(sharedMinistracionId > 0 && sharedPaqueteId > 0) {
            fetchMinistracionNetaById(sharedPaqueteId, sharedMinistracionId);
            fetchMinistracionValorActualById(sharedPaqueteId, sharedMinistracionId);
            fetchMinistracionesPorcentajeObraNumeroByPaqueteId(sharedPaqueteId, sharedMinistracionId);
        } 


        if(sharedMinistracionId > sharedMinistraciones.length) {
            fetchMinistracionesPorcentajeObraByPaqueteId(sharedPaqueteId, sharedMinistracionId - 1);
            setShowUploadFile(false);
            setAvanceObra([]);
            setDocumento("");
        }
    }, [sharedMinistracionId]);

    const fetchPaqueteById = async (paquete_id) => {
		try {
			const response = await getPaqueteById(paquete_id, token);
            if (response.estatus_paquete >= 4){
                fetchMinistracionesPorcentajeObraByPaqueteId(paquete_id, 1);
                fetchDerechohabienteValorPaqueteByPaqueteId(paquete_id);
            } else {
                alert("Para comenzar las ministraciones...", "Primero debes liberar el anticipo", "warning");
                setDocumento("");
                setMinistracionNeta("0.00");
                setPorcentaje("0.00")
                setValorPaquete("0.00")
                setMinistracionValor({
                    "anticipo": "0.00",
                    "ministracion": "0.00",
                    "valor_actual": "0.00"
                });
            }
		} catch (error) {
			console.error('Error al cargar los datos', error);
		}
	}

    const fetchDerechohabienteValorPaqueteByPaqueteId = async (paquete_id) => {
		try {
			const response = await getDerechohabienteValorPaqueteByPaqueteId(paquete_id, token);
            setValorPaquete(response.message.toString())
		} catch (error) {
			console.error('Error al cargar los datos', error);
		}
	}
  
    const fetchMinistracionesPorcentajeObraByPaqueteId = async (paquete_id, num_ministracion) => {
		try {
			const response = await getMinistracionesPorcentajeObraByPaqueteId(paquete_id, num_ministracion, token);
            if(response.message == "100.00") {
                alert("Ministraciones completadas", "", "success");
              
            } 
		} catch (error) {
			console.error('Error al cargar los datos', error);
		}
	}

    const fetchMinistracionNetaById = async (paquete_id, num_ministracion) => {
		try {
			const response = await getMinistracionNetaById(paquete_id, num_ministracion, token);
			if(response.message !== 'no found') {
				setMinistracionNeta(response.message);
			} else {
				setMinistracionNeta("0.00");
			}
			
		} catch (error) {
			console.error('Error al cargar los datos', error);
		}
	}

    const fetchMinistracionesPorcentajeObraNumeroByPaqueteId = async (paquete_id, num_ministracion) => {
		try {
			const response = await getMinistracionesPorcentajeObraNumeroByPaqueteId(paquete_id, num_ministracion, token);
            console.log(response);
			setPorcentaje(response.message);
		} catch (error) {
			console.error('Error al cargar los datos', error);
		}
	}

    const fetchMinistracionValorActualById = async (paquete_id, num_ministracion) => {
		try {
			const response = await getMinistracionValorActualById(paquete_id, num_ministracion, token);
			setMinistracionValor(response);
		} catch (error) {
			console.error('Error al cargar los datos', error);
		}
	}

   
    const fetchMinistracionListaNumeroByPaqueteId = async (paquete_id, num_ministracion) => {
		try {
			const response = await getMinistracionListaNumeroByPaqueteId(paquete_id, num_ministracion, token);
            setAvanceObra(response);
		} catch (error) {
			console.error('Error al cargar los proyectos', error);
		}
	};

    const fetchPaqueteAnticipoAdminById = async (paquete_id) => {
		try {
			const response = await getPaqueteAnticipoAdminById(paquete_id, token);
			if(response.message === 'no found') {
				setDocumento("Sin Documento");
			} else {
				setDocumento(response.message);
			}
			
		} catch (error) {
			console.error('Error al cargar los datos', error);
		}
	}

    const fetchMinistracionAvanceAdminByNumero = async (num_ministracion, paquete_id) => {
		try {
			const response = await getMinistracionAvanceAdminByNumero(num_ministracion, paquete_id, token);
            if(response.message === 'no found') {
				setDocumento("Sin Documento");
			} else {
				setDocumento(response.message);
			}
			
		} catch (error) {
			console.error('Error al cargar los datos', error);
		}
	}
 
    const fetchUpdatePaqueteAnticipoAdminById = async (paquete_id, data) => {
		try {
			const response = await updatePaqueteAnticipoAdminById(paquete_id, data, token);
			console.log(response);
            fetchPaqueteAnticipoAdminById(paquete_id);

		} catch (error) {
			console.error('Error al cargar los datos', error);
		}
	}

    const fetchUpdateMinistracionAvanceAdminByNumero = async (num_ministracion, paquete_id, data) => {
		try {
			const response = await updateMinistracionAvanceAdminByNumero(num_ministracion, paquete_id, data, token);
			console.log(response);
            fetchMinistracionAvanceAdminByNumero(num_ministracion, paquete_id);
		} catch (error) {
			console.error('Error al cargar los datos', error);
		}
	}

    const handleSubmitFile = (paquete_id, data) => {
        if(sharedMinistracionId == 0) {
            fetchUpdatePaqueteAnticipoAdminById(paquete_id, data);
        } else {
            fetchUpdateMinistracionAvanceAdminByNumero(sharedMinistracionId, paquete_id, data)
        }
	}

    return(
        <div className="row justify-content-center">
            <hr className="mb-4" />
            <div className="col-xl-11">
                <div className="col-xl-12">
                    <div id="formControls" className="mb-5">  
                        <Card>
                            <CardHeader className="fw-bold small d-flex">
                                <span className="flex-grow-1">Supervisión de Obras</span>
                            </CardHeader>
                            <CardBody>
                                <div className='col-xl-12'>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <SelectProyectos setSharedProyectoId={setSharedProyectoId} setSharedEtapaId={setSharedEtapaId} />
                                        </div>
                                        <div className='col-4'>
                                            <SelectEtapas  proyecto_id={sharedProyectoId} setSharedEtapaId={setSharedEtapaId} setSharedPaqueteId={setSharedPaqueteId} />
                                        </div>
                                        <div className='col-4'>
                                            <SelectPaquetes etapa_id={sharedEtapaId} setSharedPaqueteId={setSharedPaqueteId}  />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <SelectMinistraciones paquete_id={sharedPaqueteId} setSharedMinistracionId={setSharedMinistracionId} setSharedMinistraciones={setSharedMinistraciones}/>
                                        </div>
                                        <div className='col-4'>
                                            {
                                                showUplaodFile ? (
                                                    <div className='row justify-content-center'>
                                                        <div className='col-12' >
                                                            <div className="form-group mb-3">
                                                                <label className="form-label" htmlFor="etapas" ><strong>Documento</strong></label>
                                                                <Documento documento={documento} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                
                                                ) : null
                                            }
                                        </div>
                                        <div className='col-4'>
                                            {
                                                showUplaodFile ? (
                                                    <UploadFile type={1} paquete_id={sharedPaqueteId} onSubmitFile={handleSubmitFile} />
                                                ) : null
                                            }
                                        </div>
                                        
                                    </div>
                                </div>
                                <hr className="mb-4" />
                                <div className='row'>
                                    <TablaAvanceObra avance_obra={avanceObra} />
                                </div>	
                            </CardBody>
                            <CardFooter>
                                <CalcularDatos ministracion_neta={ministracionNeta} valor_paquete={valorPaquete} valor_actual={ministracionValor.valor_actual} porcentaje={porcentaje} />
                            </CardFooter>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default ObraAdministracion;
