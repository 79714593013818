import jwt_decode from 'jwt-decode';
import { format } from 'date-fns';
import { errorNotification } from './notificationManager';
import { alert } from './alertManager';
import { mediosRecepcion, nivelAtencion, productos, causas } from './catalogos';


export const isEmailValid = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
}

export const decodeToken  = (token) => {
    try {
        // Decodifica el token
        const decodedToken = jwt_decode(token);

        // Accede a los datos del payload decodificado
        return decodedToken; // Aquí tendrás el objeto con los datos del payload del token
    } catch (error) {
        console.error('Error al decodificar el token:', error);
    }
}

export const encodeToken = (user) => {
    const sing = require('jwt-encode');
    //const user = { sub: '1234567890', id: "ABC", name, iat: 1516239022 };
    const token  = sing(user, '9ed7811a6ab9f6581bf1985a222a9d5e', {expiresIn:86400})
    return token;
};


export const formatoMoneda = new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' });


export const deleteKeyNull = (data) => {
    const filteredData = { ...data };
    for (const key in filteredData) {
        if (filteredData.hasOwnProperty(key) && filteredData[key] === null) {
        delete filteredData[key];
        }
    }
    return filteredData;
}

export const formatearFecha = (fechaOriginal) => {
    const fecha = new Date(fechaOriginal);
    return format(fecha, 'dd/MM/yyyy');
  }

export const isObjectEmpty = (data) => {
    if (Object.keys(data).length !== 0) {
        return true;
    } else {
        return false;
    }
}

export const errorHTTP = (error, message) => {
    if (error.response.status === 401) {
        alert("Error", "Token inválido o caducado. Inicia sesión de nuevo.", "error");
    } else if (error.response.status === 403) {
        alert("Error", "Acceso no autorizado.", "error");
    } else if (error.response.status === 404) {
        alert("Error",message, "error");
    } else if (error.response && error.response.status === 409) {
        alert("Error",message, "error");
    } else {
        errorNotification("Ocurrió un error en el servidor.");
    }
}

export const numeroAMes = (numero) => {
    const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    return meses[numero - 1] || null;
}

export const buscarNivelAtencionById = (id) => {
    const elemento = nivelAtencion.find(item => item.id === id);
    return elemento ? elemento.nombre : 'N/A';
}

export const buscarMediosRecepcionById = (id) => {
    const elemento = mediosRecepcion.find(item => item.id === id);
    return elemento ? elemento.nombre : 'N/A';
}

export const buscarProductosById = (product_id) => {
    const elemento = productos.find(item => item.productId === product_id);
    return elemento ? elemento.product : 'N/A';
}

export const buscarCausasById = (causa_id) => {
    const elemento = causas.find(item => item.causaId === causa_id);
    return elemento ? elemento.causa : 'N/A';
}


//export const serverUrl = "http://192.168.101.102:35010/api/v1/dae";

//export const serverUrl = "http://sicred.dyndns.org:35010/api/v1/dae";

export const serverUrl = "https://daeliii.com/api/v1/dae";
