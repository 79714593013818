import { daeApi } from "../daeApi";

export const getHistorialEstadosCuenta = async (token) => {
  const response = await daeApi.get(`/estados/cuenta/historial`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const createHistorialEstadoCuenta = async (data, token) => {
  const response = await daeApi.post(`/estados/cuenta/historial`, data, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-access-tokens': token 
    }
  });
  return response.data;
}

export const getHistorialEstadoCuentaById = async (id, token) => {
  const response = await daeApi.get(`/estados/cuenta/historial/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getHistorialEstadoCuentaByPaqueteId = async (id, token) => {
  const response = await daeApi.get(`/estados/cuenta/historial/paquete/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-access-tokens': token 
    }
  });
  return response.data;
}

export const getHistorialEstadoCuentaByEtapaId = async (id, token) => {
  const response = await daeApi.get(`/estados/cuenta/historial/etapa/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-access-tokens': token 
    }
  });
  return response.data;
}

export const updateHistorialEstadoCuenta = async (id, data, token) => {
  const response = await daeApi.put(`/estados/cuenta/historial/${id}`, data, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const deleteHistorialEstadoCuenta = async (id, token) => {
  const response = await daeApi.delete(`/estados/cuenta/historial/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}
