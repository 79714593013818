import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Breadcrumb from '../../../../components/breadcrumb/breadcrumb';
import SelectProyectos from '../../../../components/select/proyectos';
import SelectEtapas from '../../../../components/select/etapas';
import SelectPaquetes from '../../../../components/select/paquetes';
import UploadFileXLSX from '../../../../components/upload/upload_file_xlsx';

import { Card, CardBody, CardHeader } from '../../../../components/card/card';

import { getMinistracionesPorcentajeObraByPaqueteId } from '../../../../api/ministraciones/ministraciones';
import { getPaqueteById } from '../../../../api/paquete_proyectos/paquete_proyectos';

import { errorNotification, serverUrl, successNotification, alert } from '../../../../helpers';



function SubirMinistracion(props) {
	const token = useSelector(state => state.authentication.user?.token);

	const [sharedProyectoId, setSharedProyectoId] = useState(0);
	const [sharedEtapaId, setSharedEtapaId] = useState(0);
	const [sharedPaqueteId, setSharedPaqueteId] = useState(0);

	const [showUploadFile, setShowUploadFile] = useState(false);

	useEffect(() => {
		if(sharedProyectoId == 0) {
			setSharedEtapaId(0);
			setSharedPaqueteId(0);
		}
	}, [sharedProyectoId])

	useEffect(() => {
		if(sharedPaqueteId > 0) {
			fetchPaqueteById(sharedPaqueteId);
		}
	}, [sharedPaqueteId])

	const fetchPaqueteById = async (paquete_id) => {
		try {
			const response = await getPaqueteById(paquete_id, token);
			console.log(response.estatus_paquete);
			if (response.estatus_paquete >= 4) {
				fetchMinistracionesPorcentajeObraByPaqueteId(paquete_id, 1);
			} else{
				alert("Para comenzar las ministraciones...", "Primero debes liberar el anticipo", "warning")
			}
		} catch (error) {
			console.error('Error al cargar los inconsistencias', error);
		}
	};

	const fetchMinistracionesPorcentajeObraByPaqueteId = async (paquete_id, num_ministracion) => {
		try {
			const response = await getMinistracionesPorcentajeObraByPaqueteId(paquete_id, num_ministracion, token);
			console.log(response);
            if(response.message == "100.00") {
                alert("Ministraciones completadas", "¡Ministraciones al 100%!", "success");		
            } else if(response.message != "0.00") {
                alert("Las ministraciones no se han completado", "Para llegar al 100% debe realizarlas de forma manual en el submenú 'Supervisión de obra'.",  "warning");
            } else {
				setShowUploadFile(false);
			}
		} catch (error) {
			console.error('Error al cargar los datos', error);
		}
	}

	const handleSubmitFile = (paquete_id, data) => {
		//fetchUpdatePaqueteAnticipoPDFAdminById(paquete_id, data);        
	}
	

	return (
		<div>
			<Breadcrumb title={props.title} subtitle={props.subtitle}/>
			<div className="row justify-content-center">
				<hr className="mb-4" />
				<div className="col-xl-10">
					<div className="col-xl-12">
						<div id="formControls" className="mb-5">  
							<Card>
								<CardHeader className="fw-bold small d-flex">
									<span className="flex-grow-1">Cargar historial de ministraciones</span>
								</CardHeader>
								<CardBody>
									<div className='col-xl-12'>
										<div className='row'>
											<div className='col-4'>
												<SelectProyectos setSharedProyectoId={setSharedProyectoId} setSharedEtapaId={setSharedEtapaId}  />
											</div>
											<div className='col-4'>
												<SelectEtapas  proyecto_id={sharedProyectoId} setSharedEtapaId={setSharedEtapaId} setSharedPaqueteId={setSharedPaqueteId} />
											</div>
											<div className='col-4'>
												<SelectPaquetes etapa_id={sharedEtapaId} setSharedPaqueteId={setSharedPaqueteId}  />
											</div>
										</div>
										<div className='row'>
											<div className='col-4'>	
												{
													showUploadFile ? (
														<UploadFileXLSX paquete_id={sharedPaqueteId} onSubmitFile={handleSubmitFile} />
													) : <></>
												}
											</div>
										</div>
									</div>
								</CardBody>
							</Card>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default SubirMinistracion;