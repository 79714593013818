import React, {useEffect, useState	} from 'react';
import { useSelector } from 'react-redux';

import Breadcrumb from '../../../components/breadcrumb/breadcrumb';

import { Card, CardBody, CardHeader }  from '../../../components/card/card';
import { getParametrosMinistracionById, updateParametrosMinistracionById } from '../../../api/parametros_ministraciones/parametros_ministraciones';
import { errorNotification, successNotification } from '../../../helpers';


function ParametrosPDF(props) {
	const token = useSelector(state => state.authentication.user?.token);

	const [formParametros, setFormParametros] = useState({
		nombre_lic: '',
		ocupacion: '',
		destinatario: '',
		observaciones: '',
	});
	
	useEffect(() => { 
    	fetchParametrosMinistracionById();
		// eslint-disable-next-line
	}, []);

	const fetchParametrosMinistracionById = async () => {
		try {
			const response = await getParametrosMinistracionById(1, token);
			setFormParametros(response)
		} catch (error) {
			console.error('Error al cargar los bancos', error);
		}
	};


	const handleChangeFormParametros = (event) => {
		const { name, value } = event.target;
		setFormParametros({
		  	...formParametros,
		  	[name]: value,
		});
	};

	const handleUpdateParametros = async (e) => {
		e.preventDefault(); 
		if (e.target.form.checkValidity()) {
			try {
				const response = await updateParametrosMinistracionById(1, formParametros, token);
				if(response.message === 'success') {
					successNotification("Parametros actualizado correctamente");	
					fetchParametrosMinistracionById();
				}
			} catch (error) {
				errorNotification('Error al actualizar los parametros');
			}
		} else {
			errorNotification("Faltan campos requeridos.")
		}
	}

	return (
		<div>
			<Breadcrumb title={props.title} subtitle={props.subtitle}/>
			<div className="row justify-content-center">
				<hr className="mb-4" />	
				<div className="col-xl-9">
					<div className="col-xl-12">
						<div className='row'>
							<div id="" className="mb-4">
								<Card>
									<CardHeader>
										<span className="flex-grow-1">Datos para generar Reporte de Ministraciones.</span>
									</CardHeader>
									<CardBody>
										<form id="formParametros" className="was-validated" >		
											<div className="row">
												<div className="col-xl-12">
													<div className="row">
														<div className="col-xl-6">
															<div className="form-group mb-3">
																<label className="form-label" htmlFor="nombreLic">Nombre Lic.:</label>
																<input className="form-control " placeholder="Nombre Lic.:" type='text' 
																	id="nombre_lic"
																	name="nombre_lic"
																	value={formParametros.nombre_lic}
																	onChange={handleChangeFormParametros}
																	required
																	pattern=".*\S+.*" />
																<div className="invalid-feedback" id="validationNombre">
																	Campo requerido
																</div>
															</div>
														</div>
														<div className="col-xl-6">
															<div className="form-group mb-3">
																<label className="form-label" htmlFor='ocupacionLic'>Ocupación Lic.:</label>
																<input className="form-control" placeholder="Ocupación Lic.:" type='text'
																	id="ocupacion"
																	name="ocupacion"
																	value={formParametros.ocupacion} 
																	onChange={handleChangeFormParametros} />
															</div>
														</div>
													</div>
												</div>
											</div>
											
											
											<div className="row">
												<div className="col-xl-12">
													<div className="row">
														<div className="col-xl-6">
															<div className="form-group mb-3">
																<label className="form-label" htmlFor="destinatario">Dirigida A:</label>
																<input type="text" className="form-control"  placeholder='Dirigida A:'
																	id="destinatario"
																	name="destinatario"
																	value={formParametros.destinatario} 
																	onChange={handleChangeFormParametros}
																	required
																	pattern=".*\S+.*" />
																<div className="invalid-feedback" id="validationCuenta">
																	Campo requerido
																</div>
															</div>
														</div>
														<div className="col-xl-6">
															<div className="form-group mb-3">
																<label className="form-label" htmlFor="observaciones">Observaciones:</label>
																<textarea type="text" className="form-control"  placeholder='Observaciones'
																	id="observaciones"
																	name="observaciones"
																	value={formParametros.observaciones} 
																	onChange={handleChangeFormParametros} >
																</textarea>
															</div>
														</div>
														
													</div>
												</div>
											</div>
										</form>
										<br/>
										<div className="modal-footer">
											<button type="button" className="btn btn-outline-theme"  form="formParametros" onClick={handleUpdateParametros}> Actualizar</button>
										</div>
									</CardBody>
									
								</Card>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ParametrosPDF;