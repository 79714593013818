import { configureStore } from '@reduxjs/toolkit';

import { authenticationSlice } from './slices/authentication';

export * from './slices/user.role.slice';

export const store = configureStore({
    reducer: {
        authentication: authenticationSlice.reducer
    },
});