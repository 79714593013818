import { daeApi } from "../daeApi";

export const getDerechohabientes = async (token) => {
  const response = await daeApi.get(`/derechohabientes`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const createDerechohabientes = async (data, token) => {
  const response = await daeApi.post(`/derechohabientes`, data, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const createDerechohabientesFile = async (data, token) => {
  const response = await daeApi.post(`/derechohabientes/paquete/file`, data, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-access-tokens': token 
    }
  });
  return response.data;
}

///derechohabientes/avances/porcentajes/file
export const createDerechohabientesAvancesPorcentajesFile = async (data, token) => {
  const response = await daeApi.post(`/derechohabientes/avances/porcentajes/file`, data, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-access-tokens': token 
    }
  });
  return response.data;
}

//derechohabientes/avances/porcentajes
export const createDerechohabientesAvancesPorcentajes = async (data, token) => {
  const response = await daeApi.post(`/derechohabientes/avances/porcentajes`, data, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-access-tokens': token 
    }
  });
  return response.data;
}

export const getDerechohabientesById = async (id, token) => {
  const response = await daeApi.get(`/derechohabientes/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getDerechohabientesTotalByPaqueteId = async (id, token) => {
  const response = await daeApi.get(`/derechohabientes/total/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getDerechohabientesTotalFirmadosByPaqueteId = async (id, token) => {
  const response = await daeApi.get(`/derechohabientes/total/firmados/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getDerechohabientesByPaqueteId = async (id, token) => {
  const response = await daeApi.get(`/derechohabientes/paquete/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getDerechohabienteValorLoteByPaqueteId = async (id, token) => {
  const response = await daeApi.get(`/derechohabientes/valor/lote/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-access-tokens': token 
    }
  });
  return response.data;
}

export const getDerechohabienteValorPaqueteByPaqueteId = async (id, token) => {
  const response = await daeApi.get(`/derechohabientes/valor/paquete/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}


export const getDerechohabienteMinistracionReporteAdminByPaqueteId = async (id, token) => {
  const response = await daeApi.get(`/derechohabientes/ministraciones/reporte/admin/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getDerechohabienteMinistracionReporteTecnicoByPaqueteId = async (id, token) => {
  const response = await daeApi.get(`/derechohabientes/ministraciones/reporte/tecnico/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getDerechohabienteValorRemanenteByPaqueteId = async (id, token) => {
  const response = await daeApi.get(`/derechohabientes/valor/remanente/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

//derechohabientes/retencion/
export const getDerechohabienteRetencionByPaqueteId = async (id, token) => {
  const response = await daeApi.get(`/derechohabientes/retencion/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}


export const getViviendasSubsidioByPaqueteId = async (id, token) => {
  const response = await daeApi.get(`/derechohabientes/viviendas/subsidio/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getDerechohabientesAnticposById = async (id, token) => {
  const response = await daeApi.get(`/derechohabientes/anticipos/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-access-tokens': token 
    }
  });
  return response.data;
}

export const updateDerechohabientesById = async (id, data, token) => {
  const response = await daeApi.put(`/derechohabientes/${id}`, data, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const deleteDerechohabientesById = async (id, token) => {
  const response = await daeApi.delete(`/derechohabientes/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-access-tokens': token 
    }
  });
  return response.data;
}
