import React,  { useEffect, useState }from 'react';
import { useSelector } from 'react-redux';

import { getDerechohabientesAnticposById } from '../../../../api/derechohabiente/derechohabiente';
import { errorNotification, formatoMoneda, successNotification, warningNotification } from '../../../../helpers';
import { Card, CardBody, CardHeader } from '../../../../components/card/card';
import { createDireccion, getDireccionSepomexByCp, updateDireccionById } from '../../../../api/direcciones/direcciones';
import { createBanco, updateBancoById } from '../../../../api/bancos/bancos';
import { createPromotor, getPromotorBancosById, updatePromotorById } from '../../../../api/promotores/promotores';


function ModalPromotor({ type, promotorId, onSuccessPromotor, onCloseModal }) {
    const token = useSelector(state => state.authentication.user?.token);

    const [accion, setAccion] = useState("");
    const [colonias, setColonias] = useState([]);
	const [formPromotor, setFormPromotor] = useState({
		banco_id: 0,
		calle: '',
		clabe: '',
		colonia: '',
		cp: '',
		cuenta : '',
		direccion_id : '',
		estado : '',
		mesa_inversion : '',
		municipio : '',
		nombre_banco : '',
		num_exterior : '',
		num_interior : '',
		promotor : '',
		promotor_id : '',
		rfc : ''
	});

    useEffect(() => {
        if(type == 1)
            setAccion("Nuevo");
        else if(type == 2) {
            if(promotorId > 0) {
                setAccion("Editar");
                fetchPromotorBancoById(promotorId);
            }
        }
    }, [type, promotorId]);
    

    const fetchPromotorBancoById = async (id) => {
		try {
			const response = await getPromotorBancosById(id, token);
			setFormPromotor({
				banco_id: response.banco_id,
				calle: response.calle,
				clabe: response.clabe,
				colonia: response.colonia,
				cp: response.cp,
				cuenta : response.cuenta,
				direccion_id : response.direccion_id,
				estado : response.estado,
				mesa_inversion : response.mesa_inversion,
				municipio : response.municipio,
				nombre_banco : response.nombre_banco,
				num_exterior : response.num_interior,
				num_interior : response.num_exterior,
				promotor : response.promotor,
				promotor_id : response.promotor_id,
				rfc : response.rfc
			});
			//fetchDireccionByCP(response.cp);
		} catch (error) {
			console.error('Error al cargar el promotor', error);
		}
	}

    const fetchCreateBanco = async (data) => {
		try {
			const response = await createBanco(data, token);
			if(response.status === 201) {
				let dataDireccion = {
					"calle" : formPromotor.calle,
					"num_interior" : formPromotor.num_interior,
					"num_exterior" : formPromotor.num_exterior,
					"colonia" : formPromotor.colonia,
					"municipio" : formPromotor.municipio,
					"estado" : formPromotor.estado,
					"cp" : formPromotor.cp
				}
				fetchCreateDireccion(dataDireccion, response);
			} else {
				errorNotification("No es posible crear el banco.");
			}
		} catch (error) {
			console.error('Error al crear el banco', error);
		}
	}

    const fetchCreateDireccion = async (data, respCreateBanco) => {
		try {
			const response = await createDireccion(data, token);
			if(response.status === 201) {
				let dataPromotor = {
					"banco_id": respCreateBanco.message,
					"direccion_id": response.message,
					"promotor": formPromotor.promotor,
					"rfc": formPromotor.rfc
				}
				fetchCreatePromotor(dataPromotor);
			} else {
				errorNotification("No es posible crear la direccion.");
			}
		} catch (error) {
			console.error('Error al crear la direccion', error);
		}
	}

    const fetchCreatePromotor = async (data) => {
		try {
			const response = await createPromotor(data, token);
			if(response.status === 201) {
				successNotification("Promotor Creado Correctamente");
				handleCloseModal();
			} else
				errorNotification("No es posible crear el promotor.");
		} catch (error) {
			console.error('Error al crear el promotor', error);
		}
	}

    const fetchDireccionByCP = async(cp) => {
		try {
			const response = await getDireccionSepomexByCp(cp, token);
			if(response.status === 200) {
				setFormPromotor({
					...formPromotor,
					estado : response.data.codigo_postal.estado,
					municipio : response.data.codigo_postal.municipio,
					cp : cp
				});
				setColonias(response.data.codigo_postal.colonias)
			} else if(response.status === 204) {
				errorNotification("No es posible obtener la direccion. Verifique el CP.")
			}
		} catch (error) {
			console.error('Error al cargar la direccion', error);
		}
	}

    const fetchUpdatePromotorById = async (data) => {
		try {
			const response = await updatePromotorById(formPromotor.promotor_id, data, token);
			if(response.message === "success") {
				successNotification("Promotor Editado Correctamente");
				handleCloseModal();
			} else     
				errorNotification("No es posible actualizar el banco.");
			
		} catch (error) {
			console.error('Error al actualizar el promotor', error);
		}
	}

	const fetchUpdateBancoById = async (data) => {
		try {
			const response = await updateBancoById(formPromotor.banco_id, data, token);
			if(response.message === "success") {
				let dataDireccion = {
					"calle" : formPromotor.calle,
					"num_interior" : formPromotor.num_interior,
					"num_exterior" : formPromotor.num_exterior,
					"colonia" : formPromotor.colonia,
					"municipio" : formPromotor.municipio,
					"estado" : formPromotor.estado,
					"cp" : formPromotor.cp
				}
				fetchUpdateDirecionById(dataDireccion);
			} else {
				errorNotification("No es posible actualizar el banco.");
			}
		} catch (error) {
			console.error('Error al actualizar el banco', error);
		}
	}

    const fetchUpdateDirecionById = async (data) => {
		try {
			const response = await updateDireccionById(formPromotor.direccion_id, data, token);
			if(response.message === "success") {
				let dataPromotor = {
					"banco_id": formPromotor.banco_id,
					"direccion_id": formPromotor.direccion_id,
					"promotor": formPromotor.promotor,
					"rfc": formPromotor.rfc
				}
				fetchUpdatePromotorById(dataPromotor);
			} else {
				errorNotification("No es posible actualizar la direccion.");
			}
		} catch (error) {
			console.error('Error al actualizar la direccion', error);
		}
	}

    const handleChangeFormPromotor = (event) => {
		const { name, value } = event.target;
		if (name === 'cp') {
			setFormPromotor((prevForm) => ({
				...prevForm,
				[name]: value,
			}));

			if (/^\d{5}$/.test(value)) {
				fetchDireccionByCP(value);
			} else {
				if(value.length === 5)
					errorNotification('El código postal debe contener 5 dígitos numéricos.');
			}
		} else {
			setFormPromotor({
				...formPromotor,
				[name]: value,
			});
		}
	};

    const handleActionPromotor = async (e) => {
		e.preventDefault(); 
		if (e.target.form.checkValidity()) {
			if(type === 1) {
				if(formPromotor.colonia === '') {
					warningNotification("Es necesario elegir una colonia.")
				} else {
					let dataBanco = {
						"nombre": formPromotor.nombre_banco,
						"cuenta": formPromotor.cuenta,
						"clabe": formPromotor.clabe,
						"mesa_inversion" : 0.0
					};
					fetchCreateBanco(dataBanco);
				}
			} else {
				let dataBanco = {
					"nombre": formPromotor.nombre_banco,
					"cuenta": formPromotor.cuenta,
					"clabe": formPromotor.clabe,
					"mesa_inversion" : formPromotor.mesa_inversion
				}
				fetchUpdateBancoById(dataBanco);
			}
		} else {
			errorNotification("Faltan campos requeridos.")
		}
	}

    const clearForm = () => {
		setAccion("Agregar");
		setFormPromotor({
			banco_id: '',
			calle: '',
			clabe: '',
			colonia: '',
			cp: '',
			cuenta : '',
			direccion_id : '',
			estado : '',
			municipio : '',
			nombre_banco : '',
			num_exterior : '',
			num_interior : '',
			promotor : '',
			promotor_id : '',
			rfc : ''
		});
		setColonias([]);
	}

	const handleCloseModal = () => {
        document.getElementById('modalPromotor').classList.remove('show');
		document.querySelector('.modal-backdrop').remove();
		document.getElementById('modalPromotor').style.display = 'none';

        clearForm();
		onSuccessPromotor();
    
        onCloseModal();
    }


    return(
        <div className="modal modal-cover fade" id="modalPromotor" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                    <h5 className="modal-title">{ accion } Promotor</h5>
                        <button type="button" className="btn-close" onClick={handleCloseModal}></button>
                    </div>
                    <div className="modal-body">
                        <form id="formPromotor" className="was-validated" >
                            <Card>
                                <CardHeader className="fw-bold small d-flex">
                                    <span className="flex-grow-1">Datos Fiscales</span>
                                </CardHeader>
                                <CardBody>
                                    <div className="mb-3">
                                        <div className="row row-space-12">	
                                            <div className="col-7">
                                                <label className="form-label">Nombre del Promotor</label>
                                                <input className="form-control" placeholder="Nombre del Promotor"  
                                                    id="promotor"
                                                    name="promotor"
                                                    value={formPromotor.promotor}
                                                    onChange={handleChangeFormPromotor}
                                                    required
                                                    pattern=".*\S+.*" />
                                                <div className="invalid-feedback" id="validationNombre">
                                                    Campo requerido
                                                </div>
                                            </div>
                                            <div className="col-5">
                                                <label className="form-label">RFC Promotor</label>
                                                <input className="form-control" placeholder="RFC Promotor"
                                                    id="rfc"
                                                    name="rfc"
                                                    value={formPromotor.rfc}
                                                    onChange={handleChangeFormPromotor}
                                                    required
                                                    pattern=".*\S+.*" />
                                                <div className="invalid-feedback" id="validationRFC">
                                                    Campo requerido
                                                </div>	
                                            </div>
                                            <div className="col-xl-6">
                                                    <label className="form-label">Calle</label>
                                                    <input className="form-control"  placeholder='Direccion'
                                                        id="calle"
                                                        name="calle"
                                                        value={formPromotor.calle}
                                                        onChange={handleChangeFormPromotor}
                                                        required
                                                        pattern=".*\S+.*" />
                                                    <div className="invalid-feedback" id="validationDireccion">
                                                        Campo requerido
                                                    </div>	
                                            </div>
                                            <div className="col-xl-2">
                                                    <label className="form-label" htmlFor="exampleFormControlTextarea1">No. Interior</label>
                                                    <input className="form-control"  placeholder='No. Interior'
                                                        id="num_interior"
                                                        name="num_interior"
                                                        value={formPromotor.num_interior}
                                                        onChange={handleChangeFormPromotor}
                                                        
                                                        />
                                                                                                        
                                            </div>
                                            <div className="col-xl-2">
                                                    <label className="form-label" htmlFor="exampleFormControlTextarea1">No. Exterior</label>
                                                    <input className="form-control"  placeholder='No. Exterior'
                                                        id="num_exterior"
                                                        name="num_exterior"
                                                        value={formPromotor.num_exterior}
                                                        onChange={handleChangeFormPromotor}
                                                            />
                                                            
                                                
                                            </div>

                                            <div className="col-xl-2">
                                                <div className="form-group mb-3">
                                                    <label className="form-label" htmlFor="cp">C.P.</label>
                                                    <input className="form-control"  placeholder='C.P,'
                                                        id="cp"
                                                        name="cp"
                                                        value={formPromotor.cp}
                                                        onChange={handleChangeFormPromotor}
                                                        required
                                                        pattern=".*\S+.*" />
                                                    <div className="invalid-feedback" id="validationCP">
                                                        Campo requerido
                                                    </div>	    
                                                </div>
                                            </div>

                                            <div className="col-xl-4">
                                                <div className="form-group mb-3">
                                                    <label className="form-label" htmlFor="exampleFormControlInput1">Colonia</label>
                                                    <select className="form-select" onChange={handleChangeFormPromotor} value={formPromotor.colonia} id='colonia' name='colonia' >
                                                        <option value="">Selecciona una colonia</option>
                                                        {colonias.map((colonia, key) => (
                                                            <option key={key} value={colonia}>
                                                                {colonia}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <div className="invalid-feedback" id="validationNoInterior">
                                                        Campo requerido
                                                    </div>	    
                                                </div>
                                            </div>
                                            <div className="col-xl-4">
                                                <div className="form-group mb-3">
                                                    <label className="form-label" htmlFor="exampleFormControlTextarea1">Municipio</label>
                                                    <input className="form-control" placeholder='Municipio'
                                                        id="municipio"
                                                        name="municipio"
                                                        value={formPromotor.municipio}
                                                        onChange={handleChangeFormPromotor}
                                                        required
                                                        disabled={true}
                                                        pattern=".*\S+.*" />
                                                    <div className="invalid-feedback" id="validationNoInterior">
                                                        Campo requerido
                                                    </div>	    
                                                </div>
                                            </div>
                                            <div className="col-xl-4">
                                                <div className="form-group mb-3">
                                                    <label className="form-label" htmlFor="exampleFormControlTextarea1">Estado</label>
                                                    <input className="form-control" placeholder='Estado'
                                                        id="estado"
                                                        name="estado"
                                                        value={formPromotor.estado}
                                                        onChange={handleChangeFormPromotor}
                                                        required
                                                        disabled={true}
                                                        pattern=".*\S+.*" />
                                                    <div className="invalid-feedback" id="validationNoInterior">
                                                        Campo requerido
                                                    </div>	    
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>	
                                </CardBody>
                            </Card>
                            <br/>
                            <br/>
                            <Card>
                                <CardHeader className="fw-bold small d-flex">
                                    <span className="flex-grow-1">Datos Bancarios</span>
                                </CardHeader>
                                <CardBody>
                                    
                                    <div className="mb-3">
                                        <div className="row row-space-5">
                                            <div className="col-4">
                                                <label className="form-label" htmlFor="nombre_banco">Banco</label>        
                                                <input className="form-control" placeholder="Banco"
                                                        id="nombre_banco"
                                                        name="nombre_banco"
                                                        value={formPromotor.nombre_banco}
                                                        onChange={handleChangeFormPromotor}
                                                        required
                                                        pattern=".*\S+.*" />
                                                    <div className="invalid-feedback" id="validationNoInterior">
                                                        Campo requerido
                                                    </div>	   
                                                </div>
                                            <div className="col-4">
                                                <label className="form-label" htmlFor="cuenta">Cuenta</label>
                                                <input className="form-control" placeholder="Numero de Cuenta"
                                                    id="cuenta"
                                                    name="cuenta"
                                                    value={formPromotor.cuenta}
                                                    onChange={handleChangeFormPromotor}
                                                    required
                                                    pattern=".*\S+.*" />
                                                <div className="invalid-feedback" id="validationNoInterior">
                                                    Campo requerido
                                                </div>	    
                                            </div>
                                            <div className="col-4">
                                                <label className="form-label" htmlFor="clabe">CLABE</label>
                                                <input className="form-control" placeholder="Clabe Interbancaria"
                                                    id="clabe"
                                                    name="clabe"
                                                    value={formPromotor.clabe}
                                                    onChange={handleChangeFormPromotor}
                                                    required
                                                    pattern=".*\S+.*" />
                                                <div className="invalid-feedback" id="validationNoInterior">
                                                    Campo requerido
                                                </div>	    
                                            </div>	
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>

                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-default" onClick={handleCloseModal}>Cerrar</button>
                        <button type="button" className="btn btn-outline-theme" onClick={handleActionPromotor} form="formPromotor" >{ accion }</button>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default ModalPromotor;