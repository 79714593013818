import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import useAuthentication from '../hooks/useAuthentication';
import useAuthorization from '../hooks/useAuthorization';

function PrivateRoute({ children, requiredRoles }) {
	const navigate = useNavigate();
  	const isAuthenticated = useAuthentication(); 
  	const userRole = useAuthorization(); 

	// eslint-disable-next-line react-hooks/exhaustive-deps
  	useEffect(() => {
    	if (isAuthenticated !== null) {
			if (!isAuthenticated) {
				navigate('/pages/login'); 
      		} else if (isAuthenticated && !hasAccess(requiredRoles, userRole)) {
				navigate('/pages/forbidden'); 
      		}
    	} 
  }, [isAuthenticated, navigate, requiredRoles, userRole]);

  	const hasAccess = (requiredRoles, userRole) => {
    	return requiredRoles.some(role => userRole.includes(role));
  	};

	return children;
}

export default PrivateRoute;
