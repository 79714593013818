import { daeApi } from "../daeApi";

export const getBancos = async (token) => {
  const response = await daeApi.get(`/bancos`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getBancosPromotores = async (token) => {
  const response = await daeApi.get(`/bancos/promotores`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getBancosProyectos = async (token) => {
  const response = await daeApi.get(`/bancos/proyectos`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const createBanco = async (data, token) => {
  const response = await daeApi.post(`/bancos`, data, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getBancoById = async (id, token) => {
  const response = await daeApi.get(`/bancos/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const updateBancoById = async (id, data, token) => {
  const response = await daeApi.put(`/bancos/${id}`, data, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const deleteBancoById = async (id, token) => {
  const response = await daeApi.delete(`/bancos/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
  
}
