import { daeApi } from "../daeApi";

export const uploadFiles = async (data, token) => {
    const response = await daeApi.post(`/file/upload`, data, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'x-access-tokens': token
        }
    });
    return response.data;
}

export const deleteFiles = async (data, token) => {
    const response = await daeApi.post(`/file/delete`, data, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'x-access-tokens': token 
        }
    });
    return response.data;
}