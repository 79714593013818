import React,  { useEffect, useState }from 'react';

import useAuthorization from '../../../../../hooks/useAuthorization';

import './../styles.css'; 
import TableJuridico from './juridico';
import TableTecnico from './tecnico';

function TableDerechohabientes({ paquete_id, paquete_nombre, estatus_paquete, 
        derechohabientes, onSuccessDerechohabiente, setSharedDerechohabienteId, onOpenModal }) {
    const userRole = useAuthorization();

    const [showTableTecnico, setShowTableTecnico] = useState(false);
    const [showTableJuridico, setShowTableJuridico] = useState(false);
    const [showTableMaster, setShowTableMaster] = useState(false);

    
    useEffect(() => {
        if(userRole.length > 0) {
            if (['tecnico'].some(role => userRole.includes(role))) {
                setShowTableTecnico(true);
                setShowTableJuridico(false);
                setShowTableMaster(false);
            } else if (['juridico'].some(role => userRole.includes(role))) {
                setShowTableTecnico(false);
                setShowTableJuridico(true);
                setShowTableMaster(false);
            } else if (['master'].some(role => userRole.includes(role))) {
                setShowTableTecnico(false);
                setShowTableJuridico(false);
                setShowTableMaster(true);
            }
        }
    }, [userRole])

    //  Derechohabiente no se puede firmar si  if(statusFirma != "Firmado"){
    //  Recordar actualizar la ruta al terminar una accion

    return (
        <div>
            { showTableTecnico && (
                <TableTecnico 
                    paquete_id={paquete_id} 
                    paquete_nombre={paquete_nombre} 
                    estatus_paquete={estatus_paquete} 
                    setSharedDerechohabienteId={setSharedDerechohabienteId} 
                    derechohabientes={derechohabientes}
                    onSuccessDerechohabiente={onSuccessDerechohabiente}
                    onOpenModal={onOpenModal}  />
            )}
            { showTableJuridico && (
                <TableJuridico 
                    paquete_id={paquete_id} 
                    paquete_nombre={paquete_nombre} 
                    estatus_paquete={estatus_paquete} 
                    setSharedDerechohabienteId={setSharedDerechohabienteId}
                    derechohabientes={derechohabientes}
                    onSuccessDerechohabiente={onSuccessDerechohabiente}
                    onOpenModal={onOpenModal} />
            )}
            { showTableMaster && (
                
                <>
                    <ul className="nav nav-tabs pt-3 ps-4 pe-4">
                        <li className="nav-item me-3"><a href="#tabDerechohabientesTecnico" className="nav-link active" data-bs-toggle="tab">Tecnico</a></li>
                        <li className="nav-item me-3"><a href="#tabDerechohabientesJuridico" className="nav-link" data-bs-toggle="tab">Juridico</a></li>
                    </ul>
                    <div className="tab-content p-4">
                        <div className="tab-pane fade show active" id="tabDerechohabientesTecnico">
                            <TableTecnico 
                                paquete_id={paquete_id} 
                                paquete_nombre={paquete_nombre} 
                                estatus_paquete={estatus_paquete} 
                                setSharedDerechohabienteId={setSharedDerechohabienteId} 
                                derechohabientes={derechohabientes}
                                onSuccessDerechohabiente={onSuccessDerechohabiente}
                                onOpenModal={onOpenModal} />
                        </div>
                        <div className="tab-pane fade" id="tabDerechohabientesJuridico">
                            <TableJuridico 
                                paquete_id={paquete_id} 
                                paquete_nombre={paquete_nombre} 
                                estatus_paquete={estatus_paquete} 
                                setSharedDerechohabienteId={setSharedDerechohabienteId} 
                                derechohabientes={derechohabientes}
                                onSuccessDerechohabiente={onSuccessDerechohabiente}
                                onOpenModal={onOpenModal} />
                        </div>
                    </div>
                </>
           
    )}
        </div>
    )        
}

export default TableDerechohabientes;