import React, { useEffect, useContext, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { AppSettings } from './../../config/app-settings.js';
import useAuth from '../../hooks/useAuthentication.js';
import { login } from '../../store/slices/authentication';
import { useDispatch, useSelector } from 'react-redux';

import { alert, errorNotification, isEmailValid } from '../../helpers';


function PagesLogin() {
	const context = useContext(AppSettings);
	const [redirect, setRedirect] = useState(false);
	const isAuthenticated = useAuth();
	const dispatch = useDispatch();
	const { isLoading }  = useSelector( (state) => state.authentication );

	useEffect(() => {
		context.setAppHeaderNone(true);
		context.setAppTopNav(false);
		context.setAppContentClass('p-0');
		
		return function cleanUp() {
			context.setAppHeaderNone(false);
			context.setAppTopNav(true);
			context.setAppContentClass('');
		};
		// eslint-disable-next-line
	}, []);
	
	function handleSubmit(event) {
		event.preventDefault();		

		
		const email = event.target.email.value;
    	const password = event.target.password.value;

		if(isEmailValid(email))
			dispatch(login({ email, password }))
				.unwrap()
				.then((data) => {
					setRedirect(true);
				})
				.catch((error) => {
					errorNotification(error?.data?.message || error);
				});
		else
			errorNotification("Formato de email incorrecto");
  	}
  
	if (redirect) {
		return <Navigate to='/proyecto/lista' />;
	}

	// Verificar si el usuario está autenticado
	if (isAuthenticated) {
		return <Navigate to='/proyecto/lista' />;
	}

	const openDialog = (e) => {
		e.preventDefault(); // Detiene la propagación del evento click
		alert("¿Olvidaste tu contraseña?", "Contacte al administrador de Linea III para recuperar tu contraseña.", "info");
    }
	
	return (
		<div className="login">
			<div className="login-content">
				<form onSubmit={handleSubmit}>
					<h1 className="text-center">Iniciar Sesión</h1>
					<div className="text-inverse text-opacity-50 text-center mb-4">
						Para su protección, por favor verifique su identidad.
					</div>
					<div className="mb-3">
						<label className="form-label">Email <span className="text-danger">*</span></label>
						<input disabled={isLoading} type="text" name="email" className="form-control form-control-lg bg-white bg-opacity-5" placeholder="" />
					</div>
					<div className="mb-3">
						<div className="d-flex">
						<label className="form-label">Contraseña <span className="text-danger">*</span></label>
						<a onClick={openDialog} className="ms-auto text-inverse text-decoration-none text-opacity-50">¿Olvidó su contraseña?</a>
						</div>
						<input disabled={isLoading} type="password" name="password" className="form-control form-control-lg bg-white bg-opacity-5" placeholder="" />
					</div>
					
					<button disabled={isLoading} type="submit" className="btn btn-outline-theme btn-lg d-block w-100 fw-500 mb-3">Iniciar Sesión</button>
					
				</form>
			</div>
		</div>

	)
}

export default PagesLogin;