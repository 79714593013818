import React, { useEffect, useContext } from 'react';
import { Card, CardBody, CardFooter } from '../../components/card/card.jsx';
import { AppSettings } from '../../config/app-settings.js';

function PagesTokenExpired() {
	const context = useContext(AppSettings);

	useEffect(() => {
		context.setAppHeaderNone(true);
		context.setAppTopNav(false);
		context.setAppContentClass('p-0');
		
		return function cleanUp() {
			context.setAppHeaderNone(false);
			context.setAppTopNav(true);
			context.setAppContentClass('');
		};
	}, []);

	return (
		<div className="error-page" >
			<div className="error-page-content">
				<Card className="mb-5 mx-auto" >
					<CardBody>
						<Card>
							<div className="error-code">TOKEN EXPIRADO</div>
						</Card>
					</CardBody>
				</Card>
				<CardFooter>
					<h1>Oops!</h1> 
					<h3>Comunicate con el Administrador de Linea III</h3>
				</CardFooter>
			</div>
		</div>
	)
}

export default PagesTokenExpired;