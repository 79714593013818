import React,  { useEffect, useState }from 'react';
import { useSelector } from 'react-redux';

import { Card, CardBody } from '../../../../components/card/card';

import { deleteKeyNull, errorNotification, formatoMoneda, successNotification, numeroAMes, buscarMediosRecepcionById, buscarNivelAtencionById, buscarProductosById, buscarCausasById, buscarMesById } from '../../../../helpers';
import { getQuejaById, getSepomexEstados, getSepomexMunicipiosByEstadoId, getSepomexColoniasByCp } from '../../../../api/condusef/redeco';


function ModalEditarQueja({ sharedQuejaId, onCloseModal  }) {
    const token = useSelector(state => state.authentication.user?.token);

    const [formQueja, setFormQueja] = useState({});

    useEffect(() => {
        if (sharedQuejaId !== null && token !== undefined)
            fetchQuejaById(sharedQuejaId);
    }, [sharedQuejaId, token])

    const fetchQuejaById = async (id) => {
		try {
			const response = await getQuejaById(id, token);
            setFormQueja(response);
            fetchSepomexEstados(response.QuejasEstados);
            fetchSepomexMunicipiosByEstadoId(response.QuejasEstados, response.QuejasCP);
            fetchSepomexColoniasByCp(response.QuejasCP, response.QuejasColId);
		} catch (error) {
			console.error('Error al cargar los datos', error);
		}
	}

    const fetchSepomexEstados = async (estado_id) => {
		try {
            const response = await getSepomexEstados(token);
            const estadoEncontrado = response.estados.find(estado => estado.claveEdo === estado_id);
            
            if (estadoEncontrado) {
                setFormQueja(prevState => ({
                    ...prevState,
                    QuejasEstados: estadoEncontrado.estado
                }));
            } else {
                console.log('Clave no encontrada');
            }
        } catch (error) {
            console.error('Error al cargar los datos', error);
        }
	}

    const fetchSepomexMunicipiosByEstadoId = async (estado_id, cp) => {
		try {
            const response = await getSepomexMunicipiosByEstadoId(estado_id, cp, token);
            const municipioEncontrado = response.municipios.find(municipio => 
                municipio.estadoId === estado_id
            );

            if (municipioEncontrado) {
                setFormQueja(prevState => ({
                    ...prevState,
                    QuejasMunId: municipioEncontrado.municipio 
                }));
            } else {
                console.log('Municipio no encontrado');
            }
        } catch (error) {
            console.error('Error al cargar los datos', error);
        }
	}

    const fetchSepomexColoniasByCp = async (cp, colonia_id) => {
		try {
            const response = await getSepomexColoniasByCp(cp, token);
            const coloniaEncontrada = response.colonias.find(colonia => 
                colonia.coloniaId === colonia_id
            );
            
            if (coloniaEncontrada) {
                setFormQueja(prevState => ({
                    ...prevState,
                    QuejasLocId: coloniaEncontrada.tipoLocalidad,  // Asignar tipoLocalidadId encontrado a QuejasLocId
                    QuejasColId: coloniaEncontrada.colonia         // Asignar coloniaId encontrado a QuejasColId
                }));
            } else {
                console.log('Colonia no encontrada');
            }
        } catch (error) {
            console.error('Error al cargar los datos', error);
        }
	}

    const finished = () => {
        document.getElementById('modalEditarQueja').classList.remove('show');
        document.querySelector('.modal-backdrop').remove();
        document.getElementById('modalEditarQueja').style.display = 'none';
        onCloseModal(); 
    }

    return(
        <div className="modal modal-cover fade" id="modalEditarQueja" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Detalle Queja</h5>
                        <button type="button" className="btn-close" onClick={finished}></button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3">
                            <form id="formEditarEtapa" className="was-validated" >
                                <div className="row row-space-12">	
                                    <div className="col-12">
                                        <div className='row'>
                                            <div className="col-xl-1">
                                                <label className="form-label"><strong>#</strong></label>
                                                <input className="form-control" placeholder="Nombre"
                                                    id="nombre"
                                                    name="nombre"
                                                    value={formQueja.QuejasNum}
                                                    disabled/>
                                            </div>
                                            <div className="col-xl-5">
                                                <label className="form-label"><strong>Mes a Reportar</strong></label>
                                                <input className="form-control" placeholder="Mes" 
                                                    id="mes"
                                                    name="mes"
                                                    value={numeroAMes(formQueja.QuejasNoMes)}
                                                    disabled={true} />
                                            </div>
                                            <div className="col-xl-6">
                                                <label className="form-label"><strong>Folio</strong></label>
                                                <input className="form-control" placeholder="Folio" 
                                                    id="folio"
                                                    name="folio"
                                                    value={formQueja.QuejasFolio}
                                                    disabled={true} />	
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-space-12">	
                                    <div className="col-12">
                                        <div className='row'>
                                            <div className="col-xl-4">
                                                <label className="form-label"><strong>Fecha Recepcion</strong></label>
                                                <input className="form-control" placeholder="Fecha Recepcion"
                                                    id="fecha_recepcion"
                                                    name="fecha_recepcion"
                                                    value={formQueja.QuejasFecRecepcion}
                                                    disabled/>
                                            </div>
                                            <div className="col-xl-4">
                                                <label className="form-label"><strong>Medio de entrega</strong></label>
                                                <input className="form-control" placeholder="Medio de entrega"
                                                    id="medio_entrega"
                                                    name="medio_entrega"
                                                    value={buscarMediosRecepcionById(formQueja.QuejasMedio)}
                                                    disabled={true} />
                                            </div>
                                            <div className="col-xl-4">
                                                <label className="form-label"><strong>Nivel de Atención</strong></label>
                                                <input className="form-control" placeholder="Nivel de Atención" 
                                                    id="nivel_atención" 
                                                    name="nivel_atencion"
                                                    value={buscarNivelAtencionById(formQueja.QuejasNivelAT)}
                                                    disabled={true} />	
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row row-space-12">	
                                    <div className="col-12">
                                        <div className='row'>
                                            <div className="col-xl-4">
                                                <label className="form-label"><strong>Razón Social</strong></label>
                                                <input className="form-control" placeholder="Razón Social" 
                                                    id="razon_social"
                                                    name="razon_social"
                                                    value={formQueja.QuejasDenominacion}
                                                    disabled/>
                                            </div>
                                            <div className="col-xl-4">
                                                <label className="form-label"><strong>Sector</strong></label>
                                                <input className="form-control" placeholder="Sector"
                                                    id="sector"
                                                    name="sector"
                                                    value={formQueja.QuejasSector}
                                                    disabled={true} />
                                            </div>
                                            <div className="col-xl-4">
                                                <label className="form-label"><strong>Tipo Producto</strong></label>
                                                <input className="form-control" placeholder="Nivel de Atención" 
                                                    id="nivel_atención" 
                                                    name="nivel_atencion"
                                                    value={buscarProductosById(formQueja.QuejasProducto)}
                                                    disabled={true} />	
                                            </div>
                                        </div>
                                    </div>
                                </div>
                               
                                <div className="row row-space-12">	
                                    <div className="col-12">
                                        <div className='row'>
                                            <div className="col-xl-6">
                                                <label className="form-label"><strong>Queja a Reportar</strong></label>
                                                <textarea className="form-control" placeholder="Queja a Reportar" 
                                                    id="queja_reportar" 
                                                    name="queja_reportar"
                                                    value={buscarCausasById(formQueja.QuejasCausa)}
                                                    row="10" 
                                                    disabled>
                                                </textarea>
                                            </div>
                                            <div className="col-xl-3">
                                                <label className="form-label"><strong>Posible Robo Identidad</strong></label>
                                                <input className="form-control" placeholder="Posible Robo Identidad"
                                                    id="robo_identidad"
                                                    name="robo_identidad"
                                                    value={formQueja.QuejasPORI}
                                                    disabled={true} />
                                            </div>
                                            <div className="col-xl-3">
                                                <label className="form-label"><strong>Estatus</strong></label>
                                                <input className="form-control" placeholder="Estatus" 
                                                    id="estatus" 
                                                    name="estatus"
                                                    value={formQueja.QuejasEstatus == 1 ? "PENDIENTE" : "CONCLUIDA"}
                                                    disabled={true} />	
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row row-space-12">	
                                    <div className="col-12">
                                        <div className='row'>
                                            <div className="col-xl-4">
                                                <label className="form-label"><strong>Estado</strong></label>
                                                <input className="form-control" placeholder="Estado" 
                                                    id="estado" 
                                                    name="estado"
                                                    value={formQueja.QuejasEstados}
                                                    row="10" 
                                                    disabled />
                                            </div>
                                            <div className="col-xl-4">
                                                <label className="form-label"><strong>Municipio</strong></label>
                                                <input className="form-control" placeholder="Municipio" 
                                                    id="municipio" 
                                                    name="municipio" 
                                                    value={formQueja.QuejasMunId}
                                                    disabled={true} />
                                            </div>
                                            <div className="col-xl-4">
                                                <label className="form-label"><strong>Localidad</strong></label>
                                                <input className="form-control" placeholder="Localidad" 
                                                    id="localidad" 
                                                    name="localidad"
                                                    value={formQueja.QuejasLocId}
                                                    disabled={true} />	
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row row-space-12">	
                                    <div className="col-12">
                                        <div className='row'>
                                            <div className="col-xl-4">
                                                <label className="form-label"><strong>Colonia</strong></label>
                                                <input className="form-control" placeholder="Colonia" 
                                                    id="colonia" 
                                                    name="colonia"
                                                    value={formQueja.QuejasColId}
                                                    row="10" 
                                                    disabled />
                                            </div>
                                            <div className="col-xl-4">
                                                <label className="form-label"><strong>CP</strong></label>
                                                <input className="form-control" placeholder="CP" 
                                                    type='text'
                                                    id="cp" 
                                                    name="cp" 
                                                    value={formQueja.QuejasCP}
                                                    disabled={true} />
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>

                                <div className="row row-space-12">	
                                    <div className="col-12">
                                        <div className='row'>
                                            <div className="col-xl-8">
                                                <label className="form-label"><strong>Tipo Persona</strong></label>
                                                <input className="form-control" placeholder="Tipo Persona" 
                                                    id="tipo_persona" 
                                                    name="tipo_persona"
                                                    value={formQueja.QuejasTipoPersona === 1 ? 'Persona Física' : formQueja.QuejasTipoPersona === 2 ? 'Persona Moral' : 'Tipo de persona desconocido'}
                                                    row="10" 
                                                    disabled />
                                            </div>
                                            <div className="col-xl-2">
                                                <label className="form-label"><strong>Sexo</strong></label>
                                                <input className="form-control" placeholder="sexo" 
                                                    id="sexo" 
                                                    name="sexo" 
                                                    value={formQueja.QuejasSexo !== null ? formQueja.QuejasSexo : 'N/A'}
                                                    disabled={true} />
                                            </div>
                                            <div className="col-xl-2">
                                                <label className="form-label"><strong>Edad</strong></label>
                                                <input className="form-control" placeholder="Edad" 
                                                    id="edad" 
                                                    name="edad"
                                                    value={formQueja.QuejasEdad !== null ? formQueja.QuejasEdad : 'N/A'}
                                                    disabled={true} />	
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row row-space-12">	
                                    <div className="col-12">
                                        <div className='row'>
                                            <div className="col-xl-4">
                                                <label className="form-label"><strong>Fecha Resolucion</strong></label>
                                                <input className="form-control" placeholder="Fecha Resolucion" 
                                                    id="fecha_resolucion" 
                                                    name="fecha_resolucion"
                                                    value={formQueja.QuejasFecResolucion}
                                                    row="10" 
                                                    disabled />
                                            </div>
                                            <div className="col-xl-4">
                                                <label className="form-label"><strong>Fecha Notificacion</strong></label>
                                                <input className="form-control" placeholder="Fecha Notificacion" 
                                                    id="fecha_notificacion" 
                                                    name="fecha_notificacion" 
                                                    value={formQueja.QuejasFecNotificacion}
                                                    disabled={true} />
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>

                                <div className="row row-space-12">	
                                    <div className="col-12">
                                        <div className='row'>
                                            <div className="col-xl-4">
                                                <label className="form-label"><strong>Respuesta</strong></label>
                                                <input className="form-control" placeholder="Respuesta" 
                                                    id="respuesta" 
                                                    name="respuesta"
                                                    value={formQueja.QuejasRespuesta === 1 ? 'Totalmente favorable al usuario' :
                                                    formQueja.QuejasRespuesta === 2 ? 'Desfavorable al usuario' :
                                                    formQueja.QuejasRespuesta === 3 ? 'Parcialmente favorable al usuario' :
                                                    'Resultado no válido'}
                                                    row="10" 
                                                    disabled />
                                            </div>
                                            <div className="col-xl-4">
                                                <label className="form-label"><strong>Numero Penalizaciones</strong></label>
                                                <input className="form-control" placeholder="Numero Penalizaciones"
                                                    id="numero_penalizaciones" 
                                                    name="numero_penalizaciones" 
                                                    value={formQueja.QuejasNumPenal}
                                                    disabled={true} />
                                            </div>
                                            <div className="col-xl-4">
                                                <label className="form-label"><strong>Tipo Penalizacion</strong></label>
                                                <input className="form-control" placeholder="Tipo Penalizacion" 
                                                    id="tipo_penalizacion" 
                                                    name="tipo_penalizacion"
                                                    value={formQueja.PenalizacionId === 1 ? 'Contractuales - Cancelación del contrato' :
                                                    formQueja.PenalizacionId === 2 ? 'Contractuales - Reasignación de cartera' :
                                                    formQueja.PenalizacionId === 3 ? 'Económicas - Multa' :
                                                    'Opción no válida'}
                                                    disabled={true} />	
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </form>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-default" onClick={finished}>Cerrar</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ModalEditarQueja;