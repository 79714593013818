import React from 'react';
import App from './../app.jsx';
import { Navigate } from 'react-router-dom';

import Dashboard from './../pages/dashboard/dashboard.js';
import PagesComingSoon from './../pages/pages/coming-soon.js';
import PagesError from './../pages/pages/error.js';
import PagesForbidden from './../pages/pages/forbidden.js';
import PagesTokenInvalid from './../pages/pages/token-invalid.js';
import PagesTokenExpired from './../pages/pages/token-expired.js';
import PagesAccountActivate from './../pages/pages/account-activate.js';
import PagesLogin from './../pages/pages/login.js';
import PagesRegister from './../pages/pages/register.js';
import Profile from './../pages/profile/profile.js';
import Calendar from './../pages/calendar/calendar.js';
import Settings from './../pages/settings/settings.js';

import ProyectoLista from './../pages/dae/proyectos/proyecto-lista.js';
import ProyectoDetalle from './../pages/dae/proyectos/proyecto-detalle.js';
import ProyectoNuevo from './../pages/dae/proyectos/proyecto-nuevo.js';
import ProyectoPaquetes from './../pages/dae/proyectos/proyecto-paquetes.js';

import CatalogoBancos from './../pages/dae/catalogos/catalogo-bancos.js';
import CatalogoPromotores from './../pages/dae/catalogos/catalogo-promotores.js';

import EstadosCuenta from './../pages/dae/cuentas/estado-cuentas.js';

import Anticipos from './../pages/dae/supervision/anticipos/anticipos.js';
import AutorizarAnticipos from './../pages/dae/supervision/anticipos/autorizar_anticipos.js';
import FondoGarantia from '../pages/dae/supervision/fondos/fondo_garantia.js';
import FondoInversion from './../pages/dae/inversiones/inversiones.js';
import Obra from './../pages/dae/supervision/obra/obra.js';
import ParametrosPDF from './../pages/dae/parametros/parametros.js';
import SubirMinistracion from './../pages/dae/supervision/subir/ministracion.js';

import ReportesPaquetes from './../pages/dae/reportes/paquetes.js';
import Reportes from './../pages/dae/reportes/reportes.js';

import AcreditadosLista from './../pages/dae/viviendas/acreditados.js';

import HistorialInconsistencias from '../pages/dae/historial/inconsistencias.js';
import AdministracionUsuarios from '../pages/dae/usuarios/usuarios.js';

import PrivateRoute from '../components/PrivateRoute.jsx';

import { MASTER, ADMINISTRACION, TECNICO, JURIDICO, DESARROLLADOR, DIRECCION } from '../helpers/roles.js';
import Redeco from '../pages/dae/condusef/redeco.js';
import Reune from '../pages/dae/condusef/reune.js';


const AppRoute = [{
    path: '*', 
    element: <App />,
    children: [
    	{ path: '', element: <Navigate to='/proyecto/lista' /> },
    	/*{ path: 'dashboard', element: <PrivateRoute requiredRoles={[]}><Dashboard /></PrivateRoute> },*/
		{ path: 'usuarios', element: <PrivateRoute requiredRoles={[MASTER]}><AdministracionUsuarios title="Administración" subtitle="Usuarios" /></PrivateRoute> },
    	{path: 'proyecto/*',
			children: [
				{ path: 'lista', element: <PrivateRoute requiredRoles={[MASTER, ADMINISTRACION, DIRECCION, TECNICO, JURIDICO]}><ProyectoLista title="Proyectos" subtitle="Lista de Proyectos" /></PrivateRoute> },
				{ path: 'nuevo', element: <PrivateRoute requiredRoles={[MASTER, ADMINISTRACION, DIRECCION]}><ProyectoNuevo title="Proyectos" subtitle="Nuevo Proyecto" /></PrivateRoute> },
				{ path: 'detalle', element: <PrivateRoute requiredRoles={[MASTER, ADMINISTRACION, DIRECCION, TECNICO, JURIDICO]}><ProyectoDetalle title="Proyectos" subtitle="Detalle del Proyecto" /></PrivateRoute> },
				{ path: 'paquetes', element: <PrivateRoute requiredRoles={[MASTER, ADMINISTRACION, DIRECCION, TECNICO, JURIDICO]}><ProyectoPaquetes title="Proyectos" subtitle="Paquetes del Proyecto" /></PrivateRoute> },
				{ path: '*', element: <Navigate to="/pages/error" replace />}
			]
		},
		{path: 'vivienda/acreditados', element: <PrivateRoute requiredRoles={[MASTER, TECNICO]}><AcreditadosLista title="Viviendas" subtitle="Lista Acreditados" /></PrivateRoute> },
		
		{path: 'catalogo/*',
			children: [
				{ path: 'bancos', element: <PrivateRoute requiredRoles={[MASTER, ADMINISTRACION, DIRECCION]}><CatalogoBancos title="Catalogo" subtitle="Bancos" /></PrivateRoute> },
				{ path: 'promotores', element: <PrivateRoute requiredRoles={[MASTER, ADMINISTRACION, DIRECCION]}><CatalogoPromotores title="Catalogo" subtitle="Promotores" /></PrivateRoute> },
				{ path: '*', element: <Navigate to="/pages/error" replace />}
			]
		},
		{path: 'estados/cuenta', element: <PrivateRoute requiredRoles={[MASTER, ADMINISTRACION, DIRECCION]}><EstadosCuenta title="Cuentas" subtitle="Estados de Cuentas" /></PrivateRoute> },
		{path: 'supervision/*',
			children: [
				{ path: 'anticipos', element: <PrivateRoute requiredRoles={[MASTER, ADMINISTRACION, DIRECCION]}><Anticipos title="Supervision" subtitle="Autorizar Anticipos" /></PrivateRoute> },
				{ path: 'anticipos/autorizar', element: <PrivateRoute requiredRoles={[MASTER, TECNICO]}><AutorizarAnticipos title="Supervision" subtitle="Autorizar Anticipos" /></PrivateRoute> },
				{ path: 'parametros', element: <PrivateRoute requiredRoles={[MASTER, TECNICO]}><ParametrosPDF title="Supervision" subtitle="Parametros Ministracion" /></PrivateRoute> },
				{ path: 'obra', element: <PrivateRoute requiredRoles={[MASTER, ADMINISTRACION, DIRECCION, TECNICO]}><Obra title="Supervision" subtitle="Obra" /></PrivateRoute> },
				{ path: 'ministracion', element: <PrivateRoute requiredRoles={[MASTER, TECNICO]}><SubirMinistracion title="Supervision" subtitle="Subir Ministraciones" /></PrivateRoute> },
				{ path: 'garantia', element: <PrivateRoute requiredRoles={[MASTER, ADMINISTRACION, DIRECCION, TECNICO]}><FondoGarantia title="Supervison" subtitle="Fondo en Garantia" /></PrivateRoute> },
				{ path: '*', element: <Navigate to="/pages/error" replace />}
			]
		},
		{ path: 'inversiones', element: <PrivateRoute requiredRoles={[MASTER]}><FondoInversion title="Inversiones" subtitle="Fondo de Inversiones" /></PrivateRoute> },
		{ path: 'pages/*', 
    		children: [
					{ path: 'forbidden', element: <PagesForbidden /> },
					{ path: 'token-expired', element: <PagesTokenExpired /> },
					{ path: 'token-invalid', element: <PagesTokenInvalid /> },
					{ path: 'active-account', element: <PagesAccountActivate /> },
					{ path: 'coming-soon', element: <PagesComingSoon /> },
					{ path: 'error', element: <PagesError /> },
					{ path: 'login', element: <PagesLogin /> },
					{ path: 'register', element: <PagesRegister /> },
					{ path: '*', element: <Navigate to="/pages/error" replace />}
				]
			},
		{ path: 'historial/inconsistencias', element: <PrivateRoute requiredRoles={[MASTER, ADMINISTRACION,  DIRECCION]}><HistorialInconsistencias title="Historial" subtitle="Inconsistencias" /></PrivateRoute> },
		{ path: 'reportes/*',
			children: [
				{ path: 'general', element: <PrivateRoute requiredRoles={[MASTER, TECNICO]}><Reportes title="Reportes" subtitle="Reporte General" /></PrivateRoute> },
				{ path: 'paquetes', element: <PrivateRoute requiredRoles={[MASTER, TECNICO]}><ReportesPaquetes title="Reportes" subtitle="Reporte Paquetes" /></PrivateRoute> },
		
			]
		},
		{ path: 'condusef/*',
			children: [
				{ path: 'redeco', element: <PrivateRoute requiredRoles={[MASTER, ADMINISTRACION, DIRECCION]}><Redeco title="CONDUSEF" subtitle="REDECO" /></PrivateRoute> },
				{ path: 'reune', element: <PrivateRoute requiredRoles={[MASTER, ADMINISTRACION, DIRECCION]}><Reune title="CONDUSEF" subtitle="REUNE" /></PrivateRoute> },
		
			]
		},
		{ path: 'profile', element: <PrivateRoute requiredRoles={[MASTER, ADMINISTRACION, DIRECCION, TECNICO, JURIDICO, DESARROLLADOR]}><Profile title="Perfil" subtitle=""  /></PrivateRoute> },
    	{ path: 'calendar', element: <PrivateRoute requiredRoles={[MASTER, ADMINISTRACION, DIRECCION, TECNICO, JURIDICO, DESARROLLADOR]}><Calendar title="Calendario" subtitle="" /></PrivateRoute> },
    	{ path: 'settings', element: <PrivateRoute requiredRoles={[MASTER, ADMINISTRACION, DIRECCION, TECNICO, JURIDICO, DESARROLLADOR]}><Settings title="Opciones" subtitle="" /></PrivateRoute> },
		// Redireccionar a la página de error 404 si la ruta no existe
		{ path: '*', element: <Navigate to="/pages/error" replace />},
    ]
}];


export default AppRoute;