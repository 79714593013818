import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Select from 'react-select';

import { getProyectosPromotores } from '../../api/proyectos/proyectos';

function SelectProyectos({ setSharedProyectoId, setSharedEtapaId }) {
    const token = useSelector(state => state.authentication.user?.token);

    const [proyectos, setProyectos] = useState([]);
    const [selectedProyecto, setSelectedProyecto] = useState(0);

    useEffect(() => { 
    	fetchProyectos();
	}, [token]);

    const fetchProyectos = async () => {
		try {
			const response = await getProyectosPromotores(token);
            const proyectos = response.map((value) => {
                return { value: value.proyecto_id, label: value.proyecto_nombre };
            });
            
            setProyectos(proyectos);
		} catch (error) {
			console.error('Error al cargar los proyectos', error);
		}
	};

    const handleProyectoChange = (selectedOption) => {
		setSelectedProyecto(selectedOption);
        setSharedProyectoId(selectedOption.value);
        setSharedEtapaId(0);
	};

    return (
        <div className="form-group mb-3">
			<label className="form" htmlFor="proyectos" style={{"marginBottom" : "7px"}} ><strong>Proyectos</strong></label>
			<Select 
                id="proyectos"  
                menuPortalTarget={document.body} 
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                options={proyectos} 
                onChange={handleProyectoChange}
                value={selectedProyecto}
                placeholder={"Selecciona un proyecto"} />
        </div>
    )
}

export default SelectProyectos;

