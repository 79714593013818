import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import useAuthorization from '../../../hooks/useAuthorization';

import Breadcrumb from '../../../components/breadcrumb/breadcrumb';
import { Card, CardBody, CardHeader }  from '../../../components/card/card';
import Swal from 'sweetalert2';
import { Modal } from 'bootstrap';

import SelectProyectos from '../../../components/select/proyectos';
import SelectEtapas from '../../../components/select/etapas';
import SelectPaquetes from '../../../components/select/paquetes';

import { getDerechohabientesByPaqueteId, getDerechohabientesAnticposById } from '../../../api/derechohabiente/derechohabiente';

import { errorNotification, formatoMoneda } from '../../../helpers';
import ModalCalculosAnticipos from '../proyectos/modals/anticipos';
import ModalCalculosComisiones from '../proyectos/modals/calculos-comisiones';
import { getPaqueteById } from '../../../api/paquete_proyectos/paquete_proyectos';


const $ = require('jquery');
require('datatables.net-bs5');
require('datatables.net-buttons');
require('datatables.net-buttons/js/buttons.colVis.min.js');
require('datatables.net-buttons/js/buttons.flash.min.js');
require('datatables.net-buttons/js/buttons.html5.min.js');
require('datatables.net-buttons/js/buttons.print.min.js');
require('datatables.net-buttons-bs5');
require('datatables.net-responsive');
require('datatables.net-responsive-bs5');
require('datatables.net-fixedcolumns');
require('datatables.net-fixedcolumns-bs5');


function AcreditadosLista(props) {	
	let tableDerechohabientesRef = useRef(null);

	const token = useSelector(state => state.authentication.user?.token);
    
	const [sharedProyectoId, setSharedProyectoId] = useState(0);
	const [sharedEtapaId, setSharedEtapaId] = useState(0);
	const [sharedPaqueteId, setSharedPaqueteId] = useState(0);

	const [derechohabientes, setDerechohabientes] = useState([]);
	// eslint-disable-next-line
	const [paquete, setPaquete] = useState([]);

	
	const [sharedDerechohabienteId, setSharedDerechohabienteId] = useState(0);

	useEffect(() => {
		if(sharedProyectoId == 0) {
			setSharedEtapaId(0);
			setSharedPaqueteId(0);
        }
	}, [sharedProyectoId])


	useEffect(() => {
		if(sharedPaqueteId > 0) {
			fetchPaqueteById(sharedPaqueteId)
		} else {
			setPaquete({});	
        }
	}, [sharedPaqueteId]);

	useEffect(() => {
		fetchDerechohabientesByPaqueteId();
	}, [paquete]);

	const fetchPaqueteById = async (paquete_id) => {
		try {
			const response = await getPaqueteById(paquete_id, token);
			setPaquete(response);
		} catch (error) {
			console.error('Error al cargar los inconsistencias', error);
		}
	};

	const fetchDerechohabientesByPaqueteId = async () => {
        try {
            const response = await getDerechohabientesByPaqueteId(paquete.id, token);
			setDerechohabientes(response);
        } catch (error) {}
    }

	const fetchDerechohabienteAnticiposById = async(id, nombre, estatus_firma, ministracion, subsidio) => {
		try {
			const response = await getDerechohabientesAnticposById(id, token);  
			setSharedDerechohabienteId(id)
			let myModal = new Modal(document.getElementById('modalCalculosAnticipos'));
			myModal.show();
		} catch (error) {}
    }


	useEffect(() => {
		if (tableDerechohabientesRef !== null && tableDerechohabientesRef.current !== null) {
			let index = 1;
			tableDerechohabientesRef.current.clear();
			tableDerechohabientesRef.current.rows.add(
                derechohabientes.map(dech => [index++, 
                                                dech.dh.id, 
                                                dech.dh.nombres + " " + dech.dh.apellido_paterno + " " + dech.dh.apellido_materno, 
                                                dech.dh.curp,
                                                dech.dh.num_credito,
                                                dech.dh.num_ssocial,
                                                dech.direccion.calle,
                                                dech.direccion.manzana,
                                                dech.direccion.lote,
                                                dech.direccion.num_interior,
                                                dech.direccion.num_exterior,
                                                dech.dh.monto_ministracion,
                                                dech.dh.monto_subsidio,
                                                dech.dh.numero_instrumento,
                                                dech.dh.volumen,
                                                dech.dh.firma_folio,
                                                dech.dh.estatus_firma_folio === '1' ? 'Firmado' : (dech.dh.estatus_firma_folio === '2' ? 'No Firmado' : (dech.dh.estatus_firma_folio === '3' === 'Firmado pero no forma parte del paquete' ))])); //16
            tableDerechohabientesRef.current.draw();
		} else 
			initDatatableDerechohabientes();		
		// eslint-disable-next-line
	}, [derechohabientes]);

	const initDatatableDerechohabientes = () => {
            tableDerechohabientesRef.current = $('#tableDerechohabientes').DataTable({
                dom: "<'row mb-3'<'col-md-4 mb-3 mb-md-0'l><'col-md-8 text-end'<'d-lg-flex justify-content-end'f<'d-lg-block d-none'B>>>>t<'row align-items-center'<'mr-auto col-md-6 mb-3 mb-md-0 mt-n2 'i><'mb-0 col-md-6'p>>",		
                lengthMenu: [10, 20, 30, 40, 50],
                buttons: [
					{ 
						extend: 'excelHtml5', 
						className: 'btn btn-outline-default btn-sm',
						filename: function() {
							var timestamp = new Date().getTime(); 
							return 'derechohabientes_' + timestamp; 
						},
						exportOptions: {
							columns: ':visible',
							modifier: {
								page: 'none' // Evita agregar información de paginación
							}
						},
						customizeData: function ( data ) {
							for (var i=0; i < data.body.length; i++){
								for (var j=0; j<data.body[i].length; j++ ){
									data.body[i][j] = '\u200C' + data.body[i][j];
								}
							}
						}
					}
				],
                columns: [
                    { title: "#" },//0
                    { title: "ID" }, //1 
                    { title: "Derechohabiente" }, //2 
                    { title: "CURP" }, // 3
                    { title: "No. Crédito" }, //4
                    { title: "NSS" }, //5
                    { title: "Dirección" }, // 6
                    { title: "Mza." }, // 7
                    { title: "Lote" }, // 8
                    { title: "No. Ext." }, //9
                    { title: "No. Int." }, // 10
                    { 
						title: "Dep. Infonavit",
						width: "150px",
						render: function (data, type, row, meta) {
							let number = row[11]?.replace(/,/g, '') || 0;
							return `<div style="white-space: nowrap;">${formatoMoneda.format(parseFloat(number))}</div>`;
						},
					}, //11
					{ 
						title: "Dep. CONAVI",
						width: "150px",
						render: function (data, type, row, meta) {
							let number = row[12]?.replace(/,/g, '') || 0;
							return `<div style="white-space: nowrap;">${formatoMoneda.format(parseFloat(number))}</div>`;
						},
					}, //12
                    { title: "No. Instrumento" }, //13
                    { title: "Vol." }, //14
                    { title: "Folio" }, //15
                    { 
						title: "Firma",  //16
						width: "250px",
						render: function (data, type, row, meta) {
							return `<div style="white-space: nowrap;">${row[16]}</div>`;
						},
					},{
						title: "",
						data: null,
						width: "200px",
						render: function (data, type, row, meta) {
							const derechohabiente_id = row[1];
							const derechohabiente_name = row[2];
                            const ministracion = row[11];
                            const subsidio = row[12];
                            const estatus_firma = row[15];
                            
                            
							return `<div style="text-align: right; white-space: nowrap;">
										<a  style="cursor:pointer" id="anticipos" data-derechohabiente-id="${derechohabiente_id}"  data-derechohabiente-name="${derechohabiente_name}" data-estatus-firma="${estatus_firma}" data-ministracion="${ministracion}" data-subsidio="${subsidio}"  >
											<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#008000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-dollar-sign"><line x1="12" y1="1" x2="12" y2="23"></line><path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path></svg>
										</a>
										<a style="cursor:pointer" id="comisiones" data-derechohabiente-id="${derechohabiente_id}"  data-derechohabiente-name="${derechohabiente_name}"  >
											<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#AE38DE" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file-text"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="16" y1="13" x2="8" y2="13"></line><line x1="16" y1="17" x2="8" y2="17"></line><polyline points="10 9 9 9 8 9"></polyline></svg>
										</a>  
									</div>`;
                                
                            
						},
						escape: false,
					}
				],
                columnDefs: [{
                    targets: [1], 
                    visible: false
                }]
            });

		$('#tableDerechohabientes').on('click', '#anticipos', function () {
			const id = $(this).data('derechohabiente-id');
			const nombre = $(this).data('derechohabiente-name');
			const estatus_firma = $(this).data('estatus-firma');
			const ministracion = $(this).data('ministracion');
			const subsidio = $(this).data('subsidio');
			actionAnticiposDerechohabiente(id, nombre, estatus_firma, ministracion, subsidio);
		});
	
		$('#tableDerechohabientes').on('click', '#comisiones', function () {
			const id = $(this).data('derechohabiente-id');
			const nombre = $(this).data('derechohabiente-name');
			actionComisionesDerechohabiente(id, nombre);
		});
        
	}

	

    const actionAnticiposDerechohabiente = (id, nombre, estatus_firma, ministracion, subsidio) => {
        fetchDerechohabienteAnticiposById(id, nombre, estatus_firma, ministracion, subsidio);
    }

	const actionComisionesDerechohabiente = (id, nombre) => {
		setSharedDerechohabienteId(id)
		let myModal = new Modal(document.getElementById('modalCalculosComisiones'));
		myModal.show();
    }

	return (
		<div>
			<Breadcrumb title={props.title} subtitle={props.subtitle}/>
			<div className="row justify-content-center">
                <hr className="mb-4" />
				<div className="col-xl-11">
					<div className="col-xl-12">
						<div className='row'>
							<div className='col-4'>
								<SelectProyectos setSharedProyectoId={setSharedProyectoId} setSharedEtapaId={setSharedEtapaId}  />
							</div>
							<div className='col-4'>
								<SelectEtapas  proyecto_id={sharedProyectoId} setSharedEtapaId={setSharedEtapaId} setSharedPaqueteId={setSharedPaqueteId} />
							</div>
							<div className='col-4'>
								<SelectPaquetes etapa_id={sharedEtapaId} setSharedPaqueteId={setSharedPaqueteId}  />
							</div>
						</div>	
						<br/>
						<div id="datatable" className="mb-4">
							<Card> 
								<CardHeader>
									<span className="flex-grow-1">Lista de Derechohabientes.</span>
								</CardHeader>
								<CardBody>
									<div className='row'>
										<div className="col-xl-12 tabla-scrollable table-responsive ">
											<table id="tableDerechohabientes" className="table  text-nowrap  w-100 table-striped table-derechohabientes">
												<thead></thead>
												<tbody></tbody>
											</table>
										</div>
									</div>
								</CardBody>
							</Card>
						</div>
					</div>
				</div>
			</div>
			<ModalCalculosAnticipos sharedDerechohabienteId={sharedDerechohabienteId}/>
			<ModalCalculosComisiones sharedDerechohabienteId={sharedDerechohabienteId}/>
		</div>
	)
}

export default AcreditadosLista;

