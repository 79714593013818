import { useState, useEffect } from 'react';

function useAuthentication() {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [tokenAccess, setTokenAccess] = useState(null);


  useEffect(() => {
    const checkAuthentication = () => {
    	const userFromLocalStorage =  localStorage.getItem('user');
      	if(userFromLocalStorage) {
        	const parsedUser = JSON.parse(userFromLocalStorage);
        	setIsAuthenticated(!!parsedUser.token); 
      	} else 
        	setIsAuthenticated(false);
    };

    checkAuthentication(); 
    return () => { };
  }, []);

  

  return  isAuthenticated ;
}



export default useAuthentication;
