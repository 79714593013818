import React,  { useEffect, useRef }from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useAuthorization from '../../../../hooks/useAuthorization';

import { Card, CardBody, CardHeader }  from '../../../../components/card/card';
import Swal from 'sweetalert2'
import { Modal } from 'bootstrap'

import { errorNotification, successNotification, alert } from '../../../../helpers';

import { deleteEtapaById } from '../../../../api/etapa_proyectos/etapa_proyectos';


const $ = require('jquery');

function TableEtapas({ user_roles, etapas, setSharedEtapaId, 
		onSuccessEtapa, onShowTablePaquetes, onOpenModal }) {

	const token = useSelector(state => state.authentication.user?.token);
	
    const tableEtapasRef = useRef(null);

    useEffect(() => {
		if(user_roles.length > 0) {
			if (tableEtapasRef.current !== null) {
				let index = 1;
				tableEtapasRef.current.clear();
				tableEtapasRef.current.rows.add(etapas.map(etapa => [index++, etapa.id, etapa.nombre, etapa.descripcion]));
				tableEtapasRef.current.draw();
			} else 
				initTableEtapas()
			// eslint-disable-next-line
		}
	}, [etapas, user_roles]);

    const fetchDeleteEtapaById = async (etapa_id) => {
		try {
			const response = await deleteEtapaById(etapa_id, token);
			if(response.message === "success") {
				successNotification("Etapa Eliminado Correctamente");
				onSuccessEtapa();
			}
			else if(response.message === "failed")
				alert("No es posible eliminar la etapa.", "Existen paquetes asociados a esta etapa.", "error");
		} catch (error) {
			errorNotification("Ocurrio un error en el servidor.");
		}
	};

    const initTableEtapas = () => {
		tableEtapasRef.current = $('#tableEtapas').DataTable({
			lengthMenu: [5, 10, 20, 30, 40],
			columns: [
				{ title: "#", width: "10%" },
				{ title: "ID" },	
				{ title: "Nombre" },
				{ title: "Descripcion" },
				{
					title: "",
					width: "10%",
					data: null,
					render: function (data, type, row, meta) {
						const etapa_id = row[1];
						const etapa_name = row[2];
						if (['administracion', 'master'].some(role => user_roles.includes(role))) {
							return `<div style="text-align: right;">	
										<a style="cursor:pointer; margin-right:5px"  id="verPaquetes" data-etapa-id="${etapa_id}"  data-etapa-name="${etapa_name}"  >
											<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#2874A6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>
										</a>
										<a  style="cursor:pointer; margin-right:5px" id="editarEtapa" data-etapa-id="${etapa_id}"  data-etapa-name="${etapa_name}">
											<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#F1C40F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
										</a>
										<a style="cursor:pointer" id="eliminarEtapa" data-etapa-id="${etapa_id}"  data-etapa-name="${etapa_name}" >
											<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#EE0C05" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
										</a>
									</div>`;
						} else if (['juridico', 'tecnico'].some(role => user_roles.includes(role))) {
							return `<div style="text-align: right;">	
										<a style="cursor:pointer"  id="verPaquetes" data-etapa-id="${etapa_id}"  data-etapa-name="${etapa_name}" >
											<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#2874A6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>
										</a>
									</div>`;
						} 
					},
					escape: false,
				}
			],
			columnDefs: [{
				targets: 1, 
				visible: false
			}]	
		});

		$('#tableEtapas').on('click', '#verPaquetes', function () {
			const id = $(this).data('etapa-id');
			const nombre = $(this).data('etapa-name');
			actionVerPaquetes(id, nombre);
		});
	
		$('#tableEtapas').on('click', '#editarEtapa', function () {
			const id = $(this).data('etapa-id');
			actionEditarEtapa(id);
		});
	
		$('#tableEtapas').on('click', '#eliminarEtapa', function () {
			const id = $(this).data('etapa-id');
			const nombre = $(this).data('etapa-name');
			actionEliminarEtapa(id, nombre);
		});	

	}

    const actionVerPaquetes = (id, nombre) => {
		onShowTablePaquetes(id, nombre);
	}

	const actionEditarEtapa = (id) => {
		setSharedEtapaId(id);
		onOpenModal('EditarEtapas');
	}

	const actionEliminarEtapa = (id) => {
		Swal.fire({
			title: "¿Estas seguro?",
			text: "Una vez eliminado ya no podrás acceder a esta Etapa",
			icon: "question",
			confirmButtonText: 'Continuar',
			cancelButtonText: 'Cancelar',
			showCancelButton: true,
			showCloseButton: true,
			allowOutsideClick: false,
			allowEscapeKey: false,
			allowEnterKey: false,
			reverseButtons : true
		}).then((result) => {
			if (result.isConfirmed) {
				confirmEliminarEtapa(id);
			}
		}).catch(() => {
			return false;
		})	
	}

	const confirmEliminarEtapa = (id) => {
		Swal.fire({
			title: "¿Estas seguro?",
			text: "Esta operacion no se puede deshacer.",
			icon: "warning",
			confirmButtonText: 'Eliminar',
			cancelButtonText: 'Cancelar',
			showCancelButton: true,
			showCloseButton: true,
			allowOutsideClick: false,
			allowEscapeKey: false,
			allowEnterKey: false,
			reverseButtons : true
		}).then((result) => {
			if (result.isConfirmed) {	
				fetchDeleteEtapaById(id);
			}
		}).catch(() => {
			return false;
		})
	}

    const renderButtonAgregarEtapa = () => {
		if (['administracion'].some(role => user_roles.includes(role))) {
			return (
				<div className="ml-auto">
					<a className="btn btn-outline-default" onClick={() => onOpenModal('NuevaEtapa')}>Registrar Etapa</a>
				</div>
			)
		} else if (['tecnico'].some(role => user_roles.includes(role))) {
			return (
				<div className="ml-auto">
					<a className="btn btn-outline-default" onClick={() => onOpenModal('CalculosProyectos')} >Calculos Proyecto</a>
				</div>
			)
		} else if (['master'].some(role => user_roles.includes(role))) {
			return (
				<>
				<div className="ml-auto" style={{marginRight: '1em'}}>
					<a className="btn btn-outline-default" onClick={() => onOpenModal('NuevaEtapa')}>Registrar Etapa</a>
				</div>
				<div className="ml-auto">
					<a className="btn btn-outline-default" onClick={() => onOpenModal('CalculosProyectos')}>Calculos Proyecto</a>
				</div>
				</>
			)
		}
	}

    return (
        <div className="col-xl-6">
            <div className="list-group-item d-flex align-items-center" style={{marginBottom: '1em', marginTop: '-0.5em'}}>
				<div className="flex-1 text-break"></div>
                { renderButtonAgregarEtapa() }
            </div>
            <div id="datatable" className="mb-4">
                <Card>
                    <CardHeader>
                        <span className="flex-grow-1">Lista de Etapas</span>
                    </CardHeader>
                    <CardBody>
						<div className='col-12 tabla-scrollable table-responsive'>
							<table id="tableEtapas" className="table table-striped text-nowrap w-100">
								<thead></thead>
								<tbody></tbody>
							</table>
						</div>
                    </CardBody>
                </Card>
            </div>
        </div>
    );
}

export default TableEtapas;
