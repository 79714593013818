import { useState, useEffect } from 'react';
import { decodeToken } from '../helpers';

function useAuthorization() {
	const [userRoles, setUserRoles] = useState([]);
    
	useEffect(() => {
    	
		const checkAuthorization = () => {
			const userFromLocalStorage =  localStorage.getItem('user');
			if(userFromLocalStorage) {
				const role = decodeToken(JSON.parse(userFromLocalStorage).token).role;
				setUserRoles([role]);
			} else {
				setUserRoles([]);
			}
    	};
    	checkAuthorization(); 
    	return () => {};
  	
	}, []);
	
  return userRoles;
}

export default useAuthorization;
