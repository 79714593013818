import React,  { useEffect, useState, useRef }from 'react';
import { useSelector } from 'react-redux';

import { Card, CardBody, CardHeader } from '../../../../../components/card/card';
import Swal from 'sweetalert2';

import SelectProyectos from '../../../../../components/select/proyectos';
import SelectEtapas from '../../../../../components/select/etapas';
import SelectPaquetes from '../../../../../components/select/paquetes';

import { 
    getPaqueteArchivosTerminacionById, 
    getPaqueteById, 
    getPaqueteDocumentoGarantiaAdminById, 
    getPaquetesByEtapaId, 
    updatePaqueteDocumentoGarantiaAdminById,
    updatePaqueteCartaTerminacionById,
    updatePaqueteCartaEntregaById,
    updatePaquetePolizaById,
    createPaqueteFondoGarantiaEnviar,
    createPaqueteFondoGarantiaArchivos,
    updatePaqueteDocumentoGarantiaById,
    getPaqueteDocumentoGarantiaById
} from '../../../../../api/paquete_proyectos/paquete_proyectos';
import { uploadFiles } from '../../../../../api/otros/otros';
import { serverUrl, errorNotification, successNotification,alert, formatoMoneda } from '../../../../../helpers';
import { getMinistracionFondoGrantiaByPaqueteId, getMinistracionesFondoGarantiaByPaqueteId, getMinistracionesPorcentajeObraByPaqueteId } from '../../../../../api/ministraciones/ministraciones';

import { getDerechohabienteRetencionByPaqueteId } from '../../../../../api/derechohabiente/derechohabiente';


function GarantiaTecnico() {
    const token = useSelector(state => state.authentication.user?.token);

	const [sharedProyectoId, setSharedProyectoId] = useState(0);
	const [sharedEtapaId, setSharedEtapaId] = useState(0);
	const [sharedPaqueteId, setSharedPaqueteId] = useState(0);

	const [showUplaodFile, setShowUploadFile] = useState(false);

    const [nuevoEstatus, setNuevoEstatus] = useState(0);
    
	const [documento, setDocumento] =  useState("");
	const [file, setFile] = useState(null);

    const [disabledSendFile, setDisabledSendFile] = useState(false);
    const [disabledCheckBox, setDisabledCheckBox] = useState(false);
    const [disabledButtonLiberar, setDisabledButtonLiberar] = useState(true);

    const [showButtonLiberar, setShowButtonLiberar] = useState(false);

    const [fondoGarantia, setFondoGarantia] = useState('0.0');

    //const [checkBoxFiles, setCheckBoxFiles] = useState({})
    const [checkCartaTerminacion, setCheckCartaTerminacion] = useState(false);
    const [checkCartaEntrega, setCheckCartaEntrega] = useState(false);
    const [checkPolizas, setCheckPolizas] = useState(false);

    useEffect(() => {
		if(sharedProyectoId == 0) {
			setSharedEtapaId(0);
			setSharedPaqueteId(0);
            setShowUploadFile(false);
             
            setDocumento("");
            setFile(null);
            setDisabledSendFile(false);
            setShowButtonLiberar(false);
		}
	}, [sharedProyectoId])


	useEffect(() => {
		if(sharedPaqueteId > 0) {
			setShowUploadFile(true);
			fetchPaqueteById(sharedPaqueteId)
		} else {
            setShowUploadFile(false);
             
            setDocumento("");
            setFile(null);
            setDisabledSendFile(false);
            setShowButtonLiberar(false);
        }
	}, [sharedPaqueteId]);

    useEffect(() => {
        console.log("aqui")
        //if(checkCartaTerminacion && checkCartaEntrega && checkPolizas) {
            setDisabledButtonLiberar(false);    
        //} else 
        //    setDisabledButtonLiberar(true);       
    }, [checkCartaTerminacion, checkCartaEntrega, checkPolizas]);

    useEffect(() => {
            changeEstatusPaquete();
    }, [nuevoEstatus])

    const fetchCreatePaqueteFondoGarantiaEnviar = async (data) => {
        try {
			const response = await createPaqueteFondoGarantiaEnviar(data, token);
            if(response.message === 'success') {
                setNuevoEstatus(5);
				alert("", "Notificación enviada", "success");
			} else {
                alert("", "No fue posible enviar la notificacion", "error");
            }
		} catch (error) {
			console.error('Error al cargar los inconsistencias', error);
		}
    }

    const fetchCreatePaqueteFondoGarantiaArchivos = async (data) => {
        try {
			const response = await createPaqueteFondoGarantiaArchivos(data, token);
            if(response.message === 'success') {
                alert("", "Archivos enviados", "success");
			} else if(response.message === 'no found') {
                alert("Faltan documentos", "Necesitan adjuntar el documento Fondo Garantia Administracion y/o Tecnico", "warning");
            } else if(response.message === 'error') {
                alert("", "No fue posible enviar la notificacion", "error");
            }
		} catch (error) {
			console.error('Error al cargar los inconsistencias', error);
		}
    }

    const fetchPaqueteById = async (paquete_id) => {
		try {
			const response = await getPaqueteById(paquete_id, token);
			setNuevoEstatus(response.estatus_paquete);
            fetchMinistracionesPorcentajeObraByPaqueteId(paquete_id, 1);
			fetchPaqueteDocumentoGarantiaById(paquete_id);
            
		} catch (error) {
			console.error('Error al cargar los inconsistencias', error);
		}
	};

    const fetchMinistracionesFondoGarantiaByPaqueteId= async (paquete_id) => {
		try {
			const response = await getMinistracionesFondoGarantiaByPaqueteId(paquete_id, token);
			fetchDerechohabienteRetencionByPaqueteId(paquete_id, response.message)
		} catch (error) {
			console.error('Error al cargar los datos', error);
		}
	};

    const fetchPaqueteArchivosTerminacionById= async (paquete_id) => {
		try {
			const response = await getPaqueteArchivosTerminacionById(paquete_id, token);
			setCheckCartaEntrega(response.message.vali_carta_entrega);
            setCheckCartaTerminacion(response.message.vali_carta_terminacion);
            setCheckPolizas(response.message.vali_polizas);
		} catch (error) {
			console.error('Error al cargar los datos', error);
		}
	};

    
    const fetchDerechohabienteRetencionByPaqueteId= async (paquete_id, fondo_garantia) => {
		try {
			const response = await getDerechohabienteRetencionByPaqueteId(paquete_id, token);
			setFondoGarantia((parseFloat(fondo_garantia) + parseFloat(response.message)).toFixed(2));
		} catch (error) {
			console.error('Error al cargar los datos', error);
		}
	};

	const fetchPaqueteDocumentoGarantiaById = async (paquete_id) => {
		try {
			const response = await getPaqueteDocumentoGarantiaById(paquete_id, token);
            if(response.message === 'no found') {
				setDocumento("Sin Documento");
			} else {
				setDocumento(response.message);
			}
		} catch (error) {
			console.error('Error al cargar los datos', error);
		}
	};

    const fetchMinistracionesPorcentajeObraByPaqueteId = async (paquete_id, num_ministracion) => {
		try {
            const response = await getMinistracionesPorcentajeObraByPaqueteId(paquete_id, num_ministracion, token);
            if(response.message == "100.00") {
                setShowUploadFile(true);
                fetchMinistracionesFondoGarantiaByPaqueteId(paquete_id);
                fetchPaqueteArchivosTerminacionById(paquete_id);
                
            } else {
				alert( "No cuenta con el 100%", "Avance de obra actual: "+response.message, "warning",);
                setShowUploadFile(false);
                setNuevoEstatus(0);
                setDocumento("");
                setFile(null);
                setDisabledSendFile(false);
                setShowButtonLiberar(false);
			}
			
		} catch (error) {
			console.error('Error al cargar los datos', error);
		}
	}

	const fetchUpdatePaqueteDocumentoGarantiaById = async (paquete_id, data) => {
		try {
			const response = await updatePaqueteDocumentoGarantiaById(paquete_id, data, token);
            if(response.message === 'success') {
				fetchPaqueteDocumentoGarantiaById(paquete_id);
			}
		} catch (error) {
			console.error('Error al cargar los datos', error);
		}
	};

    const fetchUpdatePaqueteCartaTerminacionById = async (paquete_id, data) => {
		try {
			const response = await updatePaqueteCartaTerminacionById(paquete_id, data, token);
		} catch (error) {
			console.error('Error al cargar los datos', error);
		}
	};

    const fetchUpdatePaqueteCartaEntregaById = async (paquete_id, data) => {
		try {
			const response = await updatePaqueteCartaEntregaById(paquete_id, data, token);
		} catch (error) {
			console.error('Error al cargar los datos', error);
		}
	};

    const fetchUpdatePaquetePolizaById = async (paquete_id, data) => {
		try {
			const response = await updatePaquetePolizaById(paquete_id, data, token);
		} catch (error) {
			console.error('Error al cargar los datos', error);
		}
	};

	const fetchUploadFile = async(form, newFileName) => {
		try {
			const response = await uploadFiles(form, token);
			if (response.message === 'success') {
				successNotification("Archivo cargado correctamente.")
				setFile(null);
				let data = {
					filepath : newFileName,
					paquete_id: sharedPaqueteId
				}
				fetchUpdatePaqueteDocumentoGarantiaById(sharedPaqueteId, data);
			} else {
				errorNotification("No es posible subir el archivo.");
			}
		} catch (error) {
			errorNotification("Ocurrio un error en el servidor.");
		}

	}

    const changeEstatusPaquete = () => {
        if (nuevoEstatus >= 5){
            setDisabledCheckBox(true);
            setShowButtonLiberar(false);
        } else{
            setDisabledCheckBox(false);
            setShowButtonLiberar(true);
        }
    }

	const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];

		if (selectedFile) {
			const fileName = selectedFile.name.toLowerCase();
			if (fileName.endsWith('.pdf')) {
				setFile(selectedFile);
			} else {
				errorNotification('Formato de archivo no válido. Por favor, selecciona un archivo PDF.');
			}
		}
    };
    
    const handleUpload = async () => {
        if (file) {
			const timestamp = Date.now();
			const form = new FormData();
			const newFileName = `Admin(${sharedPaqueteId})-Garantia-${timestamp}-${file.name}`;
            form.append('uploaded_file', file, newFileName);
            fetchUploadFile(form, newFileName);
			
        } else {
            errorNotification("Es necesario seleccionar un archivo.");
        }
    };

    const handleSendFile = () => {
        let data = {
            proyecto_id : sharedProyectoId,
            etapa_id : sharedEtapaId,
            paquete_id : sharedPaqueteId
        }
		fetchCreatePaqueteFondoGarantiaArchivos(data);
	}

    const handleLiberar = () => {
        Swal.fire({
			text: "¿Deseas liberar el paquete?",
            text: "Debes validar los datos...",
			icon: "question",
			confirmButtonText: 'Continuar',
			cancelButtonText: 'Cancelar',
			showCancelButton: true,
			showCloseButton: true,
			allowOutsideClick: false,
			allowEscapeKey: false,
			allowEnterKey: false,
			reverseButtons : true
		}).then((result) => {
			if (result.isConfirmed) {
                let data = {
                    proyecto_id : sharedProyectoId,
                    paquete_id : sharedPaqueteId,
                    fondo_garantia : fondoGarantia,
                    fecha_garantia : ""
                }
                fetchCreatePaqueteFondoGarantiaEnviar(data);
			}
		}).catch(() => {
			return false;
		});
    }

    const alertAddDate = () => {
        Swal.fire({
			title: "¿Deseas agregar la fecha manualmente?",
            text: "Si presiona 'No', se tomará la fecha actual del sistema. En caso contrario, se visualizará un calendario en el cual podrás ingresar manualmente la fecha.",
            icon: "warning",
			confirmButtonText: 'Si',
			cancelButtonText: 'No',
			showCancelButton: true,
			showCloseButton: true,
			allowOutsideClick: false,
			allowEscapeKey: false,
			allowEnterKey: false,
			reverseButtons : true
		}).then((result) => {
			if (result.isConfirmed) {
                console.log("agrgar fecha manualmente");
			} else {
                let data = {
                    proyecto_id : sharedProyectoId,
                    paquete_id : sharedPaqueteId,
                    fondo_garantia : fondoGarantia,
                    fecha_garantia : ""
                }
                fetchCreatePaqueteFondoGarantiaEnviar(data);
            }
		}).catch(() => {
			return false;
		});
    }

    const handleCheckBoxTerminacion = (event) => {
        setCheckCartaTerminacion(event.target.checked);
        let data = {
            vali_carta_terminacion : event.target.checked ? 1 : 0
        }
        fetchUpdatePaqueteCartaTerminacionById(sharedPaqueteId, data);
    }
    
    const handleCheckBoxCartaEntrega = (event) => {
        setCheckCartaEntrega(event.target.checked);
        let data = {
            vali_carta_entrega : event.target.checked ? 1 : 0
        }
        fetchUpdatePaqueteCartaEntregaById(sharedPaqueteId, data);
    }

    const handleCheckBoxPoliza = (event) => {
        setCheckPolizas(event.target.checked);
        let data = {
            vali_poliza : event.target.checked ? 1 : 0
        }
        fetchUpdatePaquetePolizaById(sharedPaqueteId, data);
    }

    return(
        <div className="row justify-content-center">
            <hr className="mb-4" />
            <div className="col-xl-10">
                <div className="col-xl-12">
                    <div className='row'>
                        <div className='col-4'>
                            <SelectProyectos setSharedProyectoId={setSharedProyectoId} setSharedEtapaId={setSharedEtapaId}  />
                        </div>
                        <div className='col-4'>
                            <SelectEtapas  proyecto_id={sharedProyectoId} setSharedEtapaId={setSharedEtapaId} setSharedPaqueteId={setSharedPaqueteId} />
                        </div>
                        <div className='col-4'>
                            <SelectPaquetes etapa_id={sharedEtapaId} setSharedPaqueteId={setSharedPaqueteId}  />
                        </div>
                    </div>	
                    {
                        showUplaodFile ? (
                            <>
                                <div className='row justify-content-center'>
                                    <div className='col-4' >
                                        <div className="form-group mb-3" style={{textAlign: "center"}}>
                                            <label className="form-label" htmlFor="etapas" ><strong>Adjuntar Documento</strong></label>
                                            <input type="file" className="form-control" id="submitFile"  onChange={handleFileChange} />
                                        </div>
                                    </div>
                                    <div className='col-2' >
                                        <div className="form-group mb-3" >
                                            <button className="btn btn-outline-default"  style={{marginTop:"2em"}} onClick={handleUpload} >
                                                <i className="fas fa-cloud-upload-alt" style={{ marginRight: "0.5em" }}></i>Cargar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12" id="archivo">
                                    <div style={{"fontStyle" : "italic", "textAlign" : "center"}}>
                                        {
                                            documento !== "Sin Documento" ? (
                                                <a href={serverUrl + "/file/download/" + documento}   id="archivoPDF" name="archivoPDF" target="_blank">{ documento }</a> 
                                            ) : (
                                                <span>{ documento }</span>
                                            )
                                            
                                        }
                                    </div>
                                    <hr/>
                                </div>
                            </>
                        ) : null
                    }
                    <br/>
                    {
                        showUplaodFile ? (
                            <div id="datatable" className="mb-4">
                                <Card> 
                                    <CardHeader>
                                        <span className="flex-grow-1">Validación de entrega de documentos</span>
                                    </CardHeader>
                                    <CardBody>
                                        <div className='row'>
                                            <div style={{"textAlign": "center"}}>
                                                <button type="submit" className="btn btn-outline-default" id="sendReport" name="sendReport"  onClick={() => handleSendFile()} disabled={disabledSendFile}> Enviar Archivo</button>
                                            </div> 
                                        </div>
                                        <br />
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="checkbox">
                                                    <label><input type="checkbox" id="cartaterminacionobra" name="cartaterminacionobra" checked={checkCartaTerminacion}  onChange={handleCheckBoxTerminacion} disabled={disabledCheckBox} /> Validación de Carta de Terminación de Obra</label>
                                                </div>
                                                <div className="checkbox">
                                                    <label><input type="checkbox" id="cartaentregarecepcion" name="cartaentregarecepcion" checked={checkCartaEntrega} onChange={handleCheckBoxCartaEntrega} disabled={disabledCheckBox} /> Validación de Carta Entrega de Recepción</label>
                                                </div>
                                                <div className="checkbox disabled">
                                                    <label><input type="checkbox" id="polizasgarantia" name="polizasgarantia" checked={checkPolizas}  onChange={handleCheckBoxPoliza}  disabled={disabledCheckBox} /> Validación de Pólizas de Garantía</label>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                            <label>Cálculo Final de Fondo de Garantía:</label>
                                                <div className="form-group">
                                                    <div className="form-line">
                                                        <input type="text" className="form-control" name="fondofinalgarantia" id="fondofinalgarantia" disabled value={formatoMoneda.format(fondoGarantia)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                                                <div className="form-group">
                                                    <div className="form-line">
                                                        { showButtonLiberar ?  <button className="btn btn-outline-success" disabled={disabledButtonLiberar} onClick={handleLiberar}>Liberar</button> :  <button className="btn btn-outline-warning" disabled>Fondo Liberado</button> }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        ) : null
                    }
                </div>
            </div>
        </div>
    )
}


export default GarantiaTecnico;
