import React,  { useEffect, useState, useRef }from 'react';
import { useSelector } from 'react-redux';

import { Card, CardBody, CardHeader }  from '../../../../../components/card/card';
import Swal from 'sweetalert2';
import { Modal } from 'bootstrap';

import { formatoMoneda, successNotification, errorNotification, alert, serverUrl, warningNotification } from '../../../../../helpers';

import { 
    getDerechohabientesTotalFirmadosByPaqueteId,
    getDerechohabienteValorPaqueteByPaqueteId,
    createDerechohabientesFile,
    deleteDerechohabientesById
} from '../../../../../api/derechohabiente/derechohabiente';
import { 
    getPaqueteDocumentoContratoById,
    updatePaqueteDocumentoContratoById,
    updatePaqueteCerrarJuridicoById,
    updatePaqueteEstatusById
} from '../../../../../api/paquete_proyectos/paquete_proyectos';
import { uploadFiles, deleteFiles } from '../../../../../api/otros/otros';

const $ = require('jquery');

function TableJuridico({ paquete_id, paquete_nombre, estatus_paquete, 
        setSharedDerechohabienteId, derechohabientes, onSuccessDerechohabiente, onOpenModal }) {
    const token = useSelector(state => state.authentication.user?.token);

    let tableDerechohabientesRef = useRef(null);

    const [file, setFile] = useState(null);
    const [nuevoEstatus, setNuevoEstatus] = useState([]);
    const [showEstatusPaquete, setShowEstatusPaquete] = useState(false);
    const [disabledSubir, setDisabledSubir] = useState(true);
    const [documento, setDocumento] =  useState("");
    const [titleTable, setTitleTable] = useState("");
    const [titleEstatus, setTitleEstatus] = useState("");
    const [montosDeposito, setMontosDeposito] = useState({
        infonavit_abierto: "$0.00",
        conavi_abierto: "$0.00",
        total_abierto: "$0.00",
        infonavit_cerrado: "$0.00",
        conavi_cerrado: "$0.00",
        total_cerrado: "$0.00"
    });

    useEffect(() => {
        if(paquete_id !== null && token !== undefined) {
            setTitleTable("");
            setFile(null);
            setDocumento("")
            setNuevoEstatus(estatus_paquete);
            fetchPaqueteDocumentoContratoById(paquete_id);
        }
    }, [paquete_id, token]);


    useEffect(() => {
    	if (tableDerechohabientesRef !== null && tableDerechohabientesRef.current !== null) {
			let index = 1;
			tableDerechohabientesRef.current.clear();
			tableDerechohabientesRef.current.rows.add(
                derechohabientes.map(dech => [index++, //0 
                                                dech.dh.id, //1
                                                dech.dh.nombres + " " + dech.dh.apellido_paterno + " " + dech.dh.apellido_materno, //2
                                                dech.dh.curp, //3
                                                dech.dh.num_credito, //4
                                                dech.dh.num_ssocial, //5
                                                dech.direccion.calle, //6
                                                dech.direccion.manzana, //7
                                                dech.direccion.lote, //8
                                                dech.direccion.num_interior, //9
                                                dech.direccion.num_exterior, //10
                                                dech.dh.monto_ministracion, //11
                                                dech.dh.monto_subsidio, //12
                                                dech.dh.numero_instrumento, //13
                                                dech.dh.volumen, //14
                                                dech.dh.firma_folio, //15
                                                dech.dh.estatus_firma_folio === "1" ? "Firmado" : (dech.dh.estatus_firma_folio === "2" || dech.dh.estatus_firma_folio === "3" ? 'No Firmado' : 'No Firmado')])); //16
            
            tableDerechohabientesRef.current.draw();
            totalDepositos();
		} else 
			initDatatableDerechohabientes();		
		// eslint-disable-next-line
	}, [derechohabientes]);


    useEffect(() => {
        setTitle();
        showColumnActions();
        totalDepositos();
    }, [nuevoEstatus]);


    const fetchPaqueteDocumentoContratoById = async () => {
        try {
            const response = await getPaqueteDocumentoContratoById(paquete_id, token);
            if(response.message === 'no found'  || response.message === '') {
				setDocumento("Sin Documento");
			} else {
				setDocumento(response.message);
			}
        } catch (error) {
            console.log("Ocurrio un error en el servidor.", error);
        }
    }

    const fetchDerechohabientesTotalFirmadosByPaqueteId= async () => {
        try {
            const response = await getDerechohabientesTotalFirmadosByPaqueteId(paquete_id, token);
            if(response.message === 0) {
                alert("No se puede cerrar el paquete", "No existen ningún acreditado FIRMADO", "warning");
            } else {
                fetchDerechohabienteValorPaqueteByPaqueteId();
            }
        } catch (error) {
            console.log("Ocurrio un error en el servidor.", error);
        }
    }

    const fetchDerechohabienteValorPaqueteByPaqueteId = async () => {
        try {
            const response = await getDerechohabienteValorPaqueteByPaqueteId(paquete_id, token);
            actionCerrarPaquete(response.message, 1);
        } catch (error) {
            console.log("Ocurrio un error en el servidor.", error);
        }
    }

    const fetchCreateDerechohabientesFile = async (data) => {
        try {
            const response = await createDerechohabientesFile(data, token);
            if(response.error != "" && response.message == 'failed') {
                alert("Ocurrio un error en el servidor.", response.error, "warning");
            } else if(response.error == "" && response.message == 'failed') {
                alert("¡Algo Salio Mal?", "No es posible cargar los derechohabientes. Por favor revise el archivo.", "error");
            } else if(response.message === 'success') {  
                if(response.error == "Existen numeros de credito en otro paquete") {
                    warningNotification("Carga de Derechohabientes Exitosa. Con Derechohabientes que no se crearon.");
                } else {    
                    successNotification("Carga de Derechohabientes Exitosa.");   
                }
                onSuccessDerechohabiente(paquete_id);
            } 
            fetchDeleteFiles(data.filepath);
        } catch (error) {
            console.log("Ocurrio un error en el servidor.", error);
        }
    }


    const fetchUploadFiles = async (form, newFileName, type) => {
        try {
            const response = await uploadFiles(form, token);
            if (response.message === 'success') {
                successNotification("Archivo cargado correctamente.")
                if(type === 1) {
                    let data = {
                        filepath : response.path,
                        paquete_id: paquete_id
                    }
                    fetchCreateDerechohabientesFile(data);
                } else {
                    let data = {
                        filepath : newFileName,
                        paquete_id: paquete_id
                    }
                    fetchUpdatePaqueteDocumentoContratoById(data);
                }
                
            } else {
                errorNotification("No es posible subir el archivo.");
            }
        } catch (error) {
            console.log("Ocurrio un error en el servidor.", error);
        }
    }


    const fetchUpdatePaqueteDocumentoContratoById = async (data) => {
        try {
            const response = await updatePaqueteDocumentoContratoById(data.paquete_id, data, token);
            if(response.message === "success") {
                fetchPaqueteDocumentoContratoById(data.paquete_id);
            } 
        } catch (error) {
            console.log("Ocurrio un error en el servidor.", error);
        }
    }


    const fetchUpdatePaqueteCerrarById = async (data) => {
        try {
            const response = await updatePaqueteCerrarJuridicoById(paquete_id, data, token);
            if(response.message === "success") {
                successNotification("Paquete cerrado correctamente.");
                setNuevoEstatus(data.estatus_paquete);
            } else if(response.message === "failed") {
                errorNotification("No fue posible cerrar el paquete.");
            }
        } catch (error) {
            console.log("Ocurrio un error en el servidor.", error);
        }
    }


    const fetchUpdatePaqueteAbrirById = async (estatus) => {
        try {
            const response = await updatePaqueteEstatusById(paquete_id, estatus, token);
            if(response.message === "success") {
                successNotification("Abriste el paquete correctamente.....")
                setNuevoEstatus(estatus);
                onSuccessDerechohabiente(paquete_id);
            } else if(response.message === "failed") {
                errorNotification("No fue posible abrir el paquete.");
            }
        } catch (error) {
            console.log("Ocurrio un error en el servidor.", error);
        }
    }


    const fetchDeleteDerechohabienteById = async (id) => {
        try {
            const response = await deleteDerechohabientesById(id, token);
            if(response.message === "success") {
                successNotification("Derechohabiente eliminado correctamente.");
                onSuccessDerechohabiente(paquete_id);
            } else if(response.message === "failed") {
                errorNotification("No fue posible eliminar el derechohabiente.");
            }
        } catch (error) {
            console.log("Ocurrio un error en el servidor.", error);
        }
    }


    const fetchDeleteFiles = async(filepath) => {
        try {
            let data = { filepath };
            const response = await deleteFiles(data, token);
        } catch(error) {
            console.log("Ocurrio un error en el servidor.", error);
        }
    }


    const createdCell = (cell) => {
        let original;
        const table = tableDerechohabientesRef.current;
        cell.setAttribute('contenteditable', true)
        cell.setAttribute('spellcheck', false)
        cell.addEventListener("focus", function(e) {
            original = e.target.textContent
        })
        cell.addEventListener("blur", function(e) {
            if (original !== e.target.textContent) {
                const row = table.row(e.target.parentElement);
                row.data()[row.index()] = e.target.textContent;
                row.invalidate();
                console.log('Row changed: ', row.data());
            }
        })
    }


    const initDatatableDerechohabientes = () => {
        tableDerechohabientesRef.current = $('#tableDerechohabientesJuridico').DataTable({
            dom: "<'row mb-3'<'col-md-4 mb-3 mb-md-0'l><'col-md-8 text-end'<'d-lg-flex justify-content-end'f<'d-lg-block d-none'B>>>>t<'row align-items-center'<'mr-auto col-md-6 mb-3 mb-md-0 mt-n2 'i><'mb-0 col-md-6'p>>",
			lengthMenu: [10, 20, 30, 40, 50],
			buttons: [
				{ 
					extend: 'excelHtml5', 
					className: 'btn btn-outline-default btn-sm',
					filename: function() {
						var timestamp = new Date().getTime(); 
						return 'derechohabientes_' + timestamp; 
					},
					exportOptions: {
						columns: ':visible',
						modifier: {
							page: 'none' // Evita agregar información de paginación
						}
					},
					customizeData: function ( data ) {
						for (var i=0; i < data.body.length; i++){
							for (var j=0; j<data.body[i].length; j++ ){
								data.body[i][j] = '\u200C' + data.body[i][j];
							}
						}
					}
				}
			],
            columns: [
                { title: "#" },//0
                { title: "ID" }, //1 
                { title: "Derechohabiente" }, //2 
                { title: "CURP" }, // 3
                { title: "No. Crédito" }, //4
                { title: "NSS" }, //5
                { title: "Dirección" }, // 6
                { title: "Mza." }, // 7
                { title: "Lote" }, // 8
                { title: "No. Ext." }, //9
                { title: "No. Int." }, // 10
                { 
                    title: "Dep. Infonavit",
                    width: "150px",
                    render: function (data, type, row, meta) {
                        let number = row[11]?.replace(/,/g, '') || 0;
                        return `<div style="white-space: nowrap;">${formatoMoneda.format(parseFloat(number))}</div>`;
                    },
                }, //11
                { 
                    title: "Dep. CONAVI",
                    width: "150px",
                    render: function (data, type, row, meta) {
                        let number = row[12]?.replace(/,/g, '') || 0;
                        return `<div style="white-space: nowrap;">${formatoMoneda.format(parseFloat(number))}</div>`;
                    },
                }, //12
                { title: "No. Instrumento" }, //13
                { title: "Vol." }, //14
                { title: "Folio" }, //15
                { 
                    title: "Firma",  //16
                    width: "250px",
                    render: function (data, type, row, meta) {
                        return `<div style="white-space: nowrap;">${row[16]}</div>`;
                    },
                },{
                    title: "",
                    data: null,
                    width: "200px",
                    render: function (data, type, row, meta) {
                        const derechohabiente_id = row[1];
                        const derechohabiente_name = row[2];
                        const ministracion = row[11];
                        const subsidio = row[12];
                        const estatus_firma = row[16];
                        
                        return `<div style="text-align: right; white-space: nowrap;">
                                    <a  style="cursor:pointer" id="editarDerechohabiente" data-derechohabiente-id="${derechohabiente_id}"  data-derechohabiente-name="${derechohabiente_name}">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#F1C40F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
                                    </a>
                                    <a style="cursor:pointer" id="eliminarDerechohabiente" data-derechohabiente-id="${derechohabiente_id}"  data-derechohabiente-name="${derechohabiente_name}">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#EE0C05" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
                                    </a>  
                                </div>`;
                        
                    },
                    escape: false,
                }
            ],
            columnDefs: [
                { targets: [1], visible: false }
                //{ targets: "_all", createdCell: createdCell }
            ],
        });  
        
        $('#tableDerechohabientesJuridico').on('click', '#editarDerechohabiente', function () {
            const id = $(this).data('derechohabiente-id');
            const nombre = $(this).data('derechohabiente-name');
            actionEditarDerechohabiente(id, nombre);
        });
    
        $('#tableDerechohabientesJuridico').on('click', '#eliminarDerechohabiente', function () {
            const id = $(this).data('derechohabiente-id');
            const nombre = $(this).data('derechohabiente-name');
            actionEliminarDerechohabiente(id, nombre);
        });
	}


    const actionEditarDerechohabiente = (id, nombre) => {
        setSharedDerechohabienteId(id)
        onOpenModal('Derechohabiente');
    }


    const actionEliminarDerechohabiente = (id, nombre) => {
        Swal.fire({
			title: "¿Deseas eliminar el derechohabiente?",
            icon: "question",
            text: nombre,
			confirmButtonText: 'Continuar',
			cancelButtonText: 'Cancelar',
			showCancelButton: true,
			showCloseButton: true,
			allowOutsideClick: false,
			allowEscapeKey: false,
			allowEnterKey: false,
			reverseButtons : true
		}).then((result) => {
			if (result.isConfirmed) {
               confirmEliminarDerechohabiente(id);
            }
		}).catch(() => {
			return false;
		});
    }


    const confirmEliminarDerechohabiente = (id) => {
		Swal.fire({
			title: "¿Estas seguro?",
			text: "Una vez eliminado ya no podrás acceder a este Derechohabiente",
			icon: "question",
			confirmButtonText: 'Eliminar',
			cancelButtonText: 'Cancelar',
			showCancelButton: true,
			showCloseButton: true,
			allowOutsideClick: false,
			allowEscapeKey: false,
			allowEnterKey: false,
			reverseButtons : true
		}).then((result) => {
			if (result.isConfirmed) {
				fetchDeleteDerechohabienteById(id);
			}
		}).catch(() => {
			return false;
		})
	}


    const setTitle = () => {
        if(nuevoEstatus === 0) {
            setShowEstatusPaquete(true);
            setDisabledSubir(false);
            setTitleEstatus("Cerrar");
            setTitleTable("( " + paquete_nombre + " - Paquete Abierto )");
        } else if(nuevoEstatus === 1 || nuevoEstatus === 2) {
            setShowEstatusPaquete(true);
            setDisabledSubir(true);
            setTitleEstatus("Abrir");
            setTitleTable("( " + paquete_nombre + " - Paquete Cerrado )");
        } else if(nuevoEstatus === 3) { 
            setShowEstatusPaquete(false);
            setDisabledSubir(true);
            setTitleTable("( " +paquete_nombre + " - Paquete Cerrado por TECNICO )");
        }  else {
            setShowEstatusPaquete(false);
            setDisabledSubir(true);
            setTitleTable("( " +paquete_nombre + " - Paquete Cerrado por ANTICIPO )");
        }
    }


    const showColumnActions = () => {
        const table = tableDerechohabientesRef.current;
        const columnIndex = 17;
        if (nuevoEstatus === 0) {
            table.column(columnIndex).visible(true);
        } else {
            table.column(columnIndex).visible(false);
        } 
    }


    const totalDepositos = () => {
        if(derechohabientes.length > 0 ) {

            let array = derechohabientes;

            let infonavit_abierto = 0.0;
            let conavi_abierto = 0.0;
            let total_abierto = 0.0;

            let infonavit_cerrado = 0.0;
            let conavi_cerrado = 0.0;
            let total_cerrado = 0.0;

            if(nuevoEstatus == 0) {
                infonavit_abierto = array.reduce((total, item) => total + (parseFloat(item.dh.monto_ministracion) || 0.0), 0);
                conavi_abierto = array.reduce((total, item) => total + (parseFloat(item.dh.monto_subsidio) || 0.0), 0);
                total_abierto = infonavit_abierto + conavi_abierto
            } else { 
                let suma1 = 0;
                array.forEach(item => {
                    if (item.dh && item.dh.estatus_firma_folio === "1") {
                        suma1 += parseFloat(item.dh.monto_ministracion) || 0.0;
                    }
                });

                infonavit_cerrado = suma1 || 0;
                
                let suma2 = 0;
                array.forEach(item => {
                    if (item.dh && item.dh.estatus_firma_folio === "1") {
                        suma2 += parseFloat(item.dh.monto_subsidio) || 0.0;
                    }
                });

                conavi_cerrado = suma2 || 0;
                total_cerrado = infonavit_cerrado + conavi_cerrado;
            }
            setMontosDeposito({
                infonavit_abierto : formatoMoneda.format(infonavit_abierto),
                conavi_abierto: formatoMoneda.format(conavi_abierto),
                total_abierto: formatoMoneda.format(total_abierto),
                infonavit_cerrado: formatoMoneda.format(infonavit_cerrado),
                conavi_cerrado: formatoMoneda.format(conavi_cerrado),
                total_cerrado: formatoMoneda.format(total_cerrado)
            });        
        } else {
            setMontosDeposito({
                infonavit_abierto: "$0.00",
                conavi_abierto: "$0.00",
                total_abierto: "$0.00",
                infonavit_cerrado: "$0.00",
                conavi_cerrado: "$0.00",
                total_cerrado: "$0.00"
            });
        }
    }


    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            const fileName = selectedFile.name.toLowerCase();
			if (fileName.endsWith('.pdf') || fileName.endsWith('.xls') || fileName.endsWith('.xlsx')  ) {
                setFile(selectedFile);
			} else {
				errorNotification('Formato de archivo no válido.');
			}
        }
    };


    const handleUpload = async (type) => {
        if (file) {
            const timestamp = Date.now();
			const form = new FormData();
			const newFileName = `Contrato(${paquete_id})-${timestamp}-${file.name}`;
            form.append('uploaded_file', file, newFileName);
            fetchUploadFiles(form, newFileName, type);
            
        } else {
            errorNotification("Es necesario seleccionar un archivo.");
        }
    };


    const handleUpdateEstatus = () => {
        Swal.fire({
			text: "¿Deseas " + titleEstatus.toLowerCase() + " el Paquete: " + paquete_nombre + "?",
			icon: "question",
			confirmButtonText: 'Continuar',
			cancelButtonText: 'Cancelar',
			showCancelButton: true,
			showCloseButton: true,
			allowOutsideClick: false,
			allowEscapeKey: false,
			allowEnterKey: false,
			reverseButtons : true
		}).then((result) => {
			if (result.isConfirmed) {
                if(titleEstatus === "Abrir") {
                    fetchUpdatePaqueteAbrirById(0)
                } else {
                    if(derechohabientes.length == 0) {
                        alert("No puede cerrar el paquete", "No existen registros en el paquete", "error");
                    } else {
                        fetchDerechohabientesTotalFirmadosByPaqueteId();
                    }
                }
			}
		}).catch(() => {
			return false;
		});
    }


    const actionCerrarPaquete = (valor_paquete_firmados, estatus_paquete) => {
        Swal.fire({
			title: "¿Deseas cerrar el paquete?",
            text: "Debes validar los datos...",
            icon: "warning",
			confirmButtonText: 'Continuar',
			cancelButtonText: 'Cancelar',
			showCancelButton: true,
			showCloseButton: true,
			allowOutsideClick: false,
			allowEscapeKey: false,
			allowEnterKey: false,
			reverseButtons : true
		}).then((result) => {
			if (result.isConfirmed) {
               let data = {
                    valor_paquete_firmados,
                    estatus_paquete
               }
               fetchUpdatePaqueteCerrarById(data);
            }
		}).catch(() => {
			return false;
		});
    }


    return (
        <div className="col-xl-12">
            <div className="row justify-content-center mb-4">
                <div className="col-md-6">
                    <div className='row'>
                        <div className="col-xl-6">
                            <label className="form-label" htmlFor="etapas" ><strong>Adjuntar Contrato de Obras</strong></label>
                            <input type="file" className="form-control" id="submitFile" onChange={handleFileChange} />
                        </div>
                        <div className="col-xl-2">
                            <button className="btn btn-outline-default" style={{marginTop: "2em"}} onClick={() => handleUpload(2)}>Cargar</button>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 text-end">
                    {
                        documento !== "Sin Documento" ? (
                            <a href={serverUrl + "/file/download/" + documento} style={{marginTop: "1.5em", marginRight: "1.0em"}} className="btn btn-outline-default" id="archivoPDF" name="archivoPDF" target="_blank">Ver Contrato</a> 
                        ) : (
                            <button  style={{marginTop: "1.5em", marginRight: "1.0em"}} className="btn btn-outline-default disable" id="archivoPDF" name="archivoPDF" disabled>Sin Contrato</button> 
                        )
                    }
                    { showEstatusPaquete && (
                        <button  className="btn btn-outline-default" style={{marginTop: "1.5em"}} onClick={() => handleUpdateEstatus()} >{titleEstatus} Paquete</button>
                    )}
                </div>
            </div>
            <div id="datatable" className="mb-4">
                <Card> 
                    <CardHeader>
                        <span className="flex-grow-1">Lista de Derechohabientes. <strong>{titleTable}</strong></span>
                    </CardHeader>
                    <CardBody>
                        <>
                            <div className="row row-space-12">	
                                <div className="col-6">
                                    <div className="row justify-content-center">
                                        <div className="col-xl-8">
                                            <table  className=" display  compact no-wrap table-bordered table-striped totales-wrapper " >
                                                <thead> 
                                                    <tr className="totales-header">
                                                        <th>ESTATUS </th>
                                                        <th>INFONAVIT </th>
                                                        <th>CONAVI</th>
                                                        <th>TOTAL</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr className="totales-row">
                                                        <th>Paquete Abierto</th>
                                                        <th className="totales-cell">{montosDeposito.infonavit_abierto}</th>
                                                        <th className="totales-cell">{montosDeposito.conavi_abierto}</th>
                                                        <th className="totales-cell">{montosDeposito.total_abierto}</th>
                                                    </tr>
                                                    <tr className="totales-row">
                                                        <th>Paquete Cerrado</th>
                                                        <th className="totales-cell">{montosDeposito.infonavit_cerrado}</th>
                                                        <th className="totales-cell">{montosDeposito.conavi_cerrado}</th>
                                                        <th className="totales-cell">{montosDeposito.total_cerrado}</th>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                { showEstatusPaquete && (
                                    <div className='col-6'>
                                        <div className="row justify-content-center">
                                            <div className="col-xl-6">
                                                <label className="form-label" htmlFor="etapas" ><strong>Subir Derechohabientes</strong></label>
                                                <input type="file" className="form-control" id="submitFile" onChange={handleFileChange} disabled={disabledSubir} />
                                            </div>
                                            <div className="col-xl-2">
                                                <button className="btn btn-outline-default" style={{marginTop: "2em"}} onClick={() => handleUpload(1)} disabled={disabledSubir}>Cargar</button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <hr className="mb-4" />
                            <div className='row tabla-scrollable table-responsive'>
                                <table id="tableDerechohabientesJuridico" className="table text-nowrap  w-100 table-striped table-derechohabientes">
                                    <thead></thead>
                                    <tbody></tbody>
                                </table>
                            </div>
                        </>
                    </CardBody>
                </Card>
            </div>
        </div>
    )

}


export default TableJuridico;