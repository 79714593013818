import { daeApi } from "../daeApi";

export const createProyecto = async (data, token) => {
  const response = await daeApi.post(`/proyectos`, data, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getProyectos = async (token) => {
  const response = await daeApi.get(`/proyectos`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getProyectoById = async (id, token) => {
  const response = await daeApi.get(`/proyectos/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getProyectoCalculosById = async (id, token) => {
  const response = await daeApi.get(`/proyectos/calculos/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getProyectoAnticiposById = async (id, token) => {
  const response = await daeApi.get(`/proyectos/anticipos/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getProyectoInversionById = async (id, token) => {
  const response = await daeApi.get(`/proyectos/inversion/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getProyectosPromotores = async (token) => {
  const response = await daeApi.get(`/proyectos/promotores`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getProyectoBancoById = async (id, token) => {
  const response = await daeApi.get(`/proyectos/banco/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getProyectosPromotoresById = async (id, token) => {
  const response = await daeApi.get(`/proyectos/promotores/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getProyectoResumenReporte = async (token) => {
  const response = await daeApi.get(`/proyectos/resumen/reporte`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const updateProyectoById = async (id, data, token) => {
  const response = await daeApi.put(`/proyectos/${id}`, data, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const deleteProyecto = async (id, token) => {
  const response = await daeApi.delete(`/proyectos`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const deleteProyectoById = async (id, token) => {
  const response = await daeApi.delete(`/proyectos/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}
