import nivelAtencionJSON from './catalogos/nivel_atencion.json';
import mediosRecepcionJSON from './catalogos/medios_recepcion.json';
import productosJSON from './catalogos/product_list.json';
import causasJSON from './catalogos/causas_list.json';
import mesesJSON from './catalogos/meses.json';

export const nivelAtencion = nivelAtencionJSON;
export const mediosRecepcion = mediosRecepcionJSON;
export const productos = productosJSON;
export const causas = causasJSON;
export const meses = mesesJSON;