import React,  { useEffect, useState }from 'react';
import { useSelector } from 'react-redux';

import DateTime from 'react-datetime';

import { getPaqueteById, updatePaqueteById } from '../../../../api/paquete_proyectos/paquete_proyectos';
import { getEstatusConavi } from '../../../../api/estatus_conavi/estatus_conavi';
import { formatoMoneda, successNotification, errorNotification, deleteKeyNull } from '../../../../helpers';

import 'react-datetime/css/react-datetime.css';

function ModalEditarPaquete({ sharedPaqueteId, onCloseModal }) {
    const token = useSelector(state => state.authentication.user?.token);

    const [formPaquete, setFormPaquete] = useState({});
    const [estatusConavi, setEstatusConavi] = useState([]);
    
    useEffect(() => {
        if(sharedPaqueteId !== null && token !== undefined)
            if(sharedPaqueteId > 0) {
                fetchGetPaqueteById();
            }
    }, [sharedPaqueteId, token]);


    const fetchGetPaqueteById = async() => {
        try {
            const response = await getPaqueteById(sharedPaqueteId, token);
            setFormPaquete(response);
            fetchGetEstatusConavi();
        } catch(e) {
            console.log(e);
        }
    }


    const fetchGetEstatusConavi = async() => {
        try {
            const response = await getEstatusConavi(token);
            setEstatusConavi(response);
        } catch(e) {
            console.log(e);
        }
    }


    const fetchUpdatePaqueteById = async () => {
        try {
            const response = await updatePaqueteById(sharedPaqueteId, deleteKeyNull(formPaquete), token);
            
            if(response.message === "success") 
                successNotification("Paquete actualizado correctamente.");
            else if(response.message === "failed") 
                errorNotification("No fue posible actualizar el paquete.");

            handleOnCloseModal();

        } catch (e) {
            console.log(e);
            if(e.response.status === 401)
                errorNotification("Token invalido o caducado.");
            else if(e.response.status === 403)
                errorNotification("No tienes permisos para realizar esta acción.");

            handleOnCloseModal();
        }
    }


    const handleChangeFormPaquete = (event) => {
        try {
            const { name, value } = event.target;
            if (name === "val_supervision_visita") {
                const unformattedValue = value.replace(/\$|,/g, "");
                const parsedValue = parseFloat(unformattedValue);
                setFormPaquete((prevForm) => ({
                    ...prevForm,
                    [name]: parsedValue || "",
                }));
            } else {
                setFormPaquete((prevForm) => ({
                    ...prevForm,
                    [name]: value,
                }));
            }   
        } catch(e) {
            const fechaMoment = event; 
            const fechaString = fechaMoment.format('YYYY-MM-DD');
            setFormPaquete((prevForm) => ({
                ...prevForm,
                fec_vigencia_licencia: fechaString
            }));
        }
    }


    const handleActionPaquete = async() =>{
        fetchUpdatePaqueteById();
    }


    const handleOnCloseModal = () => {
        document.getElementById('modalEditarPaquete').classList.remove('show');
		document.querySelector('.modal-backdrop').remove();
        document.getElementById('modalEditarPaquete').style.display = 'none';

        setFormPaquete({});
        setEstatusConavi([]);
        
        onCloseModal();
    }


    return(
        <div id="modalEditarPaquete" className="modal modal-cover fade" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                    <h5 className="modal-title">Editar Paquete</h5>
                        <button type="button" className="btn-close" onClick={handleOnCloseModal}></button>
                    </div>
                    <div className="modal-body">
                        <form id="formEditarPaquete" className="was-validated" >
                            <div className="col-xl-12">      
                                <div className="row">	
                                    <div className="col-xl-1">
                                        <div className="form-group mb-3">
                                            <label className="form-label" htmlFor="numero">#</label>
                                            <input className="form-control"  placeholder='#'
                                                id="numero"
                                                name="numero"
                                                value={formPaquete.numero}
                                                onChange={handleChangeFormPaquete}
                                                disabled={true}
                                                required
                                                pattern=".*\S+.*" />
                                            <div className="invalid-feedback" id="validationNumero">
                                                Campo requerido
                                            </div>	
                                        </div>
                                    </div>
                                    <div className="col-xl-3">
                                        <div className="form-group mb-3">
                                            <label className="form-label" htmlFor="can_meses_desarrollo">Meses de desarrollo</label>
                                            <input type='number' min="1" pattern="^[0-9]+" className="form-control"  placeholder='Meses de desarrollo'
                                                id="can_meses_desarrollo"
                                                name="can_meses_desarrollo" 
                                                value={formPaquete.can_meses_desarrollo}
                                                onChange={handleChangeFormPaquete}
                                                required/>
                                        </div>
                                    </div>
                                    <div className="col-xl-4">
                                        <div className="form-group mb-3">
                                            <label className="form-label" htmlFor="por_comision_obra">Comisión por Obra</label>
                                            <input className="form-control"  placeholder='Comisión por Obra'
                                                id="por_comision_obra"
                                                name="por_comision_obra"
                                                value={formPaquete.por_comision_obra}
                                                onChange={handleChangeFormPaquete}
                                                disabled={true} />
                                        </div>
                                    </div>
                                    <div className="col-xl-4">
                                        <div className="form-group mb-3">
                                            <label className="form-label" htmlFor="por_comision_mercantil">Comisión Mercantil</label>
                                            <input className="form-control"  placeholder='Comisión Mercantil'
                                                id="por_comision_mercantil"
                                                name="por_comision_mercantil"
                                                value={formPaquete.por_comision_mercantil}
                                                onChange={handleChangeFormPaquete}
                                                disabled={true} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">	
                                    <div className="col-xl-6">
                                        <div className="form-group mb-3">
                                            <label className="form-label" htmlFor='num_folio_licencia'>Folio Licencia</label>
                                            <input className="form-control"  placeholder='Folio Licencia'
                                                id="num_folio_licencia"
                                                name="num_folio_licencia"
                                                value={formPaquete.num_folio_licencia}
                                                onChange={handleChangeFormPaquete}  />
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <div className="form-group mb-3">
                                            <label className="form-label"><strong>Vigencia Licencia</strong></label>
                                            <DateTime closeOnSelect={true}
                                                className="form-control"  
                                                inputProps={{
                                                    placeholder:'Vigencia Licencia',
                                                    readOnly: true 
                                                }}
                                                viewMode="days"
                                                timeFormat={false}
                                                dateFormat="YYYY-MM-DD" 
                                                id="fec_vigencia_licencia"
                                                name="fec_vigencia_licencia"
                                                value={formPaquete.fec_vigencia_licencia}
                                                onChange={handleChangeFormPaquete}
                                                />
                                            <div className="invalid-feedback" id="validationDireccion">
                                                Campo requerido
                                            </div>	
                                        </div>
                                    </div>
                                </div>
                                <div className="row">	
                                    <div className="col-xl-6">
                                        <div className="form-group mb-3">
                                            <label className="form-label" htmlFor='nom_supervisor'>Nombre Supervisor</label>
                                            <input className="form-control"  placeholder='Nombre Supervisor'
                                                id="nom_supervisor"
                                                name="nom_supervisor"
                                                value={formPaquete.nom_supervisor}
                                                onChange={handleChangeFormPaquete}
                                                pattern=".*\S+.*" />
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <div className="form-group mb-3">
                                            <label className="form-label" htmlFor="val_tierra_m2">Valor de tierra (M2)</label>
                                            <input className="form-control"  placeholder='Valor de tierra (M2)'
                                                id="val_tierra_m2"
                                                name="val_tierra_m2"
                                                value={formPaquete.val_tierra_m2 || 0.00}
                                                //parseFloat(banco.mesa_inversion.replace(/,/g, '')))])
                                                onChange={handleChangeFormPaquete}
                                                required />
                                             <div className="invalid-feedback" id="validationDireccion">
                                                Campo requerido
                                            </div>	
                                        </div>
                                    </div>
                                </div>
                                <div className="row">	
                                    <div className="col-xl-6">
                                        <div className="form-group mb-3">
                                            <label className="form-label">Estatus CONAVI</label>
                                            <select className="form-select" onChange={handleChangeFormPaquete} value={formPaquete.estatus_conavi_id} id='estatus_conavi_id' name='estatus_conavi_id' >
                                                {estatusConavi.map((estatus, key) => (
                                                    <option key={key} value={estatus.id}>
                                                        {estatus.nombre}
                                                    </option>
                                                ))}
                                            </select>
                                            <div className="invalid-feedback" id="validationDireccion">
                                                Campo requerido
                                            </div>	
                                        </div>
                                    </div>
                                    <div className="col-xl-6">
                                        <div className="form-group mb-3">
                                            <label className="form-label" htmlFor="exampleFormControlTextarea1">Costo de Supervisión por Visita</label>
                                            <input className="form-control"  placeholder='Costo de Supervisión por Visita'
                                                id="val_supervision_visita"
                                                name="val_supervision_visita" 
                                                value={formatoMoneda.format(parseFloat(formPaquete.val_supervision_visita || 0.00))}
                                                onChange={handleChangeFormPaquete} />
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-default" onClick={handleOnCloseModal}>Cerrar</button>
                        <button type="button" className="btn btn-outline-theme"  form="formEditarPaquete"  onClick={handleActionPaquete} >Editar</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalEditarPaquete;