import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import useAuthorization from '../../../hooks/useAuthorization';

import Breadcrumb from '../../../components/breadcrumb/breadcrumb'
import { Card, CardBody, CardHeader } from '../../../components/card/card.jsx';

import { getProyectoResumenReporte } from '../../../api/proyectos/proyectos.js';


import jsZip from 'jszip';
import { formatoMoneda } from '../../../helpers/utils.js';

const $ = require('jquery');

function Reportes(props) {
	const token = useSelector(state => state.authentication.user?.token);

	const [head, setHead] = useState(null);
	const [body, setBody] = useState(null);
	

	const datatableRef = useRef(null);

	useEffect(() => { 
    	fetchProyectoResumenReporte();
	}, [token]);

	useEffect(() => { 
		if(body && body != null) {
			initDatatable();
		}
	}, [body]);


	const fetchProyectoResumenReporte = async () => {
		try {
			const response = await getProyectoResumenReporte(token);
			let body = [];
			let rows = [];

			
			  
			  // Objeto para almacenar proyectos agrupados
			  const proyectosAgrupados = {};
			  
			  // Iterar sobre los objetos en data
			  for (const key in response) {
				const proyecto = response[key];
			  
				// Obtener el nombre del proyecto
				const nombreProyecto = proyecto.nombre_proyecto;
			  
				// Verificar si el proyecto ya está en el objeto agrupado
				if (!proyectosAgrupados[nombreProyecto]) {
				  proyectosAgrupados[nombreProyecto] = [];
				}
			  
				// Agregar el objeto actual al arreglo del proyecto correspondiente
				proyectosAgrupados[nombreProyecto].push(proyecto);
			  }
			  
			  
			  setHead(
				<thead>
					<tr>
						<th style={{width:"250px"}}>Empresa/Paquete</th>
						<th>Estado</th>
						<th>Municipio</th>
						<th>Viviendas autorizadas</th>
						<th>Viviendas con subsidio</th>
						<th>viviendas en paquetes</th>
						<th>Valor total paquete</th>
						<th>Valor promedio por vivienda</th>
						<th>Terreno</th>
						<th>Valor Base</th>
						<th>Valor para comisión</th>
						<th>Plazo Construcción</th>
						<th>Comisión administración</th>
						<th>Comisión mercantil</th>
						<th>Comisión por vivienda</th>
						<th>N° Supervisiones realizadas</th>
						<th>Costo de supervisión</th>
						<th>INFONAVIT</th>
						<th>CONAVI</th>
						<th>Anticipo (80% Terreno)</th>
						<th>Crédito dispuesto</th>
						<th>Deposito en garantía</th>
						<th>Comprobación</th>
						<th>Viviendas al 100%</th>
						<th>Avance de obra</th>
					</tr>
				</thead>
			  )


			  for (const nombreProyecto in proyectosAgrupados) {
				// Acceder al arreglo de objetos asociados a ese proyecto
				const proyectosEncontrados = proyectosAgrupados[nombreProyecto];
			  
				// Hacer algo con los proyectos encontrados
				rows.push(
					<>
						{
							<tr>
								<td style={{"width":"250px","backgroundColor": "#323F4F", "color": "white"}}>Proyecto: {proyectosEncontrados[0].nombre_proyecto}</td>
								<td style={{"backgroundColor": "#323F4F", "color": "white"}}>{proyectosEncontrados[0].estado}</td>
								<td style={{"backgroundColor": "#323F4F", "color": "white"}}>{proyectosEncontrados[0].municipio}</td>
								<td style={{"backgroundColor": "#323F4F", "color": "white"}}>{}</td>
								<td style={{"backgroundColor": "#323F4F", "color": "white"}}>{} </td>
								<td style={{"backgroundColor": "#323F4F", "color": "white"}}>{}</td>
								<td style={{"backgroundColor": "#323F4F", "color": "white"}}>{}</td>
								<td style={{"backgroundColor": "#323F4F", "color": "white"}}>{}</td>
								<td style={{"backgroundColor": "#323F4F", "color": "white"}}>{}</td>
								<td style={{"backgroundColor": "#323F4F", "color": "white"}}>{}</td>
								<td style={{"backgroundColor": "#323F4F", "color": "white"}}>{}</td>
								<td style={{"backgroundColor": "#323F4F", "color": "white"}}>{}</td>
								<td style={{"backgroundColor": "#323F4F", "color": "white"}}>{}</td>
								<td style={{"backgroundColor": "#323F4F", "color": "white"}}>{}</td>
								<td style={{"backgroundColor": "#323F4F", "color": "white"}}>{}</td>
								<td style={{"backgroundColor": "#323F4F", "color": "white"}}>{}</td>
								<td style={{"backgroundColor": "#323F4F", "color": "white"}}>{}</td>
								<td style={{"backgroundColor": "#323F4F", "color": "white"}}>{}</td>
								<td style={{"backgroundColor": "#323F4F", "color": "white"}}>{}</td>
								<td style={{"backgroundColor": "#323F4F", "color": "white"}}>{}</td>
								<td style={{"backgroundColor": "#323F4F", "color": "white"}}>{}</td>
								<td style={{"backgroundColor": "#323F4F", "color": "white"}}>{}</td>
								<td style={{"backgroundColor": "#323F4F", "color": "white"}}>{}</td>
								<td style={{"backgroundColor": "#323F4F", "color": "white"}}>{}</td>
								<td style={{"backgroundColor": "#323F4F", "color": "white"}}>{}</td>
							</tr>
						}
					</>
				)	

				rows.push(
					proyectosEncontrados.length > 0 ? (
						proyectosEncontrados.map((row, i) => (
							<tr>
								<td  style={{width:"250px"}}>{row.nombre_paquete}</td>
								<td></td>
								<td></td>
								<td></td>
								<td></td>
								<td>{row.viviendas_paquete.viviendas}</td>
								<td>{formatoMoneda.format(parseFloat(row.viviendas_paquete.infonavit))}</td>
								<td>{}</td>
								<td>{formatoMoneda.format(parseFloat(row.terreno))}</td>
								<td>{formatoMoneda.format(parseFloat(row.visitas.total_ministracion))}</td>
								<td>{}</td>
								<td>{row.cantidad_meses_desarrollo}</td>
								<td>{}</td>
								<td>{}</td>
								<td>{}</td>
								<td>{}</td>
								<td>{}</td>
								<td>{}</td>
								<td>{}</td>
								
								<td>{}</td>
								<td>{}</td>
								<td>{}</td>
								<td>{}</td>
								<td>{}</td>
								<td>{}</td>

							</tr>
						)) 
					) : <></>
				)	
			}

			body = (
				<tbody>
					{rows}
				</tbody>
			)

			
			setBody(body);
			console.log(body);
		} catch (error) {
			console.error('Error al cargar los proyectos', error);
		}
	};


	const initDatatable = () => {
		datatableRef.current = $('#tableResumenReporte').DataTable({
			dom: "<'row mb-3'<'col-md-4 mb-3 mb-md-0'l><'col-md-8 text-end'<'d-lg-flex justify-content-end'f<'d-lg-block d-none'B>>>>t<'row align-items-center'<'mr-auto col-md-6 mb-3 mb-md-0 mt-n2 'i><'mb-0 col-md-6'p>>",		
            paging : false,
            info:  false,
			ordering: false, 
			buttons: [
                { extend: 'pdf', className: 'btn btn-outline-default btn-sm ms-2' },
                { extend: 'csv', className: 'btn btn-outline-default btn-sm' },
                { extend: 'excel', className: 'btn btn-outline-default btn-sm' }
            ],
			fixedColumns: {
				leftColumns: 1, // Número de columnas fijas a la izquierda
			}
			// ... otras opciones y configuraciones ...
		  })
	}


	return (
		<div>
			<Breadcrumb title={props.title} subtitle={props.subtitle}/>
			<div className="row justify-content-center">
				<hr className="mb-4" />
				<div className="col-xl-10">
					<div className="row">
						<div className="col-xl-12">
							<div id="formControls" className="mb-5">  
								<Card>
									<CardHeader className="fw-bold small d-flex">
										<span className="flex-grow-1">Resumen por proyecto</span>
									</CardHeader>
									<CardBody>
										<div className='row'>
											<div className='col-12 tabla-scrollable table-responsive'>
												<table id="tableResumenReporte" className="table text-nowrap w-100 table-derechohabientes">
													{head}
													{body}
												</table>
											</div>
										</div>	
									</CardBody>
								</Card>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Reportes;