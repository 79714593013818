import axios from 'axios';

//export const daeApi = axios.create({
//    baseURL: "http://192.168.1.5:35010/api/v1/dae"
//});

//export const condusefApi = axios.create({
//    baseURL: "http://192.168.1.5:35011/api/v1/dae"
//});

// export const daeApi = axios.create({
//     baseURL: "http://sicred.dyndns.org:35010/api/v1/dae"
// });

// export const condusefApi = axios.create({
//     baseURL: "http://sicred.dyndns.org:35011/api/v1/dae"
// });

export const daeApi = axios.create({
    baseURL: "https://daeliii.com/api/v1/dae"
});

export const condusefApi = axios.create({
    baseURL: "https://daeliii.com:35011/api/v1/dae"
});

// export const daeApi = axios.create({
//         baseURL: "https://daedesarrollo/api/v1/dae/"
//     });
    
//     export const condusefApi = axios.create({
//         baseURL: "https://daedesarrollo/api/v1/condusef-dae/"
//     });
    


