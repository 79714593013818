import { daeApi } from "../daeApi";

export const getEtapa = async (token) => {
  const response = await daeApi.get(`/etapas`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const createEtapa = async (data, token) => {
  const response = await daeApi.post(`/etapas`, data, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getEtapaById = async (id, token) => {
  const response = await daeApi.get(`/etapas/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getEtapasByProyectoId = async (id, token) => {
  const response = await daeApi.get(`/etapas/proyecto/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getEtapaMontoById = async (id, token) => {
  const response = await daeApi.get(`/etapas/monto/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-access-tokens': token 
    }
  });
  return response.data;
}

export const updateEtapaById = async (id, data, token) => {
  const response = await daeApi.put(`/etapas/${id}`, data, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const deleteEtapaById = async (id, token) => {
  const response = await daeApi.delete(`/etapas/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}
