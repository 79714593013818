import React,  { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { deletePromotorById, getPromotorBancosById } from '../../../../api/promotores/promotores';
import { deleteBancoById } from '../../../../api/bancos/bancos';

import Swal from 'sweetalert2'
import { Modal } from 'bootstrap'

import jsZip from 'jszip';
import { errorNotification, successNotification } from '../../../../helpers';

const $ = require('jquery');
window.JSZip = jsZip;

function TablaPromotores({ promotores, setType, setPromotorId, onOpenModal, onSuccessPromotor }) {
	const token = useSelector(state => state.authentication.user?.token);
    
    const datatablePromotoresRef = useRef(null);

	useEffect(() => {
		if (datatablePromotoresRef !== null && datatablePromotoresRef.current !== null) {
			let index = 1; 
			datatablePromotoresRef.current.clear();
			datatablePromotoresRef.current.rows.add(
				promotores.map(promotor => [index++, 
									promotor.promotor_id,
									promotor.banco_id, 
									promotor.promotor, 
									promotor.rfc, 
									promotor.calle + " " + promotor.num_interior, 
									promotor.cuenta, 
									promotor.clabe]));
	  		datatablePromotoresRef.current.draw();
		} else 
			initDatatable()
		// eslint-disable-next-line
	}, [promotores]);

    const fetchDeletePromotorById = async (id, banco_id) => {
		try {
			const response = await deletePromotorById(id, token);
			if(response.message === "success") {
				fetchDeleteBancoById(banco_id);

			} else if(response.message === "failed") {
				errorNotification("No es posible eliminar el Promotor.")
			}
		} catch (error) {
			console.error('Error al borrar el promotor', error);
		}
	}

	const fetchDeleteBancoById = async (id) => {
		try {
			const response = await deleteBancoById(id, token);
			if(response.message === "success") {
				successNotification("Banco Eliminado Correctamente");
				onSuccessPromotor();
			} else if(response.message === "failed") {
				errorNotification("No es posible eliminar el Banco.")
			}
		} catch (error) {
			console.error('Error al borrar el banco', error);
		}
	}

    const initDatatable = () => {
		datatablePromotoresRef.current = $('#tablePromotores').DataTable({
			dom: "<'row mb-3'<'col-md-4 mb-3 mb-md-0'l><'col-md-8 text-end'<'d-lg-flex justify-content-end'f<'d-lg-block d-none'B>>>>t<'row align-items-center'<'mr-auto col-md-6 mb-3 mb-md-0 mt-n2 'i><'mb-0 col-md-6'p>>",
			lengthMenu: [10, 20, 30, 40, 50],
			buttons: [
				{ 
					extend: 'excelHtml5', 
					className: 'btn btn-outline-default btn-sm',
					filename: function() {
						var timestamp = new Date().getTime(); 
						return 'lista_promotores_' + timestamp; 
					},
					exportOptions: {
						columns: ':visible',
						modifier: {
							page: 'none' // Evita agregar información de paginación
						}
					},
					customizeData: function ( data ) {
						for (var i=0; i < data.body.length; i++){
							for (var j=0; j<data.body[i].length; j++ ){
								data.body[i][j] = '\u200C' + data.body[i][j];
							}
						}
					}
				}
			],
			columns: [
				{ title: "#", width:"5%" },
				{ title: "ID" },
				{ title: "ID BANCO" },
				{ title: "Nombre Promotor" },
				{ title: "RFC" },
				{ title: "Dirección" },
				{ title: "Cuenta" },
				{ title: "CLABE" },
				{
					title: "",
					width:"5%",
					data: null,
					render: function (data, type, row, meta) {
					  const promotorId = row[1];
					  const bancoId = row[2];
					  const promotorName = row[3];
					  return `<div  style="text-align: right;" >	
								<a style="cursor:pointer; margin-right:5px"  id="editarPromotor" data-promotor-id="${promotorId}"  data-promotor-name="${promotorName}"  >
								  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#F1C40F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
								</a>
								<a style="cursor:pointer"  id="eliminarPromotor" data-promotor-id="${promotorId}"  data-banco-id="${bancoId}" data-promotor-name="${promotorName}"  >
								  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#EE0C05" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
								</a>
							  </div>`;
					},
				 	escape: false,
				}
			],
			columnDefs: [{
				  targets: [1,2], // Índice de la columna que deseas ocultar (0 en este caso)
				  visible: false
				}
			  ]
			
		});

		$('#tablePromotores').on('click', '#editarPromotor', function () {
			const id = $(this).data('promotor-id');
			const nombre = $(this).data('promotor-name');
			handleEditarPromotor(id, nombre);
		});
		
		$('#tablePromotores').on('click', '#eliminarPromotor', function () {
			const id = $(this).data('promotor-id');
			const banco_id = $(this).data('banco-id');
			const nombre = $(this).data('promotor-name');
			handleEliminarPromotor(id, banco_id, nombre);
		});
	}

    const handleEditarPromotor = (id, nombre) => {
		setType(2);
		setPromotorId(id);
		onOpenModal("Promotor");
	}
	
	const handleEliminarPromotor = (id, banco_id, nombre) => {
		Swal.fire({
			title: "¿Deseas eliminar el promotor " + nombre + "?",
			text: "Una vez eliminado ya no estara disponible este promotor.",
			icon: "question",
			confirmButtonText: 'Continuar',
			cancelButtonText: 'Cancelar',
			showCancelButton: true,
			showCloseButton: true,
			allowOutsideClick: false,
			allowEscapeKey: false,
			allowEnterKey: false,
			reverseButtons : true
		}).then((result) => {
			if (result.isConfirmed) {
				confirmEliminarPromotor(id, banco_id)
			}
		}).catch(() => {
			return false;
		})
	}

	const confirmEliminarPromotor = (id, banco_id) => {
		Swal.fire({
			title: "¿Estas seguro?",
			text: "Esta operacion no se puede deshacer.",
			icon: "warning",
			confirmButtonText: 'Eliminar',
			cancelButtonText: 'Cancelar',
			showCancelButton: true,
			showCloseButton: true,
			allowOutsideClick: false,
			allowEscapeKey: false,
			allowEnterKey: false,
			reverseButtons : true
		}).then((result) => {
			if (result.isConfirmed) {	
				fetchDeletePromotorById(id, banco_id);
			}
		}).catch(() => {
			return false;
		})
	}

    return (
        <div className='col-12 tabla-scrollable table-responsive'>
            <table id="tablePromotores" className="table text-nowrap w-100 table-striped" >
                <thead></thead>
                <tbody></tbody>
            </table>
        </div>
    )
}

export default TablaPromotores;