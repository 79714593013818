import { daeApi } from "../daeApi";

export const getPromotores = async (token) => {
  const response = await daeApi.get(`/promotores`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getPromotoresBancos = async (token) => {
  const response = await daeApi.get(`/promotores/bancos`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getPromotorBancosById = async (id, token) => {
  const response = await daeApi.get(`/promotores/bancos/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const createPromotor = async (data, token) => {
  const response = await daeApi.post(`/promotores`, data, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getPromotorById = async (id, token) => {
  const response = await daeApi.get(`/promotores/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const updatePromotorById = async (id, data, token) => {
  const response = await daeApi.put(`/promotores/${id}`, data, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const deletePromotorById = async (id, token) => {
  const response = await daeApi.delete(`/promotores/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}
