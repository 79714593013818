import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';

import { Card, CardBody, CardHeader } from '../../../components/card/card';
import Breadcrumb from '../../../components/breadcrumb/breadcrumb';
import SelectProyectos from '../../../components/select/proyectos';
import SelectEtapas from '../../../components/select/etapas';
import SelectPaquetes from '../../../components/select/paquetes';
import TablaEstadoCuentas from './tabla-estado-cuentas';
import TablaInconsistencias from './tabla-inconsistencias';
import UploadFileXLSX from '../../../components/upload/upload_file_xlsx';
import Swal from 'sweetalert2';

import { successNotification, alert, warningNotification, errorNotification } from '../../../helpers';

import { 
	getPaqueteById,
	getPaquetesEstatusAdministradorById,
	updatePaqueteEstatusById } from '../../../api/paquete_proyectos/paquete_proyectos';
import { deleteFiles } from '../../../api/otros/otros';
import {
	createEstadoCuentaFile, 
	getEstadoCuentaByPaqueteId, 
	getEstadoCuentaInconsistenciasByPaqueteId, 
	updateEstadoCuentaValorPaqueteByPaqueteId } from '../../../api/estados_cuenta/estados_cuenta';

function EstadosCuenta(props) {
	const token = useSelector(state => state.authentication.user?.token);

	const [sharedProyectoId, setSharedProyectoId] = useState(0);
	const [sharedEtapaId, setSharedEtapaId] = useState(0);
	const [sharedPaqueteId, setSharedPaqueteId] = useState(0);

	const [estadoCuentas, setEstadoCuentas] = useState([]);
	const [inconsistencias, setInconsistencias] = useState([]);

	const [disabledUpload, setDisabledUpload] = useState(true);
	const [titleEstatus, setTitleEstatus] = useState("");
	const [showButtonEstado, setShowButtonEstado] = useState(false);

	const [nuevoEstatus, setNuevoEstatus] = useState([]);

	useEffect(() => {
		if(sharedProyectoId == 0) {
			setSharedEtapaId(0);
			setSharedPaqueteId(0);
			setDisabledUpload(true);
			setShowButtonEstado(false);
		}
	}, [sharedProyectoId])

	useEffect(() => {
		if(sharedPaqueteId > 0) {
			fetchEstadoCuentaByPaqueteId(sharedPaqueteId);
			fetchPaqueteById(sharedPaqueteId);
			setDisabledUpload(false);
			setShowButtonEstado(true);
		} else {
			setDisabledUpload(true);
			setShowButtonEstado(false);
			setEstadoCuentas([]);
			setInconsistencias([]);
		}
	}, [sharedPaqueteId]);

	useEffect(() => {
       setTitle();
    }, [nuevoEstatus]);

	const fetchPaqueteById = async (paquete_id) => {
		try {
			const response = await getPaqueteById(paquete_id, token);
			setNuevoEstatus(response.estatus_paquete);
		} catch (error) {
			console.error('Error al cargar los inconsistencias', error);
		}
	};

	const fetchEstadoCuentaByPaqueteId = async (paquete_id) => {
		try {
			const response = await getEstadoCuentaByPaqueteId(paquete_id, token);
			setEstadoCuentas(response);
			fetchEstadoCuentaInconsistenciasByPaqueteId(paquete_id);
		} catch (error) {
			console.error('Error al cargar los estados de cuenta', error);
		}
	};

	const fetchEstadoCuentaInconsistenciasByPaqueteId = async (paquete_id) => {
		try {
			const response = await getEstadoCuentaInconsistenciasByPaqueteId(paquete_id, token);
			setInconsistencias(response);
		} catch (error) {
			console.error('Error al cargar los inconsistencias', error);
		}
	};

	const fetchCreateEstadoCuentaFile = async (paquete_id, data) => {
        try {
			
            const response = await createEstadoCuentaFile(data, token);
            
			if(response.error != "" && response.message == 'failed') {
                alert("Ocurrio un error en el servidor.", response.error, "warning");
            } else if(response.error == "" && response.message == 'failed') {
				alert("¡Algo Salio Mal?", "No es posible cargar los estados de cuenta. Por favor revise el archivo.", "error")
			} else if(response.message === 'success') {
				if(response.error == "Existen numeros de credito en otro paquete") {
					warningNotification("Carga de estados de cuenta Exitosa. Con estados de cuenta que no se crearon.")
				} else {
					successNotification("Carga de estados de cuenta Exitosa.");
				}
				fetchEstadoCuentaByPaqueteId(paquete_id);
            }
            fetchDeleteFiles(data.filepath);
        } catch (error) {
            console.log("Ocurrio un error en el servidor.", error);
        }
    }

	const fetchUpdatePaqueteEstatusById = async (paquete_id, estatus) => {
        try {
            const response = await updatePaqueteEstatusById(paquete_id, estatus, token);
			if(response.message === "success") {
				//createBotonEstado(paquete_id, estatus);
				setNuevoEstatus(estatus);
				alert(
					"Paquete cerrado con éxito",
					"",
					"success" 
				)
			}
        } catch (error) {
            console.error("Ocurrio un error en el servidor.", error);
        }
    }

	const fectchUpdateEstadoCuentaValorPaqueteByPaqueteId = async (paquete_id) => {
		try {
            const response = await updateEstadoCuentaValorPaqueteByPaqueteId(paquete_id, token);
            if(response === 'success') {
				fetchGetPaquetesEstatusAdministradorById(paquete_id);
			}
        } catch (error) {
            console.error("Ocurrio un error en el servidor.", error);
        }
	}

	const fetchGetPaquetesEstatusAdministradorById = async (paquete_id) => {
		try {
            const response = await getPaquetesEstatusAdministradorById(paquete_id, token);
			console.log(response)
            cierraPaquete(response.message, paquete_id)
        } catch (error) {
            console.error("Ocurrio un error en el servidor.", error);
        }
	}

	const fetchUpdatePaqueteAbrirById = async (estatus) => {
        try {
            const response = await updatePaqueteEstatusById(sharedPaqueteId, estatus, token);
            if(response.message === "success") {
                successNotification("Abriste el paquete correctamente.....")
                setNuevoEstatus(estatus);
            } else if(response.message === "failed") {
                errorNotification("No fue posible abrir el paquete.");
            }
        } catch (error) {
            console.log("Ocurrio un error en el servidor.", error);
        }
    }

	const fetchDeleteFiles = async(filepath) => {
        try {
            let data = { filepath };
            await deleteFiles(data, token);
        } catch(error) {
            console.error("Ocurrio un error en el servidor.", error);
        }
    }

	/*const createBotonEstado = (paquete_id, estatus_paquete) => {
		if(estatus_paquete >= 2)
	        setDisabledUpload(true);

		setBotonEstado(
			estatus_paquete >= 2 
			? 	<div className="form-group mb-3" >
					<label className="form-label" htmlFor="paquetes" ><strong>Estado</strong></label><br/>
					<button className="btn btn-outline-warning"  disabled="true">Paquete cerrado</button>
				</div>
			: 	<div className="form-group mb-3" >
					<label className="form-label" htmlFor="paquetes" ><strong>Estado</strong></label><br/>
					<button className="btn btn-outline-default" onClick={() => handleCerrarPaquete(paquete_id)}>Cerrar paquete</button>
				</div>
				
		);	
	};*/

	const setTitle = () => {
		if(nuevoEstatus >= 2) {
	        setDisabledUpload(true);
			setTitleEstatus("Abrir");
		} else {
            setDisabledUpload(false);
			setTitleEstatus("Cerrar");
        }
    }

	const handleUpdateEstatus = () => {
		Swal.fire({
			text: "¿Deseas " + titleEstatus.toLowerCase() + " el Paquete?",
			icon: "question",
			confirmButtonText: 'Continuar',
			cancelButtonText: 'Cancelar',
			showCancelButton: true,
			showCloseButton: true,
			allowOutsideClick: false,
			allowEscapeKey: false,
			allowEnterKey: false,
			reverseButtons : true
		}).then((result) => {
			if (result.isConfirmed) {
                if(titleEstatus === "Abrir") {
                    fetchUpdatePaqueteAbrirById(1);
                } else {
                    fectchUpdateEstadoCuentaValorPaqueteByPaqueteId(sharedPaqueteId);
                }
			}
		}).catch(() => {
			return false;
		});
	} 
	
	const actualizarTableIncon = () => {
		//fetchEstadoCuentaInconsistenciasByPaqueteId(selectedPaquete);
	}
	
	const cierraPaquete = (data, paquete_id) => {
		if(data == '0'){ 
            alert("Ocurrio un error", "Seleccione un paquete", "error");  
        } else if(data == '1'){
            alert("Pongáse en contacto contacto con el área de Jurídico.",  "El paquete necesita ser cerrado previamente por Jurídico.", "warning");
        } else if(data == '2'){
            alert(
                    "Los depósitos de valor de paquete no coinciden",
                    "Los valores de paquete entre Admin y Jurídico difieren.",
                    "warning"
                );
        } else if(data == '3'){
            alert(	"Ocurrio un error",
                	"¡Existen inconsistecias!",
                    "warning"
                );
        } else{
			fetchUpdatePaqueteEstatusById(paquete_id, 2)
        }
	}


	const handleSubmitFile = (paquete_id, data) => {
		fetchCreateEstadoCuentaFile(paquete_id, data);     
	}

	return (
		<div>
			<Breadcrumb title={props.title} subtitle={props.subtitle}/>
			<div className="row justify-content-center">
                <hr className="mb-4" />
				<div className="col-xl-10">
					<div className="col-xl-12">
						<div id="formControls" className="mb-5">  
							<Card>
								<CardHeader className="fw-bold small d-flex">
									<span className="flex-grow-1">Subir Estados de Cuenta</span>
								</CardHeader>
								<CardBody>
									<div className='row'>
										<div className='col-4'>
											<SelectProyectos setSharedProyectoId={setSharedProyectoId} setSharedEtapaId={setSharedEtapaId} />
										</div>
										<div className='col-4'>
											<SelectEtapas proyecto_id={sharedProyectoId} setSharedEtapaId={setSharedEtapaId} setSharedPaqueteId={setSharedPaqueteId} />
										</div>
										<div className='col-4'>
											<SelectPaquetes etapa_id={sharedEtapaId} setSharedPaqueteId={setSharedPaqueteId}  />
										</div>
									</div>	
									<div className='row'>
										<div className='col-6' style={{textAlign: "left"}}>
											<UploadFileXLSX type={2} paquete_id={sharedPaqueteId} onSubmitFile={handleSubmitFile} disabled={disabledUpload}/>
										</div>
										{ showButtonEstado && (
											<div className='col-6' style={{textAlign: "right"}}>
												<div className="form-group mb-3" >
													<label className="form-label" htmlFor="paquetes" ><strong>Estado: {titleEstatus == "Abrir" ? "Paquete Cerrado" : "Paquete Abierto"}</strong></label><br/>
													<button  className="btn btn-outline-default"  onClick={() => handleUpdateEstatus()} >{titleEstatus} Paquete</button>
												</div>
											</div>
										)}
									</div>
									<hr className="mb-4" />
									<div className='row'>
										<TablaEstadoCuentas estado_cuentas={estadoCuentas} />
									</div>	
								</CardBody>
							</Card>
						</div>
					</div>

					<div className="col-xl-12">
						<div id="formControls" className="mb-5">  
							<Card>
								<CardHeader className="fw-bold small d-flex">
									<span className="flex-grow-1">Inconsistencias</span>
								</CardHeader>
								<CardBody>
									<div className="list-group-item d-flex align-items-center" >
										<div className="flex-1 text-break"></div>
										<div className="ml-auto">
											<button className="btn btn-outline-default" onClick={actualizarTableIncon} >Actualizar</button>
										</div>
									</div>
									<hr className="mb-4" />
                            		<div className='row'>
										<TablaInconsistencias inconsistencias={inconsistencias} />
									</div>
								</CardBody>
							</Card>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default EstadosCuenta;