import React from 'react';
import Breadcrumb from './../../components/breadcrumb/breadcrumb';
import PagesComingSoon from './../../pages/pages/coming-soon';


function Profile(props) {
	return (
		<div>
			<Breadcrumb title={props.title} subtitle={props.subtitle}/>
			<PagesComingSoon />
		</div>
	)
}

export default Profile;