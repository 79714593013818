import { daeApi } from "../daeApi";

export const getFondosInversionDerechohabiente = async (token) => {
  const response = await daeApi.get(`/fondo-inversion/derechohabiente`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const createFondoInversionDerechohabiente = async (data, token) => {
  const response = await daeApi.post(`/fondo-inversion/derechohabiente`, data, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getFondoInversionDerechohabienteById = async (id, token) => {
  const response = await daeApi.get(`/fondo-inversion/derechohabiente/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getFondoInversionDerechohabienteByEtapaId = async (id, token) => {
  const response = await daeApi.get(`/fondo/inversion/derechohabiente/etapa/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}


export const updateFondoInversionDerechohabiente = async (id, data, token) => {
  const response = await daeApi.put(`/fondo-inversion/derechohabiente/${id}`, data, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const deleteFondoInversionDerechohabiente = async (id, token) => {
  const response = await daeApi.delete(`/fondo-inversion/derechohabiente/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}
