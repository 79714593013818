import React,  { useEffect, useState }from 'react';
import { useSelector } from 'react-redux';

import { Card, CardBody, CardHeader } from '../../../../components/card/card';

import { getDerechohabientesById, updateDerechohabientesById } from '../../../../api/derechohabiente/derechohabiente';
import { getPaqueteById } from '../../../../api/paquete_proyectos/paquete_proyectos';

import { formatoMoneda, errorNotification, deleteKeyNull, successNotification } from '../../../../helpers';

function ModalCalculosComisiones({ sharedDerechohabienteId, onCloseModal }) {
    const token = useSelector(state => state.authentication.user?.token);

    const [derechohabiente, setDerechohabiente] = useState({});
    const [admon2, setAdmon2] = useState(0.0);
    const [admon3, setAdmon3] = useState(0.0);
    const [deposito3, setDeposito3] = useState(0.0);
    const [tecnicodep4, setTecnicodep4] = useState(0.0);


    useEffect(() => {
        if (token !== undefined)
            fetchGetDerechohabientesById();
    }, [sharedDerechohabienteId, token])

   

    const fetchGetDerechohabientesById = async() => {
        try {
            const response = await getDerechohabientesById(sharedDerechohabienteId, token);
            setDerechohabiente(response);
            fetchGetPaqueteById(response.paquete_id, response);
        } catch (error) {
            console.log(error);
        }
    }

    const fetchGetPaqueteById = async(paquete_id, derechohabiente) => {
        try {
            const response = await getPaqueteById(paquete_id, token);
            calculosComisiones(response, derechohabiente);
        } catch (error) {
            console.log(error);
        }
    }

    const fetchUpdateDerechohabienteById = async () => {
        try {
            const response = await updateDerechohabientesById(derechohabiente.id, deleteKeyNull(derechohabiente), token);
            if(response.message === "success") 
                successNotification("Derechohabiente actualizado correctamente.");
			else if(response.message === "failed")
				errorNotification("No es posible actualizar el derechohabiente.");

            handleOnCloseModal();
        } catch (error) {
            console.log(error);
            handleOnCloseModal();
        }
    }

    const handleChangeFormDerechohabiente = (event) => {
        try {
            const { name, value } = event.target;
            setDerechohabiente((prevForm) => ({
                ...prevForm,
                [name]: value,
            }));
           
        } catch(e) {
            console.log(e);
        }
    }

    const handleInput = (e) => {
        const { value } = e.target;
        if (validateNumber(value)) {
            handleChangeFormDerechohabiente(e);
        }
    };

    const validateNumber = (value) => {
        // Regex to match numbers with up to 2 decimal places
        const regex = /^\d*\.?\d{0,2}$/;
        return regex.test(value);
    };

    const calculosComisiones = (data, dh) => {
        let comisionobra = parseFloat(data.por_comision_obra / 100 || 0.00);
        let comisionmercantil =  parseFloat(data.por_comision_mercantil / 100 || 0.00);
        let deposito_infonavit = parseFloat(dh.monto_ministracion || 0.00);
        let deposito_conavi = parseFloat(dh.monto_subsidio || 0.00);
        let depositos = parseFloat(deposito_infonavit) + parseFloat(deposito_conavi);
        let cantidadm2vendible = parseFloat(dh.valor_metros2_terreno_vendible || 0.00);
        let metros2vendible =  parseFloat(data.val_tierra_m2 || 0.00);
        let depositos2 = parseFloat(cantidadm2vendible * metros2vendible);
        let depositos3 = (cantidadm2vendible * metros2vendible).toFixed(2);

        setDeposito3(depositos3);

        let admon2 =  (depositos - depositos2) * comisionobra;
        setAdmon2(admon2);

        let admon3 = ((depositos - depositos2) * comisionmercantil);
        setAdmon3(admon3);

        let admon4 = depositos2*0.8;
        let tecnicodep4 = admon4;
        setTecnicodep4(tecnicodep4);
        
    }

    const handleActionComision = async() => {
        fetchUpdateDerechohabienteById()
    }

    const handleOnCloseModal = () => {
        document.getElementById('modalCalculosComisiones').classList.remove('show');
		document.querySelector('.modal-backdrop').remove();
        document.getElementById('modalCalculosComisiones').style.display = 'none';

        setDerechohabiente({});
        setAdmon2(0.0);
        setAdmon3(0.0);
        setDeposito3(0.0);
        setTecnicodep4(0.0);
        
        onCloseModal();
    }

    return(
        <div id="modalCalculosComisiones" className="modal modal-cover fade" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Cálculo de comisiones (cobro mensual)</h5>
                        <button type="button" className="btn-close" onClick={handleOnCloseModal}></button>
                    </div>
                    <div className="modal-body">
                        <form id="formCalculosComisiones" className="was-validated" >
                            <div className="col-xl-12">
                                <div id="formControls" className="mb-5">  
                                    <Card>
                                        <CardHeader>
                                            <span className="flex-grow-1">Información de Vivienda</span>
                                        </CardHeader>
                                        <CardBody>
                                            <div className="col-xl-12">
                                                <div className="row">	
                                                    <div className="col-xl-6">
                                                        <label className="form-label" htmlFor='clave_unica_vivienda'>Clave Única de Vivivenda</label>
                                                        <input className="form-control"  placeholder='Clave Unica Vivienda'
                                                            id="clave_unica_vivienda"
                                                            name="clave_unica_vivienda"
                                                            value={derechohabiente.clave_unica_vivienda !== null ? derechohabiente.clave_unica_vivienda : ""}
                                                            onChange={handleChangeFormDerechohabiente}
                                                            required
                                                            pattern=".*\S+.*" />
                                                        <div className="invalid-feedback" id="validationDireccion">
                                                            Campo requerido
                                                        </div>	
                                                    </div>
                                                    <div className="col-xl-6">
                                                        <label className="form-label" htmlFor="valor_metros2_terreno_vendible">Superficie del Lote</label>
                                                        <input className="form-control"  placeholder='Superficie de Lote'
                                                            id="valor_metros2_terreno_vendible"
                                                            name="valor_metros2_terreno_vendible"
                                                            value={derechohabiente.valor_metros2_terreno_vendible || 0.00}
                                                            onChange={handleInput}
                                                            required
                                                            />               
                                                        <div className="invalid-feedback" id="validationDireccion">
                                                            Campo requerido
                                                        </div>                                   
                                                    </div>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                            </div>
                            <div className="col-xl-12">
                                <div id="formControls" className="mb-5">  
                                    <Card>
                                        <CardHeader>
                                            <span className="flex-grow-1">Detalle de Cálculos</span>
                                        </CardHeader>
                                        <CardBody>
                                            <div className="col-xl-12">
                                                <div className="row">
                                                    <div className="col-md-12" >
                                                        <div className="table-responsive">
                                                            <table className="table">
                                                                <thead>
                                                                <tr>
                                                                    <th>Comisión por Admon. de Fondos y Verificación de Obra más IVA</th>
                                                                    <th>Comisión Mercantil más IVA</th>
                                                                    <th>Valor del lote</th>
                                                                    <th>Valor del lote al 80%</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                <tr>
                                                                    <td id="comiadmon">{formatoMoneda.format(parseFloat(admon2))}</td>
                                                                    <td id="comiadmon1">{formatoMoneda.format(parseFloat(admon3))}</td>
                                                                    <td id="comiadmon2">{formatoMoneda.format(parseFloat(deposito3))}</td>
                                                                    <td id="comiadmon3">{formatoMoneda.format(parseFloat(tecnicodep4))}</td>
                                                                </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-default" onClick={handleOnCloseModal}>Cerrar</button>
                        <button type="button" className="btn btn-outline-theme"  form="formDerechohabiente" onClick={handleActionComision}>Actualizar</button>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default ModalCalculosComisiones;