import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';

import useAuthorization from '../../../hooks/useAuthorization';

import Breadcrumb from './../../../components/breadcrumb/breadcrumb';
import { Card, CardBody, CardHeader }  from '../../../components/card/card';
import { Modal } from 'bootstrap';
import Swal from 'sweetalert2';
import { errorHTTP, successNotification } from '../../../helpers'

import { getUsuarios, deleteUsuarioById } from '../../../api/usuarios/usuarios';

import ModalNuevoUsuario from './modal/usuario';;

const $ = require('jquery');


function AdministracionUsuarios(props) {
    let tableUsuariosRef = useRef(null);

	const token = useSelector(state => state.authentication.user?.token);
    const user = useSelector(state => state.authentication.user);
    const email = user ? user.email : "";

    const userRole = useAuthorization();

    const [usuarios, setUsuarios] = useState([]); 
    const [action, setAction] = useState("");
    const [usuarioId, setUsuarioId] = useState(0);

	const [isModalOpen, setIsModalOpen] = useState(false);
    const [activeModal, setActiveModal] = useState("");


    useEffect(() => {
		if(token !== "" && token !== undefined)
        	fetchUsuarios();
    }, [token]);


	useEffect(() => {
		if(isModalOpen && activeModal === 'Usuario') {
			let myModal = new Modal(document.getElementById('modalUsuario'));
			myModal.show();
		} 
	}, [isModalOpen, activeModal]);


	useEffect(() => {
		if (tableUsuariosRef !== null && tableUsuariosRef.current !== null) {
			let index = 1;
            const usuariosFiltrados = usuarios.filter(user => user.email !== email);
			tableUsuariosRef.current.clear();
			tableUsuariosRef.current.rows.add(
                usuariosFiltrados.map(user => [index++, 
                                        user.id, 
                                        user.email, 
                                        user.username,
                                        user.creation_date,
                                        user.tipo.toUpperCase(),
                                        user.status === 1 ? "Activo" : "Inactivo"]));
            tableUsuariosRef.current.draw();
		} else 
			initDatatableUsuarios();		
		// eslint-disable-next-line
	}, [usuarios]);

    const fetchUsuarios = async() => {
		try {
			const response = await getUsuarios(token);
			if(response !== "")
				setUsuarios(response.message);
		} catch (error) {
            errorHTTP(error, "");
        }
    }

    const fetchDeleteUsuarioById = async(id) => {
        try {
            const { status } = await deleteUsuarioById(id, token);
			if(status === 200) 
                successNotification("Usuario eliminado exitosamente.");
            fetchUsuarios();
        } catch (error) {
			errorHTTP(error, "Usuario inexistente.");
        }
    }

	const initDatatableUsuarios = () => {
        tableUsuariosRef.current = $('#tableUsuarios').DataTable({
            dom: "<'row mb-3'<'col-md-4 mb-3 mb-md-0'l><'col-md-8 text-end'<'d-lg-flex justify-content-end'f<'d-lg-block d-none'B>>>>t<'row align-items-center'<'mr-auto col-md-6 mb-3 mb-md-0 mt-n2 'i><'mb-0 col-md-6'p>>",
			lengthMenu: [10, 20, 30, 40, 50],
			responsive: {
				details: {
					type: 'column',
					target: -1
				}
			},
			buttons: [
				{ 
					extend: 'excelHtml5', 
					className: 'btn btn-outline-default btn-sm',
					filename: function() {
						var timestamp = new Date().getTime(); 
						return 'usuarios_' + timestamp; 
					},
					exportOptions: {
						columns: ':visible',
						modifier: {
							page: 'none' // Evita agregar información de paginación
						}
					},
					customizeData: function ( data ) {
						for (var i=0; i < data.body.length; i++){
							for (var j=0; j<data.body[i].length; j++ ){
								data.body[i][j] = '\u200C' + data.body[i][j];
							}
						}
					}
				}
			],
            columns: [
                { title: "#" },//0
                { title: "ID" }, //1 
                { title: "Email" }, //2 
                { title: "Username" }, // 3
                { title: "Fecha de Creación" }, //4
                { title: "Tipo" }, //5
                { title: "Estatus" }, // 6
               {
                    title: "",
                    data: null,
                    width: "50px",
                    render: function (data, type, row, meta) {
                        const usuario_id = row[1];
                        const usuario_email = row[3];
                            
                        return `<div style="text-align: right; white-space: nowrap;">
                                    <a  style="cursor:pointer" id="editarUsuario" data-usuario-id="${usuario_id}"  data-usuario-email="${usuario_email}" >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#F1C40F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>    
                                    </a>
                                    <a style="cursor:pointer" id="eliminarUsuario" data-usuario-id="${usuario_id}"  data-usuario-email="${usuario_email}">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#EE0C05" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>    
                                    </a>  
                                </div>`;
                    },
                    escape: false,
                }
            ],
            columnDefs: [{
                targets: [1], 
                visible: false
            }]
        });
	
		$('#tableUsuarios').on('click', '#editarUsuario', function () {
			const id = $(this).data('usuario-id');
			const email = $(this).data('usuario-email');
			handleEditarUsuario(id, email);
		});
		
		$('#tableUsuarios').on('click', '#eliminarUsuario', function () {
			const id = $(this).data('usuario-id');
			const email = $(this).data('usuario-email');
			handleEliminarUsuario(id, email);
		});
	}

    const handleEditarUsuario = (id, username) => {
		setAction("Editar")
		setUsuarioId(id);
		handleOpenModal('Usuario');
		
	}

	const handleEliminarUsuario = (id, username) => {
		Swal.fire({
			title: "¿Deseas eliminar al usuario " + username + "?",
			text: "Una vez eliminado ya no estara disponible este usuario.",
			icon: "question",
			confirmButtonText: 'Continuar',
			cancelButtonText: 'Cancelar',
			showCancelButton: true,
			showCloseButton: true,
			allowOutsideClick: false,
			allowEscapeKey: false,
			allowEnterKey: false,
			reverseButtons : true
		}).then((result) => {
			if (result.isConfirmed) {	
				confirmEliminarUsuario(id)
			}
		}).catch(() => {
			return false;
		})
	}

	const confirmEliminarUsuario = (id) => {
		Swal.fire({
			title: "¿Estas seguro?",
			text: "Esta operacion no se puede deshacer.",
			icon: "warning",
			confirmButtonText: 'Eliminar',
			cancelButtonText: 'Cancelar',
			showCancelButton: true,
			showCloseButton: true,
			allowOutsideClick: false,
			allowEscapeKey: false,
			allowEnterKey: false,
			reverseButtons : true
		}).then((result) => {
			if (result.isConfirmed) {	
				fetchDeleteUsuarioById(id);
			}
		}).catch(() => {
			return false;
		})
	}


    const openModalNuevoUsuario = () => {
        setAction("Nuevo");
        setUsuarioId(0);
        handleOpenModal('Usuario');
    }

    const renderButtonNuevoUsuario = () => {
		if (['master'].some(role => userRole.includes(role))) {
			return (
				<div className="ml-auto">
					<button onClick={openModalNuevoUsuario} className="btn btn-outline-default">Nuevo Usuario</button>
				</div>
			)
		}
	}


	const handleOpenModal = (modalName) => {
		setIsModalOpen(true);
        setActiveModal(modalName);
    };


	const handleCloseModal = () => {
        setIsModalOpen(false);
        setActiveModal("");
		document.body.style.overflow = 'auto';
		fetchUsuarios(); 
    };


    return (
		<div>
			<Breadcrumb title={props.title} subtitle={props.subtitle}/>
			<div className="row justify-content-center">
                <hr className="mb-4" />		
				<div className="col-xl-11">
					<div className="col-xl-12">
                        <div className="list-group-item d-flex align-items-center" style={{ marginBottom: '1em', marginTop: '-0.5em'}}>
                            <div className="flex-1 text-break"></div>
                            { renderButtonNuevoUsuario() }
                        </div>
						<div id="datatable" className="mb-4">
							<Card> 
								<CardHeader>
									<span className="flex-grow-1">Lista de Usuarios.</span>
								</CardHeader>
								<CardBody>
									<div className='row'>
										<div className="col-xl-12 tabla-scrollable table-responsive">
											<table id="tableUsuarios" className="table   text-nowrap w-100 table-striped ">
												<thead></thead>
												<tbody></tbody>
											</table>
										</div>
									</div>
								</CardBody>
							</Card>
						</div>
					</div>
				</div>
			</div>
			{ isModalOpen && activeModal === "Usuario" && <ModalNuevoUsuario usuario_id={usuarioId} action={action} onCloseModal={handleCloseModal} /> }
		</div>
	)
}

export default AdministracionUsuarios;

