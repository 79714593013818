
import React from 'react';
import { useSelector } from 'react-redux';

import { formatoMoneda } from '../../../../../helpers';

function CalcularDatos({ ministracion_neta, valor_paquete, valor_actual, porcentaje }) {
    const token = useSelector(state => state.authentication.user?.token);

    return (
        <div align="right" id="calculardatos">
            <div className="col-12">
                <div className="form-group">
                    <label>Total ministración:&nbsp;&nbsp;</label> <label id="ministracion_neta" name="ministracion_neta">{formatoMoneda.format(parseFloat(ministracion_neta.replace(/,/g, '')))}</label><br/>
                    <label>Valor Total paquete:&nbsp;&nbsp;</label> <label id="valor_total_paquete" name="valor_total_paquete">{formatoMoneda.format(parseFloat(valor_paquete.replace(/,/g, '')))}</label><br/>
                    <label>Valor actual paquete:&nbsp;&nbsp;</label> <label id="valor_actual" name="valor_atual" >{formatoMoneda.format(parseFloat(valor_actual.replace(/,/g, '')))}</label><br/>
                    <label>Porcentaje de Avance:&nbsp;&nbsp;</label> <label id="porcentaje_paquete" name="porcentaje_paquete">{porcentaje}%</label> 
                </div>
            </div>
        </div>
    )
}

export default CalcularDatos;