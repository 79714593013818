import React,  { useEffect, useState }from 'react';
import { useSelector } from 'react-redux';

import { errorNotification } from '../../../../../helpers';
import { getParametrosMinistracionById, updateParametrosMinistracionById } from '../../../../../api/parametros_ministraciones/parametros_ministraciones';
import { createPaqueteAnticipoMinistracion, updatePaqueteEstatusById } from '../../../../../api/paquete_proyectos/paquete_proyectos';

import DateTime from 'react-datetime';
import format from 'date-fns/format';

function ModalParamentros({  proyecto_id, paquete_id, proyecto_nombre, paquete_nombre, form_data, onCloseModal }) {
    const token = useSelector(state => state.authentication.user?.token);

	const [formParametros, setFormParametros] = useState({
		nombre_lic: '',
		ocupacion: '',
		destinatario: '',
		observaciones: '',
        fecha_firma: new Date()
	});
    
    useEffect(() => { 
        fetchParametrosMinistracionById();
        // eslint-disable-next-line
	}, []);

	const fetchParametrosMinistracionById = async () => {
		try {
			const response = await getParametrosMinistracionById(1, token);
			setFormParametros(response)
		} catch (error) {
			console.error('Error al cargar los bancos', error);
		}
	};

    const fetchupdateParametrosMinistracionById = async (id) => {
        try {
            const response = await updateParametrosMinistracionById(id, formParametros, token);
            if(response.message === 'success') {
                fetchCreatePaqueteAnticipoMinistracion();
            }
        } catch (error) {
            errorNotification('Error al actualizar los parametros');
        }
    }

    const fetchCreatePaqueteAnticipoMinistracion = async () => {
        try {
            let data = {
                proyecto_id : proyecto_id,
                paquete_id : paquete_id,
                proyecto_nombre : proyecto_nombre,
                num_paquete : paquete_nombre,
                valor_total: form_data.valor_total,
                monto_anticipo: form_data.monto_anticipo,
                retencion: form_data.retencion,
                anticipo_constructor : form_data.anticipo_neto_constructor,
                valor_terreno: form_data.valor_terreno,
                total_credito: form_data.total_credito,
                fecha_firma : formParametros.fecha_firma === undefined ?  format(new Date(), 'yyyy-MM-dd') : formParametros.fecha_firma
            }

            const response = await createPaqueteAnticipoMinistracion(data, token);
            if(response.message === 'success') {
                fetchUpdatePaqueteEstatusById(paquete_id, 4);
            }

        } catch (error) {
           console.log(error);
        }
    }

    const fetchUpdatePaqueteEstatusById = async (paquete_id, estatus) => {
        try {
            const response = await updatePaqueteEstatusById(paquete_id, estatus, token);
            handleCloseModal(response.message);
        } catch (error) {
            errorNotification("Ocurrio un error en el servidor.");
        }
    }


	const handleChangeFormParametros = (event) => {
        try {
            const { name, value } = event.target;
            setFormParametros({
                ...formParametros,
                [name]: value,
            });
        } catch(e) {
            const fechaMoment = event; 
            const fechaString = fechaMoment.format('YYYY-MM-DD');
            setFormParametros((prevForm) => ({
                ...prevForm,
                fecha_firma: fechaString
            }));
        }
	};

	const handleUpdateParametros = async (e) => {
		e.preventDefault(); 
		if (e.target.form.checkValidity()) {
			try {
				fetchupdateParametrosMinistracionById(1);
			} catch (error) {
				errorNotification('Error al actualizar los parametros');
			}
		} else {
			errorNotification("Faltan campos requeridos.")
		}
	}

    const handleCloseModal = (message) => {
        document.getElementById('modalParametros').classList.remove('show');
        document.querySelector('.modal-backdrop').remove();
        document.getElementById('modalParametros').style.display = 'none';

        fetchParametrosMinistracionById();
    
        onCloseModal(message);
    }

    return(
        <div className="modal modal-cover fade" id="modalParametros" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Anticipos</h5>
                        <button type="button" className="btn-close" onClick={handleCloseModal}></button>
                    </div>
                    <div className="modal-body">
                        <form id="formParametros" className="was-validated" >		
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <div className="form-group mb-3">
                                                <label className="form-label" htmlFor="nombreLic">Nombre Lic.:</label>
                                                <input className="form-control " placeholder="Nombre Lic.:" type='text' 
                                                    id="nombre_lic"
                                                    name="nombre_lic"
                                                    value={formParametros.nombre_lic}
                                                    onChange={handleChangeFormParametros}
                                                    required
                                                    pattern=".*\S+.*" />
                                                <div className="invalid-feedback" id="validationNombre">
                                                    Campo requerido
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-6">
                                            <div className="form-group mb-3">
                                                <label className="form-label" htmlFor='ocupacionLic'>Ocupación Lic.:</label>
                                                <input className="form-control" placeholder="Ocupación Lic.:" type='text'
                                                    id="ocupacion"
                                                    name="ocupacion"
                                                    value={formParametros.ocupacion} 
                                                    onChange={handleChangeFormParametros} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <div className="form-group mb-3">
                                                <label className="form-label" htmlFor="destinatario">Dirigida A:</label>
                                                <input type="text" className="form-control"  placeholder='Dirigida A:'
                                                    id="destinatario"
                                                    name="destinatario"
                                                    value={formParametros.destinatario} 
                                                    onChange={handleChangeFormParametros}
                                                    required
                                                    pattern=".*\S+.*" />
                                                <div className="invalid-feedback" id="validationCuenta">
                                                    Campo requerido
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-6">
                                            <div className="form-group mb-3">
                                                <label className="form-label" htmlFor="observaciones">Observaciones:</label>
                                                <textarea type="text" className="form-control"  placeholder='Observaciones'
                                                    id="observaciones"
                                                    name="observaciones"
                                                    value={formParametros.observaciones} 
                                                    onChange={handleChangeFormParametros} >
                                                </textarea>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <div className="form-group mb-3">
                                                <label className="form-label"><strong>Fecha Firma</strong></label>
                                                <DateTime closeOnSelect={true}
                                                    className="form-control"  
                                                    inputProps={{
                                                        placeholder:' Fecha de Firma',
                                                        readOnly: true 
                                                    }}
                                                    viewMode="days"
                                                    timeFormat={false}
                                                    dateFormat="YYYY-MM-DD" 
                                                    id="fecha_firma"
                                                    name="fecha_firma"
                                                    value={formParametros.fecha_firma}
                                                    onChange={handleChangeFormParametros}
                                                    />
                                                <div className="invalid-feedback" id="validationDireccion">
                                                    Campo requerido
                                                </div>	
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-default" onClick={handleCloseModal}>Cerrar</button>
                        <button type="button" className="btn btn-outline-theme"  form="formParametros" onClick={handleUpdateParametros} >Enviar</button>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default ModalParamentros;