import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Card, CardBody }  from '../../../components/card/card';
import Breadcrumb from '../../../components/breadcrumb/breadcrumb';
import { Modal } from 'bootstrap';

import { getPromotoresBancos } from '../../../api/promotores/promotores';

import TablaPromotores from './tablas/promotores';
import ModalPromotor from './modals/promotor';


function CatalogoPromotores(props) {
	const token = useSelector(state => state.authentication.user?.token);

	const [promotores, setPromotores] = useState([]);
	const [type, setType] = useState(0);
	const [promotorId, setPromotorId] = useState(0);

	const [isModalOpen, setIsModalOpen] = useState(false);
    const [activeModal, setActiveModal] = useState("");

	
	useEffect(() => { 
        if(token !== undefined) 
    	    fetchPromotoresBancos();
	}, [token]);


	useEffect(() => {
		if(isModalOpen && activeModal === 'Promotor') {
			let myModal = new Modal(document.getElementById('modalPromotor'));
			myModal.show();
		} 
	}, [isModalOpen, activeModal]);


	const fetchPromotoresBancos = async () => {
		try {
			const response = await getPromotoresBancos(token);
			setPromotores(response)
		} catch (error) {
			console.error('Error al cargar los promotores', error);
		}
	};


	const handleSuccessPromotor = () => {
		fetchPromotoresBancos();
	}


	const handleOpenModal = (modalName) => {
        setIsModalOpen(true);
        setActiveModal(modalName);
    };


	const handleCloseModal = () => {
        setIsModalOpen(false);
        setActiveModal("");
		document.body.style.overflow = 'auto';
    };


	return (
		<div>
			<Breadcrumb title={props.title} subtitle={props.subtitle}/>
			<div className="row justify-content-center">
				<hr className="mb-4" />
				<div className="list-group-item d-flex align-items-center" style={{marginRight: '1.5em', marginBottom: '1em', marginTop: '-0.5em'}}>
					<div className="flex-1 text-break"></div>
					<div className="ml-auto">
						<a className="btn btn-outline-default" onClick={() => {setType(1); handleOpenModal("Promotor");}} >Agregar Promotor</a>
					</div>
				</div>
				<div id="datatable" className="mb-5">
					<Card>
						<CardBody>
							<TablaPromotores promotores={promotores} setType={setType} setPromotorId={setPromotorId} onOpenModal={handleOpenModal} onSuccessPromotor={handleSuccessPromotor}  />
						</CardBody>
					</Card>
				</div>
			</div>
			{ isModalOpen && activeModal === "Promotor" && <ModalPromotor type={type} promotorId={promotorId} onSuccessPromotor={handleSuccessPromotor} onCloseModal={handleCloseModal} /> }
		</div>
	)
}

export default CatalogoPromotores;