import { daeApi } from "../daeApi";

export const getFondosInversion = async (token) => {
  const response = await daeApi.get(`/fondo-inversion`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const createFondoInversion = async (data, token) => {
  const response = await daeApi.post(`/fondo-inversion`, data, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getFondoInversionById = async (id, token) => {
  const response = await daeApi.get(`/fondo-inversion/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getFondoInversionGeneradaByPaqueteId = async (id, token) => {
  const response = await daeApi.get(`/fondo/inversion/generadas/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

//fondo/inversion/historial
export const getFondoInversionHistorialByEtapaId = async (id, token) => {
  const response = await daeApi.get(`/fondo/inversion/historial/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

//fondo/inversion/total
export const getFondoInversionTotalByEtapaId = async (id, token) => {
  const response = await daeApi.get(`/fondo/inversion/total/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const updateFondoInversion = async (id, data, token) => {
  const response = await daeApi.put(`/fondo-inversion/${id}`, data, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const deleteFondoInversion = async (id, token) => {
  const response = await daeApi.delete(`/fondo-inversion/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}
