import React, { useEffect, useState, useRef }  from 'react';
import { useSelector } from 'react-redux';

import { Card, CardBody, CardHeader } from '../../../components/card/card';
import Breadcrumb from '../../../components/breadcrumb/breadcrumb';
import SelectProyectos from '../../../components/select/proyectos';
import SelectEtapas from '../../../components/select/etapas';
import SelectPaquetes from '../../../components/select/paquetes';
import TablaHistorialInconsistencias from './historial';

import { 
	getHistorialEstadoCuentaByPaqueteId,
	getHistorialEstadoCuentaByEtapaId 
} from '../../../api/historial_estados_cuenta/historial_estados_cuenta';

function HistorialInconsistencias(props) {
	const token = useSelector(state => state.authentication.user?.token);

	const [sharedProyectoId, setSharedProyectoId] = useState(0);
	const [sharedEtapaId, setSharedEtapaId] = useState(0);
	const [sharedPaqueteId, setSharedPaqueteId] = useState(0);


	const [inconsistencias, setInconsistencias] = useState([]);
	
	useEffect(() => {
		if(sharedProyectoId == 0) {
			setSharedEtapaId(0);
			setSharedPaqueteId(0);
		}
	}, [sharedProyectoId])

	useEffect(() => {
		if(sharedPaqueteId > 0) 
			fetchHistorialEstadoCuentaByPaqueteId(sharedPaqueteId);
		else if(sharedPaqueteId == "-1") 
			fetchHistorialEstadoCuentaByEtapaId(sharedEtapaId);
		else 
			setInconsistencias([]);
	}, [sharedPaqueteId])

	const fetchHistorialEstadoCuentaByPaqueteId = async (paquete_id) => {
		try {
			const response = await getHistorialEstadoCuentaByPaqueteId(paquete_id, token);
			setInconsistencias(response);
		} catch (error) {
			setInconsistencias([]);
			console.error('Error al cargar el historial por paquete', error);
		}
	};

	const fetchHistorialEstadoCuentaByEtapaId = async (etapa_id) => {
		try {
			const response = await getHistorialEstadoCuentaByEtapaId(etapa_id, token);
			setInconsistencias(response);
		} catch (error) {
			console.error('Error al cargar el historial por etapa', error);
		}
	}; 
	
	return (
		<div>
			<Breadcrumb title={props.title} subtitle={props.subtitle}/>
			<div className="row justify-content-center">
                <hr className="mb-4" />
				<div className="col-xl-11">
					<div className="col-xl-12">
						<div id="formControls" className="mb-5">  
							<Card>
								<CardHeader className="fw-bold small d-flex">
									<span className="flex-grow-1">Inconsistencias</span>
								</CardHeader>
								<CardBody>
									<div className='col-xl-12'>
										<div className='row'>
											<div className='col-4'>
												<SelectProyectos setSharedProyectoId={setSharedProyectoId} setSharedEtapaId={setSharedEtapaId} />
											</div>
											<div className='col-4'>
												<SelectEtapas  proyecto_id={sharedProyectoId} setSharedEtapaId={setSharedEtapaId} setSharedPaqueteId={setSharedPaqueteId} />
											</div>
											<div className='col-4'>
												<SelectPaquetes etapa_id={sharedEtapaId} setSharedPaqueteId={setSharedPaqueteId}  />
											</div>
										</div>
									</div>
									<hr className="mb-4" />
									<div className='col-xl-12'>
										<div className='row'>
											<TablaHistorialInconsistencias inconsistencias={inconsistencias} />
										</div>	
									</div>
								</CardBody>
							</Card>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default HistorialInconsistencias;