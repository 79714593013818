
import React,  { useEffect, useState }from 'react';
import { useSelector } from 'react-redux';
import { createBanco, getBancoById, updateBancoById } from '../../../../api/bancos/bancos';
import { errorNotification, formatoMoneda, successNotification } from '../../../../helpers';


function ModalBanco({ type, bancoId, onSuccessBancos, onCloseModal }) {
    const token = useSelector(state => state.authentication.user?.token);

    const [accion, setAccion] = useState("");
    const [formBancoNuevo, setFormBancoNuevo] = useState({
		id: 0,
		nombre: '',
		cuenta: '',
		clabe: '',
		mesa_inversion: "0.0"
	});

    useEffect(() => {
        if(type == 1)
            setAccion("Nuevo");
        else if(type == 2) {
            if(bancoId > 0) {
                setAccion("Editar");
                fetchBancoById(bancoId);
            }
        }
    }, [type, bancoId]);

    const fetchBancoById = async (id) => {
		try {
			const response = await getBancoById(id, token);
			setFormBancoNuevo({
                id: response.id,
                nombre: response.nombre,
                cuenta: response.cuenta,
                clabe: response.clabe,
                mesa_inversion: response.mesa_inversion
            });
		} catch (error) {
			console.error('Error al cargar el promotor', error);
		}
	}

    const fetchCreateBanco = async () => { 
		try {
			const response = await createBanco(formBancoNuevo, token);
			if(response.status === 201) {
				successNotification("Banco Registrado Exitosamente");
                handleCloseModal();
			}
		} catch (error) {
            console.error(error);
			errorNotification('Error al crear el banco');
		}
	}

	const fetchUpdateBancoById = async () => {
		try {
            const response = await updateBancoById(formBancoNuevo.id, formBancoNuevo, token);
            if(response.message === "success") {
				successNotification("Banco Editado Correctamente");
                handleCloseModal();
			}
		} catch (error) {
			errorNotification('Error al actualizar el banco');
		}
	}

    const handleActionBanco = async (e) => {
		e.preventDefault(); 
		if (e.target.form.checkValidity()) {
            if(type == 1) {
				fetchCreateBanco();
			} else {
				fetchUpdateBancoById();
			}
		} else {
			errorNotification("Faltan campos requeridos.")
		}
	} 

    const handleChangeFormBanco = (event) => {
        try {
            const { name, value } = event.target;
            if (name === "mesa_inversion") {
                const unformattedValue = value.replace(/\$|,/g, "");
                const parsedValue = parseFloat(unformattedValue);
                setFormBancoNuevo((prevForm) => ({
                    ...prevForm,
                    [name]: parsedValue || "",
                }));
            } else {
                setFormBancoNuevo((prevForm) => ({
                    ...prevForm,
                    [name]: value,
                }));
            }
        } catch(e) {
            console.error(e);
        }
	};

    const handleCloseModal = () => {
        document.getElementById('modalNuevoBanco').classList.remove('show');
        document.querySelector('.modal-backdrop').remove();
        document.getElementById('modalNuevoBanco').style.display = 'none';

        setFormBancoNuevo({
            nombre: '',
            cuenta: '',
            clabe: '',
            mesa_inversion: "0.0"
        });
        onSuccessBancos();
    
        onCloseModal();
    }

    return(
        <div className="modal modal-cover fade" id="modalNuevoBanco" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <div className="modal-dialog modal-lg" >
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{ accion } Banco</h5>
                        <button type="button" className="btn-close" onClick={handleCloseModal}></button>
                    </div>
            
                    <div className="modal-body">
                        <form id="formBancoNuevo" className="was-validated" >		
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="row">
                                        <div className="col-xl-8">
                                            <div className="form-group mb-3">
                                                <label className="form-label" htmlFor="nombre">Nombre Banco</label>
                                                <input className="form-control " placeholder="Nombre del Banco" type='text' 
                                                    id="nombre"
                                                    name="nombre"
                                                    value={formBancoNuevo.nombre}
                                                    onChange={handleChangeFormBanco}
                                                    required
                                                    pattern=".*\S+.*" />
                                                <div className="invalid-feedback" id="validationNombre">
                                                    Campo requerido
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-4">
                                            <div className="form-group mb-3">
                                                <label className="form-label" htmlFor='mesa_inversion'>Saldo Promedio</label>
                                                <input className="form-control" placeholder="$0.0" type='text'
                                                    id="mesa_inversion"
                                                    name="mesa_inversion"
                                                    value={formatoMoneda.format(parseFloat(formBancoNuevo.mesa_inversion || 0.00 ))}
                                                    onChange={handleChangeFormBanco} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="row">
                                        <div className="col-xl-6">
                                            <div className="form-group mb-3">
                                                <label className="form-label" htmlFor="cuenta">Cuenta</label>
                                                <input type="text" className="form-control"  placeholder='Cuenta'
                                                    id="cuenta"
                                                    name="cuenta"
                                                    value={formBancoNuevo.cuenta} 
                                                    onChange={handleChangeFormBanco}
                                                    required
                                                    pattern=".*\S+.*" />
                                                <div className="invalid-feedback" id="validationCuenta">
                                                    Campo requerido
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-6">
                                            <div className="form-group mb-3">
                                                <label className="form-label" htmlFor="clabe">CLABE</label>
                                                <input type="text" className="form-control"  placeholder='CLABE'
                                                    id="clabe"
                                                    name="clabe"
                                                    value={formBancoNuevo.clabe} 
                                                    onChange={handleChangeFormBanco}
                                                    required
                                                    pattern=".*\S+.*" />
                                                <div className="invalid-feedback" id="validationClabe">
                                                    Campo requerido
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </form>	
                    </div>
                    
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-default" onClick={handleCloseModal}>Cerrar</button>
                        <button type="button" className="btn btn-outline-theme" onClick={handleActionBanco} form="formBancoNuevo" >{ accion }</button>
                    </div>
                        
                </div>
            </div>
        </div>
    )
}


export default ModalBanco;