import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import useAuthorization from '../../../hooks/useAuthorization';


import Breadcrumb from '../../../components/breadcrumb/breadcrumb';
import { Card, CardBody, CardHeader } from '../../../components/card/card.jsx';

import { getProyectosPromotores } from '../../../api/proyectos/proyectos.js';
import { getEtapasByProyectoId, getEtapaById } from '../../../api/etapa_proyectos/etapa_proyectos.js';
import { getTasaInversionByEtapaId, updateTasaInversion } from '../../../api/tasa_inversion/tasa_inversion.js';
import { getFondoInversionHistorialByEtapaId , getFondoInversionTotalByEtapaId} from '../../../api/fondo_inversion/fondo_inversion.js';
import { getFondoInversionDerechohabienteByEtapaId } from '../../../api/fondo_inversion_derechohabiente/fondo_inversion_derechohabien.js';

import { alert } from '../../../helpers/alertManager.js';
import { formatoMoneda } from '../../../helpers/utils.js';
import Swal from 'sweetalert2';
import SelectProyectos from '../../../components/select/proyectos.js';
import SelectEtapas from '../../../components/select/etapas.js';



const $ = require('jquery');

function FondoInversion(props) {
	const token = useSelector(state => state.authentication.user?.token);

	const datatableInversionesRef = useRef(null);
	const datatableInversionesInconRef = useRef(null);

	const [sharedProyectoId, setSharedProyectoId] = useState(0);
	const [sharedEtapaId, setSharedEtapaId] = useState(0);
	const [sharedPaqueteId, setSharedPaqueteId] = useState(0);


	const [proyectos, setProyectos] = useState([]);
	const [etapas, setEtapas] = useState([]);
	const [paquetes, setPaquetes] = useState([]);

	const [selectedProyecto, setSelectedProyecto] = useState(0);
	const [selectedEtapa, setSelectedEtapa] = useState(0);

	const [inversiones, setInversiones] = useState([]);
	const [inversionesInconsistencias, setInversionesInconsistencias] = useState([]);
	const [totalInversiones, setTotalInversiones] = useState("0.00");
	const [tasaInversion, setTasaInversion] = useState("0.00");
	const [tasa, setTasa] = useState({});

	const [buttonLibearFI, setButtonLibearFI] = useState({
		disabled: true,
		text: "",
		type: "btn btn-outline-warning",
	});
	const [showButtonLiberar, setShowButtonLiberar] = useState(false);
	const [showTasaInversion, setShowTasaInversion] = useState(false);

	useEffect(() => {
		if(sharedProyectoId == 0) {    
			setEtapas([]);
			setInversiones([]);
			setInversionesInconsistencias([]);
			setTotalInversiones(0.0);
			setShowButtonLiberar(false);
			setButtonLibearFI({
				disabled: true,
				text: "",
				type: "btn btn-outline-warning"
			});
			setShowTasaInversion(false);
		}
	}, [sharedProyectoId])


	useEffect(() => {
		if(sharedEtapaId > 0) {
			setShowButtonLiberar(true);
			fetchEtapaById(sharedEtapaId);
		} else {
            setEtapas([]);
			setInversiones([]);
			setInversionesInconsistencias([]);
			setTotalInversiones(0.0);
			setShowButtonLiberar(false);
			setButtonLibearFI({
				disabled: true,
				text: "",
				type: "btn btn-outline-warning"
			});
			setShowTasaInversion(false);
        }
	}, [sharedEtapaId]);

	useEffect(() => {
		if (datatableInversionesRef.current !== null) {
			let index = 1;
			datatableInversionesRef.current.clear();
			datatableInversionesRef.current.rows.add(inversiones.map(inversion => [
				index++, 
				inversion.num_paquete, 
				inversion.inversion_total, 
				inversion.inversion_mensual, 
				inversion.estatus_paquete]));
			datatableInversionesRef.current.draw();
		} else 
			initDatatableInversiones();
		// eslint-disable-next-line
	}, [inversiones]);

	useEffect(() => {
		if (datatableInversionesInconRef.current !== null) {
			let index = 1;
			datatableInversionesInconRef.current.clear();
			datatableInversionesInconRef.current.rows.add(inversionesInconsistencias.map(inversion => [
				index++, 
				inversion.acreditado, 
				inversion.inversion_total,
				inversion.ind_estatus]));
			datatableInversionesInconRef.current.draw();
		} else 
			initDatatableInversionesIncon();
		// eslint-disable-next-line
	}, [inversionesInconsistencias]);

	
	const fetchEtapaById = async (etapa_id) => {
		try {
			const response = await getEtapaById(etapa_id, token);
			if(response.des_liberar_inversion == 1) {
				setButtonLibearFI({
					disabled: true,
					text: "Inversión Liberada",
					type: "btn btn-outline-info",
				});
			} else {
				setButtonLibearFI({
					disabled: false,
					text: "Liberar Inversiones",
					type: "btn btn-success",
				});
			}		
			fetchTasaInversionByEtapaId(etapa_id);
		} catch (error) {
			console.error('Error al cargar los inconsistencias', error);
		}
	};

	const fetchTasaInversionByEtapaId = async (etapa_id) => {
		try {
			const response = await getTasaInversionByEtapaId(etapa_id, token);
			if(response.message == "not found") {
				alert("¡No hay tasa inversión!", "", "warning");
				setInversiones([]);
				setInversionesInconsistencias([]);
				setTotalInversiones(0.00);
				setTasaInversion("NINGUNA");	
				setShowTasaInversion(true);		
				setButtonLibearFI({
					disabled: true,
					text: "Liberar Inversiones",
					type: "btn btn-outline-warning"
				});	
			} else {
				setTasa(response.message)
				fetchFondoInversionHistorialByEtapaId(etapa_id);
				fetchFondoInversionDerechohabienteByEtapaId(etapa_id);
				fetchFondoInversionTotalByEtapaId(etapa_id);
				setTasaInversion(response.message.por_tasa_inversion + "%");	
				setShowTasaInversion(true);	
			}	
		} catch (error) {
			setInversiones([]);
			setInversionesInconsistencias([]);
			setShowButtonLiberar(false);
			console.error('Error al cargar los tasa inversion', error);
		}
	};

	const fetchFondoInversionHistorialByEtapaId = async (etapa_id) => {
		try {
			const response = await getFondoInversionHistorialByEtapaId(etapa_id, token);
			setInversiones(response.message);
		} catch (error) {
			console.error('Error al cargar los datos', error);
		}
	};

	const fetchFondoInversionDerechohabienteByEtapaId = async (etapa_id) => {
		try {
			const response = await getFondoInversionDerechohabienteByEtapaId(etapa_id, token);
			setInversionesInconsistencias(response.message);
		} catch (error) {
			console.error('Error al cargar los datos', error);
		}
	};

	const fetchFondoInversionTotalByEtapaId = async (etapa_id) => {
		try {
			const response = await getFondoInversionTotalByEtapaId(etapa_id, token);
			setTotalInversiones(response.message);
		} catch (error) {
			console.error('Error al cargar los etapas', error);
		}
	};
	


	const initDatatableInversiones = () => {
		datatableInversionesRef.current = $('#tableInversiones').DataTable({
			dom: "<'row mb-3'<'col-md-4 mb-3 mb-md-0'l><'col-md-8 text-end'<'d-lg-flex justify-content-end'f<'d-lg-block d-none'B>>>>t<'row align-items-center'<'mr-auto col-md-6 mb-3 mb-md-0 mt-n2 'i><'mb-0 col-md-6'p>>",
			lengthMenu: [10, 20, 30, 40, 50],
			buttons: [
				{ extend: 'print', className: 'btn btn-outline-default btn-sm ms-2' },
				{ extend: 'csv', className: 'btn btn-outline-default btn-sm' }
			],
			columns: [
				{ 
					title: "#",
					width: "20px" 
				},
				{ title: "Paquete"},
				{ 
                    title: "Total de invesiones",
                    width: "150px",
					render: function (data, type, row, meta) {
                        let number = row[2]?.replace(/,/g, '') || 0;
                        return `<div style="white-space: nowrap;">${formatoMoneda.format(parseFloat(number))}</div>`;
                    }
                },
				{ 
                    title: "Inversiones Mensuales",
                    width: "150px",
					render: function (data, type, row, meta) {
                        let number = row[3]?.replace(/,/g, '') || 0;
                        return `<div style="white-space: nowrap;">${formatoMoneda.format(parseFloat(number))}</div>`;
                    }
                },
				{ 
					title: "Estatus",
					render: function (data, type, row, meta) {
                        let estatus = row[4];
						let estado = "";
						if(estatus=="0"){
							estado = "Abierto";
						} else if(estatus=="1"){
							estado = "En progreso"
						} else if(estatus=="2"){
							estado = "En progreso";
						} else if(estatus=="3"){ 
							estado = "En progreso";
						} else if(estatus=="4"){
							estado = "Anticipo entregado";
						} else if(estatus=="5"){
							estado = "FG entregado";
						} else if(estatus=="6"){
							estado = "Cerrrado";
						}
						return `<div style="white-space: nowrap;">${estado}</div>`;
                    }
				},    
			]
		});
	}

	const initDatatableInversionesIncon = () => {
		datatableInversionesInconRef.current = $('#tableInversionesIncon').DataTable({
			dom: "<'row mb-3'<'col-md-4 mb-3 mb-md-0'l><'col-md-8 text-end'<'d-lg-flex justify-content-end'f<'d-lg-block d-none'B>>>>t<'row align-items-center'<'mr-auto col-md-6 mb-3 mb-md-0 mt-n2 'i><'mb-0 col-md-6'p>>",
			lengthMenu: [10, 20, 30, 40, 50],
			buttons: [
				{ extend: 'print', className: 'btn btn-outline-default btn-sm ms-2' },
				{ extend: 'csv', className: 'btn btn-outline-default btn-sm' }
			],
			columns: [
				{ 
					title: "#",
					width: "20px" 
				},
				{ title: "Derechohabiente"},
				{ 
                    title: "Total de invesiones",
                    width: "150px"
                },
				{ 
					title: "Estatus",
					render: function(data, type, row, meta ){
                        if (row[3] == 1) {
                            return'<input type="button" class="btn btn-warning" name="liberarFDH" value="Inversion liberado" disabled="true" >'
                        }else{
                            return'<input type="button" class="btn btn-success" name="liberarFDH" value="Liberar inversiones">'
                        }
                    }
				},    
			]
		});
	}

	

	const liberarFI = async () => {
		Swal.fire({
			title: "Ingresa una tasa de interés:",
            icon: "info",
			input: "text",
			confirmButtonText: 'Continuar',
			cancelButtonText: 'Cancelar',
			showCancelButton: true,
			showCloseButton: true,
			allowOutsideClick: false,
			allowEscapeKey: false,
			allowEnterKey: false,
			reverseButtons : true,
			showLoaderOnConfirm: true,
			preConfirm: async (por_tasa) => {
				try {
					let data = {
						etapa_id : selectedEtapa,
						por_tasa_inversion: por_tasa,
					}
					const response = await updateTasaInversion(tasa.id, data, token);
				} catch (error) {
					Swal.showValidationMessage(`
						Request failed: ${error}
					`);
				}
			},
			allowOutsideClick: () => !Swal.isLoading()
		}).then((result) => {
			if (result.isConfirmed) {
				alert(`Actualizacion correcta`, "", "success");
				fetchTasaInversionByEtapaId(selectedEtapa);
			}
		});
	}

	return (
		<div>
			<Breadcrumb title={props.title} subtitle={props.subtitle}/>
			<div className="row justify-content-center">
				<hr className="mb-4" />
				<div className="col-xl-10">
					<div className="row">
						<div className="col-xl-12">
							<div id="formControls" className="mb-5">  
								<Card>
									<CardHeader className="fw-bold small d-flex">
										<span className="flex-grow-1"></span>
									</CardHeader>
									<CardBody>
										<div className='row'>
											<div className='col-3'>
												<SelectProyectos setSharedProyectoId={setSharedProyectoId} setSharedEtapaId={setSharedEtapaId}  />
											</div>
											<div className='col-3'>
												<SelectEtapas  proyecto_id={sharedProyectoId} setSharedEtapaId={setSharedEtapaId} setSharedPaqueteId={setSharedPaqueteId} />
											</div>
											<div className="col-2">
												<div className="form-group mb-3">
													<label className="form-label" htmlFor="etapas" ><strong>Tasa de inversión</strong></label>
													<input id="tasaInversionFI" type="text" className="form-control" name="tasaInversionFI" placeholder="Ingresa % de Tasa de Inv." disabled />
												</div>
											</div>
											<div className="col-2">
												<div className="form-group mb-3">
													<label className="form-label" htmlFor="etapas" ><strong>Total de inversión a la fecha</strong></label>
													<input type="text" id="totalInversionFI" className="form-control" value={formatoMoneda.format(parseFloat(totalInversiones))} disabled={true} />	
												</div>
											</div>
											{ showButtonLiberar ?
												<div className="col-2" style={{"textAlign": "center", "marginTop": "2em"}}>
													<div className="form-group mb-3">
														<button className={buttonLibearFI.type} id="liberarFI" name="liberarFI" onClick={liberarFI} disabled={buttonLibearFI.disabled}>{buttonLibearFI.text}</button>	
													</div>
												</div> : <></>
											}
										</div>
									</CardBody>
								</Card>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-xl-12">
							<div id="formControls" className="mb-5">  
								<Card>
									<CardHeader className="fw-bold small d-flex">
										<span className="flex-grow-1">Detalle de Cálculo</span>
										{ showTasaInversion ?
											<span className="flex-grow-2" style={{"float": "right"}}>Tasa de inversión utilizada: {tasaInversion}</span> : <></>
										}		
									</CardHeader>
									<CardBody>
										<div className='row'>
											<div className='col-12 tabla-scrollable table-responsive'>
												<table id="tableInversiones" className="table  table-striped text-nowrap w-100">
													<thead></thead>
													<tbody></tbody>
												</table>
											</div>
										</div>	
									</CardBody>
								</Card>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-xl-12">
							<div id="formControls" className="mb-5">  
								<Card>
									<CardHeader className="fw-bold small d-flex">
										<span className="flex-grow-1">Detalle de Cálculo Inconsistencias</span>
									</CardHeader>
									<CardBody>
										<div className='row'>
											<div className='col-12 tabla-scrollable table-responsive'>
												<table id="tableInversionesIncon" className="table table-striped text-nowrap w-100">
													<thead></thead>
													<tbody></tbody>
												</table>
											</div>
										</div>	
									</CardBody>
								</Card>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default FondoInversion;