import React, { useEffect, useState, useRef } from 'react';
import useAuthorization from '../../../../hooks/useAuthorization';

import Breadcrumb from '../../../../components/breadcrumb/breadcrumb';
import ObraTecnico from './roles/tecnico/tecnico';
import ObraAdministracion from './roles/administracion/administracion';
import { Card, CardBody } from '../../../../components/card/card';

function Obra(props) {
	const userRole = useAuthorization();

    const [showObraTecnico, setShowObraTecnico] = useState(false);
    const [showObraAdministrador, setShowObraAdministrador] = useState(false);
    const [showObraMaster, setShowObraMaster] = useState(false);


    useEffect(() => {
        if(userRole.length > 0) {
			if (['tecnico'].some(role => userRole.includes(role))) {
                setShowObraTecnico(true);
                setShowObraAdministrador(false);
                setShowObraMaster(false);
            } else if (['administracion'].some(role => userRole.includes(role))) {
                setShowObraTecnico(false);
                setShowObraAdministrador(true);
                setShowObraMaster(false);
            } else if (['master'].some(role => userRole.includes(role))) {
                setShowObraTecnico(false);
                setShowObraAdministrador(false);
                setShowObraMaster(true);
            }
        }
    }, [userRole]);

	return (
		<div>
			<Breadcrumb title={props.title} subtitle={props.subtitle}/>
            { showObraTecnico && (
                <ObraTecnico  />
            )}
            { showObraAdministrador && (
                <ObraAdministracion  />
            )}
             { showObraMaster && (
                
                        <>
                            <ul className="nav nav-tabs pt-3 ps-4 pe-4">
                                <li className="nav-item me-3"><a href="#tabObraAdmin" className="nav-link active" data-bs-toggle="tab">Administración</a></li>
                                <li className="nav-item me-3"><a href="#tabObraTecnico" className="nav-link" data-bs-toggle="tab">Tecnico</a></li>
                            </ul>
                            <div className="tab-content p-4">
                                <div className="tab-pane fade show active" id="tabObraAdmin">
                                    <ObraAdministracion  />
                                </div>
                                <div className="tab-pane fade" id="tabObraTecnico">
                                    <ObraTecnico  />
                                </div>
                            </div>
                        </>
                   
            )}
        </div>
	)
}

export default Obra;
