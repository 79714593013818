import React from 'react';

import { Card, CardBody } from './../../components/card/card.jsx';

function PagesComingSoon() {
	
  
	return (
		<div className="error-page" style={{display:'block'}}>
			<div className="error-page-content">
				<Card className="mb-5 mx-auto">
					<CardBody>
						<Card>
							<div className="error-code">Proximamente</div>
						</Card>
					</CardBody>
				</Card>
			</div>
		</div>
	)
}

export default PagesComingSoon;