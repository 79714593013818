import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { uploadFiles } from '../../api/otros/otros';
import { errorNotification, successNotification } from '../../helpers';

function UploadFile({ type, paquete_id, onSubmitFile, disabled } ) {
    const token = useSelector(state => state.authentication.user?.token);

    const [title, setTitle] = useState("");
    const [file, setFile] = useState(null);

    useEffect(() => {
        if(type == 1) 
            setTitle("Adjuntar Documento");
        else if(type == 2)
            setTitle("Subir");
        else if(type == 3)
            setTitle("Adjuntar Documento de Avance");
    }, [type]);

    const fetchUploadFiles = async (form, newFileName) => {
		try {
			const response = await uploadFiles(form, token);
			if (response.message === 'success') {
				successNotification("Archivo cargado correctamente.")
				setFile(null);
				let data = {
					filepath : newFileName,
					paquete_id: paquete_id
				}
				onSubmitFile(paquete_id, data);
			} else {
				errorNotification("No es posible subir el archivo.");
			}
		} catch (error) {
			errorNotification("Ocurrio un error en el servidor.");
		}
	}

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];

		if (selectedFile) {
			const fileName = selectedFile.name.toLowerCase();
			if (fileName.endsWith('.pdf')) {
				setFile(selectedFile);
			} else {
				errorNotification('Formato de archivo no válido. Por favor, selecciona un archivo PDF.');
			}
		}
    };
    
    const handleUpload = async () => {
        if (file) {
			const timestamp = Date.now();
			const form = new FormData();
			const newFileName = `Admin(${paquete_id})-${timestamp}-${file.name}`;
            form.append('uploaded_file', file, newFileName);
            
			fetchUploadFiles(form, newFileName);
        } else {
            errorNotification("Es necesario seleccionar un archivo.");
        }
    };

    return (
        <div className='row'>
            <div className='col-8 text-center' >
                <label className="form-label" htmlFor="submitFile" ><strong>{title}</strong></label>
                <input type="file" className="form-control" id="submitFile"  onChange={handleFileChange} disabled={disabled} />
            </div>
            <div className='col-4' >
                <button className="btn btn-outline-default"  style={{marginTop:"2em"}} onClick={handleUpload} disabled={disabled} >
                    <i className="fas fa-cloud-upload-alt" style={{ marginRight: "0.5em" }}></i>Subir
                </button>
            </div>
        </div>
    )
}

export default UploadFile;