import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useAuthorization from '../../../../hooks/useAuthorization';

import Breadcrumb from '../../../../components/breadcrumb/breadcrumb';

import GarantiaTecnico from './roles/tecnico';
import GarantiaAdministracion from './roles/administracion';


function Anticipos(props) {
	const token = useSelector(state => state.authentication.user?.token);
    const userRole = useAuthorization();

    const [showGarantiaTecnico, setShowGarantiaTecnico] = useState(false);
    const [showGarantiaAdministrador, setShowGarantiaAdministrador] = useState(false);
    const [showGarantiaMaster, setShowGarantiaMaster] = useState(false);


	useEffect(() => {
        if(userRole.length > 0) {
            if (['tecnico'].some(role => userRole.includes(role))) {
                setShowGarantiaTecnico(true);
                setShowGarantiaAdministrador(false);
                setShowGarantiaMaster(false);
            } else if (['administracion'].some(role => userRole.includes(role))) {
                setShowGarantiaTecnico(false);
                setShowGarantiaAdministrador(true);
                setShowGarantiaMaster(false);
            } else if (['master'].some(role => userRole.includes(role))) {
                setShowGarantiaTecnico(false);
                setShowGarantiaAdministrador(false);
                setShowGarantiaMaster(true);
            }
        }
    }, [userRole])

	return (
		<div>
			<Breadcrumb title={props.title} subtitle={props.subtitle}/>
			{ showGarantiaTecnico && (
                <GarantiaTecnico  />
            )}
            { showGarantiaAdministrador && (
                <GarantiaAdministracion  />
            )}
            { showGarantiaMaster && (
                
                <>
                    <ul className="nav nav-tabs pt-3 ps-4 pe-4">
                        <li className="nav-item me-3"><a href="#tabGarantiaAdmin" className="nav-link active" data-bs-toggle="tab">Administración</a></li>
                        <li className="nav-item me-3"><a href="#tabGarantiaTecnico" className="nav-link" data-bs-toggle="tab">Tecnico</a></li>
                    </ul>
                    <div className="tab-content p-4">
                        <div className="tab-pane fade show active" id="tabGarantiaAdmin">
                            <GarantiaAdministracion  />
                        </div>
                        <div className="tab-pane fade" id="tabGarantiaTecnico">
                            <GarantiaTecnico  />
                        </div>
                    </div>
                </>
           
    )}
		</div>
	)
}

export default Anticipos;