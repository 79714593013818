import React, { useEffect, useState,  } from 'react';
import { useSelector } from 'react-redux';
import useAuthorization from '../../../hooks/useAuthorization.js';

import Breadcrumb from '../../../components/breadcrumb/breadcrumb';
import { Card, CardBody, CardHeader } from '../../../components/card/card.jsx';

import { 
	getDerechohabienteMinistracionReporteAdminByPaqueteId, 
	getDerechohabienteMinistracionReporteTecnicoByPaqueteId
} from '../../../api/derechohabiente/derechohabiente.js';
import { formatoMoneda, serverUrl } from '../../../helpers/utils.js';

import SelectProyectos from '../../../components/select/proyectos.js';
import SelectEtapas from '../../../components/select/etapas.js';
import SelectPaquetes from '../../../components/select/paquetes.js';


function ReportesPaquetes(props) {
	const token = useSelector(state => state.authentication.user?.token);
	const userRole = useAuthorization();
    
	const [sharedProyectoId, setSharedProyectoId] = useState(0);
	const [sharedEtapaId, setSharedEtapaId] = useState(0);
	const [sharedPaqueteId, setSharedPaqueteId] = useState(0);

	const [tabla, setTabla] = useState(null);
	const [body, setBody] = useState(null);
	const [file, setFile] = useState("");
	const [showButton, setShowButton] = useState(false);

	useEffect(() => {
		if(sharedProyectoId == 0) {
			setBody(null);
			setTabla(null);
			setFile("");
			setShowButton(false);
		}
	}, [sharedProyectoId])

	useEffect(() => {
		if(sharedPaqueteId > 0) {
			setBody(null);
			setTabla(null);
			setFile("");
			setShowButton(false);
			if (['tecnico', 'master'].some(role => userRole.includes(role))) {
                fetchDerechohabienteMinistracionReporteTecnicoByPaqueteId(sharedPaqueteId);
            } else if (['administracion'].some(role => userRole.includes(role))) {
				fetchDerechohabienteMinistracionReporteAdminByPaqueteId(sharedPaqueteId);
            }
			
		} else {
			setBody(null);
			setTabla(null);
			setFile("");
			setShowButton(false);
		}
	}, [sharedPaqueteId]);

	const fetchDerechohabienteMinistracionReporteAdminByPaqueteId = async (paquete_id) => {
		try {
			const res = await getDerechohabienteMinistracionReporteAdminByPaqueteId(paquete_id, token);
			
			const response = res.message;
			setFile(res.file);
			setShowButton(true);

			let ministraciones = response.derechohabientes[0].ministraciones;
			let tabla = [];
			let body = [];

			if (response.derechohabientes.length > 0) {
				let x = response.derechohabientes[0].ministraciones.length;
				let i = 1;
				let min = [];

				while (i <= x) {
					if (i % 2 == 0) {
						min.push(<th colSpan='6' style={{ 'color': 'black', 'textAlign': 'center', 'border': '1px solid gray', 'backgroundColor': '#c0eaf3'}}>Ministración  {i}</th>);
					} else {
						min.push(<th colSpan='6' style={{'color': 'black', 'textAlign': 'center', 'border': '1px solid gray', 'backgroundColor': '#f2efeb'}}>Ministración  {i}</th>);
					}
					i++;
				}

				tabla = (
					<thead style={{}}>
						<tr style={{'textAlign': 'center'}}>
							<th colSpan='13' style={{ 'color': 'black','textAlign': 'center', 'backgroundColor': '#c0eaf3', 'border': '1px solid gray'}}>Datos del DerechoHabiente</th>
							<th colSpan='5' style={{'color': 'black','textAlign': 'center', 'backgroundColor': '#a8fac6', 'border': '1px solid gray'}}>Cálculo del anticipo</th>
							<th colSpan='5' style={{'color': 'black','textAlign': 'center', 'backgroundColor': '#f9d096', 'border': '1px solid gray'}}>Cálculo de comisiones</th>
							{min}
						</tr>
						<tr>
							<th >Acreditado</th>
							<th >N° Crédito</th>
							<th >NSS</th>
							<th >Calle</th>
							<th >Mza.</th>
							<th >Lote</th>
							<th >Num Ext.</th>
							<th >Num Int.</th>
							<th >M<sup>2</sup> Terreno Vendible</th>
							<th >Precio alzado</th>
							<th >Monto de crédito</th>
							<th >Subsidio</th>
							<th >Total crédito</th>
							<th >Valor del terreno</th>
							<th >Monto anticipo</th>
							<th >Fondo de Garantía (10%)</th>
							<th >Anticipo Neto para constructor</th>
							<th >Saldo del proyecto</th>
							<th >Comisión por Admon. de Fondos y Verificación de Obra</th>
							<th >Comisión Mercantil</th>
							<th >Suma Comisiones</th>
							<th >IVA</th>
							<th >Total comisiones</th>
							{
								ministraciones.length > 0 ? (
									ministraciones.map((ministracion, i) => (
										<>
											<th >Avance acumulado</th>
											<th >Avance parcial</th>
											<th >Ministración  {i + 1}</th>
											<th >Retención 10% Fondo de Garantía</th>
											<th >Comisión Mercantil y por Admon</th>
											<th >Ministración Neta</th>
										</>
									)) 
								) : <></>
								
							}
						</tr>
					</thead>	
				)

				body = (
					<tbody>
						{
							response.derechohabientes.length > 0 ? (
								response.derechohabientes.map((derechohabiente, i) => (
									<tr>
										<td>{derechohabiente.nombre}</td>
										<td>{derechohabiente.num_credito}</td>
										<td>{derechohabiente.num_ssocial}</td>
										<td>{derechohabiente.calle}</td>
										<td>{derechohabiente.manzana}</td>
										<td>{derechohabiente.lote}</td>
										<td>{derechohabiente.num_exterior}</td>
										<td>{derechohabiente.num_interior}</td>
										<td>{derechohabiente.valor_metros2_terreno_vendible}</td>
										<td>{formatoMoneda.format(parseFloat(derechohabiente.precio_alzado.replace(/,/g, '')))}</td>
										<td>{formatoMoneda.format(parseFloat(derechohabiente.monto_ministracion.replace(/,/g, '')))}</td>
										<td>{formatoMoneda.format(parseFloat(derechohabiente.monto_subsidio.replace(/,/g, '')))}</td>
										<td>{formatoMoneda.format(parseFloat(derechohabiente.total_credito.replace(/,/g, '')))}</td>
										<td>{
												formatoMoneda.format(
													parseFloat(response.valor_terreno.val_tierra_m2) * parseFloat(derechohabiente.valor_metros2_terreno_vendible)
												)
											}
										</td>
										<td>{
												formatoMoneda.format(
													(parseFloat(response.valor_terreno.val_tierra_m2) * parseFloat(derechohabiente.valor_metros2_terreno_vendible)) * 0.8
												)
											}
										</td>
										<td>{
												formatoMoneda.format(
													((parseFloat(response.valor_terreno.val_tierra_m2) * parseFloat(derechohabiente.valor_metros2_terreno_vendible)) * 0.8) * 0.1
												)
											}
										</td>
										<td>{	
												formatoMoneda.format(
													((parseFloat(response.valor_terreno.val_tierra_m2) * parseFloat(derechohabiente.valor_metros2_terreno_vendible)) * 0.8) -
													(((parseFloat(response.valor_terreno.val_tierra_m2) * parseFloat(derechohabiente.valor_metros2_terreno_vendible)) * 0.8) * 0.1)
												)
											}
										</td>
										<td>{
												formatoMoneda.format(
													parseFloat(derechohabiente.total_credito) - ((parseFloat(response.valor_terreno.val_tierra_m2) * parseFloat(derechohabiente.valor_metros2_terreno_vendible)) * 0.8)
												)
											}
										</td>
										<td>{
												formatoMoneda.format(
													((parseFloat(derechohabiente.precio_alzado)) -
													(parseFloat(response.valor_terreno.val_tierra_m2) * parseFloat(derechohabiente.valor_metros2_terreno_vendible))) *
													(parseFloat(response.valor_terreno.por_comision_obra) / 100)
												)
											}
										</td>
										<td>{
												formatoMoneda.format(
													((parseFloat(derechohabiente.total_credito)) -
													(parseFloat(response.valor_terreno.val_tierra_m2) * parseFloat(derechohabiente.valor_metros2_terreno_vendible))) *
													(parseFloat(response.valor_terreno.por_comision_mercantil) / 100)
												)
											}
										</td>
										<td>{
												formatoMoneda.format(
													(((parseFloat(derechohabiente.precio_alzado)) -
													(parseFloat(response.valor_terreno.val_tierra_m2) * parseFloat(derechohabiente.valor_metros2_terreno_vendible))) *
													(parseFloat(response.valor_terreno.por_comision_obra) / 100)) + 
													(((parseFloat(derechohabiente.total_credito)) -
													(parseFloat(response.valor_terreno.val_tierra_m2) * parseFloat(derechohabiente.valor_metros2_terreno_vendible))) *
													(parseFloat(response.valor_terreno.por_comision_mercantil) / 100))
												)
											}
										</td>
										<td>
											{
												formatoMoneda.format(
													((((parseFloat(derechohabiente.precio_alzado)) -
													(parseFloat(response.valor_terreno.val_tierra_m2) * parseFloat(derechohabiente.valor_metros2_terreno_vendible))) *
													(parseFloat(response.valor_terreno.por_comision_obra) / 100)) + 
													(((parseFloat(derechohabiente.total_credito)) -
													(parseFloat(response.valor_terreno.val_tierra_m2) * parseFloat(derechohabiente.valor_metros2_terreno_vendible))) *
													(parseFloat(response.valor_terreno.por_comision_mercantil) / 100))) * 0.16
												)
											}
										</td>
										<td>
											{
												formatoMoneda.format(
													((((parseFloat(derechohabiente.precio_alzado)) -
													(parseFloat(response.valor_terreno.val_tierra_m2) * parseFloat(derechohabiente.valor_metros2_terreno_vendible))) *
													(parseFloat(response.valor_terreno.por_comision_obra) / 100)) + 
													(((parseFloat(derechohabiente.total_credito)) -
													(parseFloat(response.valor_terreno.val_tierra_m2) * parseFloat(derechohabiente.valor_metros2_terreno_vendible))) *
													(parseFloat(response.valor_terreno.por_comision_mercantil) / 100))) +
													(((((parseFloat(derechohabiente.precio_alzado)) -
													(parseFloat(response.valor_terreno.val_tierra_m2) * parseFloat(derechohabiente.valor_metros2_terreno_vendible))) *
													(parseFloat(response.valor_terreno.por_comision_obra) / 100)) + 
													(((parseFloat(derechohabiente.total_credito)) -
													(parseFloat(response.valor_terreno.val_tierra_m2) * parseFloat(derechohabiente.valor_metros2_terreno_vendible))) *
													(parseFloat(response.valor_terreno.por_comision_mercantil) / 100))) * 0.16)
												)
											}
										</td>
										
										{
											derechohabiente.ministraciones.length > 0 ? (
												derechohabiente.ministraciones.map((ministracion, i) => (
													<>
														<td>{(parseFloat(ministracion.por_avance_acomulado) * 100).toFixed(2)}%</td>
														<td>{(parseFloat(ministracion.por_avance_parcial) * 100).toFixed(2)}%</td>
														<td>{formatoMoneda.format(parseFloat(ministracion.val_ministracion))}</td>
														<td>{formatoMoneda.format(parseFloat(ministracion.val_retencion_garantia))}</td>
														<td>{formatoMoneda.format(parseFloat(ministracion.val_comision_mercantil_administracion))}</td>
														<td>{formatoMoneda.format(parseFloat(ministracion.val_ministracion_neta))}</td>
													</>
												))
											) : <></>
										}
										
									</tr>
								)) 
							) : <></>
						}
						
					</tbody>
			
				)

			}
			setTabla(tabla);
			setBody(body)
		} catch (error) {
			console.error('Error al cargar los datos', error);
		}
	};

	const fetchDerechohabienteMinistracionReporteTecnicoByPaqueteId = async (paquete_id) => {
		try {
			const res = await getDerechohabienteMinistracionReporteTecnicoByPaqueteId(paquete_id, token);
			
			const response = res.message;
			setFile(res.file);
			setShowButton(true);

			let ministraciones = response.derechohabientes[0].ministraciones;
			let tabla = [];
			let body = [];

			if (response.derechohabientes.length > 0) {
				let x = response.derechohabientes[0].ministraciones.length;
				let i = 1;
				let min = [];

				while (i <= x) {
					if (i % 2 == 0) {
						min.push(<th colSpan='6' style={{ 'color': 'black', 'textAlign': 'center', 'border': '1px solid gray', 'backgroundColor': '#c0eaf3'}}>Ministración  {i}</th>);
					} else {
						min.push(<th colSpan='6' style={{'color': 'black', 'textAlign': 'center', 'border': '1px solid gray', 'backgroundColor': '#f2efeb'}}>Ministración  {i}</th>);
					}
					i++;
				}

				tabla = (
					<thead style={{}}>
						<tr style={{'textAlign': 'center'}}>
							<th colSpan='13' style={{ 'color': 'black','textAlign': 'center', 'backgroundColor': '#c0eaf3', 'border': '1px solid gray'}}>Datos del DerechoHabiente</th>
							<th colSpan='5' style={{'color': 'black','textAlign': 'center', 'backgroundColor': '#a8fac6', 'border': '1px solid gray'}}>Cálculo del anticipo</th>
							{min}
						</tr>
						<tr>
							<th >Acreditado</th>
							<th >N° Crédito</th>
							<th >NSS</th>
							<th >Calle</th>
							<th >Mza.</th>
							<th >Lote</th>
							<th >Num Ext.</th>
							<th >Num Int.</th>
							<th >M<sup>2</sup> Terreno Vendible</th>
							<th >Precio alzado</th>
							<th >Monto de crédito</th>
							<th >Subsidio</th>
							<th >Total crédito</th>
							<th >Valor del terreno</th>
							<th >Monto anticipo</th>
							<th >Fondo de Garantía (10%)</th>
							<th >Anticipo Neto para constructor</th>
							<th >Saldo del proyecto</th>
							{
								ministraciones.length > 0 ? (
									ministraciones.map((ministracion, i) => (
										<>
											<th >Avance acumulado</th>
											<th >Avance parcial</th>
											<th >Ministración  {i + 1}</th>
											<th >Retención 10% Fondo de Garantía</th>
											<th >Comisión Mercantil y por Admon</th>
											<th >Ministración Neta</th>
										</>
									)) 
								) : <></>
								
							}
						</tr>
					</thead>	
				)

				body = (
					<tbody>
						{
							response.derechohabientes.length > 0 ? (
								response.derechohabientes.map((derechohabiente, i) => (
									<tr>
										<td>{derechohabiente.nombre}</td>
										<td>{derechohabiente.num_credito}</td>
										<td>{derechohabiente.num_ssocial}</td>
										<td>{derechohabiente.calle}</td>
										<td>{derechohabiente.manzana}</td>
										<td>{derechohabiente.lote}</td>
										<td>{derechohabiente.num_exterior}</td>
										<td>{derechohabiente.num_interior}</td>
										<td>{derechohabiente.valor_metros2_terreno_vendible}</td>
										<td>{formatoMoneda.format(parseFloat(derechohabiente.precio_alzado.replace(/,/g, '')))}</td>
										<td>{formatoMoneda.format(parseFloat(derechohabiente.monto_ministracion.replace(/,/g, '')))}</td>
										<td>{formatoMoneda.format(parseFloat(derechohabiente.monto_subsidio.replace(/,/g, '')))}</td>
										<td>{formatoMoneda.format(parseFloat(derechohabiente.total_credito.replace(/,/g, '')))}</td>
										<td>{
												formatoMoneda.format(
													parseFloat(response.valor_terreno.val_tierra_m2) * parseFloat(derechohabiente.valor_metros2_terreno_vendible)
												)
											}
										</td>
										<td>{
												formatoMoneda.format(
													(parseFloat(response.valor_terreno.val_tierra_m2) * parseFloat(derechohabiente.valor_metros2_terreno_vendible)) * 0.8
												)
											}
										</td>
										<td>{
												formatoMoneda.format(
													((parseFloat(response.valor_terreno.val_tierra_m2) * parseFloat(derechohabiente.valor_metros2_terreno_vendible)) * 0.8) * 0.1
												)
											}
										</td>
										<td>{	
												formatoMoneda.format(
													((parseFloat(response.valor_terreno.val_tierra_m2) * parseFloat(derechohabiente.valor_metros2_terreno_vendible)) * 0.8) -
													(((parseFloat(response.valor_terreno.val_tierra_m2) * parseFloat(derechohabiente.valor_metros2_terreno_vendible)) * 0.8) * 0.1)
												)
											}
										</td>
										<td>{
												formatoMoneda.format(
													parseFloat(derechohabiente.total_credito) - ((parseFloat(response.valor_terreno.val_tierra_m2) * parseFloat(derechohabiente.valor_metros2_terreno_vendible)) * 0.8)
												)
											}
										</td>	
										{
											derechohabiente.ministraciones.length > 0 ? (
												derechohabiente.ministraciones.map((ministracion, i) => (
													<>
														<td>{(parseFloat(ministracion.por_avance_acomulado) * 100).toFixed(2)}%</td>
														<td>{(parseFloat(ministracion.por_avance_parcial) * 100).toFixed(2)}%</td>
														<td>{formatoMoneda.format(parseFloat(ministracion.val_ministracion))}</td>
														<td>{formatoMoneda.format(parseFloat(ministracion.val_retencion_garantia))}</td>
														<td>{formatoMoneda.format(parseFloat(ministracion.val_comision_mercantil_administracion))}</td>
														<td>{formatoMoneda.format(parseFloat(ministracion.val_ministracion_neta))}</td>
													</>
												))
											) : <></>
										}
										
									</tr>
								)) 
							) : <></>
						}
						
					</tbody>
			
				)

			}
			setTabla(tabla);
			setBody(body)
		} catch (error) {
			console.error('Error al cargar los datos', error);
		}
	};

	return (
		<div>
			<Breadcrumb title={props.title} subtitle={props.subtitle}/>
			<div className="row justify-content-center">
				<hr className="mb-4" />
				<div className="col-xl-10">
					<div className="row">
						<div className="col-xl-12">
							<div id="formControls" className="mb-5">  
								<Card>
									<CardHeader className="fw-bold small d-flex">
										<span className="flex-grow-1">Reporte de ministraciones por paquetes</span>
									</CardHeader>
									<CardBody>
										<div className='col-xl-12'>
											<div className='row'>
												<div className='col-4'>
													<SelectProyectos setSharedProyectoId={setSharedProyectoId} setSharedEtapaId={setSharedEtapaId}  />
												</div>
												<div className='col-4'>
													<SelectEtapas proyecto_id={sharedProyectoId} setSharedEtapaId={setSharedEtapaId} setSharedPaqueteId={setSharedPaqueteId} />
												</div>
												<div className='col-4'>
													<SelectPaquetes etapa_id={sharedEtapaId} setSharedPaqueteId={setSharedPaqueteId}  />
												</div>
											</div>
										</div>
										<hr className="mb-4" />
										<div className="list-group-item d-flex align-items-center" style={{marginBottom: '1em', marginTop: '-0.5em'}}>
											<div className="flex-1 text-break"></div>
											{
												showButton ?
													<div className="ml-auto">
														<a href={serverUrl + "/file/download/" + file} target="_blank" className="btn btn-outline-default">Descargar Reporte</a>
													</div> : <></>
											}
										</div>
										<div className='row tabla-scrollable'>
											<div className='col-12 tabla-scrollable table-responsive'>
												<table id="tableReportePaquete" className="table text-nowrap w-100 table-derechohabientes">
													{tabla}
													{body}
												</table>
											</div>
										</div>	
									</CardBody>
								</Card>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ReportesPaquetes;