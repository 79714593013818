import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import useAuthorization from '../../../../hooks/useAuthorization';

import Breadcrumb from '../../../../components/breadcrumb/breadcrumb';
import { Card, CardBody, CardHeader } from '../../../../components/card/card';
import Swal from 'sweetalert2';
import { Modal } from 'bootstrap'

import { getPaqueteAnticiposById, getPaqueteAnticipoTecnicoById, createPaqueteAnticipoArchivos,
			updatePaqueteAnticipoTecnicoById, getPaqueteAnticipoArchivosById, getPaqueteById } from '../../../../api/paquete_proyectos/paquete_proyectos';

import { uploadFiles } from '../../../../api/otros/otros';

import { alert, errorNotification, formatoMoneda, serverUrl, successNotification } from '../../../../helpers';
import ModalParamentros from './modals/parametros';
import SelectProyectos from '../../../../components/select/proyectos';
import SelectEtapas from '../../../../components/select/etapas';
import SelectPaquetes from '../../../../components/select/paquetes';
import { getProyectoById } from '../../../../api/proyectos/proyectos';

const $ = require('jquery');

function AutorizarAnticipos(props) {
	const token = useSelector(state => state.authentication.user?.token);

	let tableAnticipoRef = useRef(null);

    const userRole = useAuthorization()

	const [sharedProyectoId, setSharedProyectoId] = useState(0);
	const [sharedEtapaId, setSharedEtapaId] = useState(0);
	const [sharedPaqueteId, setSharedPaqueteId] = useState(0);

	const [formData, setFormData] = useState({});

	const [proyectoName, setProyectoName] = useState("");
	const [paqueteName, setPaqueteName] = useState("");

	const [showUplaodFile, setShowUploadFile] = useState(false);
	const [enableSendReport, setEnableSendReport] = useState(false);
		
	const [anticipos, setAnticipos] =  useState([]);

	const [documento, setDocumento] =  useState("");
	const [file, setFile] = useState(null);

	const [isModalOpen, setIsModalOpen] = useState(false);
    const [activeModal, setActiveModal] = useState("");

	useEffect(() => {
		if(sharedProyectoId == 0) {
			setSharedEtapaId(0);
			setSharedPaqueteId(0);
			setAnticipos([]);
        } else {
			fetchProyectoById(sharedProyectoId);
		}
	}, [sharedProyectoId]);


	useEffect(() => {
		if(sharedPaqueteId > 0) {
			fetchPaquetesAnticiposById(sharedPaqueteId);
			fetchPaqueteById(sharedPaqueteId);
		} 
	}, [sharedPaqueteId]);


	useEffect(() => {
		if(isModalOpen && activeModal === 'Parametros') {
			let myModal = new Modal(document.getElementById('modalParametros'));
			myModal.show();
		} 
	}, [isModalOpen, activeModal]);


	const fetchProyectoById = async (proyecto_id) => {
		try {
			const response = await getProyectoById(proyecto_id, token);
			setProyectoName(response.nombre);
		} catch (error) {
			console.error('Error al cargar los inconsistencias', error);
		}
	};


	const fetchPaqueteById = async (paquete_id) => {
		try {
			const response = await getPaqueteById(paquete_id, token);
			setPaqueteName(response.nombre);
		} catch (error) {
			console.error('Error al cargar los inconsistencias', error);
		}
	};


	const fetchPaquetesAnticiposById = async (paquete_id) => {
		try {
			const response = await getPaqueteAnticiposById(paquete_id, token);
			setEnableSendReport(response[0].estatus_paquete >= 4 ? false : true);
			setAnticipos(response);
			setShowUploadFile(true);
			fetchPaqueteAnticipoTecnicoById(paquete_id);
		} catch (error) {
			setShowUploadFile(false)
			setAnticipos([]);
			console.error('Error al cargar los datos', error);
		}
	};
	

	const fetchPaqueteAnticipoArchivosById = async (paquete_id) => {
		try {
			const response = await getPaqueteAnticipoArchivosById(paquete_id, token);
			if(response.message !== 'no found') {
				actionSendReport(response.message)
			}
		} catch (error) {
			setShowUploadFile(false)
			setAnticipos([]);
			console.error('Error al cargar los datos', error);
		}
	};


	const fetchCreatePaqueteAnticipoArchivos = async (data) => {
		try {
			const response = await createPaqueteAnticipoArchivos(data, token);
			if(response.message === 'success') {
				alert("", "Documentos enviados correctamente.", "success");
			}
		} catch (error) {
			setShowUploadFile(false)
			setAnticipos([]);
			console.error('Error al cargar los datos', error);
		}
	};


	const fetchPaqueteAnticipoTecnicoById = async (paquete_id) => {
		try {
			const response = await getPaqueteAnticipoTecnicoById(paquete_id, token);
			if(response.message === 'no found') {
				setDocumento("Sin Documento");
			} else {
				setDocumento(response.message);
			}
		} catch (error) {
			console.error('Error al cargar los datos', error);
		}
	};

	const fetchUpdatePaqueteAnticipoTecnicoById = async (paquete_id, data) => {
        try {
            const response = await updatePaqueteAnticipoTecnicoById(paquete_id, data, token);
            if(response.message === 'success') {
				fetchPaquetesAnticiposById(paquete_id);
			}
        } catch (error) {
            errorNotification("Ocurrio un error en el servidor.");
        }
    }

	useEffect(() => {
		if (tableAnticipoRef !== null && tableAnticipoRef.current !== null) {
			let index = 1;
			tableAnticipoRef.current.clear();
			tableAnticipoRef.current.rows.add(
                anticipos.map(anticipo => [
											index++, 
											anticipo.paquete,
											anticipo.valor_total,
											anticipo.monto_anticipo,
											anticipo.retencion,
											anticipo.anticipo_neto_constructor,
											anticipo.valor_terreno,
											anticipo.total_credito,
											anticipo.estatus_paquete

				]));
			tableAnticipoRef.current.draw();
		} else 
			initDatatableAnticipo(userRole);		
		// eslint-disable-next-line
	}, [anticipos]);

	const initDatatableAnticipo = (role) => {
		    tableAnticipoRef.current = $('#tableAnticipo').DataTable({
                lengthMenu: [10, 20, 30, 40, 50],
                columns: [
                    { title: "#" }, //0
                    { title: "Paquete" },//1 
                    { 
						title: "Total monto Anticipo",
						render: function (data, type, row, meta) {
							let number = row[2]?.replace(/,/g, '') || 0;
							return `<div">${formatoMoneda.format(parseFloat(number))}</div>`;
						},
					}, //2
                    { 
						title: "Valor del terro al 80%",
						render: function (data, type, row, meta) {
							let number = row[3]?.replace(/,/g, '') || 0;
							return `<div>${formatoMoneda.format(parseFloat(number))}</div>`;
						},
					}, //3
                    { 
						title: "Retención 10% fondo de garantía",
						render: function (data, type, row, meta) {
							let number = row[4]?.replace(/,/g, '') || 0;
							return `<div>${formatoMoneda.format(parseFloat(number))}</div>`;
						},
					}, //4 
                    { 
						title: "Total neto para el constructor",
						
						render: function (data, type, row, meta) {
							let number = row[5]?.replace(/,/g, '') || 0;
							return `<div>${formatoMoneda.format(parseFloat(number))}</div>`;
						},
					}, //5
                    { 
						title: "Valor del proyecto antes de comisiones",
						width: "200px",
						render: function (data, type, row, meta) {
							let number = row[6]?.replace(/,/g, '') || 0;
							return `<div>${formatoMoneda.format(parseFloat(number))}</div>`;
						},
					}, //6 
					{ 
						title: "Total Credito",
						render: function (data, type, row, meta) {
							let number = row[7]?.replace(/,/g, '') || 0;
							return `<div>${formatoMoneda.format(parseFloat(number))}</div>`;
						},
					}, //7
                    { //8
						title: "Autorizar Anticipo",
						data: null,
						width: "50px",
						render: function (data, type, row, meta) {
							let html = "";
							let index = row[0];
							let paquete = row[1];
							let valor_total = row[2];
							let monto_anticipo = row[3];
							let retencion = row[4];
							let anticipo_neto_constructor = row[5];
							let valor_terreno = row[6];
							let total_credito = row[7];
							let estatus_paquete = row[8];

							if(estatus_paquete >= 4)  
                            	//html = '<button class="btn btn-outline-default" disabled="true"><i class="fa fa-check-square"></i> Anticipo autorizado</button> '
								html = `<div style="text-align: right; white-space: nowrap;">
											<a style="cursor:not-allowed" id="anticipos"  disabled="true" title="Anticipo autorizado" >
												<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke=" #008000 " stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-square"><polyline points="9 11 12 14 22 4"></polyline><path d="M21 12v7a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11"></path></svg>	
											</a>
										</div>`;
                       	 	else
                            	html = `<div style="text-align: right; white-space: nowrap;">
											<a style="cursor:pointer"  id="autorizar" data-valor-total="${valor_total}" data-monto-anticipo="${monto_anticipo}" data-retencion="${retencion}" data-anticipo-neto-constructor="${anticipo_neto_constructor}" data-valor-terreno="${valor_terreno}" data-total-credito="${total_credito}" data-estatus-paquete="${estatus_paquete}" title="Autorizar anticipo" >
												<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke=" #ffca28 " stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-square"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect></svg>
											</a>
										</div>`;
							return html;
						},
						escape: false,
					}
				],
                columnDefs: [{
                    targets: [7], 
                    visible: false
                }]
            });
	
		$('#tableAnticipo').on('click', '#autorizar', function () {
			const valorTotal = $(this).data('valor-total');
			const montoAnticipo = $(this).data('monto-anticipo');
			const retencion = $(this).data('retencion');
			const anticipoNetoConstructor = $(this).data('anticipo-neto-constructor');
			const valorTerreno = $(this).data('valor-terreno');
			const totalCredito = $(this).data('total-credito');
			const estatusPaquete = $(this).data('estatus-paquete');
	
			actionAutorizarPaquete(valorTotal, montoAnticipo, retencion, anticipoNetoConstructor, valorTerreno, totalCredito, estatusPaquete);
		});
        
	}

	

	const actionAutorizarPaquete = (valor_total, monto_anticipo, retencion, anticipo_neto_constructor, valor_terreno, total_credito, estatus_paquete) => {
		Swal.fire({
			title: "¿Deseas autorizar el anticipo?",
			text: "Debes validar los datos...",
			icon: "question",
			confirmButtonText: 'Continuar',
			cancelButtonText: 'Cancelar',
			showCancelButton: true,
			showCloseButton: true,
			allowOutsideClick: false,
			allowEscapeKey: false,
			allowEnterKey: false,
			reverseButtons : true
		}).then((result) => {
			if (result.isConfirmed) {
				if(estatus_paquete == 3) {
					setFormData({
						valor_total : valor_total,
						monto_anticipo : monto_anticipo,
						retencion : retencion,
						anticipo_neto_constructor : anticipo_neto_constructor,
						valor_terreno : valor_terreno,
						total_credito : total_credito
					})
					handleOpenModal("Parametros");
				} else {
					alert("Error", "Es necesario cerrar el paquete.", "error");
				}
			}
		}).catch(() => {
			return false;
		});
		
    }    

	const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];

		if (selectedFile) {
			const fileName = selectedFile.name.toLowerCase();
			if (fileName.endsWith('.pdf')) {
				setFile(selectedFile);
			} else {
				errorNotification('Formato de archivo no válido. Por favor, selecciona un archivo PDF.');
			}
		}
    };
    
    const handleUpload = async () => {
        if (file) {
			const timestamp = Date.now();
			const form = new FormData();
			const newFileName = `Tecnico(${sharedPaqueteId})-Avance_0-${timestamp}-${file.name}`;
            form.append('uploaded_file', file, newFileName);
            
			try {
                const response = await uploadFiles(form, token);
                if (response.message === 'success') {
                    successNotification("Archivo cargado correctamente.")
					setFile(null);
                    let data = {
                        filepath : newFileName,
                        paquete_id: sharedPaqueteId
                    }
                    fetchUpdatePaqueteAnticipoTecnicoById(sharedPaqueteId, data);
                } else {
                    errorNotification("No es posible subir el archivo.");
                }
            } catch (error) {
                errorNotification("Ocurrio un error en el servidor.");
            }

        } else {
            errorNotification("Es necesario seleccionar un archivo.");
        }
    };

	const handleSendReport = () => {
		fetchPaquetesAnticiposById(sharedPaqueteId);
	}

	const actionSendReport = (data) => {
		if((data.anticipo_tecnico != null) && (data.anticipo_tecnico != "")){
			if((data.anticipo_pdf_admin != null) && (data.anticipo_pdf_admin != '')) {
				if((data.reporte_global != null) && (data.reporte_global != '') ) {
					data.nombre_proyecto = proyectoName;
					data.nombre_etapa = paqueteName;
					fetchCreatePaqueteAnticipoArchivos(data);
				} else{
					alert("", "Se necesita el documento de Reporte Global", "warning");
				}
			} else{
				alert("", "Se necesita el documento de Administración", "warning");
			}
		} else{
			alert("", "Necesita adjuntar el documento de avance", "warning");
		}
	}


	const getButtonSendReport = () => { 
		if(anticipos.length > 0) {
			return(
				<div style={{"textAlign": "center"}}>
					<button type="submit" className="btn btn-outline-default" id="sendReport" name="sendReport"  onClick={() => handleSendReport()} disabled={enableSendReport}> Enviar reporte</button>
				</div> 			
			)
		}				
	}


	const handleUpdateEstatus = (message) => {
		if(message === 'success') {
			alert("Anticipo Autorizado", "Notificación enviada", "success");
		} else  {
			errorNotification("No fue posible enviar la notificación");
		}
		fetchPaquetesAnticiposById(sharedPaqueteId);
	};


	const handleOpenModal = (modalName) => {
        setIsModalOpen(true);
        setActiveModal(modalName);
    };


	const handleCloseModal = (message) => {
        setIsModalOpen(false);
        setActiveModal("");
		document.body.style.overflow = 'auto';

		handleUpdateEstatus(message)
    };


	return (
		<div>
			<Breadcrumb title={props.title} subtitle={props.subtitle}/>
			<div className="row justify-content-center">
                <hr className="mb-4" />
				<div className="col-xl-11">
					<div className="col-xl-12">
						<div className='row'>
							<div className='col-4'>
								<SelectProyectos setSharedProyectoId={setSharedProyectoId} setSharedEtapaId={setSharedEtapaId}  />
							</div>
							<div className='col-4'>
								<SelectEtapas  proyecto_id={sharedProyectoId} setSharedEtapaId={setSharedEtapaId} setSharedPaqueteId={setSharedPaqueteId} />
							</div>
							<div className='col-4'>
								<SelectPaquetes etapa_id={sharedEtapaId} setSharedPaqueteId={setSharedPaqueteId}  />
							</div>
						</div>
						{
							showUplaodFile ? (
								<>
									<div className='row justify-content-center'>
										<div className='col-4' >
											<div className="form-group mb-3" style={{textAlign: "center"}}>
												<label className="form-label" htmlFor="etapas" ><strong>Adjuntar Documento</strong></label>
												<input type="file" className="form-control" id="submitFile" onChange={handleFileChange} />
											</div>
										</div>
										<div className='col-2' >
											<div className="form-group mb-3" >
												<button className="btn btn-outline-default"  style={{marginTop:"2em"}} onClick={handleUpload} >
													<i className="fas fa-cloud-upload-alt" style={{ marginRight: "0.5em" }}></i>Cargar
												</button>
											</div>
										</div>
									</div>
									<div className="col-md-12" id="archivo">
										<div style={{"fontStyle" : "italic", "textAlign" : "center"}}>
											{
												documento !== "Sin Documento" ? (
													<a href={serverUrl + "/file/download/" + documento}   id="archivoPDF" name="archivoPDF" target="_blank">{ documento }</a> 
												) : (
													<span>{ documento }</span>
												)
												 
											}
										</div>
										<hr/>
									</div>
								</>
							) : null}
						
						<br/>
						<div id="datatable" className="mb-4">
							<Card> 
								<CardHeader>
									<span className="flex-grow-1">Autorizar Anticipos.</span>
								</CardHeader>
								<CardBody>
									<div className='row'>
										{ getButtonSendReport() }
										<div className="col-xl-12 tabla-scrollable table-responsive">
											<table id="tableAnticipo" className="table table-striped ">
												<thead></thead>
												<tbody></tbody>
											</table>
										</div>
									</div>
								</CardBody>
							</Card>
						</div>
					</div>
				</div>
			</div>
			{ isModalOpen && activeModal === "Parametros" && <ModalParamentros proyecto_id={sharedProyectoId} paquete_id={sharedPaqueteId} proyecto_nombre={proyectoName} paquete_nombre={paqueteName} form_data={formData} onCloseModal={handleCloseModal} /> }
			
		</div>
	)
}

export default AutorizarAnticipos;

