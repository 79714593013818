import React,  { useEffect, useState, useRef }from 'react';
import { useSelector } from 'react-redux';

import { Card, CardBody }  from '../../../../components/card/card';
import { deleteKeyNull, errorNotification, formatoMoneda, successNotification } from '../../../../helpers';

import { getEtapaById, updateEtapaById, getEtapaMontoById } from '../../../../api/etapa_proyectos/etapa_proyectos';
import { getPaquetesEstatusByEtapaId, getPaquetesCountByEtapaId } from '../../../../api/paquete_proyectos/paquete_proyectos';


function ModalEditarEtapas({ etapa_id, onSuccessEtapa, onCloseModal }) {
    const token = useSelector(state => state.authentication.user?.token);

    const [formEtapa, setFormEtapa] = useState({});
    const [monto, setMonto] = useState("");
    const [labelEstatus, setLabelEstatus] = useState("");
    const [estatusPaquete, setEstatusPaquete] = useState(false);
    const [garantiaLiberada, setGarantiaLiberada] = useState(false);
    const [cerrado, setCerrado] = useState(false);

    useEffect(() => {
        if (token !== null && token !== undefined) 
            fetchGetEtapaById();
    }, [etapa_id, token]);


    const fetchGetEtapaById = async () => {
		try {
            const response = await getEtapaById(etapa_id, token);
            setFormEtapa(response);
            fetchGetEtapaMontoById();
            fetchGetPaquetesCountByEtapaId();
		} catch (e) {
			errorNotification("Ocurrio un error en el servidor.");
		}
	};


    const fetchGetEtapaMontoById = async () => {
		try {
			const response = await getEtapaMontoById(etapa_id, token);
			setMonto(formatoMoneda.format(response.message));
		} catch (e) {
			errorNotification("Ocurrio un error en el servidor.");
		}
	};

    
    const fetchGetPaquetesCountByEtapaId = async () => {
		try {
            const response = await getPaquetesCountByEtapaId(etapa_id, token);
            if(response.message > 0) fetchGetPaquetesEstatusByEtapaId();
            else setLabelEstatus("Sin Paquetes")
        } catch (e) {
			errorNotification("Ocurrio un error en el servidor.");
		}
    }

    
    const fetchGetPaquetesEstatusByEtapaId = async() => {
        try {
			const response = await getPaquetesEstatusByEtapaId(etapa_id, token);
			setEstatusPaquete(response.message === 1 ? true : false)
            setGarantiaLiberada(response.message === 0 ? true : false)
            
            /*if(response.des_liberar_inversion === null || response.des_liberar_inversion === 0) {
                setCerrado(false)
            } else if(response.message === 1) {
                setCerrado(true);
                setEstatusPaquete(false);
                setGarantiaLiberada(false);
            }*/
		} catch (e) {
			errorNotification("Ocurrio un error en el servidor.");
		}
    }

    
    const fetchUpdateEtapaById = async() => {
        try {
            const response = await updateEtapaById(formEtapa.id, deleteKeyNull(formEtapa), token);
            if(response.message === "success") {
	    		successNotification("Etapa Editada Correctamente");
                onSuccessEtapa();
                handleOnCloseModal();
            } else if(response.message === "failed") {
                errorNotification("No fue posible actualizar el paquete.");
            }
        } catch(e) {
            if(e.response.status === 401) {
                errorNotification("Token invalido o caducado.");
            } else if(e.response.status === 403) {
                errorNotification("No tienes permisos para realizar esta acción.");
            }
        }
    }

    
    const handleChangeFormEtapa = (event) => {
		const { name, value } = event.target;
		setFormEtapa({
			...formEtapa,
			[name]: value,
		});	
	};


	const handleEditarEtapa = (e) => {
        e.preventDefault(); 
		if (e.target.form.checkValidity()) fetchUpdateEtapaById();	 
        else errorNotification("Faltan campos requeridos.");
	}

    
    const handleOnCloseModal = () => {
        document.getElementById('modalEditarEtapas').classList.remove('show');
		document.querySelector('.modal-backdrop').remove();
        document.getElementById('modalEditarEtapas').style.display = 'none';

        setFormEtapa({});
      
        onCloseModal();
    }


    return(
        <div className="modal modal-cover fade" id="modalEditarEtapas" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Editar Etapa</h5>
                        <button type="button" className="btn-close" onClick={handleOnCloseModal}></button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3">
                            <form id="formEditarEtapa" className="was-validated" >
                                <div className="row row-space-12">	
                                    <div className="col-6">
                                        <div className='row'>
                                            <div className="mb-3">
                                                <div className="col-xl-12">
                                                    <label className="form-label">Nombre</label>
                                                    <input className="form-control" placeholder="Nombre"
                                                        id="nombre"
                                                        name="nombre"
                                                        value={formEtapa.nombre}
                                                        onChange={handleChangeFormEtapa}
                                                        required
                                                        pattern=".*\S+.*" />
                                                    <div className="invalid-feedback" id="validationRFC">
                                                        Campo requerido
                                                    </div>	
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="mb-3">
                                                <div className="col-xl-12">
                                                    <label className="form-label">Descripción</label>
                                                    <textarea className="form-control"  placeholder='Descripción'
                                                        id="descripcion"
                                                        name="descripcion"
                                                        value={formEtapa.descripcion}
                                                        onChange={handleChangeFormEtapa}
                                                        row="10" >
                                                    </textarea>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="mb-3">
                                                <div className="col-xl-12">
                                                    <label className="form-label">Monto</label>
                                                    <input className="form-control" placeholder="Monto" 
                                                        id="monto"
                                                        name="monto"
                                                        value={monto}
                                                        onChange={handleChangeFormEtapa}
                                                        required={true}
                                                        disabled={true}
                                                        pattern=".*\S+.*" />
                                                    <div className="invalid-feedback" id="validationRFC">
                                                        Campo requerido
                                                    </div>	
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className='row'>
                                            <div className="mb-3">
                                                <div className="col-xl-12">
                                                    <label className="form-label">Estatus <strong>({labelEstatus})</strong></label>
                                                    <Card>
                                                        <CardBody>
                                                            <div className="form-group mb-4">
                                                                <div className="form-check">
                                                                    <input className="form-check-input" 
                                                                        type="checkbox" 
                                                                        defaultValue="" 
                                                                        id="estatus_paquete" 
                                                                        checked={estatusPaquete}
                                                                        disabled={true} />
                                                                    <label className="form-check-label" htmlFor="estatus_paquete">Paquetes en activo</label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" 
                                                                        type="checkbox" 
                                                                        defaultValue="" 
                                                                        id="inv_entregadas" 
                                                                        checked={garantiaLiberada}
                                                                        disabled={true} />
                                                                    <label className="form-check-label" htmlFor="defaultCheck2">Fondos de garantía entregadas a INFONAVIT</label>
                                                                </div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" 
                                                                        type="checkbox" 
                                                                        defaultValue="" 
                                                                        id="cerrado" 
                                                                        checked={cerrado}
                                                                        disabled={true} />
                                                                    <label className="form-check-label" htmlFor="cerrado">Paquetes cerrados e inversiones entregadas a Infonavit</label>
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-default" onClick={handleOnCloseModal}>Cerrar</button>
                        <button type="button" className="btn btn-outline-theme" onClick={handleEditarEtapa} form="formEditarEtapa" >Editar</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ModalEditarEtapas;