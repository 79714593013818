import React, { useEffect, useContext } from 'react';
import { Card, CardBody, CardFooter } from './../../components/card/card.jsx';
import { AppSettings } from './../../config/app-settings.js';

function PagesError() {
	const context = useContext(AppSettings);
		
	useEffect(() => {
		context.setAppHeaderNone(true);
		context.setAppTopNav(false);
		context.setAppContentClass('p-0');
		
		return function cleanUp() {
			context.setAppHeaderNone(false);
			context.setAppTopNav(true);
			context.setAppContentClass('');
		};
	}, []);

	return (
		<div className="error-page">
			<div className="error-page-content">
				<Card className="mb-5 mx-auto" >
					<CardBody>
						<Card>
							<div className="error-code">404</div>
						</Card>
					</CardBody>
				</Card>
				<CardFooter>
					<h1>Oops!</h1> 
					<h3>Parece que no podemos encontrar la página que estás buscando.</h3>
				</CardFooter>
			</div>
		</div>
	)
}

export default PagesError;