import React,  { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { formatoMoneda } from '../../../helpers';

import jsZip from 'jszip';

import './styles.css'; 
import { updatePaqueteInconsistenciaById, updatePaqueteValorTodoById } from '../../../api/paquete_proyectos/paquete_proyectos';
import { createHistorialEstadoCuenta } from '../../../api/historial_estados_cuenta/historial_estados_cuenta';
import { updateEstadoCuentaEstatusById, updateEstadoCuentaEstatusMinistracionById, updateEstadoCuentaEstatusSubsidioById } from '../../../api/estados_cuenta/estados_cuenta';

const $ = require('jquery');
window.JSZip = jsZip;

function TablaInconsistencias({ inconsistencias }) {
    const token = useSelector(state => state.authentication.user?.token);

    const datatableInconsistenciasRef = useRef(null);

    useEffect(() => {
		if (datatableInconsistenciasRef.current !== null) {
			let index = 1;
			datatableInconsistenciasRef.current.clear();
			datatableInconsistenciasRef.current.rows.add(inconsistencias.map(inconsistencia => [
																		index++, 
																		inconsistencia.paquete_id,
																		inconsistencia.estado_cuenta_id,
																		inconsistencia.estatus_2,
																		inconsistencia.num_credito, 
																		inconsistencia.nombre, 
																		inconsistencia.val_ministracion_1, 
																		inconsistencia.monto_subsidio_1,
																		inconsistencia.estatus_firma_folio,
																		inconsistencia.estatus_1]));
	  		datatableInconsistenciasRef.current.draw();
		} else 
			initDatatable();
		// eslint-disable-next-line
	}, [inconsistencias]);


    const fetchUpdatePaqueteInconsistenciaById = async (paquete_id, data) => {
        try {
            const response = await updatePaqueteInconsistenciaById(paquete_id, data, token);
        } catch (error) {
            console.error("Ocurrio un error en el servidor.", error);
        }
    }

    const fetchCreateHistorialEstadoCuenta = async (data) => {
        try {
            const response = await createHistorialEstadoCuenta(data, token); 
        } catch (error) {
            console.log("Ocurrio un error en el servidor.", error);
        }
    }

    const fetchUpdateEstadoCuentaEstatusById = async (estado_cuenta_id, data) => {
        try {
            const response = await updateEstadoCuentaEstatusById(estado_cuenta_id, data, token);
        } catch (error) {
            console.error("Ocurrio un error en el servidor.", error);
        }
    }

    const fectchUpdatePaqueteValorTodoById = async (paquete_id, data) => {
		try {
            const response = await updatePaqueteValorTodoById(paquete_id, data, token);
        } catch (error) {
            console.log("Ocurrio un error en el servidor.", error);
        }
	}

    const fetchUpdateEstadoCuentaEstatusMinistracionById = async (estado_cuenta_id, data) => {
        try {
            const response = await updateEstadoCuentaEstatusMinistracionById(estado_cuenta_id, data, token);
        } catch (error) {
            console.error("Ocurrio un error en el servidor.", error);
        }
    }

    const fetchUpdateEstadoCuentaEstatusSubsidioById = async (estado_cuenta_id, data) => {
        try {
            const response = await updateEstadoCuentaEstatusSubsidioById(estado_cuenta_id, data, token);
        } catch (error) {
            console.error("Ocurrio un error en el servidor.", error);
        }
    }

    const initDatatable = () => {
		datatableInconsistenciasRef.current = $('#tablaInconsistencias').DataTable({
			dom: "<'row mb-3'<'col-md-4 mb-3 mb-md-0'l><'col-md-8 text-end'<'d-lg-flex justify-content-end'f<'d-lg-block d-none'B>>>>t<'row align-items-center'<'mr-auto col-md-6 mb-3 mb-md-0 mt-n2 'i><'mb-0 col-md-6'p>>",
			lengthMenu: [10, 20, 30, 40, 50],
			buttons: [
				{ 
					extend: 'excelHtml5', 
					className: 'btn btn-outline-default btn-sm',
					filename: function() {
						var timestamp = new Date().getTime(); 
						return 'inconsistencias_' + timestamp; 
					},
					exportOptions: {
						columns: ':visible',
						modifier: {
							page: 'none' // Evita agregar información de paginación
						}
					},
					customizeData: function ( data ) {
						for (var i=0; i < data.body.length; i++){
							for (var j=0; j<data.body[i].length; j++ ){
								data.body[i][j] = '\u200C' + data.body[i][j];
							}
						}
					}
				}
			],
			columns: [
				{ 
					title: "#",
					width: "10px",
					render: function (data, type, row, meta) {
						return `<div style="white-space: nowrap;">${row[0]}</div>`;
					},
				},
				{ title: "ID Paquete"},
				{ title: "ID Estado Cuenta"},
				{ title: "Estado #2"},
				{ 
					title: "No. Credito",
					width: "100px",
					render: function (data, type, row, meta) {
						return `<div style="white-space: nowrap;">${row[4]}</div>`;
					},
				},
				{ title: "Acreditado",},
				{ 
					title: "Monto Ministración (INFONAVIT)",
					width: "150px",
					render: function (data, type, row, meta) {
						return `<div style="white-space: nowrap;">${formatoMoneda.format(parseFloat(row[6].replace(/,/g, '')))}</div>`;
					},
				},
				{ 
					title: "Monto de Subsidio (CONAVI)",
					width: "150px",
					render: function (data, type, row, meta) {
						return `<div style="white-space: nowrap;">${formatoMoneda.format(parseFloat(row[7].replace(/,/g, '')))}</div>`;
					},
				},
				{ 
					title: "Motivo Inconsistencia",
					render: function (data, type, row, meta) {
						const estatusFirmaFolioMapping = {
							"4": "INFONAVIT",
							"5": "CONAVI",
							"6": "INFONAVIT",
							"7": "CONAVI",
							"2": "NO FIRMADO",
							"3": "FIRMADO PERO NO PERTENECE AL PAQUETE",
						};
						
						return estatusFirmaFolioMapping[row[8]] || "Valor Desconocido";
					}
				},
				{
					title: "Devolución del Depósito",
					sortable: false,
					render: function(data, type, row, meta) {
						let index = row[0];
						let paquete_id = row[1];
						let estado_cuenta_id = row[2];
						let estatus_2 = row[3];
						let num_credito = row[4];
						let nombre = row[5];
						let val_ministracion_1 = row[6];
						let monto_subsidio_1 = row[7];
						let estatus_firma_folio = row[8];
						let estatus_1 = "3";

						let date_id = "fecha"+estado_cuenta_id;
						let paquete_estado_id = "paquete"+paquete_id+estado_cuenta_id;
						
						let selected_id;

						if(estado_cuenta_id !== '0')
							selected_id = "devolucionInco"+num_credito;
						else
							selected_id = "devolucionInco"+estado_cuenta_id;

						let estatusOptions = {
							"1": "",
							"2": "",
							"3": "En cuenta bancaria|Devuelto a INFONAVIT",
							"4": "En cuenta bancaria|Devuelto a CONAVI",
							"5": "En cuenta bancaria|Devuelto a INFONAVIT",
							"6": "En cuenta bancaria|Devuelto a CONAVI"
						};
						
						let selectedOptions = (estatus_2 == 1) ? 'selected' : '';
						let displayOptions = (estatus_2 == 1) ? 'block' : 'none';
						
						let options = estatusOptions[estatus_1].split("|").map(function (option) {
							return '<option value="' + option + '" ' + selectedOptions + '>' + option + '</option>';
						});
						
						let cadena = '<select id=' + selected_id + ' name=' + selected_id + ' data-paquete-id="'+paquete_id+'" ' +
										'data-estado-cuenta-id="'+estado_cuenta_id+'" data-estatus-2="'+estatus_2+'" data-num-credito="'+num_credito+'" ' +
										'data-nombre="'+nombre+'" data-val-ministracion-1="'+val_ministracion_1+'" data-monto-subsidio-1="'+monto_subsidio_1+'" '+
										'data-estatus-firma-folio="'+estatus_firma_folio+'" data-estatus-1="'+estatus_1+'" class="form-control select-devolucion">' + 
											options.join('') + 
										'</select>' +
										'<br>'+
										'<input class="form-control" type="date" id=' + date_id + ' style="display:' + displayOptions+ ';">';
						return cadena;
					}
				}
			],
			columnDefs: [{
				targets: [1,2,3],
				visible: false
			  }
			]
		});

		$('#tableInconsistencias').on('change', '.select-devolucion', function(event) {
			event.stopPropagation();

			let $select = $(this);
			let selectedValue = this.value;
	
			let paqueteId = $select.data('paquete-id');
			let estadoCuentaId = $select.data('estado-cuenta-id');
			let estatus2 = $select.data('estatus-2');
			let numCredito = $select.data('num-credito');
			let nombre = $select.data('nombre');
			let valMinistracion1 = $select.data('val-ministracion-1');
			let montoSubsidio1 = $select.data('monto-subsidio-1');
			let estatusFirmaFolio = $select.data('estatus-firma-folio');
			let estatus1 = $select.data('estatus-1');

			handleDevolucionChange(selectedValue, paqueteId, estadoCuentaId, estatus2, numCredito, nombre, valMinistracion1, montoSubsidio1, estatusFirmaFolio, estatus1);
		});
	}
	
	const handleDevolucionChange = (selectedValue, paqueteId, estadoCuentaId, estatus2, numCredito, nombre, valMinistracion1, montoSubsidio1, estatusFirmaFolio, estatus1) => {
		let selected_id = 0;
		switch (selectedValue) {
			case 'En cuenta bancaria':
				selected_id = 1;
				break;
			case 'Devuelto a CONAVI':
				selected_id = 2;
				break;
			case 'Devuelto a INFONAVIT':
				selected_id = 3;
				break;
			default:
				break;
		}

		if (selected_id) 
			actionSelected(selected_id, paqueteId, estadoCuentaId, estatus2, numCredito, nombre, valMinistracion1, montoSubsidio1, estatusFirmaFolio, estatus1);		
	}

	const actionSelected = (selected_id, paqueteId, estadoCuentaId, estatus2, numCredito, nombre, valMinistracion1, montoSubsidio1, estatusFirmaFolio, estatus1) => {
		
		if(selected_id === 1) {
			document.getElementById("fecha"+estadoCuentaId).style.display='none';
			let total = parseFloat(valMinistracion1) + parseFloat(montoSubsidio1);
			let data1 = {
				"valor_inconsistencia" : total
			}

			fetchUpdatePaqueteInconsistenciaById(paqueteId, data1);
			let data2 = {
				"paquete_id" : paqueteId,
				"nom_num_credito_derechohabiente": numCredito,
				"nom_derechohabiente": nombre,
				"val_ministracion": valMinistracion1,
				"val_moto_subsidio": montoSubsidio1,
				"nom_deposito_no_reconocido": selected_id == 1 ? "En cuenta bancaria" : ( selected_id == 2 ? "Devuelto a INFONAVIT" : "Devuelto a CONAVI" ) ,
				"num_motivo_inconsitencia": estatusFirmaFolio
			}

			fetchCreateHistorialEstadoCuenta(data2)
			let data3 = {
				"num_credito_derechohabiente": numCredito,
				"paquete_id" : paqueteId,
				"estatus" : selected_id,
				"val_ministracion" : valMinistracion1,
				"monto_subsidio" : montoSubsidio1,
				"estado_cuenta_id" : estadoCuentaId,
				"fec_fecha_deposito" : "2000-01-01"
			}
			fetchUpdateEstadoCuentaEstatusById(estadoCuentaId, data3);

			let data4 = {
				"ministracion" : parseFloat(valMinistracion1),
				"subsidio" : parseFloat(montoSubsidio1)
			}
			fectchUpdatePaqueteValorTodoById(paqueteId, data4);			
		} else {
			document.getElementById("fecha" + estadoCuentaId).style.display = 'block';
			$('#tableInconsistencias').on('change', "#fecha" + estadoCuentaId, function(event) {
				let date = $("#fecha" + estadoCuentaId).val();

				let data1 = {
					"paquete_id" : paqueteId,
					"nom_num_credito_derechohabiente": numCredito,
					"nom_derechohabiente": nombre,
					"val_ministracion": valMinistracion1,
					"val_moto_subsidio": montoSubsidio1,
					"nom_deposito_no_reconocido": selected_id == 1 ? "En cuenta bancaria" : ( selected_id == 2 ? "Devuelto a INFONAVIT" : "Devuelto a CONAVI" ) ,
					"num_motivo_inconsitencia": estatusFirmaFolio
				}
				fetchCreateHistorialEstadoCuenta(data1)
				
				
				if (estatus2==1) {
					let data4 = {
						"fec_fecha_deposito" : date,
						"estatus" : selected_id,
						"num_credito_derechohabiente" : numCredito
					}
					if(selected_id === 2) {
						fetchUpdateEstadoCuentaEstatusMinistracionById(estadoCuentaId, data4);
					} else if(selected_id === 3) {
						fetchUpdateEstadoCuentaEstatusSubsidioById(estadoCuentaId, data4);
					}
				} else {
					let data5 = {
						"num_credito_derechohabiente" : numCredito,
						"estatus" : selected_id,
						"fec_fecha_deposito" : date
					}
	
					if(selected_id === 2) {
						fetchUpdateEstadoCuentaEstatusMinistracionById(estadoCuentaId, data5);
					} else if(selected_id === 3) {
						fetchUpdateEstadoCuentaEstatusSubsidioById(estadoCuentaId, data5);
					}
					let data4 = {
						"ministracion" : parseFloat(valMinistracion1),
						"subsidio" : parseFloat(montoSubsidio1)
					}
					fectchUpdatePaqueteValorTodoById(paqueteId, data4);
		
					fectchUpdatePaqueteValorTodoById(paqueteId, data4);
				}
			
			});
		}	
	}


    return (
        <div className='col-12 tabla-scrollable table-responsive'>
            <table id="tablaInconsistencias" className="table text-nowrap  w-100 table-striped ">
                <thead></thead>
                <tbody></tbody>
            </table>
        </div>
    )
}

export default TablaInconsistencias;