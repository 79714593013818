import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useAuthorization from '../../../hooks/useAuthorization';

import { Card, CardBody, CardHeader }  from '../../../components/card/card';
import Breadcrumb from '../../../components/breadcrumb/breadcrumb';
import { alert, errorNotification, successNotification } from '../../../helpers';

import { getDireccionSepomexByCp, updateDireccionById } from '../../../api/direcciones/direcciones';
import { getBancoById, getBancosProyectos } from '../../../api/bancos/bancos';
import { deleteProyectoById, getProyectosPromotoresById, updateProyectoById } from '../../../api/proyectos/proyectos';
import { getPromotorBancosById, getPromotores } from '../../../api/promotores/promotores';
import Swal from 'sweetalert2';


function ProyectoDetalle(props) {
    const navigate = useNavigate();
    const token = useSelector(state => state.authentication.user?.token);

    const [proyectoData, setProyectoData] = useState([]);
    
    const [promotores, setPromotores] = useState([]);
    const [promotor, setPromotor] = useState({});
    const [bancos, setBancos] = useState([]);
    const [colonias, setColonias] = useState([]);

    const userRole = useAuthorization();

    const [formProyecto, setFormProyecto] = useState({
        proyecto_id : 0,
        promotor_id : 0,
        nombre_proyecto : '',
        val_mesa_inversion : 0.0,
        des_mesa_inversion : 1,
        can_estado_cuenta_proyecto : 0,
        num_viviendas_autorizadas : 0,

        direccion_id : 0,
		calle: '',
		num_exterior : '',
		num_interior : '',
		colonia: '',
        municipio : '',
        estado : '',
		cp: '',

        banco_id : 0,
        proyecto_banco_nombre : '',
        proyecto_cuenta : '',
        proyecto_clabe : ''
	});


    const location = useLocation();
    

    useEffect(() => { 
        if (location.state && location.state.proyecto) {
            setProyectoData(location.state.proyecto);
        } else {
            navigate('/proyecto/lista');
        }
    }, [location]);

    
    useEffect(() => { 
        if(token !== undefined) {
            fetchPromotorBancoById(location.state.proyecto.promotor_id);
            fetchPromotores();
            fetchBancosProyectos();
            fetchProyectosPromotoresById(location.state.proyecto.proyecto_id);
        }
        // eslint-disable-next-line
    }, [token]);


    const fetchPromotorBancoById = async (id) => {
        try {
            const response = await getPromotorBancosById(id, token);
            setPromotor(response);
        } catch (error) {
            console.error('Error al cargar el banco promotor por ID', error);
        }
    };

    const fetchPromotores = async () => {
        try {
            const response = await getPromotores(token);
            setPromotores(response);
        } catch (error) {
            console.error('Error al cargar los promotores', error);
        }
    };

    const fetchBancosProyectos = async () => {
        try {
            const response = await getBancosProyectos(token);
            setBancos(response) 
        } catch (error) {
            console.error('Error al cargar los bancos de los proyectos', error);
        }
    };

    const fetchProyectosPromotoresById = async (id) => {
        try {
            const response = await getProyectosPromotoresById(id, token);
            
            setFormProyecto({
                proyecto_id : response.proyecto_id,
                promotor_id : response.promotor_id,
                nombre_proyecto : response.proyecto_nombre,
                val_mesa_inversion : response.proyecto_val_mesa_inversion,
                des_mesa_inversion : response.proyecto_des_mesa_inversion,
                can_estado_cuenta_proyecto : response.proyecto_can_estado_cuenta_proyecto,
                num_viviendas_autorizadas : response.proyecto_viviendas,

                direccion_id: response.direccion_id,
                calle: response.proyecto_calle,
                num_exterior : response.proyecto_num_interior,
                num_interior : response.proyecto_num_exterior,
                colonia: response.proyecto_colonia,
                municipio : response.proyecto_municipio,
                estado : response.proyecto_estado,
                cp: response.proyecto_cp,

                banco_id: response.banco_id,
                proyecto_banco_nombre : response.proyecto_banco_nombre,
                proyecto_cuenta : response.proyecto_cuenta,
                proyecto_clabe : response.proyecto_clabe
            });
            
            if(response.proyecto_cp !== '') {
                fetchDirecionByCP(response.proyecto_cp);
            }

        } catch (error) {
            console.error('Error al cargar los datos', error);
        }
    };

    const fetchDirecionByCP = async(cp) => {
        try {
            const response = await getDireccionSepomexByCp(cp, token);
            if(response.status === 200) {
                setColonias(response.data.codigo_postal.colonias)
            } else if(response.status === 204) {
                errorNotification("No es posible obtener la direccion. Verifique el CP.")
            }
        } catch (error) {
            console.error('Error al cargar los datos', error);
        }
	}

    const fetchUpdateProyectoById = async (data) => {
        try {
            const response = await updateProyectoById(formProyecto.proyecto_id ,data, token);
            if(response.message === "success") {
                successNotification("Proyecto Actualizado Correctamente");
            }
        } catch(e) {
            console.log('No es posible actualizar el proyecto', e)
        }
    }

    const fetchUpdateDireccionById = async(data) => {
        try {
            const response = await updateDireccionById(formProyecto.direccion_id, data, token);
            if(response.message === "success") {
                let dataProyecto = {
                    "banco_id" : formProyecto.banco_id,
                    "direccion_id" : formProyecto.direccion_id,
                    "promotor_id" : formProyecto.promotor_id,
                    "nombre" : formProyecto.nombre_proyecto,
                    "num_viviendas_autorizadas" : formProyecto.num_viviendas_autorizadas
                }
                fetchUpdateProyectoById(dataProyecto);
            }
        } catch(e) {
            console.log(e);
        }
    }

    const fetchBancoById = async (id) => {
        try {
            const response = await getBancoById(id, token);
            setFormProyecto({
				...formProyecto,
                banco_id : response.id,
                proyecto_cuenta : response.cuenta,
                proyecto_clabe : response.clabe
            });
        } catch (error) {
            console.error('Error al cargar los bancos', error);
        };
    }

    const fetchDeleteProyectoById = async (proyecto_id) => {
        try {
            const response = await deleteProyectoById(proyecto_id, token);
            if(response.message == 'success') {
                successNotification("Proyecto eliminado correctamente");  
                navigate('/proyecto/lista');
            } else {
                alert("Ocurrio un error", "No fue posible eliminar el proyecto. Es posible que algunos datos ya no esten disponible", "error");
            }
        } catch (error) {
            console.error('Ocurrio un error en el servidor', error);
        }
    };
    
    const handleBancosChange = async (event) => {
		const selectedId = event.target.value;
		fetchBancoById(selectedId);
	};

	const handlePromotorChange = async (event) => {
		const selectedId = event.target.value;
		try {
            fetchPromotorBancoById(selectedId);
            setFormProyecto({
				...formProyecto,
                promotor_id : selectedId
            });
        } catch (error) {
            console.error('Error al cargar los bancos', error);
        } 
	};

    const handleChangeFormProyecto = (event) => {
		const { name, value } = event.target;
		if (name === 'cp') {
			setFormProyecto((prevForm) => ({
				...prevForm,
				[name]: value,
			}));

			if (/^\d{5}$/.test(value)) {
				fetchDirecionByCP(value);
			} else {
				if(value.length === 5)
					errorNotification('El código postal debe contener 5 dígitos numéricos.');
			}
		} else {
			setFormProyecto({
				...formProyecto,
				[name]: value,
			});
		}
	};

    const handleActualizarProyecto = (e) => {
        e.preventDefault(); 
		if (e.target.form.checkValidity()) {
            let dataDireccion = {
                "calle" : formProyecto.calle,
                "num_interior" : formProyecto.num_interior,
                "num_exterior" : formProyecto.num_exterior,
                "colonia" : formProyecto.colonia,
                "municipio" : formProyecto.municipio,
                "estado" : formProyecto.estado,
                "cp" : formProyecto.cp
            }
            fetchUpdateDireccionById(dataDireccion);
        } else 
            errorNotification("Faltan campos requeridos.")
    }
    
    const handleAgregarPaquete = () => {
        navigate('/proyecto/paquetes', {
            state: {
                proyecto : {
                    proyecto_id : formProyecto.proyecto_id,
                    nom_proyecto : formProyecto.nombre_proyecto
                } 
            }
        });
    }

    const handleEliminarProyecto = () => {
        Swal.fire({
			title: "¿Deseas eliminar el Proyecto: " + formProyecto.nombre_proyecto  + "?",
			text: "Una vez eliminado ya no podrás acceder a esta Etapa",
			icon: "question",
			confirmButtonText: 'Continuar',
			cancelButtonText: 'Cancelar',
			showCancelButton: true,
			showCloseButton: true,
			allowOutsideClick: false,
			allowEscapeKey: false,
			allowEnterKey: false,
			reverseButtons : true
		}).then((result) => {
			if (result.isConfirmed) {
				confirmEliminarProyecto(formProyecto.proyecto_id);
			}
		}).catch(() => {
			return false;
		})	
    }

    const confirmEliminarProyecto = (id) => {
		Swal.fire({
			title: "¿Estas seguro?",
			text: "Una vez eliminado ya no podrás acceder a este Proyecto",
			icon: "question",
			confirmButtonText: 'Eliminar',
			cancelButtonText: 'Cancelar',
			showCancelButton: true,
			showCloseButton: true,
			allowOutsideClick: false,
			allowEscapeKey: false,
			allowEnterKey: false,
			reverseButtons : true
		}).then((result) => {
			if (result.isConfirmed) {
				fetchDeleteProyectoById(id);
			}
		}).catch(() => {
			//return false;
		})
	}

    const renderButtonActualizarProyecto = () => {
        if (['administracion', 'master'].some(role => userRole.includes(role))) {
            return (
                <div className="ml-auto">
                    <button className="btn btn-outline-default" onClick={handleActualizarProyecto} form="formProyecto">Actualizar Proyecto</button>
                </div>
			)
		}
    }

    const renderButtonEliminarProyecto = () => {
        if (['master'].some(role => userRole.includes(role))) {
            return (
                <div className="ml-auto">
                    <button className="btn btn-outline-danger" style={{marginRight : '1em'}} onClick={handleEliminarProyecto} >Eliminar Proyecto</button>
                </div>
			)
		}
    }

    const areInputsEnabled = (userRoles) => {
        return userRoles.includes('master') || userRoles.includes('administracion');
    }

    const handleBackRoute = () => {
        navigate('/proyecto/lista');
    }
    
	
    return (
		<div>
			<Breadcrumb title={props.title} subtitle={proyectoData ? proyectoData.proyecto_nombre ?? "" : ""} />
			<div className="row justify-content-center">
				<hr className="mb-4" />
                <div className="col-xl-10">
                    
                    <div className="list-group-item d-flex align-items-center" style={{marginBottom: '1em', marginTop: '-0.5em'}}>
                        <a className="btn btn-outline-default text-nowrap btn-sm px-3 rounded-pill" onClick={handleBackRoute}><i className="fa fa-arrow-left me-1 ms-n1"></i> Atras</a>
                        <div className="flex-1 text-break"></div>
                        { renderButtonEliminarProyecto() }
                        <div className="ml-auto" style={{marginRight : '1em'}}>
                            <button className="btn btn-outline-default" onClick={handleAgregarPaquete} >Etapas / Paquetes</button>
                        </div>
                       { renderButtonActualizarProyecto() }
                    </div>
                </div>
                <div className="col-xl-10">
                    <form id="formProyecto" className="was-validated" >
                        <div className="col-xl-12">
                            <div id="formControls" className="mb-5">
                                <Card>
                                    <CardHeader className="fw-bold small d-flex">
                                        <span className="flex-grow-1">Datos del Proyecto</span>
                                    </CardHeader>
                                    <CardBody>
                                        <div className="row">
                                            <div className="col-xl-12">
                                                <div className="row">
                                                    <div className="col-xl-4">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label" htmlFor="nombre_proyecto">Nombre del Proyecto</label>
                                                            <input type="text" className="form-control" placeholder='Nombre del Proyecto'
                                                                id="nombre_proyecto"
                                                                name="nombre_proyecto"
                                                                value={formProyecto.nombre_proyecto}
                                                                onChange={handleChangeFormProyecto}
                                                                required
                                                                disabled={!areInputsEnabled(userRole)}
                                                                pattern=".*\S+.*" />
                                                            <div className="invalid-feedback" id="validationNombre">
                                                                Campo requerido
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label" htmlFor="calle">Direccion</label>
                                                            <input className="form-control" placeholder='Direccion' 
                                                                id="calle"
                                                                name="calle"
                                                                value={formProyecto.calle}
                                                                onChange={handleChangeFormProyecto}
                                                                required
                                                                disabled={!areInputsEnabled(userRole)}
                                                                pattern=".*\S+.*" />
                                                            <div className="invalid-feedback" id="validationDireccion">
                                                                Campo requerido
                                                            </div>	
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-2">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label" htmlFor="num_interior">No. Interior</label>
                                                            <input className="form-control" placeholder='No. Interior'
                                                                id="num_interior"
                                                                name="num_interior"
                                                                disabled={!areInputsEnabled(userRole)}
                                                                value={formProyecto.num_interior}
                                                                onChange={handleChangeFormProyecto}
                                                                    />
                                                                
                                                            
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-2">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label" htmlFor="num_exterior">No. Exterior</label>
                                                            <input className="form-control" placeholder='No. Exterior'
                                                                id="num_exterior"
                                                                name="num_exterior"
                                                                disabled={!areInputsEnabled(userRole)}
                                                                value={formProyecto.num_exterior}
                                                                onChange={handleChangeFormProyecto}
                                                                    />
                                                                
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-xl-12">
                                                <div className="row">
                                                    <div className="col-xl-3">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label" htmlFor="colonia">Colonia</label>
                                                            <select className="form-select" onChange={handleChangeFormProyecto} value={formProyecto.colonia} id='colonia' name='colonia' disabled={!areInputsEnabled(userRole)}>
                                                                {colonias.map((colonia, key) => (
                                                                    <option key={key} value={colonia}>
                                                                        {colonia}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            <div className="invalid-feedback" id="validationNoInterior">
                                                                Campo requerido
                                                            </div>	 
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label" htmlFor="municipio">Municipio</label>
                                                            <input className="form-control" placeholder='Municipio'
                                                                id="municipio"
                                                                name="municipio"
                                                                value={formProyecto.municipio}
                                                                onChange={handleChangeFormProyecto}
                                                                required
                                                                disabled={true}
                                                                pattern=".*\S+.*" />
                                                            <div className="invalid-feedback" id="validationNoInterior">
                                                                Campo requerido
                                                            </div>	    
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label" htmlFor="estado">Estado</label>
                                                            <input className="form-control"  placeholder='Estado'
                                                                id="estado"
                                                                name="estado"
                                                                value={formProyecto.estado}
                                                                onChange={handleChangeFormProyecto}
                                                                required
                                                                disabled={true}
                                                                pattern=".*\S+.*" />
                                                            <div className="invalid-feedback" id="validationNoInterior">
                                                                Campo requerido
                                                            </div>	    
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label" htmlFor="cp">C.P.</label>
                                                            <input className="form-control" placeholder='C.P,'
                                                                id="cp"
                                                                name="cp"
                                                                value={formProyecto.cp}
                                                                onChange={handleChangeFormProyecto}
                                                                required
                                                                disabled={!areInputsEnabled(userRole)}
                                                                pattern=".*\S+.*" />
                                                            <div className="invalid-feedback" id="validationCP">
                                                                Campo requerido
                                                            </div>	
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-xl-12">
                                                <div className="row">
                                                    <div className="col-xl-3">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label" htmlFor="exampleFormControlInput1">Viviendas Autorizadas</label>
                                                            <input type="text" className="form-control" placeholder='Viviendas Autorizadas' 
                                                                id="num_viviendas_autorizadas"
                                                                name="num_viviendas_autorizadas"
                                                                value={formProyecto.num_viviendas_autorizadas}
                                                                onChange={handleChangeFormProyecto}
                                                                required
                                                                disabled={!areInputsEnabled(userRole)}
                                                                pattern=".*\S+.*" />
                                                            <div className="invalid-feedback" id="validationCP">
                                                                Campo requerido
                                                            </div>	
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                        <div className="col-xl-12">
                            <div id="formControls" className="mb-5">
                                <Card>
                                    <CardHeader className="fw-bold small d-flex">
                                        <span className="flex-grow-1">Datos del Promotor</span>
                                    </CardHeader>
                                    <CardBody>
                                        <div className="row">
                                            <div className="col-xl-12">
                                                <div className="row">
                                                    <div className="col-xl-6">
                                                        <div className="form-group mb-3">
                                                        <label className="form-label" htmlFor="promotor_nombre" >Promotor</label>
                                                            <select className="form-select" id="promotor_nombre"  onChange={handlePromotorChange} value={formProyecto.promotor_id} disabled={!areInputsEnabled(userRole)}>
                                                            {promotores.map((promotor, key) => (
                                                                <option key={key} value={promotor.id}>
                                                                    {promotor.promotor}
                                                                </option>
                                                            ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label" htmlFor="promotor_rfc">RFC</label>
                                                            <input className="form-control" id="promotor_rfc" value={promotor.rfc ?? ''} disabled={true} placeholder='RFC'/>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xl-12">
                                                <div className="row">
                                                    <div className="col-xl-6">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label" htmlFor="promotor_calle">Direccion</label>
                                                            <input type="text" className="form-control" id="promotor_calle"  placeholder='Direccion' value={promotor.calle ?? ''} disabled={true}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label" htmlFor="promotor_no_interior">No. Interior</label>
                                                            <input className="form-control" id="promotor_no_interior" placeholder='No. Interior' value={promotor.num_interior ?? ''} disabled={true} />
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label" htmlFor="promotor_no_exterior">No. Exterior</label>
                                                            <input className="form-control" id="promotor_no_exterior" placeholder='No. Exterior' value={promotor.num_exterior ?? ''} disabled={true}/>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xl-12">
                                                <div className="row">
                                                    <div className="col-xl-3">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label" htmlFor="promotor_colonia">Colonia</label>
                                                            <input type="text" className="form-control" id="promotor_colonia" placeholder='Colonia' value={promotor.colonia ?? ''} disabled={true}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label" htmlFor="promotor_municipio">Municipio</label>
                                                            <input className="form-control" id="promotor_municipio"  placeholder='Municipio' value={promotor.municipio ?? ''} disabled={true}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label" htmlFor="promotor_estado">Estado</label>
                                                            <input className="form-control" id="promotor_estado" placeholder='Estado' value={promotor.estado ?? ''} disabled={true}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label" htmlFor="promotor_cp">C.P.</label>
                                                            <input className="form-control" id="promotor_cp"  placeholder='C.P.' value={promotor.cp ?? ''} disabled={true}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                        <div className="col-xl-12">
                            <div id="formControls" className="mb-5">
                                <Card>
                                    <CardHeader className="fw-bold small d-flex">
                                        <span className="flex-grow-1">Datos Bancarios</span>
                                    </CardHeader>
                                    <CardBody>
                                        <div className="row">
                                            <h6>Datos DAE</h6>
                                            <div className="col-xl-12">
                                                <div className="row">
                                                    <div className="col-xl-4">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label" htmlFor="proyecto_banco">Nombre del Banco</label>
                                                            <select className="form-select"  id="proyecto_banco" onChange={handleBancosChange} value={formProyecto.banco_id} disabled={!areInputsEnabled(userRole)}>
                                                                {bancos.map((b, key) => (
                                                                    <option key={key} value={b.id}>
                                                                        {b.nombre}
                                                                    </option>
                                                                ))}
                                                            </select> 
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label" htmlFor="proyecto_cuenta">No. Cuenta</label>
                                                            <input type="text" className="form-control" id="proyecto_cuenta"  placeholder='No. Cuenta'  disabled={true} value={formProyecto.proyecto_cuenta ?? ''} />
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label" htmlFor="proyecto_clabe">CLABE</label>
                                                            <input className="form-control" id="proyecto_clabe"  placeholder='CLABE' disabled={true} value={formProyecto.proyecto_clabe ?? ''}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <h6>Datos Promotor</h6>
                                            <div className="col-xl-12">
                                                <div className="row">
                                                    <div className="col-xl-4">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label" htmlFor="promotor_banco">Nombre del Banco</label>
                                                            <input type="text" className="form-control" id="promotor_banco" placeholder='Banco' value={promotor.nombre_banco ?? ''} disabled={true}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label" htmlFor="promotor_cuenta">No. Cuenta</label>
                                                            <input type="text" className="form-control" id="promotor_cuenta" placeholder='No. Cuenta' value={promotor.cuenta ?? ''} disabled={true}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label" htmlFor="promotor_clabe">CLABE</label>
                                                            <input className="form-control" id="promotor_clabe"  placeholder='CLABE' value={promotor.clabe ?? ''} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
		</div>
	)
}

export default ProyectoDetalle;