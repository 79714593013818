import React,  { useState }from 'react';
import { useSelector } from 'react-redux';

import { createEtapa } from '../../../../api/etapa_proyectos/etapa_proyectos';

import { errorNotification, successNotification } from '../../../../helpers';


function ModalNuevaEtapa({ proyecto_id, onSuccessEtapa, onCloseModal }) {
    const token = useSelector(state => state.authentication.user?.token);

    const [formEtapa, setFormEtapa] = useState({
        proyecto_id
    });


    const fecthCreateEtapa = async () => {
        try {
            const response = await createEtapa(formEtapa, token);
            if(response.status === 201) {
                successNotification("Etapa Registrado Correctamente");
                onSuccessEtapa();
                handleOnCloseModal();
            }
        } catch(e) {
            console.error(e);
            errorNotification("Ocurrio un error en el servidor.");
        }
    }


	const handleChangeFormEtapa = (event) => {
		const { name, value } = event.target;
		setFormEtapa({
			...formEtapa,
			[name]: value,
		});
	};


    const handleRegistrarEtapa = (e) => {
		e.preventDefault(); 
		if (e.target.form.checkValidity()) fecthCreateEtapa();
		else errorNotification("Faltan campos requeridos.");
	}


    const cleanForm = () => {
        setFormEtapa({
            proyecto_id
        });
    }


    const handleOnCloseModal = () => {
        document.getElementById('modalRegistrarEtapa').classList.remove('show');
		document.querySelector('.modal-backdrop').remove();
        document.getElementById('modalRegistrarEtapa').style.display = 'none';

        cleanForm();
      
        onCloseModal();
    }

    return(
        <div className="modal modal-cover fade" id="modalRegistrarEtapa" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <div className="modal-dialog modal-md">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Registrar Etapa</h5>
                        <button type="button" className="btn-close" onClick={handleOnCloseModal}></button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3">
                            <form id="formRegistrarEtapa" className="was-validated" >
                                <div className="row row-space-12">	
                                    <div className='row'>
                                        <div className="mb-3">
                                            <div className="col-xl-12">
                                                <label className="form-label">Nombre</label>
                                                <input className="form-control" placeholder="Nombre"
                                                    id="nombre"
                                                    name="nombre"
                                                    value={formEtapa.etapa_nombre}
                                                    onChange={handleChangeFormEtapa}
                                                    required
                                                    pattern=".*\S+.*" />
                                                <div className="invalid-feedback" id="validationRFC">
                                                    Campo requerido
                                                </div>	
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="mb-3">
                                            <div className="col-xl-12">
                                                <label className="form-label">Descripción</label>
                                                <textarea className="form-control"  placeholder='Descripción'
                                                    id="descripcion"
                                                    name="descripcion"
                                                    value={formEtapa.etapa_descripcion}
                                                    onChange={handleChangeFormEtapa}
                                                    >
                                                </textarea>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-default" onClick={handleOnCloseModal}>Cerrar</button>
                        <button type="button" className="btn btn-outline-theme"  onClick={handleRegistrarEtapa} form="formRegistrarEtapa" >Registrar</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ModalNuevaEtapa;