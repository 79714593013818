import { daeApi } from "../daeApi";

export const getEstadosCuenta = async (token) => {
  const response = await daeApi.get(`/estados-cuenta`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const createEstadoCuenta = async (data, token) => {
  const response = await daeApi.post(`/estados/cuenta`, data, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-access-tokens': token 
    }
  });
  return response.data;
}

export const createEstadoCuentaFile = async (data, token) => {
  const response = await daeApi.post(`/estados/cuenta/paquete/file`, data, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-access-tokens': token 
    }
  });
  return response.data;
}

export const getEstadoCuentaById = async (id, token) => {
  const response = await daeApi.get(`/estados-cuenta/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}

export const getEstadoCuentaByPaqueteId = async (id, token) => {
  const response = await daeApi.get(`/estados/cuenta/paquete/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-access-tokens': token 
    }
  });
  return response.data;
}

export const getEstadoCuentaInconsistenciasByPaqueteId = async (id, token) => {
  const response = await daeApi.get(`/estados/cuenta/inconsistencias/paquete/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-access-tokens': token 
    }
  });
  return response.data;
}

export const updateEstadoCuenta = async (id, data, token) => {
  const response = await daeApi.put(`/estados-cuenta/${id}`, data, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}


export const updateEstadoCuentaEstatusMinistracionById = async (id, data, token) => {
  const response = await daeApi.put(`/estados/cuenta/estatus/ministracion/${id}`, data, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-access-tokens': token 
    }
  });
  return response.data;
}

export const updateEstadoCuentaEstatusSubsidioById = async (id, data, token) => {
  const response = await daeApi.put(`/estados/cuenta/estatus/subsidio/${id}`, data, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-access-tokens': token 
    }
  });
  return response.data;
}

export const updateEstadoCuentaEstatusById = async (id, data, token) => {
  const response = await daeApi.put(`/estados/cuenta/estatus/${id}`, data, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-access-tokens': token 
    }
  });
  return response.data;
}

///estados/cuenta/valor/paquete/
export const updateEstadoCuentaValorPaqueteByPaqueteId = async (id, token) => {
  const response = await daeApi.get(`/estados/cuenta/valor/paquete/${id}`, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'x-access-tokens': token 
    }
  });
  return response.data;
}


export const deleteEstadoCuenta = async (id, token) => {
  const response = await daeApi.delete(`/estados-cuenta/${id}`, {
    headers: {
      'Content-Type'	: 'application/json',
      'Accept'			: 'application/json',
      'x-access-tokens' : token
    }
  });
  return response.data;
}
