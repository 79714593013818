import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardFooter } from '../../components/card/card.jsx';
import { AppSettings } from '../../config/app-settings.js';

function PagesAccountActivate() {
	const context = useContext(AppSettings);
	
	useEffect(() => {
		context.setAppHeaderNone(true);
		context.setAppTopNav(false);
		context.setAppContentClass('p-0');
		
		return function cleanUp() {
			context.setAppHeaderNone(false);
			context.setAppTopNav(true);
			context.setAppContentClass('');
		};
	}, []);

	return (
		<div className="error-page"  >
			<div className="error-page-content">
				<Card className="mb-5 mx-auto" >
					<CardBody>
						<Card>
							<div className="error-code">CUENTA ACTIVA</div>
						</Card>
					</CardBody>
				</Card>
				<CardFooter>
					<div className="ml-auto">
						<Link to="/pages/login"  className="btn btn-outline-default">Iniciar Sesión</Link>
					</div>
				</CardFooter>	
			</div>
		</div>
	)
}

export default PagesAccountActivate;