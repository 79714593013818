import React, { useEffect, useState }  from 'react';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';

import Breadcrumb from '../../../components/breadcrumb/breadcrumb';
import { Card, CardBody, CardHeader } from '../../../components/card/card';

import { getPromotores, getPromotorBancosById } from '../../../api/promotores/promotores'
import { getBancosProyectos, getBancoById } from '../../../api/bancos/bancos';
import { createDireccion, getDireccionSepomexByCp } from '../../../api/direcciones/direcciones';
import { createProyecto } from '../../../api/proyectos/proyectos';

import { errorNotification, warningNotification, successNotification } from '../../../helpers';

function ProyectoNuevo(props) {
    const navigate = useNavigate();
    const token = useSelector(state => state.authentication.user?.token);

	const [promotores, setPromotores] = useState([]);
    const [promotor, setPromotor] = useState({});
    const [bancos, setBancos] = useState([]);
    const [banco, setBanco] = useState({});
    const [colonias, setColonias] = useState([]);

	const [selectedPromotor, setSelectedPromotor] = useState(0);
    const [selectedBanco, setSelectedBanco] = useState(0);

    const [formProyecto, setFormProyecto] = useState({
		banco_id: 0,
        promotor_id : 0,
        nombre_proyecto : '',
        val_mesa_inversion : 0.0,
        des_mesa_inversion : 1,
        can_estado_cuenta_proyecto : 0,
        num_viviendas_autorizadas : 0,

		calle: '',
		num_exterior : '',
		num_interior : '',
		colonia: '',
        municipio : '',
        estado : '',
		cp: '',
	});

	useEffect(() => { 
		fetchPromotores();
        fetchBancos();
    }, [token]);

    const fetchPromotores = async () => {
        try {
            const response = await getPromotores(token);
            setPromotores(response) 
        } catch (error) {
            console.error('Error al cargar los bancos', error);
        }
    };

    const fetchBancos = async () => {
        try {
            const response = await getBancosProyectos(token);
            setBancos(response) 
        } catch (error) {
            console.error('Error al cargar los bancos', error);
        }
    };

    const fetchBancoById = async (id) => {
        try {
            const response = await getBancoById(id, token);
            setBanco(response);
        } catch (error) {
            console.error('Error al cargar el banco', error);
        }
    }

    const fetchPromotorBancosById = async (id) => {
        try {
            const response = await getPromotorBancosById(id, token);
            setPromotor(response);
        } catch (error) {
            console.error('Error al cargar el promotor', error);
        }  
    }

    const fetchDireccionByCP = async (cp) => {
        const response = await getDireccionSepomexByCp(cp, token);
        if(response.status === 200) {
            setFormProyecto({
                ...formProyecto,
                estado : response.data.codigo_postal.estado,
                municipio : response.data.codigo_postal.municipio,
                cp : cp
            });
            setColonias(response.data.codigo_postal.colonias)
        } else if(response.status === 204) {
            errorNotification("No es posible obtener la direccion. Verifique el CP.")
        }
    }

    const fetchCreateProyecto = async (data) => {
        const respCreateProyecto = await createProyecto(data, token);
        if(respCreateProyecto.status === 201) {
            successNotification("Proyecto Creado Correctamente");
            clearForm();
            navigate('/proyecto/lista');
        } else {
            errorNotification("No es posible crear el proyecto");
        }
    }

    const fetchCreateDireccion = async (data) => {
        const respCreateDireccion = await createDireccion(data, token);
        if(respCreateDireccion.status === 201) {
            let dataProyecto = {
                "banco_id" : formProyecto.banco_id,
                "direccion_id" : respCreateDireccion.message,
                "promotor_id" : formProyecto.promotor_id,
                "nombre" : formProyecto.nombre_proyecto,
                "val_mesa_inversion" : formProyecto.val_mesa_inversion,
                "des_mesa_inversion" : formProyecto.des_mesa_inversion,
                "can_estado_cuenta_proyecto" : formProyecto.des_mesa_inversion,
                "num_viviendas_autorizadas" : formProyecto.num_viviendas_autorizadas
            }
            fetchCreateProyecto(dataProyecto);
        } else {
            errorNotification("No es posible crear la dirección");
        }
    }

    const handleNuevoProyecto = async (e) => {
        e.preventDefault(); 
		if (e.target.form.checkValidity()) {
            if(formProyecto.colonia === '')
                warningNotification("Es necesario elegir una colonia.")
            else
                nuevoProyecto()
        } else 
			errorNotification("Faltan campos requeridos.")
    }
    
    const nuevoProyecto = async() => {
        if(formProyecto.promotor_id === 0) warningNotification("Es necesario elegir un Promotor");
        else 
            if(formProyecto.banco_id === 0) warningNotification("Es necesario elegir un Banco")
            else {
                let dataDireccion = {
                    "calle" : formProyecto.calle,
                    "num_interior" : formProyecto.num_interior,
                    "num_exterior" : formProyecto.num_exterior,
                    "colonia" : formProyecto.colonia,
                    "municipio" : formProyecto.municipio,
                    "estado" : formProyecto.estado,
                    "cp" : formProyecto.cp
                }
                fetchCreateDireccion(dataDireccion);
            }
    }

	const handleBancosChange = async (event) => {
		const selectedId = event.target.value;
		setSelectedBanco(selectedId);
        fetchBancoById(selectedId);
        setFormProyecto({
            ...formProyecto,
            banco_id : selectedId
        });
	}

	const handlePromotorChange = async (event) => {
		const selectedId = event.target.value;
		setSelectedPromotor(selectedId);
        fetchPromotorBancosById(selectedId);
        setFormProyecto({
            ...formProyecto,
            promotor_id : selectedId
        });
	}

    const handleChangeFormProyecto = (event) => {
		const { name, value } = event.target;
		if (name === 'cp') {
			setFormProyecto((prevForm) => ({
				...prevForm,
				[name]: value,
			}));

			if (/^\d{5}$/.test(value)) {
				fetchDireccionByCP(value);
			} else {
				if(value.length === 5)
					errorNotification('El código postal debe contener 5 dígitos numéricos.');
			}
		} else {
			setFormProyecto({
				...formProyecto,
				[name]: value,
			});
		}
	};

    const clearForm = () => {
		setFormProyecto({
			banco_id: 0,
            promotor_id : 0,
            nombre_proyecto : '',
            val_mesa_inversion : 0.0,
            des_mesa_inversion : 1,
            can_estado_cuenta_proyecto : 0,
            num_viviendas_autorizadas : 0,

            calle: '',
            num_exterior : '',
            num_interior : '',
            colonia: '',
            municipio : '',
            estado : '',
            cp: '',
		});

		setColonias([]);
        setPromotor({});
        setBanco({});
    }

    const handleBackRoute = () => {
        navigate('/proyecto/lista');
    }

	return (
		<div>
			<Breadcrumb title={props.title} subtitle={props.subtitle}/>
			<div className="row justify-content-center">
                <hr className="mb-4" />
				<div className="col-xl-10">
                    <div className="list-group-item d-flex align-items-center" style={{marginBottom: '1em', marginTop: '-0.5em'}}>
                        <a class="btn btn-outline-default text-nowrap btn-sm px-3 rounded-pill" onClick={handleBackRoute}><i class="fa fa-arrow-left me-1 ms-n1"></i> Atras</a>
                        <div className="flex-1 text-break"></div>
                        <div className="ml-auto">
                            <button className="btn btn-outline-default" onClick={handleNuevoProyecto} form="formProyecto" >Registrar Proyecto</button>
                        </div>
                    </div>
                </div>
                <div className="col-xl-10">
				<form id="formProyecto" className="was-validated" >
                    <div className="col-xl-12">
                        <div id="formControls" className="mb-5">  
                            <Card>
                                <CardHeader className="fw-bold small d-flex">
                                    <span className="flex-grow-1">Datos del Proyecto</span>
                                </CardHeader>
                                <CardBody>
                                
                                        <div className="row">
                                            <div className="col-xl-12">
                                                <div className="row">
                                                    <div className="col-xl-4">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label" htmlFor="nombre_proyecto">Nombre del Proyecto</label>
                                                            <input type="text" className="form-control" placeholder='Nombre del Proyecto'
                                                                id="nombre_proyecto"
                                                                name="nombre_proyecto"
                                                                value={formProyecto.nombre_proyecto}
                                                                onChange={handleChangeFormProyecto}
                                                                required
                                                                pattern=".*\S+.*" />
                                                            <div className="invalid-feedback" id="validationNombre">
                                                                Campo requerido
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label" htmlFor="calle">Direccion</label>
                                                            <input className="form-control" placeholder='Direccion' 
                                                                id="calle"
                                                                name="calle"
                                                                value={formProyecto.calle}
                                                                onChange={handleChangeFormProyecto}
                                                                required
                                                                pattern=".*\S+.*" />
                                                            <div className="invalid-feedback" id="validationDireccion">
                                                                Campo requerido
                                                            </div>	
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-2">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label" htmlFor="num_interior">No. Interior</label>
                                                            <input className="form-control" placeholder='No. Interior'
                                                                id="num_interior"
                                                                name="num_interior"
                                                                value={formProyecto.num_interior}
                                                                onChange={handleChangeFormProyecto}
                                                                 />
                                                            	
                                                            
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-2">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label" htmlFor="num_exterior">No. Exterior</label>
                                                            <input className="form-control" placeholder='No. Exterior'
                                                                id="num_exterior"
                                                                name="num_exterior"
                                                                value={formProyecto.num_exterior}
                                                                onChange={handleChangeFormProyecto}
                                                                 />
                                                            	
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-xl-12">
                                                <div className="row">
                                                    <div className="col-xl-3">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label" htmlFor="colonia">Colonia</label>
                                                            <select className="form-select" onChange={handleChangeFormProyecto} value={formProyecto.colonia} id='colonia' name='colonia' >
                                                                <option value="">Selecciona una colonia</option>
                                                                {colonias.map((colonia, key) => (
                                                                    <option key={key} value={colonia}>
                                                                        {colonia}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                            <div className="invalid-feedback" id="validationNoInterior">
                                                                Campo requerido
                                                            </div>	 
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label" htmlFor="municipio">Municipio</label>
                                                            <input className="form-control" placeholder='Municipio'
                                                                id="municipio"
                                                                name="municipio"
                                                                value={formProyecto.municipio}
                                                                onChange={handleChangeFormProyecto}
                                                                required
                                                                disabled={true}
                                                                pattern=".*\S+.*" />
                                                            <div className="invalid-feedback" id="validationNoInterior">
                                                                Campo requerido
                                                            </div>	    
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label" htmlFor="estado">Estado</label>
                                                            <input className="form-control"  placeholder='Estado'
                                                                id="estado"
                                                                name="estado"
                                                                value={formProyecto.estado}
                                                                onChange={handleChangeFormProyecto}
                                                                required
                                                                disabled={true}
                                                                pattern=".*\S+.*" />
                                                            <div className="invalid-feedback" id="validationNoInterior">
                                                                Campo requerido
                                                            </div>	    
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label" htmlFor="cp">C.P.</label>
                                                            <input className="form-control" placeholder='C.P,'
                                                                id="cp"
                                                                name="cp"
                                                                value={formProyecto.cp}
                                                                onChange={handleChangeFormProyecto}
                                                                required
                                                                pattern=".*\S+.*" />
                                                            <div className="invalid-feedback" id="validationCP">
                                                                Campo requerido
                                                            </div>	
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-xl-12">
                                                <div className="row">
                                                    <div className="col-xl-3">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label" htmlFor="num_viviendas_autorizadas">Viviendas Autorizadas</label>
                                                            <input type="text" className="form-control" placeholder='Viviendas Autorizadas' 
                                                                id="num_viviendas_autorizadas"
                                                                name="num_viviendas_autorizadas"
                                                                value={formProyecto.num_viviendas_autorizadas}
                                                                onChange={handleChangeFormProyecto}
                                                                required
                                                                pattern=".*\S+.*" />
                                                            <div className="invalid-feedback" id="validationCP">
                                                                Campo requerido
                                                            </div>	
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                    <div className="col-xl-12">
                        <div id="formControls" className="mb-5">
                            <Card>
                                <CardHeader className="fw-bold small d-flex">
                                    <span className="flex-grow-1">Datos del Promotor</span>
                                </CardHeader>
                                <CardBody>
                                    
                                        <div className="row">
                                            <div className="col-xl-12">
                                                <div className="row">
                                                    <div className="col-xl-6">
                                                        <div className="form-group mb-3">
                                                        <label className="form-label" htmlFor="promotor_nombre" >Promotor</label>
                                                            <select className="form-select" id="promotor_nombre"  onChange={handlePromotorChange} value={selectedPromotor}>
                                                            <option value="">Selecciona un promotor</option>
                                                            {promotores.map((promotor) => (
                                                                <option key={promotor.id} value={promotor.id}>
                                                                    {promotor.promotor}
                                                                </option>
                                                            ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label" htmlFor="promotor_rfc">RFC</label>
                                                            <input className="form-control" id="promotor_rfc" value={promotor.rfc} disabled={true} placeholder='RFC'/>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-xl-12">
                                                <div className="row">
                                                    <div className="col-xl-6">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label" htmlFor="promotor_calle">Direccion</label>
                                                            <input type="text" className="form-control" id="promotor_calle"  placeholder='Direccion' value={promotor.calle} disabled={true}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label" htmlFor="promotor_no_interior">No. Interior</label>
                                                            <input className="form-control" id="promotor_no_interior" placeholder='No. Interior' value={promotor.num_interior} disabled={true} />
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label" htmlFor="promotor_no_exterior">No. Exterior</label>
                                                            <input className="form-control" id="promotor_no_exterior" placeholder='No. Exterior' value={promotor.num_exterior} disabled={true}/>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-xl-12">
                                                <div className="row">
                                                    <div className="col-xl-3">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label" htmlFor="promotor_colonia">Colonia</label>
                                                            <input type="text" className="form-control" id="promotor_colonia" placeholder='Colonia' value={promotor.colonia} disabled={true}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label" htmlFor="promotor_municipio">Municipio</label>
                                                            <input className="form-control" id="promotor_municipio"  placeholder='Municipio' value={promotor.municipio} disabled={true}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label" htmlFor="promotor_estado">Estado</label>
                                                            <input className="form-control" id="promotor_estado" placeholder='Estado' value={promotor.estado} disabled={true}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-3">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label" htmlFor="promotor_cp">C.P.</label>
                                                            <input className="form-control" id="promotor_cp"  placeholder='C.P.' value={promotor.cp} disabled={true}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                

                                </CardBody>
                            </Card>
                        </div>
                    </div>
                    <div className="col-xl-12">
                        <div id="formControls" className="mb-5">
                            <Card>
                                <CardHeader className="fw-bold small d-flex">
                                    <span className="flex-grow-1">Datos del Bancarios</span>
                                </CardHeader>
                                <CardBody>
                                    
                                        <div className="row">
                                            <h6>Datos DAE</h6>
                                            <div className="col-xl-12">
                                                <div className="row">
                                                    <div className="col-xl-4">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label" htmlFor="proyecto_banco">Nombre del Banco</label>
                                                            <select className="form-select"  id="proyecto_banco" onChange={handleBancosChange} value={selectedBanco}>
                                                                <option value="">Selecciona un Banco</option>
                                                                {bancos.map((b) => (
                                                                    <option key={b.id} value={b.id}>
                                                                        {b.nombre}
                                                                    </option>
                                                                ))}
                                                            </select>        
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label" htmlFor="proyecto_cuenta">No. Cuenta</label>
                                                            <input type="text" className="form-control" id="proyecto_cuenta"  placeholder='No. Cuenta'  disabled={true} value={banco.cuenta} />
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label" htmlFor="proyecto_clabe">CLABE</label>
                                                            <input className="form-control" id="proyecto_clabe"  placeholder='CLABE' disabled={true} value={banco.clabe}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <h6>Datos Promotor</h6>
                                            <div className="col-xl-12">
                                                <div className="row">
                                                    <div className="col-xl-4">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label" htmlFor="promotor_banco">Nombre del Banco</label>
                                                            <input type="text" className="form-control" id="promotor_banco" placeholder='Banco' value={promotor.nombre_banco} disabled={true}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label" htmlFor="promotor_cuenta">No. Cuenta</label>
                                                            <input type="text" className="form-control" id="promotor_cuenta" placeholder='No. Cuenta' value={promotor.cuenta} disabled={true}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4">
                                                        <div className="form-group mb-3">
                                                            <label className="form-label" htmlFor="promotor_clabe">CLABE</label>
                                                            <input className="form-control" id="promotor_clabe"  placeholder='CLABE' value={promotor.clabe} disabled={true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </form>
                </div>
			</div>
		</div>
	)
}

export default ProyectoNuevo;