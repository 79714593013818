import React,  { useEffect, useState }from 'react';
import { useSelector } from 'react-redux';

import { Card, CardBody, CardHeader }  from '../../../../components/card/card';

import { 
    getPaqueteById, 
    getPaqueteAnticiposById 
} from '../../../../api/paquete_proyectos/paquete_proyectos';
import { getDerechohabientesTotalByPaqueteId, 
    getViviendasSubsidioByPaqueteId, 
    getDerechohabienteValorPaqueteByPaqueteId,
    getDerechohabienteValorRemanenteByPaqueteId,
} from '../../../../api/derechohabiente/derechohabiente';
import { 
    getMinistracionesPorcentajeObraByPaqueteId, 
    getMinistracionesComisionesByPaqueteId, 
    getMinistracionesValorByPaqueteId,
    getMinistracionesTotalByPaqueteId,
    getMinistracionesFondoGarantiaByPaqueteId
} from '../../../../api/ministraciones/ministraciones';
import { getFondoInversionGeneradaByPaqueteId } from '../../../../api/fondo_inversion/fondo_inversion';

import { formatoMoneda } from '../../../../helpers';

function ModalDetallePaquete({ sharedPaqueteId, onCloseModal }) {
    const token = useSelector(state => state.authentication.user?.token);
    
    const [paquete, setPaquete] = useState({});
    const [totalViviendas, setTotalViviendas] = useState(0);
    const [subisidioViviendas, setSubsidioViviendas] = useState(0);
    const [porcentajeObra, setPorcentajeObra] = useState("");
    const [comisionObra, setComisionObra] = useState(0.0);
    const [comisionMercantil, setComisionMercantil] = useState({});
    const [comisionTotal, setComisionTotal] = useState(0.0);
    const [comisionCobrada, setComisionCobrada] = useState(0.0);
    const [valorPaqute, setValorPaquete] = useState(0);
    const [valorMinistracion, setValorMinistracion] = useState(0);
    const [valorMinistracionNeta, setValorMinistracionNeta] = useState(0);
    const [valorRemanente, setValorRemanente] = useState(0);
    const [fondoGarantia, setFondoGarantia] = useState(0);
    const [fondoInversion, setFondoInversion] = useState("");
    
    useEffect(() => {
        if(sharedPaqueteId !== null && token !== undefined)
            if(sharedPaqueteId !== 0) {
                fetchPaqueteById();
            }
    }, [sharedPaqueteId, token]);


    const fetchPaqueteById = async() => {
        try {
            const response = await getPaqueteById(sharedPaqueteId, token);
            setPaquete(response);
            fetchDerechohabientesTotalByPaqueteId();
        } catch(e) {
            console.log(e);
        }
    }


    const fetchDerechohabientesTotalByPaqueteId = async() => {
        try {
            const response = await getDerechohabientesTotalByPaqueteId(sharedPaqueteId, token);
            setTotalViviendas(response.message)
            fetchViviendasSubsidioByPaqueteId();
        } catch(e) {
            console.log(e);
        }
    }


    const fetchViviendasSubsidioByPaqueteId = async() => {
        try {
            const response = await getViviendasSubsidioByPaqueteId(sharedPaqueteId, token);
            setSubsidioViviendas(response.message);
            fetchMinistracionesPorcentajeObraByPaqueteId();
        } catch(e) {
            console.log(e);
        }
    }


    const fetchMinistracionesPorcentajeObraByPaqueteId = async() => {
        try {
            const response = await getMinistracionesPorcentajeObraByPaqueteId(sharedPaqueteId, 1, token);
            setPorcentajeObra(response.message);
            fetchMinistracionesComisionesByPaqueteId();
        } catch(e) {
            console.log(e);
        }
    }


    const fetchMinistracionesComisionesByPaqueteId= async() => {
        try {
            const response = await getMinistracionesComisionesByPaqueteId(sharedPaqueteId, token);
            
            let comision_total = parseFloat(response.comision)  + parseFloat(response.iva) + parseFloat(response.mercantil) ;
            let comision_cobrada = parseFloat(response.val_comision_mercantil_administracion);

            setComisionObra(parseFloat(response.comision));
            setComisionMercantil(parseFloat(response.mercantil));
            setComisionTotal(comision_total);
            setComisionCobrada(comision_cobrada);

            fetchFondoInversionGeneradaByPaqueteId();
        } catch(e) {
            console.log(e);
        }
    }


    const fetchFondoInversionGeneradaByPaqueteId = async() => {
        try {
            const response = await getFondoInversionGeneradaByPaqueteId(sharedPaqueteId, token);
            setFondoInversion(parseFloat(response.message));
            fetchDerechohabienteValorPaqueteByPaqueteId();
        } catch(e) {
            console.log(e);
        }
    }


    const fetchDerechohabienteValorPaqueteByPaqueteId = async() => {
        try {
            const response = await getDerechohabienteValorPaqueteByPaqueteId(sharedPaqueteId, token);
            setValorPaquete(parseFloat(response.message));
            fetchMinistracionesValorByPaqueteId(response.message);
        } catch(e) {
            console.log(e);
        }
    }


    const fetchMinistracionesValorByPaqueteId = async(valor_paquete) => {
        try {
            const response = await getMinistracionesValorByPaqueteId(sharedPaqueteId, token);
            setValorMinistracion(parseFloat(response.message));
            fetchMinistracionesTotalByPaqueteId(valor_paquete, response.message);
        } catch(e) {
            console.log(e);
        }
    }


    const fetchMinistracionesTotalByPaqueteId = async(valor_paquete, ministracion_total) => {
        try {
            const response = await getMinistracionesTotalByPaqueteId(sharedPaqueteId, token);
            setValorMinistracionNeta(parseFloat(response.message));
            fetchDerechohabienteValorRemanenteByPaqueteId(valor_paquete, ministracion_total);
        } catch(e) {
            console.log(e);
        }
    }


    const fetchDerechohabienteValorRemanenteByPaqueteId = async(valor_paquete, ministracion_total) => {
        try {
            const response = await getDerechohabienteValorRemanenteByPaqueteId(sharedPaqueteId, token);

            var remanentes_cuenta_total = parseFloat(response.message);
			var remanentes_cuenta = parseFloat(valor_paquete) - parseFloat(ministracion_total) - remanentes_cuenta_total;

            setValorRemanente(remanentes_cuenta);
            fetchMinistracionesFondoGarantiaByPaqueteId();
        } catch(e) {
            console.log(e);
        }
    }


    const fetchMinistracionesFondoGarantiaByPaqueteId = async() => {
        try {
            const response = await getMinistracionesFondoGarantiaByPaqueteId(sharedPaqueteId, token);
            fetchPaquetesAnticiposById(response.message);
        } catch(e) {
            console.log(e);
        }
    }


    const fetchPaquetesAnticiposById = async(fondo_garantia) => {
        try {
            const response = await getPaqueteAnticiposById(sharedPaqueteId, token);
            let retencion = parseFloat(response[0].retencion);
            setFondoGarantia(retencion + parseFloat(fondo_garantia));
        } catch(e) {
            console.log(e);
        }
    }


    const handleOnCloseModal = () => {
        document.getElementById('modalDetallePaquete').classList.remove('show');
		document.querySelector('.modal-backdrop').remove();
        document.getElementById('modalDetallePaquete').style.display = 'none';

        setPaquete({});
        setTotalViviendas(0);
        setSubsidioViviendas(0);
        setPorcentajeObra("");
        setComisionObra(0.0);
        setComisionMercantil({});
        setComisionTotal(0.0);
        setComisionCobrada(0.0);
        setValorPaquete(0);
        setValorMinistracion(0);
        setValorMinistracionNeta(0);
        setValorRemanente(0);
        setFondoGarantia(0);
        setFondoInversion("");

        onCloseModal();
    }

    
    return(
        <div id="modalDetallePaquete" className="modal modal-cover fade" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" >
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                    <h5 className="modal-title">Detalle Paquete</h5>
                        <button type="button" className="btn-close" onClick={handleOnCloseModal}></button>
                    </div>
                    <div className="modal-body">
                        <form id="formDetallePaquete" className="was-validated" >
                            <div className="col-xl-12">
                                <div id="formControls" className="mb-5">  
                                    <Card>
                                        <CardHeader>
                                            <span className="flex-grow-1">Detalles Generales</span>
                                        </CardHeader>
                                        <CardBody>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="table-responsive">
                                                        <table className="table">
                                                            <thead>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Meses de desarrollo</th>
                                                                <th>Comisión por Obra</th>
                                                                <th>Comisión Mercantil</th>
                                                                <th>Otros: </th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            <tr>
                                                                <td id="paquetenumero">{paquete.numero}</td>
                                                                <td id="cantidadmesespaquete">{paquete.can_meses_desarrollo} {paquete.can_meses_desarrollo === 1 ? " Mes" : " Meses"}</td>
                                                                <td id="comisionobratotal">{formatoMoneda.format(comisionObra)}</td>
                                                                <td id="comisionmercantiltotal">{formatoMoneda.format(comisionMercantil)}</td>
                                                                <td>
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <div className="row">
                                                                                <div className="col-md-8">
                                                                                    <b>Avance Total del Paquete</b>
                                                                                </div>
                                                                                <div className="col-md-4">
                                                                                    <b id="avanceprocentaje" style={{ fontSize: "20px" }}>{porcentajeObra}</b>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-md-8">
                                                                                    <b>Número Total de Viviendas</b>
                                                                                </div>
                                                                                <div className="col-md-4">
                                                                                    <b id="numtotalviviendas">{totalViviendas}</b>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row">
                                                                                <div className="col-md-8">
                                                                                    <b>Número Total de Vivienda con subsidio</b>
                                                                                </div>
                                                                                <div className="col-md-4">
                                                                                    <b id="numtotalviviendassub">{subisidioViviendas}</b>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                            </div>
                            <div className="col-xl-12">
                                <div id="formControls" className="mb-5">  
                                    <Card>
                                        <CardHeader>
                                            <span className="flex-grow-1">Detalle de Cálculos</span>
                                        </CardHeader>
                                        <CardBody>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="table-responsive">
                                                        <table className="table">
                                                            <thead>
                                                            <tr>
                                                                <th>Comisión Total a Cobrar</th>
                                                                <th>Comisión Cobrada</th>
                                                                <th>Comisión Faltante por Cobrar</th>
                                                                <th>Total de Inversiones generadas</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            <tr>
                                                                <td id="comisiontotal">{formatoMoneda.format(comisionTotal)}</td>
                                                                <td id="comisioncobrada">{formatoMoneda.format(comisionCobrada)}</td>
                                                                <td id="comisionfaltantetotal">{formatoMoneda.format(comisionTotal - comisionCobrada)}</td>
                                                                <td id="invercionesgeneradas">{formatoMoneda.format(fondoInversion)}</td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                            </div>
                            <div className="col-xl-12">
                                <div id="formControls" className="mb-5">  
                                    <Card>
                                        <CardHeader>
                                            <span className="flex-grow-1">Detalle de Cálculos</span>
                                        </CardHeader>
                                        <CardBody>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="table-responsive">
                                                        <table className="table">
                                                            <thead>
                                                            <tr>
                                                                <th>Valor del paquete</th>
                                                                <th>Valor de ministraciones</th>
                                                                <th>Montos netos ministrados al promotor</th>
                                                                <th>Remanente en la cuenta</th>
                                                                <th>Fondo de garantía</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            <tr>
                                                                <td id="valorpaquete" >{formatoMoneda.format(valorPaqute)}</td>
                                                                <td id="ministracionestotal">{formatoMoneda.format(valorMinistracion)}</td>
                                                                <td id="montosministradopro">{formatoMoneda.format(valorMinistracionNeta)}</td>
                                                                <td id="remanentesP">{formatoMoneda.format(valorRemanente)}</td>
                                                                <td id="fondogarantiapa">{formatoMoneda.format(parseFloat(fondoGarantia))}</td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalDetallePaquete;