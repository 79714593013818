import React,  { useState, useEffect }from 'react';
import { useSelector } from 'react-redux';

import { useNavigate, useLocation } from "react-router-dom";
import useAuthorization from '../../../hooks/useAuthorization';

import Breadcrumb from '../../../components/breadcrumb/breadcrumb';
import { Modal } from 'bootstrap'

import TableDerechohabientes from './tablas/derechohabientes/derechohabientes';
import TableEtapas from './tablas/etapas';
import TablePaquetes from './tablas/paquetes';
import ModalNuevaEtapa from './modals/nueva-etapa';
import ModalPaquete from './modals/paquete';
import ModalEditarEtapas from './modals/editar-etapa';
import ModalDerechohabiente from './modals/derechohabiente';
import ModalCalculosProyectos from './modals/calculos-proyectos';
import ModalDetallePaquete from './modals/detalle-paquete';
import ModalEditarPaquete from './modals/editar-paquete';
import ModalCalculosComisiones from './modals/calculos-comisiones';
import ModalCalculosAnticipos from './modals/anticipos';

import { errorNotification } from '../../../helpers';

import { getEtapasByProyectoId } from '../../../api/etapa_proyectos/etapa_proyectos';
import { getPaquetesByEtapaId } from '../../../api/paquete_proyectos/paquete_proyectos';
import { getDerechohabientesByPaqueteId } from '../../../api/derechohabiente/derechohabiente';

const $ = require('jquery');


function ProyectoPaquetes(props) {
	const token = useSelector(state => state.authentication.user?.token);

	const navigate = useNavigate();
	const location = useLocation();
	const userRole = useAuthorization();

	const [sharedEtapaId, setSharedEtapaId] = useState(null);
	const [sharedPaqueteId, setSharedPaqueteId] = useState(null);
	const [sharedDerechohabienteId, setSharedDerechohabienteId] = useState(null);
	
	const [etapas, setEtapas] = useState([]);
	const [paquetes, setPaquetes] = useState([]);
	const [derechohabientes, setDerechohabientes] = useState([]);

	const [showTablePaquetes, setShowTablePaquetes] = useState(false);
	const [showTableDerechohabientes, setShowTableDerechohabientes] = useState(false);
	
	const [etapaNombre, setEtapaNombre] = useState(""); 

	const [paqueteNombre, setPaqueteNombre] = useState(""); 
	const [paqueteEstatus, setPaqueteEstatus] = useState(false); 

	const [isModalOpen, setIsModalOpen] = useState(false);
    const [activeModal, setActiveModal] = useState("");
	
	let proyecto_id, nom_proyecto;

	if (location.state && location.state.proyecto) {
		proyecto_id = location.state.proyecto.proyecto_id;
		nom_proyecto = location.state.proyecto.nom_proyecto;
		
	} else {
		navigate('/proyecto/lista');
	}


	useEffect(() => {
		if(token != undefined)
			fetchEtapasByProyectoId(proyecto_id);
	}, [token])


	useEffect(() => {
		if(isModalOpen && activeModal === 'CalculosProyectos') {
			let myModal = new Modal(document.getElementById('modalCalculos'));
			myModal.show();
		} else if(isModalOpen && activeModal === 'DetallePaquete') {
			let myModal = new Modal(document.getElementById('modalDetallePaquete'));
			myModal.show();
		} else if(isModalOpen && activeModal === 'EditarPaquete') {
			let myModal = new Modal(document.getElementById('modalEditarPaquete'));
			myModal.show();
		} else if(isModalOpen && activeModal === 'CalculosComisiones') {
			let myModal = new Modal(document.getElementById('modalCalculosComisiones'));
			myModal.show();
		} else if(isModalOpen && activeModal === 'CalculosAnticipos') {
			let myModal = new Modal(document.getElementById('modalCalculosAnticipos'));
			myModal.show();
		} else if(isModalOpen && activeModal === 'NuevaEtapa') {
			let myModal = new Modal(document.getElementById('modalRegistrarEtapa'));
			myModal.show();
		} else if(isModalOpen && activeModal === 'EditarEtapas') {
			let myModal = new Modal(document.getElementById('modalEditarEtapas'));
			myModal.show();
		} else if(isModalOpen && activeModal === 'Paquete') {
			let myModal = new Modal(document.getElementById('modalPaquete'));
			myModal.show();
		} else if(isModalOpen && activeModal === 'Derechohabiente') {
			let myModal = new Modal(document.getElementById('modalDerechohabiente'));
			myModal.show();
		}
	}, [isModalOpen, activeModal]);


	const fetchEtapasByProyectoId = async (proyecto_id) => {
		try {
			const response = await getEtapasByProyectoId(proyecto_id, token);
			setEtapas(response);
		} catch (error) {
			errorNotification("Ocurrio un error en el servidor.");
		}
	};

	
	const fetchPaquetesByEtapaId = async (etapa_id) => {
		try {
			const response = await getPaquetesByEtapaId(etapa_id, token);
			setPaquetes(response);
		} catch (error) {
			errorNotification("Ocurrio un error en el servidor.");
		}
	};


	const fetchDerechohabientesByPaqueteId = async (paquete_id) => {
        try {
            const response = await getDerechohabientesByPaqueteId(paquete_id, token);
            setDerechohabientes(response);
        } catch (error) {
            console.log("Ocurrio un error en el servidor.", error);
        }
    }

	
	const handleShowTablePaquetes = (etapa_id, nombre) => {
        setShowTablePaquetes(true);
		setSharedEtapaId(etapa_id);
		setEtapaNombre(nombre);
		setSharedDerechohabienteId(0);
		setShowTableDerechohabientes(false);
		fetchPaquetesByEtapaId(etapa_id);
    };
 
	
	const handleShowDerechohabientes = (paquete_id, nombre, view, estatus) => {
		setShowTableDerechohabientes(view);
		setSharedPaqueteId(paquete_id);
		setPaqueteNombre(nombre);
		setPaqueteEstatus(estatus);
		fetchDerechohabientesByPaqueteId(paquete_id);
	}

	
	const renderModals = () => {
		if (['tecnico'].some(role => userRole.includes(role))) {
			return (
				<>
					{ isModalOpen && activeModal === "CalculosProyectos" && <ModalCalculosProyectos proyecto_id={proyecto_id} onCloseModal={handleCloseModal} /> }
					{ isModalOpen && activeModal === "DetallePaquete" && <ModalDetallePaquete sharedPaqueteId={sharedPaqueteId} onCloseModal={handleCloseModal} /> }
					{ isModalOpen && activeModal === "EditarPaquete" && <ModalEditarPaquete sharedPaqueteId={sharedPaqueteId} onCloseModal={handleCloseModal} /> }
					{ isModalOpen && activeModal === "CalculosComisiones" && <ModalCalculosComisiones sharedDerechohabienteId={sharedDerechohabienteId} onCloseModal={handleCloseModal} /> }
					{ isModalOpen && activeModal === "CalculosAnticipos" && <ModalCalculosAnticipos sharedDerechohabienteId={sharedDerechohabienteId} onCloseModal={handleCloseModal} /> }
				</>
			)
		} else if (['administracion'].some(role => userRole.includes(role))) {
			return (
				<>
					{ isModalOpen && activeModal === "NuevaEtapa" && <ModalNuevaEtapa proyecto_id={proyecto_id} onSuccessEtapa={handleSuccessEtapa} onCloseModal={handleCloseModal} /> }
					{ isModalOpen && activeModal === "EditarEtapas" && <ModalEditarEtapas etapa_id={sharedEtapaId} onSuccessEtapa={handleSuccessEtapa} onCloseModal={handleCloseModal} /> }
					{ isModalOpen && activeModal === "Paquete" && <ModalPaquete etapa_id={sharedEtapaId} sharedPaqueteId={sharedPaqueteId} onSuccessPaquete={handleSuccessPaquete} onCloseModal={handleCloseModal} /> }
				</>
			)
		} else if (['juridico'].some(role => userRole.includes(role))) {
			return (
				<>
					{ isModalOpen && activeModal === "Derechohabiente" && <ModalDerechohabiente sharedDerechohabienteId={sharedDerechohabienteId} proyecto_id={proyecto_id} paquete_id={sharedPaqueteId} onSuccessDerechohabiente={handleSuccessDerechohabientes} onCloseModal={handleCloseModal} /> }
				</>
			)
		} else if (['master'].some(role => userRole.includes(role))) {
			return (
				<>
					{ isModalOpen && activeModal === "CalculosProyectos" && <ModalCalculosProyectos proyecto_id={proyecto_id} onCloseModal={handleCloseModal} /> }
					{ isModalOpen && activeModal === "DetallePaquete" && <ModalDetallePaquete sharedPaqueteId={sharedPaqueteId} onCloseModal={handleCloseModal} /> }
					{ isModalOpen && activeModal === "EditarPaquete" && <ModalEditarPaquete sharedPaqueteId={sharedPaqueteId} onCloseModal={handleCloseModal} /> }
					{ isModalOpen && activeModal === "CalculosComisiones" && <ModalCalculosComisiones sharedDerechohabienteId={sharedDerechohabienteId} onCloseModal={handleCloseModal} /> }
					{ isModalOpen && activeModal === "CalculosAnticipos" && <ModalCalculosAnticipos sharedDerechohabienteId={sharedDerechohabienteId} onCloseModal={handleCloseModal} /> }
					{ isModalOpen && activeModal === "NuevaEtapa" && <ModalNuevaEtapa proyecto_id={proyecto_id} onSuccessEtapa={handleSuccessEtapa} onCloseModal={handleCloseModal} /> }
					{ isModalOpen && activeModal === "EditarEtapas" && <ModalEditarEtapas etapa_id={sharedEtapaId} onSuccessEtapa={handleSuccessEtapa} onCloseModal={handleCloseModal} /> }
					{ isModalOpen && activeModal === "Paquete" && <ModalPaquete etapa_id={sharedEtapaId} sharedPaqueteId={sharedPaqueteId} onSuccessPaquete={handleSuccessPaquete} onCloseModal={handleCloseModal} /> }
					{ isModalOpen && activeModal === "Derechohabiente" && <ModalDerechohabiente sharedDerechohabienteId={sharedDerechohabienteId} proyecto_id={proyecto_id}  paquete_id={sharedPaqueteId} onSuccessDerechohabiente={handleSuccessDerechohabientes} onCloseModal={handleCloseModal} /> }
				</>
			)
		}
	}


	const handleSuccessEtapa = () => {
		if(proyecto_id > 0)
			fetchEtapasByProyectoId(proyecto_id);
	}


	const handleSuccessPaquete = (etapa_id) => {
		if(proyecto_id > 0)
			fetchPaquetesByEtapaId(etapa_id);
	}

	const handleSuccessDerechohabientes = (paquete_id) => {
		if(proyecto_id > 0)
			fetchDerechohabientesByPaqueteId(paquete_id);
	}


	const handleOpenModal = (modalName) => {
        setIsModalOpen(true);
        setActiveModal(modalName);
    };


	const handleCloseModal = () => {
        setIsModalOpen(false);
        setActiveModal("");
		document.body.style.overflow = 'auto';
    };


	return (
		<div>
			<Breadcrumb title={"Etapas - Paquetes"} subtitle={nom_proyecto}/>
			<div className="row justify-content-center">				
				<hr className="mb-4" />
				<div className='row'>
					<TableEtapas 
						user_roles={userRole} 
						etapas={etapas}  
						setSharedEtapaId={setSharedEtapaId} 
						onSuccessEtapa={handleSuccessEtapa} 
						onShowTablePaquetes={handleShowTablePaquetes}
						onOpenModal={handleOpenModal} />
					{ showTablePaquetes && (
						<TablePaquetes 
							user_roles={userRole}
							paquetes={paquetes}
							etapa_nombre={etapaNombre} 
							onSuccessPaquete={handleSuccessPaquete}
							onShowTableDerechohabientes={handleShowDerechohabientes} 
							etapa_id={sharedEtapaId} 
							setSharedPaqueteId={setSharedPaqueteId}  
							setSharedDerechohabienteId={setSharedDerechohabienteId}
							onOpenModal={handleOpenModal} />
					)}
					{ showTableDerechohabientes && (
						<TableDerechohabientes 
							paquete_id={sharedPaqueteId} 
							paquete_nombre={paqueteNombre} 
							estatus_paquete={paqueteEstatus} 
							derechohabientes={derechohabientes}
							onSuccessDerechohabiente={handleSuccessDerechohabientes}
							setSharedDerechohabienteId={setSharedDerechohabienteId} 
							onOpenModal={handleOpenModal} />
					)}
				</div>
			</div>
			{ renderModals() }
		</div>
	)
}

export default ProyectoPaquetes;
