import { condusefApi } from "../daeApi";

export const getQuejas = async (token) => {
    const response = await condusefApi.get(`/quejas`, {
        headers: {
            'Content-Type'	: 'application/json',
            'Accept'			: 'application/json',
            'x-access-tokens' : token
        }
    });
    return response.data;
}

export const getQuejaById = async (id, token) => {
    const response = await condusefApi.get(`/quejas/${id}`, {
        headers: {
            'Content-Type'	: 'application/json',
            'Accept'			: 'application/json',
            'x-access-tokens' : token
        }
    });
    return response.data;
}

export const deleteQuejaById = async (id, token) => {
    const response = await condusefApi.delete(`/quejas/${id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'x-access-tokens': token
      }
    });
    return response.data;
}


export const getMyTickets = async (token, token_access) => {
    const response = await condusefApi.get(`/redeco/get-my-tickets`, {
        headers: {
            'Content-Type'	: 'application/json',
            'Accept'			: 'application/json',
            'x-access-tokens' : token,
            'Authorization' : token_access
        }
    });
    return response.data;
}

export const getStatusTicketById = async (id, token, token_access) => {
    const response = await condusefApi.get(`/redeco/get-estatus-redeco-ticket/${id}`, {
        headers: {
            'Content-Type'	: 'application/json',
            'Accept'			: 'application/json',
            'x-access-tokens' : token,
            'Authorization' : token_access
        }
    });
    return response.data;
}

export const getQuejasByYear = async (year, mounth, token, token_access) => {
    const response = await condusefApi.get(`/redeco/quejas/${year}/${mounth}`, {
        headers: {
            'Content-Type'	: 'application/json',
            'Accept'			: 'application/json',
            'x-access-tokens' : token,
            'Authorization' : token_access
        }
    });
    return response.data;
}

export const getCatalogosMedioRecepcion = async (token, token_access) => {
    const response = await condusefApi.get(`/redeco/catalogos/medio-recepcion`, {
        headers: {
            'Content-Type'	: 'application/json',
            'Accept'			: 'application/json',
            'x-access-tokens' : token,
            'Authorization' : token_access
        }
    });
    return response.data;
}

export const getCatalogosNivelesAtencion = async (token, token_access) => {
    const response = await condusefApi.get(`/redeco/catalogos/niveles-atencion`, {
        headers: {
            'Content-Type'	: 'application/json',
            'Accept'			: 'application/json',
            'x-access-tokens' : token,
            'Authorization' : token_access
        }
    });
    return response.data;
}

export const getSepomexEstados = async (token, token_access) => {
    const response = await condusefApi.get(`/redeco/sepomex/estados`, {
        headers: {
            'Content-Type'	: 'application/json',
            'Accept'			: 'application/json',
            'x-access-tokens' : token,
            'Authorization' : token_access
        }
    });
    return response.data;
}

export const getSepomexCpByEstadoId = async (estado_id, token, token_access) => {
    const response = await condusefApi.get(`/redeco/sepomex/codigos-postales/${estado_id}`, {
        headers: {
            'Content-Type'	: 'application/json',
            'Accept'			: 'application/json',
            'x-access-tokens' : token,
            'Authorization' : token_access
        }
    });
    return response.data;
}

export const getSepomexMunicipiosByEstadoId = async (estado_id, cp, token, token_access) => {
    const response = await condusefApi.get(`/redeco/sepomex/municipios/${estado_id}/${cp}`, {
        headers: {
            'Content-Type'	: 'application/json',
            'Accept'			: 'application/json',
            'x-access-tokens' : token,
            'Authorization' : token_access
        }
    });
    return response.data;
}

export const getSepomexColoniasByCp = async (cp, token, token_access) => {
    const response = await condusefApi.get(`/redeco/sepomex/colonias/${cp}`, {
        headers: {
            'Content-Type'	: 'application/json',
            'Accept'			: 'application/json',
            'x-access-tokens' : token,
            'Authorization' : token_access
        }
    });
    return response.data;
}

export const getUsersList = async (token, token_access) => {
    const response = await condusefApi.get(`/redeco/users/users-list`, {
        headers: {
            'Content-Type'	: 'application/json',
            'Accept'			: 'application/json',
            'x-access-tokens' : token,
            'Authorization' : token_access
        }
    });
    return response.data;
}

export const getProductsList = async (token, token_access) => {
    const response = await condusefApi.get(`/redeco/products-list`, {
        headers: {
            'Content-Type'	: 'application/json',
            'Accept'			: 'application/json',
            'x-access-tokens' : token,
            'Authorization' : token_access
        }
    });
    return response.data;
}

export const getCausasListByProduct = async (product, token, token_access) => {
    const response = await condusefApi.get(`/redeco/causas-list/${product}`, {
        headers: {
            'Content-Type'	: 'application/json',
            'Accept'			: 'application/json',
            'x-access-tokens' : token,
            'Authorization' : token_access
        }
    });
    return response.data;
}

export const createData = async (data, token, token_access) => {
    const response = await condusefApi.post(`/redeco/add-redeco-data`, data, {
      headers: {
        'Content-Type'	: 'application/json',
        'Accept'			: 'application/json',
        'x-access-tokens' : token,
        'Authorization' : token_access
      }
    });
    return response.data;
}

export const createQuejas = async (data, token, token_access) => {
    const response = await condusefApi.post(`/redeco/quejas`, data, {
      headers: {
        'Content-Type'	: 'application/json',
        'Accept'			: 'application/json',
        'x-access-tokens' : token,
        'Authorization' : token_access
      }
    });
    return response.data;
}

export const createSuperUser = async (data, token, token_access) => {
    const response = await condusefApi.post(`/redeco/users/create-super-user/`, data, {
      headers: {
        'Content-Type'	: 'application/json',
        'Accept'			: 'application/json',
        'x-access-tokens' : token,
        'Authorization' : token_access
      }
    });
    return response.data;
}

export const createUser = async (data, token, token_access) => {
    const response = await condusefApi.post(`/redeco/users/create-user/`, data, {
      headers: {
        'Content-Type'	: 'application/json',
        'Accept'			: 'application/json',
        'x-access-tokens' : token,
        'Authorization' : token_access
      }
    });
    return response.data;
}

export const createUserToken = async (data, token) => {
    const response = await condusefApi.post(`/redeco/users/token`, data, {
      headers: {
        'Content-Type'	: 'application/json',
        'Accept'			: 'application/json',
        'x-access-tokens' : token
      }
    });
    return response.data;
}

export const deleteUser = async (data, token, token_access) => {
    const response = await condusefApi.delete(`/redeco/users/user`, data,  {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'x-access-tokens': token ,
        'Authorization' : token_access
      }
    });
    return response.data;
}

export const deleteQuejasByFolio = async (id, folio, token, token_access) => {
    const response = await condusefApi.delete(`/redeco/quejas/${id}/${folio}`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'x-access-tokens': token,
        'Authorization' : token_access
      }
    });
    return response.data;
}

