import React,  { useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';

import { getProyectosPromotores } from '../../../../api/proyectos/proyectos';

import jsZip from 'jszip';

const $ = require('jquery');
window.JSZip = jsZip;

function TablaProyectos() {
	const token = useSelector(state => state.authentication.user?.token);
    const navigate = useNavigate();

    const datatableProyectosRef = useRef(null);

    const [proyectos, setProyectos] = useState([]);

    useEffect(() => { 
        if(token !== undefined)
    	    fetchProyectosPromotores();
	}, [token]);

	const fetchProyectosPromotores = async () => {
		try {
			const response = await getProyectosPromotores(token);
			setProyectos(response)
		} catch (error) {
			console.error('Error al cargar los proyectos', error);
		}
	};

    useEffect(() => {
		if (datatableProyectosRef !== null && datatableProyectosRef.current !== null) {
			let index = 1;
			datatableProyectosRef.current.clear();
			datatableProyectosRef.current.rows.add(
				proyectos.map(proyecto => [index++, 
								proyecto.proyecto_id, 
								proyecto.promotor_id, 
								proyecto.proyecto_nombre, 
								proyecto.promotor_nombre,
								proyecto.proyecto_calle + " " + proyecto.proyecto_num_interior, 
								proyecto.proyecto_cuenta, 
								proyecto.proyecto_clabe]));
                                datatableProyectosRef.current.draw();
		} else 
			initDatatable()
		// eslint-disable-next-line
	}, [proyectos]);

    const initDatatable = () => {
		datatableProyectosRef.current = $('#tableProyectos').DataTable({
			dom: "<'row mb-3'<'col-md-4 mb-3 mb-md-0'l><'col-md-8 text-end'<'d-lg-flex justify-content-end'f<'d-lg-block d-none'B>>>>t<'row align-items-center'<'mr-auto col-md-6 mb-3 mb-md-0 mt-n2 'i><'mb-0 col-md-6'p>>",
			lengthMenu: [10, 20, 30, 40, 50],
			buttons: [
				{ 
					extend: 'excelHtml5', 
					className: 'btn btn-outline-default btn-sm',
					filename: function() {
						var timestamp = new Date().getTime(); 
						return 'lista_proyectos_' + timestamp; 
					},
					exportOptions: {
						columns: ':visible',
						modifier: {
							page: 'none' // Evita agregar información de paginación
						}
					},
					customizeData: function ( data ) {
						for (var i=0; i < data.body.length; i++){
							for (var j=0; j<data.body[i].length; j++ ){
								data.body[i][j] = '\u200C' + data.body[i][j];
							}
						}
					}
				}
			],
			rowCallback: (row, data) => {
				$(row).css('cursor', 'pointer');
				$(row).on('click', () => {
					let props = {
						proyecto_id : data[1],
						promotor_id : data[2],
						proyecto_nombre : data[3],
						promotor_nombre : data[4],
						proyecto_calle : data[5],
						proyecto_cuenta : data[6],
						proyecto_clabe : data[7]
					}
					handleDetalleProyecto(props);
				});
			},
			columns: [
				{ title: "#",  width:"5%" },
				{ title: "ID Proyecto"},
				{ title: "ID Promotor"},
				{ title: "Nombre Proyecto" },
				{ title: "Promotor" },
				{ title: "Dirección" },
				{ title: "Cuenta Bancaria" },
				{ title: "CLABE" }	
			],
			columnDefs: [{
				  targets: [1,2], // Índice de la columna que deseas ocultar (empezando desde 0)
				  visible: false
				}
			],
		});
	}

	const handleDetalleProyecto = (props) => {
		navigate('/proyecto/detalle', {
			state: {
				proyecto: props,
			}
		});
	};
    
    return (
        <div className='col-12 tabla-scrollable table-responsive'>
            <table id="tableProyectos" className="table text-nowrap w-100 table-striped">
                <thead></thead>
                <tbody></tbody>
            </table>
        </div>
    )
}

export default TablaProyectos;