import { createAsyncThunk } from '@reduxjs/toolkit';
import { daeApi } from '../../../api/daeApi';

export const login = createAsyncThunk(
    'authetication/login',
    async({ email, password }, thunkAPI) => {
        try {
            const response = await daeApi.post(`/login`, {
                email,
                password
            });
            if (response.status === 200) {
                const data = await response.data;
                return data;
            } else {
                return thunkAPI.rejectWithValue(response); 
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response || error.message);
        }
    }
);