import React,  { useEffect, useState }from 'react';
import { useSelector } from 'react-redux';

import { deleteKeyNull, errorNotification, successNotification } from '../../../../helpers';

import { getPaqueteById, getPaquetesCountByEtapaId, updatePaqueteById, createPaquete } from '../../../../api/paquete_proyectos/paquete_proyectos';

function ModalPaquete({ etapa_id, sharedPaqueteId, onSuccessPaquete, onCloseModal }) {
    const token = useSelector(state => state.authentication.user?.token);

    const [formPaquete, setFormPaquete] = useState({});
    const [actionPaquete, setActionPaquete] = useState("");

    useEffect(() => {
        if(sharedPaqueteId !== null && token !== undefined)
            if(sharedPaqueteId === 0) {
                setActionPaquete("Registrar");
                fetchPaquetesCountByEtapaId();
            }
            else {
                setActionPaquete("Editar");
                fetchPaqueteById();
            }
    }, [sharedPaqueteId, token]);


    const fetchCreatePaquete = async() => {
        try {
            const response = await createPaquete(formPaquete, token);
            if(response.status === 201) {
                successNotification("Paquete Registrado Correctamente");
                onSuccessPaquete(etapa_id);
                handleOnCloseModal();
            }
		} catch (e) {
			errorNotification("Ocurrio un error en el servidor.");
        }
    }
    

    const fetchPaqueteById = async() => {
        try {
			const response = await getPaqueteById(sharedPaqueteId, token);
			setFormPaquete(response);
		} catch (e) {
			errorNotification("Ocurrio un error en el servidor.");
		}
    }


    const fetchUpdatePaqueteById = async() => {
        try {
            const response = await updatePaqueteById(formPaquete.id, deleteKeyNull(formPaquete), token);	
            if(response.message === "success") {
                successNotification("Paquete Editado Correctamente");
                onSuccessPaquete(etapa_id);
                handleOnCloseModal();
            }
        } catch(e) {
            if(e.response.status === 401) {
                errorNotification("Token invalido o caducado.");
            } else if(e.response.status === 403) {
                errorNotification("No tienes permisos para realizar esta acción.");
            }
        }
    }


    const fetchPaquetesCountByEtapaId = async () => {
		try {
            const response = await getPaquetesCountByEtapaId(etapa_id, token);
            let numero = response.message + 1;
            setFormPaquete({
                etapa_id: etapa_id,
                numero: numero,
                estatus_paquete : 0,
                nombre: "Paquete #" + numero,
                por_comision_mercantil : "",
                por_comision_obra : "",
                can_meses_desarrollo : "",
            });
		} catch (e) {
			errorNotification("Ocurrio un error en el servidor.");
		}
	};


	const handleChangeFormPaquete = (event) => {
		const { name, value } = event.target;
		setFormPaquete({
			...formPaquete,
			[name]: value,
		});
	};


    const handleAccionPaquete = (e) => {
		e.preventDefault(); 
		if (e.target.form.checkValidity()) {
            if(actionPaquete == "Registrar") {
                fetchCreatePaquete();
            } else {
                fetchUpdatePaqueteById();
            }
		} else errorNotification("Faltan campos requeridos.");
	}


    const handleOnCloseModal = () => {
        document.getElementById('modalPaquete').classList.remove('show');
		document.querySelector('.modal-backdrop').remove();
        document.getElementById('modalPaquete').style.display = 'none';

        setFormPaquete({});
        
        onCloseModal();
    }


    return(
        <div className="modal modal-cover fade" id="modalPaquete" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{ actionPaquete } Paquete</h5>
                        <button type="button" className="btn-close" onClick={handleOnCloseModal}></button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3">
                            <form id="formRegistrarPaquete" className="was-validated" >
                                <div className="row row-space-12">	
                                    <div className="col-1">
                                        <label className="form-label">#</label>
                                        <div className="row row-space-10">
                                            <div className="col-12">
                                                <input type='text' className="form-control" 
                                                    id="numero"
                                                    name="numero"
                                                    value={formPaquete.numero}
                                                    onChange={handleChangeFormPaquete}
                                                    disabled={true}
                                                    required
                                                    pattern=".*\S+.*" />
                                                <div className="invalid-feedback" id="validationDireccion">
                                                    Campo requerido
                                                </div>	
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <label className="form-label">* Meses de desarrollo</label>
                                        <div className="row row-space-10">
                                            <div className="col-12">
                                                <input type='number' min="1" pattern="^[0-9]+" className="form-control" placeholder="Cantidad meses de desarrollo"  
                                                    id="can_meses_desarrollo"
                                                    name="can_meses_desarrollo"
                                                    value={formPaquete.can_meses_desarrollo}
                                                    onChange={handleChangeFormPaquete}
                                                    required={true} />
                                                <div className="invalid-feedback" id="validationDireccion">
                                                    Campo requerido
                                                </div>	
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <label className="form-label">* Comisión por Obra</label>
                                        <div className="row row-space-10">
                                            <div className="col-12">
                                                <input type='text' className="form-control" placeholder="Ejem. 1.50" 
                                                    id="por_comision_obra"
                                                    name="por_comision_obra"
                                                    value={formPaquete.por_comision_obra}
                                                    onChange={handleChangeFormPaquete}
                                                
                                                    required={true}
                                                    pattern=".*\S+.*" />
                                                <div className="invalid-feedback" id="validationDireccion">
                                                    Campo requerido
                                                </div>	
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-3">
                                        <label className="form-label">* Comisión Mercantil</label>
                                        <div className="row row-space-10">
                                            <div className="col-12">
                                                <input className="form-control" placeholder="Ejem. 0.20"
                                                    id="por_comision_mercantil"
                                                    name="por_comision_mercantil"
                                                    value={formPaquete.por_comision_mercantil}
                                                    onChange={handleChangeFormPaquete}
                                                    
                                                    required={true}
                                                    pattern=".*\S+.*" />
                                                <div className="invalid-feedback" id="validationDireccion">
                                                    Campo requerido
                                                </div>	
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-default" onClick={handleOnCloseModal}>Cerrar</button>
                        <button type="button" className="btn btn-outline-theme" onClick={handleAccionPaquete} form="formRegistrarPaquete" >{ actionPaquete }</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ModalPaquete;