import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, useRoutes, useLocation } from "react-router-dom";
import { Provider } from 'react-redux';

import AppRoute from './config/app-route.jsx';
import { store } from './store/index.js';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



import 'bootstrap';

import 'bootstrap-icons/font/bootstrap-icons.css';
import '@fortawesome/fontawesome-free/css/all.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './index.css';
import './scss/styles.scss';

import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import 'datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css';
import 'datatables.net-fixedcolumns-bs5/css/fixedColumns.bootstrap5.min.css';
import 'datatables.net-buttons-bs5/css/buttons.bootstrap5.min.css';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-quill/dist/quill.snow.css';
import 'react-datetime/css/react-datetime.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

const container = document.getElementById('root');
const root = createRoot(container);

function App() {
	let element = useRoutes(AppRoute);
	let location = useLocation();
	
	React.useEffect(() => {
		var elm = document.querySelector('.app');
    	if (elm) {
      		elm.classList.remove('app-sidebar-mobile-toggled');
    	}
  	}, [location]);

  	return element;
}

root.render(
	<Provider store={store}>
    	<BrowserRouter>
    		<App />
     		 <ToastContainer />
    	</BrowserRouter>
  	</Provider>
);
