import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useAuthorization from '../../hooks/useAuthorization';

import { getMinistracionesByPaqueteId } from '../../api/ministraciones/ministraciones';

function SelectMinistraciones({ paquete_id, setSharedMinistracionId }) {
    const token = useSelector(state => state.authentication.user?.token);
    const userRole = useAuthorization();

    const [selectedMinistracion, setSelectedMinistracion] = useState(0);
    const [ministracionesOption, setMinistracionesOption] = useState([]);

    const [showAnticipo, setShowAnticipo] = useState(false);

    useEffect(() => {
        if(paquete_id > 0) {
            setMinistracionesOption([]);
            setSelectedMinistracion(0);
            setSharedMinistracionId(0);
            fetchMinistracionesByPaqueteId(paquete_id);
        } else {
            setMinistracionesOption([]);
            setSelectedMinistracion(0);
            setSharedMinistracionId(0);
        }
    }, [paquete_id]);

    useEffect(() => {
        if(userRole.length > 0) {
			if (['tecnico'].some(role => userRole.includes(role))) {
                setShowAnticipo(false);
            } else {
                setShowAnticipo(true);
            }
        }
    }, [userRole]);

    const fetchMinistracionesByPaqueteId = async (paquete_id) => {
		try {
			const response = await getMinistracionesByPaqueteId(paquete_id, token);
            let options = [];		
            if(showAnticipo)
                options.push(<option key={-1} value="-1">Anticipo</option>)
            options.push(
                response.map((ministracion, i) => (
                    <option key={ministracion.num_ministracion} value={ministracion.num_ministracion}>
                        Ministracion #{ministracion.num_ministracion}
                    </option>
                ))
            );
            options.push(
                <option key={response.length + 1} value={response.length + 1}>Ministración #{response.length + 1}</option>
            );
			setMinistracionesOption(options);
		} catch (error) {
			console.error('Error al cargar los datos', error);
		}
	}

    const handleMinistracionChange = (event) => {
		const selectedId = event.target.value;
		setSelectedMinistracion(selectedId);
        setSharedMinistracionId(selectedId);
    }

    return (
        <div className="form-group mb-3">
            <label className="form-label" htmlFor="ministraciones" ><strong>Ministraciones</strong></label>
            <select className="form-select" id="ministraciones"  onChange={handleMinistracionChange} value={selectedMinistracion}  >
                <option key={0} value={0} >Seleccione una ministración</option>
                {ministracionesOption}
            </select>
        </div>
    )
}

export default SelectMinistraciones;