import React,  { useEffect, useState }from 'react';
import { useSelector } from 'react-redux';

import { getDerechohabientesAnticposById } from '../../../../api/derechohabiente/derechohabiente';
import { formatoMoneda } from '../../../../helpers';
import { CardBody } from '../../../../components/card/card';


function ModalCalculosAnticipos({ sharedDerechohabienteId, onCloseModal }) {
    const token = useSelector(state => state.authentication.user?.token);

    const [comisiones, setComisiones] = useState({});

    useEffect(() => {
        setComisiones({});
        if (sharedDerechohabienteId !== 0 && token !== undefined) 
            fetchGetDerechohabientesAnticposById();
    }, [sharedDerechohabienteId, token])



    const fetchGetDerechohabientesAnticposById = async() => {
        try {
            const response = await getDerechohabientesAnticposById(sharedDerechohabienteId, token);  
            calculosComisiones(response);
            if(response.length > 0)
                calculosComisiones(response[0]);
            else {
                setComisiones({
                    valor_terreno : 0.0,
                    monto_anticipo : 0.0,
                    fondo_garantia_anticipo : 0.0,
                    anticipo_neto : 0.0,
                    saldo_valor : 0.0
                });
            }
        } catch (error) {
            console.log(error);
        }
    }

    const calculosComisiones = (data) => {
        let valor_terreno = parseFloat(data.valor_metros2_terreno_vendible || 0.0).toFixed(2) * parseFloat(data.val_tierra_m2 || 0.0).toFixed(2);
        let monto_anticipo = parseFloat(valor_terreno * 0.8 || 0.0) .toFixed(2);
        let fondo_garantia_anticipo = parseFloat(monto_anticipo * 0.1 || 0.0).toFixed(2);
        var anticipo_neto = parseFloat((monto_anticipo - fondo_garantia_anticipo) || 0.0).toFixed(2);
        var saldo_valor = parseFloat(data.valor_terreno || 0.0).toFixed(2) - parseFloat(monto_anticipo || 0.0).toFixed(2);

        
        setComisiones({
            valor_terreno,
            monto_anticipo,
            fondo_garantia_anticipo,
            anticipo_neto,
            saldo_valor
        });
    }

    const handleOnCloseModal = () => {
        document.getElementById('modalCalculosAnticipos').classList.remove('show');
		document.querySelector('.modal-backdrop').remove();
        document.getElementById('modalCalculosAnticipos').style.display = 'none';

        
        
        onCloseModal();
    }

    return(
        <div id="modalCalculosAnticipos" className="modal modal-cover fade"  aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Anticipos</h5>
                        <button type="button" className="btn-close" onClick={handleOnCloseModal}></button>
                    </div>
                    <div className="modal-body">
                        <form id="formCalculosComisiones" className="was-validated" >
                            <div className="col-xl-12">
                                <div id="formControls" className="mb-5">  
                                    <div className="row clearfix">
                                        <div className="col-xl-6">
                                            <label className="form-label" htmlFor='valor_terreno'>Valor del Terreno</label>
                                            <input type="text" 
                                                    className="form-control" 
                                                    id="valor_terreno" 
                                                    name="valor_terreno"  
                                                    value={formatoMoneda.format(comisiones.valor_terreno)}
                                                    disabled={true} />
                                        </div>
                                        <div className="col-xl-6">
                                            <label className="form-label" htmlFor='monto_anticipo'>Monto de Anticipo</label>
                                            <input type="text" 
                                                className="form-control" 
                                                id="monto_anticipo" 
                                                name="monto_anticipo"  
                                                value={formatoMoneda.format(comisiones.monto_anticipo)}
                                                disabled={true}  />
                                        </div>
                                        <div className="col-xl-6">
                                            <label className="form-label" htmlFor='fondo_garantia_anticipo'>Fondo de Garantia</label>
                                            <input type="text" 
                                                    className="form-control" 
                                                    id="fondo_garantia_anticipo" 
                                                    name="fondo_garantia_anticipo" 
                                                    value={formatoMoneda.format(comisiones.fondo_garantia_anticipo)}
                                                    disabled={true}  />
                                        </div>
                                        <div className="col-sm-6">
                                            <label className="form-label" htmlFor='anticipo_neto'>Anticipo Neto</label>
                                            <input type="text" 
                                                    className="form-control" 
                                                    id="anticipo_neto" 
                                                    name="anticipo_neto" 
                                                    value={formatoMoneda.format(comisiones.anticipo_neto)}
                                                    disabled={true}  />
                                        </div>
                                        <div className="col-sm-6">
                                            <label className="form-label" htmlFor='saldo_valor'>Valor del Proyecto</label>
                                            <input type="text" 
                                                    className="form-control" 
                                                    id="saldo_valor" 
                                                    name="saldo_valor"  
                                                    value={formatoMoneda.format(comisiones.saldo_valor)}
                                                    disabled={true}  />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default ModalCalculosAnticipos;