import React, {useEffect, useRef, useState	} from 'react';
import { useSelector } from 'react-redux';
import Breadcrumb from '../../../components/breadcrumb/breadcrumb';
import Swal from 'sweetalert2'

import { 
    getMyTickets,
    getStatusTicketById,
    getQuejasByYear,
    getCatalogosMedioRecepcion,
    getCatalogosNivelesAtencion,
    getSepomexEstados,
    getSepomexCpByEstadoId,
    getSepomexMunicipiosByEstadoId,
    getSepomexColoniasByCp,
    getUsersList,
    getProductsList,
    getCausasListByProduct,
    createData,
    createQuejas,
    createSuperUser,
    createUser,
    createUserToken,
    deleteUser,
    deleteQuejasByFolio,
	getQuejas,
	getQuejaById, 
	deleteQuejaById
} from '../../../api/condusef/redeco';
import { setTokenAccess } from '../../../store/slices/authentication/authenticationSlice';
import { useDispatch } from 'react-redux';

import {  buscarMediosRecepcionById, buscarNivelAtencionById, successNotification, errorNotification, buscarProductosById, buscarCausasById } from '../../../helpers';
import { alert } from '../../../helpers/alertManager';
import { Card, CardBody } from '../../../components/card/card';
import useAuthorization from '../../../hooks/useAuthorization';

import { Modal } from 'bootstrap'

import ModalEditarQueja from './modals/editar-queja';
import ModalNuevaQueja from './modals/nueva-queja';

const $ = require('jquery');


function Redeco(props) {
	const dispatch = useDispatch();
	
    const token = useSelector(state => state.authentication.user?.token);
	const token_access = useSelector(state => state.authentication.user?.token_access);
	const userRole = useAuthorization();

	const tableQuejasRef = useRef(null);

	const [isLoading, setIsLoading] = useState(false);
	const [quejas, setQuejas] = useState([]);

	const [sharedQuejaId, setSharedQuejaId] = useState(null);

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [activeModal, setActiveModal] = useState("");

    useEffect(() => { 
		if(token !== undefined)
			if(token_access !== undefined)	
				fetchQuejas();
	}, [token, token_access]);

	useEffect(() => {
		if(isModalOpen && activeModal === 'NuevaQueja') {
			let myModal = new Modal(document.getElementById('modalNuevaQueja'));
			myModal.show();
		} else if(isModalOpen && activeModal === 'EditarQueja') {
			let myModal = new Modal(document.getElementById('modalEditarQueja'));
			myModal.show();
		} 
	}, [isModalOpen, activeModal]);

	
	const fetchQuejas = async () => {
		try {
			const response = await getQuejas(token);
			setQuejas(response)
		} catch (error) {
			console.error('Error al cargar los datos', error);
		}
	}

	const fetchCreateUserToken = async (data) => {
		try {
			const response = await createUserToken(data, token);
			setIsLoading(false);
			dispatch(setTokenAccess(response.token_access));
		} catch (error) {
			setIsLoading(false);
			alert("Error", "Credenciales incorrectas.", "error");
		}
	}

	const fetchDeleteQuejaByFolio = async (id, folio) => {
		try {
			const response = await deleteQuejasByFolio(id, folio, token);
			if(response.message === "success") {
				successNotification("Queja Eliminada Correctamente");
				fetchQuejas();
			}
		} catch (error) {
			if (error.response.status == 500) 
				alert("Ocurrio un error.", error.response.data.message, "error");
		}
	}

	useEffect(() => {
		if(userRole.length > 0) {
			if (tableQuejasRef !== null && tableQuejasRef.current !== null) {
				let index = 1;
				tableQuejasRef.current.clear();
				tableQuejasRef.current.rows.add(
					quejas.map(queja => [
							index++, 
							queja.id, 
							queja.QuejasFolio, 
							queja.QuejasFecRecepcion, 
							queja.QuejasTipoPersona === 1 ? 'Persona Física' : queja.QuejasTipoPersona === 2 ? 'Persona Moral' : 'Tipo de persona desconocido',
							queja.QuejasSexo !== null ? queja.QuejasSexo : 'N/A', 
							queja.QuejasEdad !== null ? queja.QuejasEdad : 'N/A', 
							buscarNivelAtencionById(queja.QuejasNivelAT),
							buscarMediosRecepcionById(queja.QuejasMedio),
							buscarProductosById(queja.QuejasProducto),
							buscarCausasById(queja.QuejasCausa)
						]
					)
				);
				tableQuejasRef.current.draw();
			} else 
				initDatatable()
			// eslint-disable-next-line
		}
	}, [quejas, userRole]);

	const initDatatable = () => {
		tableQuejasRef.current = $('#tableQuejas').DataTable({
			dom: "<'row mb-3'<'col-md-4 mb-3 mb-md-0'l><'col-md-8 text-end'<'d-lg-flex justify-content-end'f<'d-lg-block d-none'B>>>>t<'row align-items-center'<'mr-auto col-md-6 mb-3 mb-md-0 mt-n2 'i><'mb-0 col-md-6'p>>",
			lengthMenu: [10, 20, 30, 40, 50],
			buttons: [
				{ extend: 'print', className: 'btn btn-outline-default btn-sm ms-2' },
				{ extend: 'csv', className: 'btn btn-outline-default btn-sm' }
			],
			columns: [
				{ title: "#" },
				{ title: "ID Queja"},
				{ title: "Folio"},
				{ title: "Fecha Recepcion" },
				{ title: "Tipo Persona" },
				{ title: "Sexo" },
				{ title: "Edad" },
				{ title: "Nivel Atención" },
				{ title: "Medio Recepción" },
				{ title: "Tipo Produto" },
				{ title: "Causa" },
				{
					title: "",
					data: null,
					width: "15%",
					render: function (data, type, row, meta) {
						const queja_id = row[1];
						const folio = row[2];
						if (['administracion', 'master'].some(role => userRole.includes(role))) {
							return `<div style="text-align: right;">
										<a  style="cursor:pointer" id="detalleQueja" data-queja-id="${queja_id}" data-folio="${folio}" >
											<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#F1C40F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
										</a>
										<a style="cursor:pointer" id="eliminarQueja" data-queja-id="${queja_id}" data-folio="${folio}">
											<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#EE0C05" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
										</a>
									</div>`;
						}
					}
				}
			],
			columnDefs: [{
				  targets: [1], // Índice de la columna que deseas ocultar (empezando desde 0)
				  visible: false
				}
			],
		});

		$('#tableQuejas').on('click', '#detalleQueja', function () {
			const id = $(this).data('queja-id');
			const folio = $(this).data('folio');
			actionVerQueja(id, folio);
		});
	
		$('#tableQuejas').on('click', '#eliminarQueja', function () {
			const id = $(this).data('queja-id');
			const folio = $(this).data('folio');
			actionEliminarQueja(id, folio);
		});
	}

	

	const actionVerQueja = (id, folio) => {
		Swal.fire({
			text: "¿Deseas ver el detalle de la Queja: " + folio +"?",
			icon: "question",
			confirmButtonText: 'Continuar',
			cancelButtonText: 'Cancelar',
			showCancelButton: true,
			showCloseButton: true,
			allowOutsideClick: false,
			allowEscapeKey: false,
			allowEnterKey: false,
			reverseButtons : true
		}).then((result) => {
			if (result.isConfirmed) {
				setSharedQuejaId(id);
				setIsModalOpen(true);
				setActiveModal("EditarQueja");
			}
		}).catch(() => {
			return false;
		})
	}

	const actionEliminarQueja = (id, folio) => {
		Swal.fire({
			title: "¿Estas seguro de eliminar la Queja: "+ folio +"?",
			text: "Una vez eliminado ya no podrás acceder a esta Queja",
			icon: "question",
			confirmButtonText: 'Continuar',
			cancelButtonText: 'Cancelar',
			showCancelButton: true,
			showCloseButton: true,
			allowOutsideClick: false,
			allowEscapeKey: false,
			allowEnterKey: false,
			reverseButtons : true
		}).then((result) => {
			if (result.isConfirmed) {
				confirmEliminarQueja(id, folio);
			}
		}).catch(() => {
			return false;
		})	
	}

	const confirmEliminarQueja = (id, folio) => {
		Swal.fire({
			title: "¿Estas seguro?",
			text: "Esta operacion no se puede deshacer.",
			icon: "warning",
			confirmButtonText: 'Eliminar',
			cancelButtonText: 'Cancelar',
			showCancelButton: true,
			showCloseButton: true,
			allowOutsideClick: false,
			allowEscapeKey: false,
			allowEnterKey: false,
			reverseButtons : true
		}).then((result) => {
			if (result.isConfirmed) {	
				fetchDeleteQuejaByFolio(id, folio);
			}
		}).catch(() => {
			return false;
		})
	}

	function handleSubmit(event) {
		setIsLoading(true);
		event.preventDefault();		
		let data = {
			'username' : event.target.email.value,
			'password' : event.target.password.value
		}
		fetchCreateUserToken(data);
  	}

	const handleOpenModal = () => {
		setIsModalOpen(true);
		setActiveModal("NuevaQueja");
    };

	const handleCloseModal = () => {
        setIsModalOpen(false);
        setActiveModal("");
		document.body.style.overflow = 'auto';
		fetchQuejas();
    };

	const renderButtonNuevaQueja = () => {
		if (['administracion', 'master'].some(role => userRole.includes(role))) {
			return (
				<div className="ml-auto">
					<a  className="btn btn-outline-default" onClick={handleOpenModal}>Nueva Queja</a>
				</div>
			)
		}
	}

	
    return (
        <div>
			{ token_access === undefined ? (
				<div className="login" style={{'minHeight' : '0'}}>
					<div className="login-content">
						<form onSubmit={handleSubmit}>
							<h1 className="text-center">Iniciar Sesión</h1>
							<div className="text-inverse text-opacity-50 text-center mb-4">
								Por seguridad, por favor verifique su identidad en CONDUSEF.
							</div>
							<div className="mb-3">
								<label className="form-label">Username <span className="text-danger">*</span></label>
								<input disabled={isLoading} type="text" name="email" className="form-control form-control-lg bg-white bg-opacity-5" placeholder="" />
							</div>
							<div className="mb-3">
								<div className="d-flex">
									<label className="form-label">Contraseña <span className="text-danger">*</span></label>
								</div>
								<input disabled={isLoading} type="password" name="password" className="form-control form-control-lg bg-white bg-opacity-5" placeholder="" />
							</div>
							<button disabled={isLoading} type="submit" className="btn btn-outline-theme btn-lg d-block w-100 fw-500 mb-3">Iniciar Sesión</button>		
						</form>
					</div>
				</div>) : 
				(
					<div>
						<Breadcrumb title={props.title} subtitle={props.subtitle}/>
						<div className="row justify-content-center">
							<hr className="mb-4" />
							<div className="col-xl-10">
								<div className="list-group-item d-flex align-items-center" style={{marginBottom: '1em', marginTop: '-0.5em'}}>
									<div className="flex-1 text-break"></div>
									{ renderButtonNuevaQueja() }
								</div>
								<div id="datatable" className="mb-5">
									<Card>
										<CardBody>
											<div className='col-12 tabla-scrollable table-responsive'>
												<table id="tableQuejas" className="table text-nowrap w-100  table-striped">
													<thead></thead>
													<tbody></tbody>
												</table>
											</div>
										</CardBody>
									</Card>
								</div>
							</div>
						</div>
						{ isModalOpen && activeModal === "EditarQueja" && <ModalEditarQueja sharedQuejaId={sharedQuejaId} onCloseModal={handleCloseModal}/> }
						{ isModalOpen && activeModal === "NuevaQueja" && <ModalNuevaQueja  onCloseModal={handleCloseModal} /> }
					</div>
				)}
		</div>

    )
}

export default Redeco;