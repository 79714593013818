import React,  { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { deleteBancoById } from '../../../../api/bancos/bancos';
import { errorNotification, formatoMoneda, successNotification } from '../../../../helpers';

import jsZip from 'jszip';
import Swal from 'sweetalert2';
import { Modal } from 'bootstrap';

const $ = require('jquery');
window.JSZip = jsZip;

function TablaBancosPromotores({ bancos, setType, setBancoId, onSuccessBancos }) {
	const token = useSelector(state => state.authentication.user?.token);

    const datatableBancosRef = useRef(null);

    useEffect(() => {
		if (datatableBancosRef !== null && datatableBancosRef.current !== null) {
			let index = 1;
			datatableBancosRef.current.clear();
			datatableBancosRef.current.rows.add(
				bancos.map(banco => [index++, 
							banco.id, 
							banco.nombre, 
							banco.cuenta, 
							banco.clabe, 
							formatoMoneda.format(parseFloat(banco.mesa_inversion.replace(/,/g, '')))]));
	  		datatableBancosRef.current.draw();
		} else 
			initDatatable()
		// eslint-disable-next-line
	}, [bancos]);

    const fetchDeleteBancoById = async (id) => {
		try {
			const response = await deleteBancoById(id, token);
			if(response.message === "success") {
				successNotification("Banco Eliminado Correctamente");
				onSuccessBancos();
			} else if(response.message === "failed") {
				errorNotification("No es posible eliminar el Banco.")
			}
		} catch (error) {
			errorNotification('No es posible eliminar el Banco.');
		}
	}

    const initDatatable = () => {
		datatableBancosRef.current = $('#tableBancosPromotores').DataTable({
			dom: "<'row mb-3'<'col-md-4 mb-3 mb-md-0'l><'col-md-8 text-end'<'d-lg-flex justify-content-end'f<'d-lg-block d-none'B>>>>t<'row align-items-center'<'mr-auto col-md-6 mb-3 mb-md-0 mt-n2 'i><'mb-0 col-md-6'p>>",
			lengthMenu: [10, 20, 30, 40, 50],
			responsive: {
				details: {
					type: 'column',
					target: -1
				}
			},
			buttons: [
				{ 
					extend: 'excelHtml5', 
					className: 'btn btn-outline-default btn-sm',
					filename: function() {
						var timestamp = new Date().getTime(); 
						return 'catalogo_bancos_' + timestamp; 
					},
					exportOptions: {
						columns: ':visible',
						modifier: {
							page: 'none' // Evita agregar información de paginación
						}
					},
					customizeData: function ( data ) {
						for (var i=0; i < data.body.length; i++){
							for (var j=0; j<data.body[i].length; j++ ){
								data.body[i][j] = '\u200C' + data.body[i][j];
							}
						}
					}
				}
			],
			columns: [
				{ title: "#",  width:"5%" },
				{ title: "ID" },
				{ title: "Nombre Banco" },
				{ title: "Cuenta" },
				{ title: "CLABE" },
				{ title: "Mesa Inversion", width:"10%" },
				{
					title: "",
					width:"5%",
					data: null,
					render: function (data, type, row, meta) {
					  const bancoId = row[1];
					  const bancoNombre = row[2];
					  const bancoMesaInversion = row[5];
					  const bancoCuenta = row[3];
					  const bancoClabe = row[4];
					  return `<div  style="text-align: right;" >	
								<a style="cursor:pointer"  id="editarBanco"  data-banco-id="${bancoId}" data-banco-name="${bancoNombre}" data-banco-cuenta="${bancoCuenta}" data-banco-clabe="${bancoClabe}" data-banco-mesa="${bancoMesaInversion}"   >
									<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#F1C40F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
								</a>
								<a style="cursor:pointer"  id="eliminarBanco"  data-banco-id="${bancoId}" data-banco-name="${bancoNombre}"  >
									<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#EE0C05" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg>
								</a>
							</div>`;
					}
				}
			],
			columnDefs: [{
				targets: 1, // Índice de la columna que deseas ocultar (0 en este caso)
				visible: false
			  },
			],
			orderCellsTop: true
			
		});

        $('#tableBancosPromotores').on('click', '#editarBanco', function () {
			const id = $(this).data('banco-id');
			const nombre = $(this).data('banco-name');
			const mesa = $(this).data('banco-mesa');
			const cuenta = $(this).data('banco-cuenta');
			const clabe = $(this).data('banco-clabe');
			handleEditarBanco(id, nombre, mesa, cuenta, clabe);
		});
		
		$('#tableBancosPromotores').on('click', '#eliminarBanco', function () {
			const id = $(this).data('banco-id');
			const nombre = $(this).data('banco-name');
			handleEliminarBanco(id, nombre);
		});
	}

    const handleEditarBanco = (id, nombre, mesa, cuenta, clabe) => {
        setType(2);
		setBancoId(id);
		let myModal = new Modal(document.getElementById('modalNuevoBanco'));
        myModal.show();	
	}

	const handleEliminarBanco = (id, nombre) => {
		Swal.fire({
			title: "¿Deseas eliminar el banco " + nombre + "?",
			text: "Una vez eliminado ya no estara disponible este banco.",
			icon: "question",
			confirmButtonText: 'Continuar',
			cancelButtonText: 'Cancelar',
			showCancelButton: true,
			showCloseButton: true,
			allowOutsideClick: false,
			allowEscapeKey: false,
			allowEnterKey: false,
			reverseButtons : true
		}).then((result) => {
			if (result.isConfirmed) {	
				//borrarBanco(id);
				confirmEliminarBanco(id)
			}
		}).catch(() => {
			return false;
		})
	}

	const confirmEliminarBanco = (id) => {
		Swal.fire({
			title: "¿Estas seguro?",
			text: "Esta operacion no se puede deshacer.",
			icon: "warning",
			confirmButtonText: 'Eliminar',
			cancelButtonText: 'Cancelar',
			showCancelButton: true,
			showCloseButton: true,
			allowOutsideClick: false,
			allowEscapeKey: false,
			allowEnterKey: false,
			reverseButtons : true
		}).then((result) => {
			if (result.isConfirmed) {	
				fetchDeleteBancoById(id);
			}
		}).catch(() => {
			return false;
		})
	}


    return (
        <div className='col-12 tabla-scrollable table-responsive'>
            <table id="tableBancosPromotores" className="table text-nowrap w-100 table-striped" >
                <thead></thead>
                <tbody></tbody>
            </table>
        </div>
    )
}

export default TablaBancosPromotores;