import React, { useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { slideToggle } from '../../composables/slideToggle.js';
import { useDispatch } from 'react-redux';
import { logout } from '../../store/slices/authentication'

function Header() {
	const [redirect, setRedirect] = useState(false);
	const dispatch = useDispatch();

	const user = useSelector(state => state.authentication.user);

  	const email = user ? user.email : "";

  	const handleLogout = () => {
    	dispatch( logout() );
		setRedirect(true);
  	};

	const notificationData = [];	

	
	const toggleAppSidebarMobile = () => {
		var elm = document.querySelector('.app');
		if (elm) {
			if (!(elm.classList.contains('app-with-top-nav') && elm.classList.contains('app-without-sidebar'))) {
				elm.classList.toggle('app-sidebar-mobile-toggled');
			} else {
				slideToggle(document.querySelector('.app-top-nav'));
			}
		}
	}
	
	

	if (redirect) {
		return <Navigate to='/pages/login' />;
	}

	return (
		<div id="header" className="app-header">
			
			
			<div className="mobile-toggler">
				<button type="button" className="menu-toggler" onClick={toggleAppSidebarMobile}>
					<span className="bar"></span>
					<span className="bar"></span>
					<span className="bar"></span>
				</button>
			</div>
			
			<div className="brand">
				<Link to="/" className="brand-logo">
					<span className="brand-img">
						<span className="brand-img-text text-theme"></span>
					</span>
					<span className="brand-text">Crédito Integral Línea III</span>
				</Link>
			</div>
			
			<div className="menu">
				<div className="menu-item dropdown dropdown-mobile-full">
					<a href="#/" data-bs-toggle="dropdown" data-bs-display="static" className="menu-link">
						<div className="menu-icon"><i className="bi bi-grid-3x3-gap nav-icon"></i></div>
					</a>
					<div className="dropdown-menu fade dropdown-menu-end w-300px text-center p-0 mt-1">
						<div className="row row-grid gx-0">
							<div className="col-4">
								<Link to="/calendar" className="dropdown-item text-decoration-none p-3 bg-none">
									<div><i className="bi bi-calendar4 h2 opacity-5 d-block my-1"></i></div>
									<div className="fw-500 fs-10px text-inverse">CALENDARIO</div>
								</Link>
							</div>
							<div className="col-4">
								<Link  className="dropdown-item text-decoration-none p-3 bg-none">
									<div><i className="bi bi-terminal h2 opacity-5 d-block my-1"></i></div>
									<div className="fw-500 fs-10px text-inverse">AYUDA</div>
								</Link>
							</div>
							<div className="col-4">
								<Link to="/settings" className="dropdown-item text-decoration-none p-3 bg-none">
									<div className="position-relative">
										<i className="bi bi-circle-fill position-absolute text-theme top-0 mt-n2 me-n2 fs-6px d-block text-center w-100"></i>
										<i className="bi bi-sliders h2 opacity-5 d-block my-1"></i>
									</div>
									<div className="fw-500 fs-10px text-inverse">OPCIONES</div>
								</Link>
							</div>
						</div>
					</div>
				</div>
				<div className="menu-item dropdown dropdown-mobile-full">
					<a href="#/" data-bs-toggle="dropdown" data-bs-display="static" className="menu-link">
						<div className="menu-icon"><i className="bi bi-bell nav-icon"></i></div>
						<div className="menu-badge bg-theme"></div>
					</a>
					<div className="dropdown-menu dropdown-menu-end mt-1 w-300px fs-11px pt-1">
						<h6 className="dropdown-header fs-10px mb-1">NOTIFICACIONES</h6>
						<div className="dropdown-divider mt-1"></div>
						{notificationData.length > 0 ? (notificationData.map((notification, index) => ((
							<a href="#/" key={index} className="d-flex align-items-center py-10px dropdown-item text-wrap fw-semibold">
								<div className="fs-20px">
									<i className={notification.icon}></i>
								</div>
								<div className="flex-1 flex-wrap ps-3">
									<div className="mb-1 text-inverse">{notification.title}</div>
									<div className="small text-inverse text-opacity-50">{notification.time}</div>
								</div>
								<div className="ps-2 fs-16px">
									<i className="bi bi-chevron-right"></i>
								</div>
							</a>
						)))) : (
							<div className="dropdown-notification-item p-3 text-center">
								No hay notificaciones
							</div>
						)}
						<hr className="mb-0 mt-2" />
						<div className="py-10px mb-n2 text-center">
							<a  className="text-decoration-none fw-bold">VER TODAS</a>
						</div>
					</div>
				</div>
				<div className="menu-item dropdown dropdown-mobile-full">
					<a href="#/" data-bs-toggle="dropdown" data-bs-display="static" className="menu-link">
						<div className="menu-img online">
							<img src="/assets/img/user/logo.png" alt="Profile" height="60" />
						</div>
						<div className="menu-text d-sm-block d-none w-170px">{email}</div>
					</a>
					<div className="dropdown-menu dropdown-menu-end me-lg-3 fs-11px mt-1">
						<Link to="/profile" className="dropdown-item d-flex align-items-center">PERFIL <i className="bi bi-person-circle ms-auto text-theme fs-16px my-n1"></i></Link>
						{/*<Link to="/email/inbox" className="dropdown-item d-flex align-items-center">INBOX <i className="bi bi-envelope ms-auto text-theme fs-16px my-n1"></i></Link>*/}
						<Link to="/calendar" className="dropdown-item d-flex align-items-center">CALENDARIO <i className="bi bi-calendar ms-auto text-theme fs-16px my-n1"></i></Link>
						<Link to="/settings" className="dropdown-item d-flex align-items-center">OPCIONES <i className="bi bi-gear ms-auto text-theme fs-16px my-n1"></i></Link>
						<div className="dropdown-divider"></div>
						<button className="dropdown-item d-flex align-items-center" onClick={handleLogout}>CERRAR SESIÓN <i className="bi bi-toggle-off ms-auto text-theme fs-16px my-n1"></i></button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Header;
