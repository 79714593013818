import React,  { useEffect, useState, useRef }from 'react';
import { useSelector } from 'react-redux';

import { Card, CardBody, CardHeader }  from '../../../../components/card/card';

import { getProyectoCalculosById, getProyectoAnticiposById, getProyectoInversionById } from '../../../../api/proyectos/proyectos';
import { getMinistracionesLiberadasByProyectoId } from '../../../../api/ministraciones/ministraciones';

import { formatoMoneda } from '../../../../helpers';

function ModalCalculosProyectos({ proyecto_id, onCloseModal }) {
    const token = useSelector(state => state.authentication.user?.token);

    const [nombreProyecto, setNombreProyecto] = useState(0.0);
    const [valorTotal, setValorTotal] = useState(0.0);
    const [montoMinistraciones, setMontoMinistraciones] = useState(0.0);
    const [remanente, setRemanente] = useState(0.0);
    const [inversion, setInversion] = useState(0.0);


    useEffect(() => {
        if(token != undefined)
            fetchGetProyectoCalculosById();
    }, [token])
    

    const fetchGetProyectoCalculosById = async () => {
        try {
            const response = await getProyectoCalculosById(proyecto_id, token);
            setValorTotal(response.valor_total);
            setNombreProyecto(response.proyecto_nombre);
            fetchGetMinistracionesLiberadasByProyectoId(response.valor_total);
        } catch(e) {
            console.log(e);
        }
    }


    const fetchGetMinistracionesLiberadasByProyectoId = async(valor_total) => {
        try {
            const response = await getMinistracionesLiberadasByProyectoId(proyecto_id, token);
            fetchGetProyectoAnticiposById(parseFloat(response.message), parseFloat(valor_total));
        } catch(e) {
            console.log(e);
        }
    }


    const fetchGetProyectoAnticiposById = async(monto_ministracion, valor_total) => {
        try {
            const response = await getProyectoAnticiposById(proyecto_id, token);
            
            monto_ministracion += response.message;
            monto_ministracion = Math.round( monto_ministracion*100 )/100;
            
            let remanente = valor_total - monto_ministracion
            setMontoMinistraciones(monto_ministracion);
            setRemanente(remanente);
            
            fetchGetProyectoInversionById(remanente);
        } catch(e) {
            console.log(e);
        }
    }


    const fetchGetProyectoInversionById = async(remanente) => {
        try {
            const response = await getProyectoInversionById(proyecto_id, token);
            setInversion(remanente + response.message);
        } catch(e) {
            console.log(e);
        }
    }


    const handleOnCloseModal = () => {
        document.getElementById('modalCalculos').classList.remove('show');
		document.querySelector('.modal-backdrop').remove();
        document.getElementById('modalCalculos').style.display = 'none';

        setNombreProyecto("");
        setValorTotal(0.0);
        setMontoMinistraciones(0.0);
        setRemanente(0.0);
        setInversion(0.0);

        onCloseModal();
    }


    return(
        <div id="modalCalculos" className="modal modal-cover fade" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" >
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                    <h5 className="modal-title">Calculos Proyectos</h5>
                        <button type="button" className="btn-close" onClick={handleOnCloseModal} ></button>
                    </div>
                    <div className="modal-body">
                        <form id="formCalculos" className="was-validated" >
                            <Card>
                                <CardHeader>
                                    <span className="flex-grow-1">Estado de Cuenta</span>
                                </CardHeader>
                                <CardBody>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <thead>
                                                    <tr>
                                                        <th>Proyecto:</th>
                                                        <th>Valor Total de Paquetes</th>
                                                        <th>Monto Liberado a Promotor</th>
                                                        <th>Remanentes en la cuenta</th>
                                                        <th>Remanentes más inversiones generadas</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td id="proyectotablanombre" style={{color: "blue", fontSize: "15px", fontWeight: "bold"}}>{nombreProyecto}</td>
                                                        <td id="valorproyecto">{ formatoMoneda.format(valorTotal) }</td>
                                                        <td id="montopromotor">{ formatoMoneda.format( montoMinistraciones ) }</td>
                                                        <td id="remanentes">{ formatoMoneda.format( remanente ) }</td>
                                                        <td id="remanentesMASinversiones">{formatoMoneda.format( remanente + parseFloat(inversion) )}</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default ModalCalculosProyectos;