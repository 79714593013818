import React,  { useEffect, useState }from 'react';
import { useSelector } from 'react-redux';

import { Card, CardHeader, CardBody, CardFooter } from '../../../../../../components/card/card';

import SelectProyectos from '../../../../../../components/select/proyectos';
import SelectEtapas from '../../../../../../components/select/etapas';
import SelectPaquetes from '../../../../../../components/select/paquetes';
import SelectMinistraciones from '../../../../../../components/select/ministraciones';
import TablaAvanceObra from './avance_obra';
import CalcularDatos from '../calcular_datos';
import Documento from '../../../../../../components/upload/documento';
import UploadFile from '../../../../../../components/upload/upload_file';
import UploadFileXLSX from '../../../../../../components/upload/upload_file_xlsx';

import { errorNotification, successNotification, alert } from '../../../../../../helpers';

import { getPaqueteById } from '../../../../../../api/paquete_proyectos/paquete_proyectos';
import { getMinistracionNetaById, 
        getMinistracionValorActualById, 
        getMinistracionListaNumeroByPaqueteId, 
        getMinistracionesPorcentajeObraByPaqueteId, 
        getMinistracionesPorcentajeObraNumeroByPaqueteId, 
        getMinistracionesComisionStatusByPaqueteId, 
        getMinistracionAvanceTecnicoByNumero, 
        updateMinistracionAvanceTecnicoByNumero, 
        deleteMinistracionByPaqueteId,
        getMinistracionAvancesArchivosByPaqueteId,
        createMinistracionAvanceArchivos,
        createMinistracionSinComision,
        createMinistracionConComision,
        getMinistracionesByPaqueteId
} from '../../../../../../api/ministraciones/ministraciones';

import { 
    createDerechohabientesAvancesPorcentajes, 
    createDerechohabientesAvancesPorcentajesFile, 
    getDerechohabienteValorPaqueteByPaqueteId 
} from '../../../../../../api/derechohabiente/derechohabiente';

import { uploadFiles } from '../../../../../../api/otros/otros';

import Swal from 'sweetalert2';


function ObraTecnico() {
    const token = useSelector(state => state.authentication.user?.token);

    const [sharedProyectoId, setSharedProyectoId] = useState(0);
	const [sharedEtapaId, setSharedEtapaId] = useState(0);
	const [sharedPaqueteId, setSharedPaqueteId] = useState(0);
    const [sharedMinistracionId, setSharedMinistracionId] = useState(0);

    const [paquete, setPaquete] = useState({});
    const [ministraciones, setMinistraciones] = useState([]);
    const [avanceObra, setAvanceObra] = useState([]);

    const [showEliminarMinistracion, setShowEliminarMinistracion] = useState(false);
    const [disabledAutorizarMinistracion, setDisabledAutorizarMinistracion] = useState(true);
    const [disabledEnviarReporte, setDisabledEnviarReporte] = useState(true);
    const [disabledAplicarComi, setDisabledAplicarComi] = useState(true);
    const [checkedAplicarComi, setCheckAplicarComi] = useState(false);

    const [disabledUploadPorcentajes, setDisabledUploadPorcentajes] = useState(true);
    const [disabledUploadFile, setDisabledUploadFile] = useState(true);
    const [documento, setDocumento] =  useState("");

    const [statusComision, setStatusComision] = useState(0);

    const [dataFile, setDataFile] = useState({});

    const [porcentaje, setPorcentaje] = useState("0.00");
    const [ministracionNeta, setMinistracionNeta] = useState("0.00");
    const [valorPaquete, setValorPaquete] = useState("0.00");
	const [ministracionValor, setMinistracionValor] = useState({
        "anticipo": "0.00",
        "ministracion": "0.00",
        "valor_actual": "0.00"
    });

    const [reloadKey, setReloadKey] = useState(0); // Estado adicional para forzar la recarga

    const recargarSelectMinistraciones = () => {
        setReloadKey(prevKey => prevKey + 1);
    };

    useEffect(() => {
        clear();
        setSharedMinistracionId(0);
        if(sharedPaqueteId > 0) 
            fetchPaqueteById(sharedPaqueteId);
       
	}, [sharedPaqueteId]);

    useEffect(() => {
        fetchMinistracionesByPaqueteId(sharedPaqueteId);
        if(sharedMinistracionId <= 0) {
            clear();
        }
    }, [sharedMinistracionId]);

    const clear = () => {
        setDisabledUploadFile(true);
        setDisabledUploadPorcentajes(true);
        setDisabledAplicarComi(true);
        setDisabledAutorizarMinistracion(true);
        setDisabledEnviarReporte(true);
        setCheckAplicarComi(false);
        setShowEliminarMinistracion(false);
        setDocumento("");
        setAvanceObra([]);
        setPorcentaje("0.00");
        setMinistracionNeta("0.00");
	    setMinistracionValor({
            "anticipo": "0.00",
            "ministracion": "0.00",
            "valor_actual": "0.00"
        });
    }

    const fetchPaqueteById = async (paquete_id) => {
		try {
			const response = await getPaqueteById(paquete_id, token);
            setPaquete(response);
            if (response.estatus_paquete >= 4){
                fetchMinistracionesPorcentajeObraByPaqueteId(paquete_id, 1);
                fetchDerechohabienteValorPaqueteByPaqueteId(paquete_id);
            } else {
                alert("Para comenzar las ministraciones...", "Primero debes liberar el anticipo", "warning");
                clear();
            }
		} catch (error) {
			console.error('Error al cargar los datos', error);
		}
	}

    const fetchMinistracionesByPaqueteId = async (paquete_id) => {
		try {
            const response = await getMinistracionesByPaqueteId(paquete_id, token);
            setMinistraciones(response);
            if(sharedMinistracionId > 0 && sharedMinistracionId <= response.length) {
                fetchMinistracionListaNumeroByPaqueteId(sharedPaqueteId, sharedMinistracionId);
                fetchMinistracionNetaById(sharedPaqueteId, sharedMinistracionId);
                fetchMinistracionValorActualById(sharedPaqueteId, sharedMinistracionId);
                fetchMinistracionesPorcentajeObraNumeroByPaqueteId(sharedPaqueteId, sharedMinistracionId, 1);
            } else if(sharedMinistracionId > response.length) {
                setMinistracionNeta("0.00");
                fetchMinistracionValorActualById(sharedPaqueteId, sharedMinistracionId - 1);
                fetchMinistracionesPorcentajeObraNumeroByPaqueteId(sharedPaqueteId, sharedMinistracionId - 1, 2);
                setAvanceObra([]);
                setDisabledEnviarReporte(true);
                setDisabledUploadFile(true);
            } else if(sharedMinistracionId < 0) {
                clear();
            }

            if(response.length > 0) setShowEliminarMinistracion(true);
            else setShowEliminarMinistracion(false);

        } catch (error) {
			console.error('Error al cargar los datos', error);
		}
	}

    const fetchMinistracionNetaById = async (paquete_id, num_ministracion) => {
		try {
			const response = await getMinistracionNetaById(paquete_id, num_ministracion, token);
			if(response.message !== 'no found') {
				setMinistracionNeta(response.message);
			} else {
				setMinistracionNeta("0.00");
			}
		} catch (error) {
			console.error('Error al cargar los datos', error);
		}
	}
    

    const fetchMinistracionValorActualById = async (paquete_id, num_ministracion) => {
		try {
			const response = await getMinistracionValorActualById(paquete_id, num_ministracion, token);
            setMinistracionValor(response);
		} catch (error) {
			console.error('Error al cargar los datos', error);
		}
	}

    const fetchMinistracionListaNumeroByPaqueteId = async (paquete_id, num_ministracion) => {
		try {
			const response = await getMinistracionListaNumeroByPaqueteId(paquete_id, num_ministracion, token);
            setAvanceObra(response);
            setDisabledUploadPorcentajes(true);
            setDisabledUploadFile(false);
            setDisabledEnviarReporte(false);
            setDisabledAutorizarMinistracion(true);
            fetchMinistracionAvanceTecnicoByNumero(paquete_id, num_ministracion);
            fetchMinistracionesComisionStatusByPaqueteId(paquete_id, num_ministracion);
		} catch (error) {
			console.error('Error al cargar los datos: ', error);
		}
	};

    const fetchCreateMinistracionAvanceArchivos = async (data) => {
		try {
			const response = await createMinistracionAvanceArchivos(data, token);
			if(response.message === 'success') {
				alert("", "Documentos enviados correctamente.", "success");
			}
		} catch (error) {
			console.error('Error al cargar los datos', error);
		}
	};

    const fetchMinistracionesPorcentajeObraByPaqueteId = async (paquete_id, num_ministracion) => {
		try {
			const response = await getMinistracionesPorcentajeObraByPaqueteId(paquete_id, num_ministracion, token);
            if(response.message == "100.00") {
                alert("Ministraciones completadas", "", "success");          
                setShowEliminarMinistracion(true);
            } 
		} catch (error) {
			console.error('Error al cargar los datos', error);
		}
	}

    const fetchMinistracionesPorcentajeObraNumeroByPaqueteId = async (paquete_id, num_ministracion, type) => {
		try {
			const response = await getMinistracionesPorcentajeObraNumeroByPaqueteId(paquete_id, num_ministracion, token);
            setPorcentaje(response.message);
            if(type == 2) {
                if(response.message !== "100.00") {
                    setDisabledUploadPorcentajes(false);
                    setDisabledAplicarComi(false);
                }
            }
		} catch (error) {
			console.error('Error al cargar los datos', error);
		}
	}

    const fetchMinistracionAvanceTecnicoByNumero = async (paquete_id, num_ministracion) => {
		try {
			const response = await getMinistracionAvanceTecnicoByNumero(paquete_id, num_ministracion, token);
			if(response.message === 'no found') setDocumento("Sin Documento");
			else setDocumento(response.message);
		} catch (error) {
			console.error('Error al cargar los datos', error);
		}
	}

    const fetchDerechohabienteValorPaqueteByPaqueteId = async (paquete_id) => {
		try {
			const response = await getDerechohabienteValorPaqueteByPaqueteId(paquete_id, token);
            setValorPaquete(response.message.toString())
		} catch (error) {
			console.error('Error al cargar los datos', error);
		}
	}

    const fetchMinistracionesComisionStatusByPaqueteId = async (paquete_id, num_ministracion) => {
		try {
			const response = await getMinistracionesComisionStatusByPaqueteId(paquete_id, num_ministracion, token);
			setStatusComision(response.message);
            setDisabledAplicarComi(true);
            if(response.message == 0) setCheckAplicarComi(false);
            else setCheckAplicarComi(true);
		} catch (error) {
			console.error('Error al cargar los datos', error);
		}
	}

    const fetchMinistracionAvancesArchivosByPaqueteId = async (paquete_id, num_ministracion) => {
		try {
			const response = await getMinistracionAvancesArchivosByPaqueteId(paquete_id, num_ministracion, token);
			if(response.message !== 'no found') {
				actionSendReport(response.message)
			}
		} catch (error) {
			console.error('Error al cargar los datos', error);
		}
	};

    const fetchUpdateMinistracionAvanceTecnicoByNumero = async (num_ministracion, paquete_id, data) => {
		try {
			const response = await updateMinistracionAvanceTecnicoByNumero(num_ministracion, paquete_id, data, token);
			fetchMinistracionAvanceTecnicoByNumero(paquete_id, num_ministracion);
		} catch (error) {
			console.error('Error al cargar los datos', error);
		}
	}

    const fetchCreateMinistracionSinComision = async (data) => {
        try {
            const response = await createMinistracionSinComision(data, token);
            if(response.message === 'success') {
                recargarSelectMinistraciones();
                alert("", "Notificacion enviada.", "success");
            }
			
		} catch (error) {
			console.error('Error al cargar los datos', error);
		}
	};

    const fetchCreateMinistracionConComision = async (data) => {
        try {
            const response = await createMinistracionConComision(data, token);
            if(response.message === 'success') {
                recargarSelectMinistraciones();
                alert("", "Notificacion enviada.", "success");
            }
			
		} catch (error) {
			console.error('Error al cargar los datos', error);
		}
	};


    const fetchCreateDerechohabientesAvancesPorcentajesFile = async (data) => {
		try {
			const response = await createDerechohabientesAvancesPorcentajesFile(data, token);
            if(response.message.length > 0) {
                setAvanceObra(response.message);
                setDisabledAutorizarMinistracion(false);
                let ministracion_neta = 0.0;
                let avances_totales = 0.0;
                response.message.map(avance => {
                    ministracion_neta += parseFloat(avance.ministracion_neta);
                    avances_totales += parseFloat(avance.avance);
                });

                setMinistracionNeta(ministracion_neta.toString());
                setPorcentaje((avances_totales / response.message.length).toFixed(2) + "%");
            } else { 
                alert(
                    "Verifique su documento",
                    "No se encontraron registros coincidentes",
                    "warning"
                );
                setDisabledAutorizarMinistracion(true);
            }
		} catch (error) {
			console.error('Error al cargar los datos', error);
		}
	};

    const fetchCreateDerechohabientesAvancesPorcentajes = async (data) => {
        
		try {
            const response = await createDerechohabientesAvancesPorcentajes(data, token);
            if(response.message) {
                successNotification("Ministraciones creadas");
                fetchMinistracionListaNumeroByPaqueteId(sharedPaqueteId, sharedMinistracionId + 1);
                setSharedMinistracionId(sharedMinistracionId + 1);

                if(!checkedAplicarComi) {
                    let data = {
                        proyecto_id: sharedProyectoId,
                        paquete_id: sharedPaqueteId,
                        num_ministracion: sharedMinistracionId
                    }
                    fetchCreateMinistracionSinComision(data);
                } else {
                    let data = {
                        proyecto_id: sharedProyectoId,
                        paquete_id: sharedPaqueteId,
                        num_ministracion: sharedMinistracionId,
                        comisionadmonfondos : response.message.total_comision_mercantil,
                        comisionmercantil : response.message.total_comision_admon,
                        comisionministracion : response.message.total_comision_mercantil_admin
    
                    }
                    fetchCreateMinistracionConComision(data);
                }
            }
		} catch (error) {
			console.error('Error al cargar los datos', error);
		}
	};

    const fetchDeleteMinistracionByPaqueteId = async (paquete_id) => {
		try {
			const response = await deleteMinistracionByPaqueteId(paquete_id, token);
            if(response.message == 'success') {
                successNotification("La Ministración se eliminó correctamente.");
                setSharedMinistracionId(0);
                setPorcentaje("0.00");
                recargarSelectMinistraciones();
            } else if(response.message == 'failed') 
                errorNotification("¡Ocurrio un error en el servidor! Intente nuevamente.");
            else 
                alert("No existen Ministraciones.", "", "error");
		} catch (error) {
			console.error('Error al cargar los datos', error);
		}
	}

    const actionEliminarMinistracion = () => {
        Swal.fire({
			title: "¿Está seguro de eliminar la ministración?",
            text: "",
            icon: "warning",
			confirmButtonText: 'Continuar',
			cancelButtonText: 'Cancelar',
			showCancelButton: true,
			showCloseButton: true,
			allowOutsideClick: false,
			allowEscapeKey: false,
			allowEnterKey: false,
			reverseButtons : true
		}).then((result) => {
			if (result.isConfirmed) 
                confirmEliminarMinistracion(sharedPaqueteId);
		}).catch(() => {
			return false;
		});
    }

    const confirmEliminarMinistracion = (paquete_id) => {
		Swal.fire({
			title: "¿Estas seguro?",
			text: "Esta operacion no se puede deshacer.",
			icon: "warning",
			confirmButtonText: 'Eliminar',
			cancelButtonText: 'Cancelar',
			showCancelButton: true,
			showCloseButton: true,
			allowOutsideClick: false,
			allowEscapeKey: false,
			allowEnterKey: false,
			reverseButtons : true
		}).then((result) => {
			if (result.isConfirmed) 
				fetchDeleteMinistracionByPaqueteId(paquete_id);
		}).catch(() => {
			return false;
		})
	}

    const actionSendReport = (data) => {
		if((data.avance_admin != null) && (data.avance_admin != "")){
			if((data.avance_tecnico != null) && (data.avance_tecnico != '')) {
				if((data.nom_reporte_global != null) && (data.nom_reporte_global != '')) {
                    if((data.reporte_global != null) && (data.reporte_global != '') ) {
                        fetchCreateMinistracionAvanceArchivos(data);
                    } else{
                        alert("", "Se necesita el documento de Reporte Global", "warning");
                    }
                } else {
                    alert("", "Se necesita el documento de NOM Reporte Global", "warning");
                }
			} else{
				alert("", "Se necesita el documento avance tecnico", "warning");
			}
		} else{
			alert("", "Necesita adjuntar el documento de avance administración", "warning");
		}
	}

    const handleEliminarMinistracion = () => {
        if (paquete.estatus_paquete == 0) alert("No existen Ministraciones.", "", "error");
        else actionEliminarMinistracion();
    }

    const handledEviarReporte = () => {
        fetchMinistracionAvancesArchivosByPaqueteId(sharedPaqueteId, sharedMinistracionId)
    }

    const handledAutorizar = () => {
        actionAutorizarMinistracion();
    }

    const actionAutorizarMinistracion = () => {
        Swal.fire({
			title: "¿Está seguro de autorizar la ministración?",
            text: "Una vez autorizada ya no podrá subir un nuevo archivo",
            icon: "warning",
			confirmButtonText: 'Continuar',
			cancelButtonText: 'Cancelar',
			showCancelButton: true,
			showCloseButton: true,
			allowOutsideClick: false,
			allowEscapeKey: false,
			allowEnterKey: false,
			reverseButtons : true
		}).then((result) => {
			if (result.isConfirmed) {
                fetchCreateDerechohabientesAvancesPorcentajes(dataFile);
            }
		}).catch(() => {
			return false;
		});
    }

    const handleSubmitFile = (paquete_id, data) => {
        if(sharedMinistracionId > 0)
            fetchUpdateMinistracionAvanceTecnicoByNumero(sharedMinistracionId, paquete_id, data);
	}

    const handleSubmitFileXLSX = (paquete_id, data) => {
        if(sharedMinistracionId > 0) {
            data.comision = checkedAplicarComi ? 1 : 0
            data.num_ministracion = sharedMinistracionId
            setDataFile(data);
            fetchCreateDerechohabientesAvancesPorcentajesFile(data);
        }
	}

    const handleCheckboxChange = () => {  
        setCheckAplicarComi(!checkedAplicarComi);
        if( !checkedAplicarComi ){
			alert(
                "Asegurese de seleccionar el archivo de porcentajes",
                "Una vez seleccionado el archivo, presione el botón 'Subir'",
                "info"
            );
	    } 
    };

    return(
        <div className="row justify-content-center">
            <hr className="mb-4" />
            <div className="col-xl-11">
                <div className="col-xl-12">
                    <div className="list-group-item d-flex align-items-center" style={{marginBottom: '1em', marginTop: '-0.5em'}}>
                        {
                            showEliminarMinistracion ? 
                                <div className="ml-auto" >
                                    <button className="btn btn-outline-danger" onClick={handleEliminarMinistracion} >Eliminar última ministración</button>
                                </div> : <></>
                        }
                        <div className="flex-1 text-break">
                        </div>
                        <div className="ml-auto" style={{marginInlineEnd: "1em"}}>
                            <button className="btn btn-outline-info" disabled={disabledAutorizarMinistracion} onClick={handledAutorizar}>Autorizar Ministracion</button>
                        </div>
                        <div className="ml-auto">
                            <button className="btn btn-outline-success" disabled={disabledEnviarReporte} onClick={handledEviarReporte}>Enviar Reporte</button>
                        </div>
                    </div>
                </div>
                <div className="col-xl-12">
                    <div id="formControls" className="mb-5">  
                        <Card>
                            <CardHeader className="fw-bold small d-flex">
                                <span className="flex-grow-1">Supervisión de Obras</span>
                            </CardHeader>
                            <CardBody>
                                <div className='col-xl-12'>
                                    <div className='row'>
                                        <div className='col-4'>
                                            <SelectProyectos setSharedProyectoId={setSharedProyectoId} setSharedEtapaId={setSharedEtapaId} />
                                        </div>
                                        <div className='col-4'>
                                            <SelectEtapas  proyecto_id={sharedProyectoId} setSharedEtapaId={setSharedEtapaId} setSharedPaqueteId={setSharedPaqueteId} />
                                        </div>
                                        <div className='col-4'>
                                            <SelectPaquetes etapa_id={sharedEtapaId} setSharedPaqueteId={setSharedPaqueteId}  />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-4'>
                                        <SelectMinistraciones 
                                            key={reloadKey}
                                            paquete_id={sharedPaqueteId} 
                                            setSharedMinistracionId={setSharedMinistracionId} />
                                    </div>
                                    <div className='col-4'>
                                        <div className="form-group mb-3" style={{"marginTop": "30px"}}>
                                            <label>
                                                <input type="checkbox" id="aplicarcomi" name="aplicarcomi" value="activo" disabled={disabledAplicarComi} checked={checkedAplicarComi} onChange={handleCheckboxChange} /> Aplicar comisión
                                            </label>
                                            <br/>
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-4'>
                                        <UploadFileXLSX type={3} paquete_id={sharedPaqueteId} onSubmitFile={handleSubmitFileXLSX} disabled={disabledUploadPorcentajes} />
                                    </div>
                                    <div className='col-4'>
                                        <UploadFile type={3} paquete_id={sharedPaqueteId} onSubmitFile={handleSubmitFile} disabled={disabledUploadFile} />
                                    </div>
                                    <div className='col-4'>
                                        <div className='row justify-content-center'>
                                            <div className='col-12' >
                                                <div className="form-group mb-3">
                                                    <label className="form-label" htmlFor="etapas" ><strong>Documento</strong></label>
                                                    <Documento documento={documento} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr className="mb-4" />
                                <div className='row'>
                                    <TablaAvanceObra avance_obra={avanceObra} status_comision={statusComision}  />
                                </div>
                            </CardBody>
                            <CardFooter>
                                <CalcularDatos ministracion_neta={ministracionNeta} valor_paquete={valorPaquete} valor_actual={ministracionValor.valor_actual} porcentaje={porcentaje} />
                            </CardFooter>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ObraTecnico;

