import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import SelectEtapas from '../../../../../components/select/etapas';
import SelectPaquetes from '../../../../../components/select/paquetes';
import UploadFile from '../../../../../components/upload/upload_file';
import SelectProyectos from '../../../../../components/select/proyectos';

import { getPaqueteDocumentoGarantiaAdminById, updatePaqueteDocumentoGarantiaAdminById } from '../../../../../api/paquete_proyectos/paquete_proyectos';
import Documento from '../../../../../components/upload/documento';



function GarantiaAdministracion() {
    const token = useSelector(state => state.authentication.user?.token);

	const [sharedProyectoId, setSharedProyectoId] = useState(0);
	const [sharedEtapaId, setSharedEtapaId] = useState(0);
	const [sharedPaqueteId, setSharedPaqueteId] = useState(0);

	const [showUploadFile, setShowUploadFile] = useState(false);
	const [documento, setDocumento] =  useState("");
	
	useEffect(() => {
		if(sharedProyectoId == 0) {
			setSharedEtapaId(0);
			setSharedPaqueteId(0);
		}
	}, [sharedProyectoId])

	useEffect(() => {
		if(sharedPaqueteId > 0) {
			setShowUploadFile(true);
			fetchPaqueteDocumentoGarantiaAdminById(sharedPaqueteId);
		}
	}, [sharedPaqueteId])

	
	const fetchPaqueteDocumentoGarantiaAdminById = async (paquete_id) => {
		try {
			const response = await getPaqueteDocumentoGarantiaAdminById(paquete_id, token);
			if(response.message === 'no found') {
				setDocumento("Sin Documento");
			} else {
				setDocumento(response.message);
			}
		} catch (error) {
			console.error('Error al cargar los datos', error);
		}
	};


	const fetchUpaqueteDocumentoGarantiaAdminById = async (paquete_id, data) => {
		try {
			const response = await updatePaqueteDocumentoGarantiaAdminById(paquete_id, data, token);
            if(response.message === 'success') {
				fetchPaqueteDocumentoGarantiaAdminById(paquete_id);
			}
		} catch (error) {
			console.error('Error al cargar los datos', error);
		}
	};

	const handleSubmitFile = (paquete_id, data) => {
		fetchUpaqueteDocumentoGarantiaAdminById(paquete_id, data);        
	}
	
	return (
		
			<div className="row justify-content-center">
                <hr className="mb-4" />
				<div className="col-xl-10">
					<div className="col-xl-12">
						<div className='row'>
							<div className='col-4'>
								<SelectProyectos setSharedProyectoId={setSharedProyectoId} setSharedEtapaId={setSharedEtapaId} />
							</div>
							<div className='col-4'>
								<SelectEtapas  proyecto_id={sharedProyectoId} setSharedEtapaId={setSharedEtapaId} setSharedPaqueteId={setSharedPaqueteId} />
							</div>
							<div className='col-4'>
								<SelectPaquetes etapa_id={sharedEtapaId} setSharedPaqueteId={setSharedPaqueteId}  />
							</div>
						</div>	
					</div>
					<br />
					<br />
					<div className="col-xl-8 offset-md-3">
						{
							showUploadFile ? (
								<UploadFile type={1} paquete_id={sharedPaqueteId} onSubmitFile={handleSubmitFile} />
							) : <></>
						}
					</div>
					<br/>
					<div className='col-xl-12 text-center'>
						{
							showUploadFile ? (
								<Documento documento={documento} />
							) : <></>
						}
					</div>
					<hr/>
				</div>
			</div>
	)
}


export default GarantiaAdministracion;
