import React,  { useEffect, useRef }from 'react';

import { formatoMoneda } from "../../../../../../helpers";
import jsZip from 'jszip';

const $ = require('jquery');

window.JSZip = jsZip;

function TablaAvanceObra({ avance_obra }) {

    const datatableObraRef = useRef(null);

    useEffect(() => {
		if (datatableObraRef.current !== null) {
			let index = 1;
			datatableObraRef.current.clear();
			datatableObraRef.current.rows.add(avance_obra.map(avance => [index++, 
																		avance.acreditado, 
																		avance.avance, 
																		avance.avance_parcial, 
																		avance.ministracion,
																		avance.retencion,
                                                                        avance.comision,
																		avance.ministracion_neta]));
	  		datatableObraRef.current.draw();
		} else 
			initDatatable();
		// eslint-disable-next-line
	}, [avance_obra]);

    const initDatatable = () => {
		datatableObraRef.current = $('#tablaAvanceObra').DataTable({
			dom: "<'row mb-3'<'col-md-4 mb-3 mb-md-0'l><'col-md-8 text-end'<'d-lg-flex justify-content-end'f<'d-lg-block d-none'B>>>>t<'row align-items-center'<'mr-auto col-md-6 mb-3 mb-md-0 mt-n2 'i><'mb-0 col-md-6'p>>",
			lengthMenu: [10, 20, 30, 40, 50],
			buttons: [
				{ 
					extend: 'excelHtml5', 
					className: 'btn btn-outline-default btn-sm',
					filename: function() {
						var timestamp = new Date().getTime(); 
						return 'supervision_obra_' + timestamp; 
					},
					exportOptions: {
						columns: ':visible',
						modifier: {
							page: 'none' // Evita agregar información de paginación
						}
					},
					customizeData: function ( data ) {
						for (var i=0; i < data.body.length; i++){
							for (var j=0; j<data.body[i].length; j++ ){
								data.body[i][j] = '\u200C' + data.body[i][j];
							}
						}
					}
				}
			],
			columns: [
				{ title: "#" },
				{ title: "Acreditado"},
				{ 
                    title: "% Avance",
                    width: "150px"
                },
				{ 
                    title: "Avance Parcial",
                    width: "150px"
                },
				{ 
                    title: "Ministración",
                    width: "150px",
                    render: function (data, type, row, meta) {
                        let number = row[4]?.replace(/,/g, '') || 0;
                        return `<div style="white-space: nowrap;">${formatoMoneda.format(parseFloat(number))}</div>`;
                    },
                }, 
                { 
                    title: "Retención",
                    width: "150px",
                    render: function (data, type, row, meta) {
                        let number = row[5]?.replace(/,/g, '') || 0;
                        return `<div style="white-space: nowrap;">${formatoMoneda.format(parseFloat(number))}</div>`;
                    },
                }, 
                { 
                    title: "Comisión Mercantil",
                    width: "150px",
                    render: function (data, type, row, meta) {
                        let number = row[6]?.replace(/,/g, '') || 0;
                        return `<div style="white-space: nowrap;">${formatoMoneda.format(parseFloat(number))}</div>`;
                    },
                },
                { 
                    title: "Ministración Neta",
                    width: "150px",
                    render: function (data, type, row, meta) {
                        let number = row[7]?.replace(/,/g, '') || 0;
                        return `<div style="white-space: nowrap;">${formatoMoneda.format(parseFloat(number))}</div>`;
                    },
                }, 
                
			],
            columnDefs: [{
                targets: [6], 
                visible: false
            }]
		});
	}

    return (
        <div className='col-12 tabla-scrollable table-responsive'>
            <table id="tablaAvanceObra" className="table table-striped text-nowrap w-100">
                <thead></thead>
                <tbody></tbody>
            </table>
        </div>
    )
}

export default TablaAvanceObra;